import { ListItemIcon, Menu, MenuItem, Typography } from "@material-ui/core";
import { css, jsx } from "@emotion/core";

import { Announcement } from "@material-ui/icons";
import InfoIcon from "@material-ui/icons/Info";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import React from "react";

/** @jsx jsx */

const AppMenu: React.FC<any> = ({
  app,
  handleClose,
  anchorEl,
  setAppDisplayAboutInfo,
  setAppDisabledDisplayError,
  setStartOnboarding,
  appIsDisabled,
  useEnableIntegration,
  useEnableProcessHtml,
  appOnboardingIsAvailable,
}) => {
  return (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      {appIsDisabled(app) && appOnboardingIsAvailable(app) && useEnableIntegration && (
        <MenuItem
          onClick={() => {
            setStartOnboarding(true);
            handleClose();
          }}
        >
          <ListItemIcon
            css={css`
              min-width: 30px;
            `}
          >
            <PowerSettingsNewIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Ativar</Typography>
        </MenuItem>
      )}

      <MenuItem
        onClick={() => {
          setAppDisplayAboutInfo(true);
          handleClose();
        }}
      >
        <ListItemIcon
          css={css`
            min-width: 30px;
          `}
        >
          <InfoIcon fontSize="small" />
        </ListItemIcon>
        <Typography variant="inherit">Sobre {app.name}</Typography>
      </MenuItem>


      {appIsDisabled(app) && useEnableProcessHtml && (
        <MenuItem
          onClick={() => {
            setAppDisabledDisplayError(true);
            handleClose();
          }}
        >
          <ListItemIcon
            css={css`
              min-width: 30px;
            `}
          >
            <Announcement fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Como solicitar acesso</Typography>
        </MenuItem>
      )}
    </Menu>
  );
};

export default AppMenu;
