import "moment/locale/pt-br";

import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React, { Fragment, useEffect, useState } from "react";
import { css, jsx } from "@emotion/core";

import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import Header from "app/components/Header/Header.container";
import MomentUtils from "@date-io/moment";
import SearchIcon from "@material-ui/icons/Search";
import TransactionsIssuer from "./components/TransactionsIssuer";
import TransactionsIssuerDetails from "./components/TransactionsIssuerDetails";
import { getIntegrationTypeFlags } from "../../components/getAppTypeFlags";
import moment from "moment";
import { screenPropsType } from "./ReportMatching.type";

/** @jsx jsx */

const Screen: React.FC<screenPropsType> = ({
  history,
  getTransactions,
  getListTransactions,
  getIssuers,
  reportsState,
  transactionsExtState,
  issuerState,
  getApps,
  applications,
  activeMerchant,
  availableMerchants,
}) => {
  const { loading: loadingTransactions, transactionsDetails } =
    reportsState || {};
  const {
    loading: loadingExtTransactions,
    transactions: transactionsExtList,
  } = transactionsExtState;
  const { loading: loadingIssuers, issuers } = issuerState || {};

  const [selectedMerchants, setSelectedMerchants] = useState<any>([]);
  const handleMerchantChange = (event: React.ChangeEvent<{ value: any }>) => {
    const value = event.target.value;
    if (value) setSelectedMerchants(value);
  };

  useEffect(() => {
    if (activeMerchant)
      setSelectedMerchants(
        availableMerchants.filter((m: any) => m.id === activeMerchant.id),
      );
  }, [activeMerchant]);

  const [transactionsExtGroup, setTransactionsExtGroup] = useState([]);
  // const [transactionsGroup, setTransactionsGroup] = useState([]);

  // Dates
  const [selectedDateBegin, setSelectedDateBegin] = useState(
    new Date(new Date()).setDate(new Date().getDate() - 1),
  );
  const [selectedDateEnd, setSelectedDateEnd] = useState(
    new Date().setDate(new Date().getDate() - 1),
  );

  const handleDateBeginChange = (date: any) => {
    if (!date)
      setSelectedDateBegin(
        new Date(new Date()).setDate(new Date().getDate() - 1),
      );
    else setSelectedDateBegin(date);
  };

  const handleDateEndChange = (date: any) => {
    if (!date) setSelectedDateEnd(new Date().setDate(new Date().getDate() - 1));
    else setSelectedDateEnd(date);
  };

  const [selectedIssuer, setSelectedIssuer] = useState<any>({
    identifier_code: "",
    name: "",
    id: "",
  });

  useEffect(() => {
    setSelectedIssuer(issuers[0]);
  }, [issuers]);

  const handleChangeIssuer = (event: React.ChangeEvent<{ value: any }>) => {
    const value = event.target.value;
    if (value) {
      setSelectedIssuer(value);
      setSelectedApp({
        name: "",
        id: "",
      });
    }
  };

  const [selectedApp, setSelectedApp] = useState<any>({
    name: "",
    id: "",
  });
  useEffect(() => {
    if (selectedIssuer && selectedIssuer.identifier_code.length > 0)
      getApps({
        issuer_id: selectedIssuer.id,
      });
  }, [selectedIssuer]);

  useEffect(() => {
    setSelectedApp(applications[0]);
  }, [applications]);

  const handleChangeApp = (event: React.ChangeEvent<{ value: any }>) => {
    const value = event.target.value;
    if (value) setSelectedApp(value);
  };

  //View mode
  const [viewModeAsResume, setViewModeAsResume] = useState(true);

  // Search Ext Transactions
  const handleGetExtTransactions = () => {
    getListTransactions({
      issuer_id: selectedIssuer?.id,
      application_id: selectedApp?.id,
      dateBegin: selectedDateBegin,
      dateEnd: selectedDateEnd,
      merchant_ids: selectedMerchants.map((it: any) => it.id),
    });
  };

  let isPinbankApp = false;
  let isAmeApp = false;
  let isPayInsightsApp = false;
  useEffect(() => {
    if (selectedIssuer?.id) handleGetExtTransactions();
  }, [selectedIssuer, selectedApp]);
  // useEffect(() => {
  //   if (
  //     selectedIssuer &&
  //     selectedIssuer.identifier_code.length > 0 &&
  //     selectedApp &&
  //     selectedApp.id.length > 0
  //   )
  //     handleGetExtTransactions();
  //   const opts = getIntegrationTypeFlags(selectedIssuer?.identifier_code);
  //   isPinbankApp = opts.isPinbankApp;
  //   isAmeApp = opts.isAmeApp;
  //   isPayInsightsApp = opts.isPayInsightsApp;
  // }, [selectedIssuer, selectedApp]);

  useEffect(() => {
    const opts = getIntegrationTypeFlags(selectedIssuer?.identifier_code);
    isPinbankApp = opts.isPinbankApp;
    isAmeApp = opts.isAmeApp;
    isPayInsightsApp = opts.isPayInsightsApp;

    var groupedData: any = Object.values(
      transactionsExtList.reduce((r: any, e: any) => {
        var key = "";
        var value = 0;
        let date = "";

        let dateKey = "date",
          valueKey = "amount";
        if (isPinbankApp) {
          dateKey = "DataCaptura";
          valueKey = "ValorTransacao";
        }
        if (isPayInsightsApp) {
          dateKey = "Dt_movimentacao";
          valueKey = "VLR_movimentado";
        }
        let valueGroup = e[valueKey] || 0;
        if (isAmeApp) {
          dateKey = "date";
          valueKey = "amount";
          valueGroup = Number(e[valueKey] / 100);
        }
        date = moment(e[dateKey]).format("YYYY-MM-DD");
        key = `${date}-${e.merchant.id}`;
        value = Number(valueGroup);
        if (!r[key])
          r[key] = {
            date,
            value: value,
            total: 1,
            merchant: e.merchant,
          };
        else {
          r[key].total++;
          r[key].value += value;
        }
        return r;
      }, {}),
    );
    setTransactionsExtGroup(groupedData);
  }, [transactionsExtState]);

  // Search Qrway Transactions
  const handleGetTransactions = () => {
    // getTransactions({
    //   dateBegin: moment(new Date(selectedDateBegin)).format(
    //     "YYYY-MM-DD 00:00:01",
    //   ),
    //   dateEnd: moment(new Date(selectedDateEnd)).format("YYYY-MM-DD 23:59:59"),
    //   grouped: false,
    //   offset: 0,
    //   limit: 50,
    //   onlyConfirmed: true,
    //   issuer_id: selectedIssuer.id,
    // });
  };

  useEffect(() => {
    if (selectedIssuer && selectedIssuer.id.length > 0) handleGetTransactions();
  }, [selectedIssuer]);

  // useEffect(() => {
  //   if (transactionsDetails)
  //     var groupedData: any = Object.values(
  //       transactionsDetails.reduce((r: any, e: any) => {
  //         var key = moment(e.transaction_date).format("DD/MM/YYYY");
  //         if (!r[key])
  //           r[key] = {
  //             transaction_date: moment(e.transaction_date).format("DD/MM/YYYY"),
  //             transaction_amount: Number(e.transaction_amount),
  //             total: 1,
  //           };
  //         else {
  //           r[key].total++;
  //           r[key].transaction_amount += Number(e.transaction_amount);
  //         }
  //         return r;
  //       }, {}),
  //     );

  //   setTransactionsGroup(groupedData);
  // }, [reportsState]);

  const isValid = () => {
    return (
      moment(selectedDateBegin).isValid() &&
      moment(selectedDateEnd).isValid() &&
      selectedIssuer
    );
  };

  // issuers
  useEffect(() => {
    getIssuers({});
  }, []);

  return (
    <Fragment>
      <Header
        authentication={null}
        loading={
          loadingTransactions || loadingExtTransactions || loadingIssuers
        }
      />
      <Grid
        container
        spacing={2}
        justify="center"
        alignItems="center"
        direction="row"
      >
        <Grid item xs={8} lg={12}>
          <GridList>
            <GridListTile key="Subheader" cols={2} style={{ height: "auto" }}>
              <Grid
                container
                justify="flex-end"
                css={css`
                  && {
                    margin-top: 24px;
                  }
                `}
              ></Grid>
              <Typography
                variant="h6"
                align={"center"}
                css={css`
                  margin-bottom: 35px;
                `}
              >
                Extrato de Conciliação
              </Typography>
            </GridListTile>
          </GridList>

          {/* Filters */}

          <Grid container justify="space-evenly">
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Grid item>
                <KeyboardDatePicker
                  size={"small"}
                  label="Data início"
                  cancelLabel="Cancelar"
                  value={selectedDateBegin}
                  format="DD/MM/YYYY"
                  onChange={handleDateBeginChange}
                  maxDate={selectedDateEnd}
                  maxDateMessage="Data deve ser menor ou igual a data final"
                  invalidDateMessage="Data inválida"
                  disableFuture
                  css={{
                    maxWidth: 150,
                  }}
                />
              </Grid>
              <Grid item>
                <KeyboardDatePicker
                  size={"small"}
                  label="Data final"
                  cancelLabel="Cancelar"
                  value={selectedDateEnd}
                  format="DD/MM/YYYY"
                  onChange={handleDateEndChange}
                  minDate={selectedDateBegin}
                  minDateMessage="Data deve ser maior ou igual a data inicial"
                  invalidDateMessage="Data inválida"
                  disableFuture
                  css={{
                    maxWidth: 150,
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
            <Grid item>
              <FormControl
                css={css`
                  && {
                    min-width: 150px;
                  }
                `}
              >
                <InputLabel>Emissor</InputLabel>
                <Select onChange={handleChangeIssuer} value={selectedIssuer}>
                  {issuers.map((row: any, index: any) => (
                    <MenuItem key={index} value={row}>
                      {row.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl
                css={css`
                  && {
                    min-width: 150px;
                  }
                `}
              >
                <InputLabel>Aplicativo</InputLabel>
                <Select onChange={handleChangeApp} value={selectedApp}>
                  {applications.map((row: any, index: any) => (
                    <MenuItem key={index} value={row}>
                      {row.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl
                css={css`
                  && {
                    min-width: 150px;
                  }
                `}
              >
                <InputLabel>Estabelecimento</InputLabel>
                <Select
                  onChange={handleMerchantChange}
                  value={selectedMerchants}
                  renderValue={(selected: any) =>
                    selected.map((it: any) => it.name).join(", ")
                  }
                  multiple
                  css={{
                    maxWidth: 350,
                  }}
                >
                  {availableMerchants.map((row: any, index: any) => (
                    <MenuItem key={row.id} value={row}>
                      <Checkbox
                        checked={
                          selectedMerchants.filter(
                            (it: any) => it.name === row.name,
                          )?.length > 0
                        }
                      />
                      {row.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <Button
                onClick={() => {
                  handleGetTransactions();
                  handleGetExtTransactions();
                }}
                fullWidth
                color="secondary"
                startIcon={<SearchIcon />}
                disabled={!isValid()}
                css={css`
                  && {
                    margin-top: 10px;
                    font-weight: bold;
                  }
                `}
              >
                PESQUISAR
              </Button>
            </Grid>
          </Grid>
          <Grid
            item
            css={css`
              margin-bottom: 15px;
            `}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={viewModeAsResume}
                  onChange={() => setViewModeAsResume(v => !v)}
                  color="secondary"
                />
              }
              label={"Resumido"}
            />
          </Grid>

          {/* Resumido */}
          {viewModeAsResume && (
            <Grid container spacing={2} direction="row">
              {/* Transações QrWay */}
              {/* <Transactions
                transactions={transactionsGroup}
                loading={loadingTransactions}
                filters={{
                  selectedDateBegin,
                  selectedDateEnd,
                }}
              /> */}

              {/* Transações Emissor */}
              <TransactionsIssuer
                transactions={transactionsExtGroup || []}
                loading={loadingExtTransactions || loadingIssuers}
                filters={{
                  selectedDateBegin,
                  selectedDateEnd,
                  selectedIssuer,
                }}
              />
            </Grid>
          )}

          {/* Detalhes */}
          {!viewModeAsResume && (
            <Grid container spacing={2} direction="row">
              {/* Transações QrWay */}
              {/* <TransactionsDetails
                transactions={transactionsDetails}
                loading={loadingTransactions}
                filters={{
                  selectedDateBegin,
                  selectedDateEnd,
                }} 
              />
              */}

              {/* Transações Emissor */}
              <TransactionsIssuerDetails
                transactions={transactionsExtList}
                loading={loadingExtTransactions || loadingIssuers}
                filters={{
                  selectedDateBegin,
                  selectedDateEnd,
                  selectedIssuer,
                }}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default Screen;
