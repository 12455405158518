import { API_URL_WITH_PORT, PORTS } from "shared/configs/api.config";

import { Dispatch } from "redux";
import fetch from "shared/utils/fetch.util";
import { stateReduxUsers } from "../screens/Users/Users.type";

type Actions = {
  type: string;
  payload?: object;
  error?: object;
};

export const Types = {
  INIT: "app/users/INIT",
  SUCCESS: "app/users/SUCCESS",
  ERROR: "app/users/ERROR",
  SUCCESS_UPDATE_USER: "app/users/SUCCESS_UPDATE_USER"
};

const initialState: stateReduxUsers = {
  users: [],
  user: null,
  loading: false,
  error: null
};

export default (state = initialState, action: Actions) => {
  const { type, payload, error } = action;

  const reducers = {
    [Types.INIT]: {
      ...state,
      error: null,
      loading: true
    },
    [Types.ERROR]: {
      ...state,
      loading: false,
      error
    },
    [Types.SUCCESS]: {
      loading: false,
      users: payload,
      error: false
    },
    [Types.SUCCESS_UPDATE_USER]: {
      loading: false,
      users: getUpdatedState(state.users, payload),
      error: false
    }
  };

  return reducers[type] || state;
};

const getUpdatedState = (users: any, payload: any) => {
  if (!payload?.id) return users;
  return users.map((user: any) =>
    user.id === payload.id ? { ...user, ...payload } : user
  );
};

export const handleGetUsers = () => async (dispatch: Dispatch<any>) => {
  try {
    dispatch({
      type: Types.INIT
    });

    const response = await fetch(
      `${API_URL_WITH_PORT(PORTS.LOGIN)}/accounts/users`,
      {
        customUrl: true,
        method: "GET",
        auth: true
      }
    );

    const result = await response.json();
    if (!response.ok) {
      throw result;
    }

    const { results } = result;
    dispatch({
      type: Types.SUCCESS,
      payload: results
    });

    return results;
  } catch (e) {
    const error = {
      visible: true,
      ...e
    };
    dispatch({
      type: Types.ERROR,
      error
    });
    return error;
  }
};

export const handleUpdateUser = (data: any) => async (
  dispatch: Dispatch<any>
) => {
  try {
    dispatch({
      type: Types.INIT
    });

    const response = await fetch(
      `${API_URL_WITH_PORT(PORTS.LOGIN)}/accounts/users/${data.id}`,
      {
        customUrl: true,
        method: "PATCH",
        auth: true,
        body: JSON.stringify({
          name: data.name,
          active: data.active
        })
      }
    );

    const result = await response.json();
    if (!response.ok) {
      throw result;
    }

    dispatch({
      type: Types.SUCCESS_UPDATE_USER,
      payload: data
    });

    return result;
  } catch (e) {
    const error = {
      visible: true,
      ...e
    };
    dispatch({
      type: Types.ERROR,
      error
    });
    return error;
  }
};

export const handleAddUser = (data: any) => async (dispatch: Dispatch<any>) => {
  try {
    dispatch({
      type: Types.INIT
    });

    const response = await fetch(
      `${API_URL_WITH_PORT(PORTS.LOGIN)}/accounts/users`,
      {
        customUrl: true,
        method: "POST",
        auth: true,
        body: JSON.stringify({
          ...data
        })
      }
    );

    const result = await response.json();
    if (!response.ok) {
      throw result;
    }

    dispatch(handleGetUsers());
    return result;
  } catch (e) {
    const error = {
      visible: true,
      ...e
    };
    dispatch({
      type: Types.ERROR,
      error
    });
    return error;
  }
};
