import { css, jsx } from "@emotion/core";

import { CircularProgress } from "@material-ui/core";
/** @jsx jsx */
import React from "react";

const ConfirmEmailScreen: React.FC<any> = () => {
  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <CircularProgress
        size={120}
        color="secondary"
        css={css`
          && {
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -12px;
            margin-left: -12px;
          }
        `}
      />
    </div>
  );
};

export default ConfirmEmailScreen;
