import React, { useCallback, useEffect } from "react";
import { confirmUser, setAuth } from "shared/ducks/authentication.duck";

import ConfirmEmail from "./ConfirmEmail.screen";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import useQuery from "shared/hooks/useQuery.hook";

const ConfirmEmailContainer: React.FC = () => {
  const dispatch = useDispatch();

  const query = useQuery();

  const history = useHistory();

  const callConfirm = useCallback(async () => {
    const code = query.get("code");

    const response = await dispatch(
      confirmUser({
        code: code
      })
    );

    if (!response) {
      return null;
    }

    await dispatch(setAuth(response));

    return history.push("/app");
  }, [dispatch, query, history]);

  useEffect(() => {
    callConfirm();
  }, [callConfirm]);

  return <ConfirmEmail />;
};

export default ConfirmEmailContainer;
