import { API_URL_WITH_PORT, PORTS } from "shared/configs/api.config";

import { Dispatch } from "redux";
import fetch from "shared/utils/fetch.util";
import { stateReduxTransactions } from "../screens/Transactions/Transactions.type";

type Actions = {
  type: string;
  payload?: object;
  error?: object;
};

export const Types = {
  INIT_STATE: "app/transactions/INIT_STATE",
  INIT: "app/transactions/INIT",
  SUCCESS: "app/transactions/SUCCESS",
  ERROR: "app/transactions/ERROR",
  SUCCESS_NEW_TRANSACTION: "app/transactions/SUCCESS_NEW_TRANSACTION",
  INIT_GET_TRANSACTIONS: "app/transactions/INIT_GET_TRANSACTIONS",
  SUCCESS_GET_TRANSACTIONS: "app/transactions/SUCCESS_GET_TRANSACTIONS",
  INIT_CHECK_PAYMENT: "app/transactions/INIT_CHECK_PAYMENT",
  SUCCESS_CHECK_PAYMENT: "app/transactions/SUCCESS_CHECK_PAYMENT",
  END_CHECK_PAYMENT: "app/transactions/END_CHECK_PAYMENT",
  SUCCESS_CANCEL: "app/transactions/SUCCESS_CANCEL",
  INIT_GET_TRANSACTION_ITEMS: "app/transactions/INIT_GET_TRANSACTION_ITEMS",
  SUCCESS_GET_TRANSACTION_ITEMS:
    "app/transactions/SUCCESS_GET_TRANSACTION_ITEMS",
  INIT_ISSUER_ACTION: "app/transactions/INIT_ISSUER_ACTION",
  SUCCESS_ISSUER_ACTION: "app/transactions/SUCCESS_ISSUER_ACTION",
  SUCCESS_RESEND: "app/transactions/SUCCESS_RESEND",
};

const initialState: stateReduxTransactions = {
  transactions: [],
  transactionItems: [],
  currentTransaction: null,
  paymentConfirmed: null,
  paymentStatus: null,
  qrCodeDynamic: null,
  loading: false,
  error: null,
  issuerActions: null,
};

export default (state = initialState, action: Actions) => {
  const { type, payload, error }: any = action;

  const reducers = {
    [Types.INIT_STATE]: {
      ...state,
      error: null,
      transactions: [],
      issuerActions: null,
      paymentConfirmed: null,
      paymentStatus: null,
      currentTransaction: null,
      qrCodeDynamic: null,
    },
    [Types.INIT]: {
      ...state,
      error: null,
      loading: true,
    },
    [Types.ERROR]: {
      ...state,
      paymentConfirmed: null,
      loading: false,
      transactions: [],
      error,
    },
    [Types.SUCCESS]: {
      ...state,
      qrCodeDynamic: payload,
      loading: false,
      error: false,
    },
    [Types.SUCCESS_NEW_TRANSACTION]: {
      ...state,
      currentTransaction: payload,
    },
    [Types.INIT_GET_TRANSACTIONS]: {
      ...state,
      transactions: [],
      error: null,
      loading: true,
    },
    [Types.SUCCESS_GET_TRANSACTIONS]: {
      ...state,
      paymentConfirmed: null,
      loading: false,
      transactions: payload,
      error: false,
    },
    [Types.INIT_CHECK_PAYMENT]: {
      ...state,
      error: null,
      paymentConfirmed: null,
      loading: true,
    },
    [Types.END_CHECK_PAYMENT]: {
      ...state,
      loading: false,
      paymentStatus: payload,
    },
    [Types.SUCCESS_CHECK_PAYMENT]: {
      ...state,
      loading: false,
      paymentConfirmed: payload,
      error: false,
    },
    [Types.SUCCESS_CANCEL]: {
      ...state,
      paymentConfirmed: null,
      qrCodeDynamic: null,
      loading: false,
      error: false,
    },

    [Types.INIT_GET_TRANSACTION_ITEMS]: {
      ...state,
      transactionItems: [],
      loading: true,
      error: false,
    },

    [Types.SUCCESS_GET_TRANSACTION_ITEMS]: {
      ...state,
      transactionItems: payload,
      loading: false,
      error: false,
    },

    [Types.INIT_ISSUER_ACTION]: {
      ...state,
      loading: true,
      error: false,
    },
    [Types.SUCCESS_ISSUER_ACTION]: {
      ...state,
      issuerActions: {
        ...(state?.issuerActions || {}),
        metadata: {
          ...(state?.issuerActions?.metadata || {}),
          ...(payload?.metadata || {}),
        },
      },
      loading: false,
      error: false,
    },

    [Types.SUCCESS_RESEND]: {
      ...state,
      loading: false,
      error: false,
    },
  };

  return reducers[type] || state;
};

export const newTransaction = ({ data, auth }: any) => async (
  dispatch: Dispatch<any>,
) => {
  try {
    dispatch({
      type: Types.INIT,
    });

    let payload: any = {
      merchant_id: auth.user.merchants[0].id,
      application_id: data.application.id,
      transaction_amount: Number(data.valor.replace(",", ".")),
      external_reference: data.external_reference,
      installments: data.installments,
      details: data.details,
    };
    if (data.application_root_id) {
      payload = {
        ...payload,
        application_root_id: data.application_root_id,
      };
    }

    const response = await fetch(
      `${API_URL_WITH_PORT(PORTS.TRANSACTION)}/transactions`,
      {
        customUrl: true,
        method: "POST",
        auth: true,
        body: JSON.stringify(payload),
      },
    );

    const result = await response.json();
    if (!response.ok) {
      throw result;
    }
    const { qrcode: qrcodeObj } = result;
    const { value: qrcode } = qrcodeObj;

    dispatch({
      type: Types.SUCCESS_NEW_TRANSACTION,
      payload: result,
    });

    dispatch({
      type: Types.SUCCESS,
      payload: qrcode,
    });

    return result;
  } catch (e) {
    console.log("🔥 e", e);

    dispatch({
      type: Types.ERROR,
      error: {
        visible: true,
        ...e,
      },
    });
    return false;
  }
};

export const handleListTransactions = ({
  issuer_id,
  merchant_id,
  application_id,
  dateBegin,
  dateEnd,
  meta,
  merchant_ids,
}: any) => async (dispatch: Dispatch<any>) => {
  try {
    dispatch({
      type: Types.INIT_GET_TRANSACTIONS,
    });

    let begin = "";
    let end = "";
    let queryParams = "";

    if (dateBegin && dateEnd) {
      begin = new Date(dateBegin).toISOString();
      end = new Date(dateEnd).toISOString();
    } else {
      begin = new Date().toISOString();
      end = begin;
    }
    if (meta) {
      queryParams += `&meta=${JSON.stringify(meta)}`;
    }
    if (merchant_ids?.length > 0) {
      queryParams += merchant_ids
        .map((it: any) => `&merchant_id=${it}`)
        .join("");
    }
    if (merchant_id) {
      queryParams += `&merchant_id=${merchant_id}`;
    }

    const response = await fetch(
      `${API_URL_WITH_PORT(
        PORTS.TRANSACTION,
      )}/transactions/${issuer_id}/entries?dateBegin=${begin}&dateEnd=${end}&application_id=${application_id}${queryParams}`,
      {
        customUrl: true,
        method: "GET",
        auth: true,
      },
    );
    const result = await response.json();
    if (!response.ok) {
      throw result;
    }
    const { results } = result;

    dispatch({
      type: Types.SUCCESS_GET_TRANSACTIONS,
      payload: results,
    });

    return results;
  } catch (e) {
    dispatch({
      type: Types.ERROR,
      error: null,
    });
    return false;
  }
};

export const initState = () => async (dispatch: Dispatch<any>) => {
  dispatch({
    type: Types.INIT_STATE,
  });
  return;
};

export const handleCheckPayment = ({
  currentTransaction,
  application,
  merchant,
}: any) => async (dispatch: Dispatch<any>) => {
  try {
    dispatch({
      type: Types.INIT_CHECK_PAYMENT,
    });
    const { id } = currentTransaction || {};
    const response = await fetch(
      `${API_URL_WITH_PORT(PORTS.TRANSACTION)}/transactions/${id}/status`,
      {
        customUrl: true,
        method: "GET",
        auth: true,
      },
    );
    const result = await response.json();
    if (!response.ok) {
      throw result;
    }
    const { authorization_date, confirmed } = result;
    if (!authorization_date) {
      dispatch({
        type: Types.END_CHECK_PAYMENT,
        payload: result,
      });
      return false;
    }

    if (confirmed) {
      dispatch({
        type: Types.SUCCESS_CHECK_PAYMENT,
        payload: result,
      });
    }

    return result;
  } catch (e) {
    dispatch({
      type: Types.END_CHECK_PAYMENT,
    });
    return false;
  }
};

export const cancelTransaction = ({ id }: any) => async (
  dispatch: Dispatch<any>,
) => {
  try {
    dispatch({
      type: Types.INIT,
    });
    const response = await fetch(
      `${API_URL_WITH_PORT(PORTS.TRANSACTION)}/transactions/${id}`,
      {
        customUrl: true,
        method: "DELETE",
        auth: true,
      },
    );
    const result = await response.json();
    // if (!response.ok) {
    //   throw result;
    // }
    dispatch({
      type: Types.SUCCESS_CANCEL,
    });
    return result;
  } catch (e) {
    dispatch({
      type: Types.ERROR,
      error: {
        visible: true,
        ...e,
      },
    });
    return false;
  }
};

export const requestReversalTransaction = (data: any) => async (
  dispatch: Dispatch<any>,
) => {
  try {
    const { id, reason } = data;
    dispatch({
      type: Types.INIT,
    });
    const response = await fetch(
      `${API_URL_WITH_PORT(PORTS.TRANSACTION)}/transactions/${id}/reversal`,
      {
        customUrl: true,
        method: "POST",
        auth: true,
        body: JSON.stringify({ reason }),
      },
    );
    const result = await response.json();
    if (!response.ok) {
      throw result;
    }
    dispatch({
      type: Types.SUCCESS_CANCEL,
    });
    return result;
  } catch (e) {
    dispatch({
      type: Types.ERROR,
      error: {
        visible: true,
        ...e,
      },
    });
    return false;
  }
};

export const getTransactionItems = ({
  issuer_id,
  merchant_id,
  application_id,
}: any) => async (dispatch: Dispatch<any>) => {
  try {
    dispatch({
      type: Types.INIT_GET_TRANSACTION_ITEMS,
    });

    const response = await fetch(
      `${API_URL_WITH_PORT(
        PORTS.TRANSACTION,
      )}/transactions/${issuer_id}/items?application_id=${application_id}&merchant_id=${merchant_id}`,
      {
        customUrl: true,
        method: "GET",
        auth: true,
      },
    );
    const result = await response.json();
    if (!response.ok) {
      throw result;
    }
    const { results } = result;

    dispatch({
      type: Types.SUCCESS_GET_TRANSACTION_ITEMS,
      payload: results,
    });

    return results;
  } catch (e) {
    dispatch({
      type: Types.ERROR,
      error: null,
    });
    return false;
  }
};

export const handleResendNotification = ({ currentTransaction }: any) => async (
  dispatch: Dispatch<any>,
) => {
  try {
    dispatch({
      type: Types.INIT,
    });

    const { id } = currentTransaction || {};
    const response = await fetch(
      `${API_URL_WITH_PORT(
        PORTS.TRANSACTION,
      )}/transactions/${id}/resend-notification`,
      {
        customUrl: true,
        method: "POST",
        auth: true,
      },
    );
    const result = await response.json();

    dispatch({
      type: Types.SUCCESS_RESEND,
    });

    return result;
  } catch (e) {
    dispatch({
      type: Types.ERROR,
      error: null,
    });
    return false;
  }
};

export const executeIssuerAction = ({
  payload,
  merchant_id,
  application_id,
}: any) => async (dispatch: Dispatch<any>) => {
  try {
    dispatch({
      type: Types.INIT_ISSUER_ACTION,
    });

    const response = await fetch(
      `${API_URL_WITH_PORT(PORTS.TRANSACTION)}/transactions/issuer-action`,
      {
        customUrl: true,
        method: "POST",
        auth: true,
        body: JSON.stringify({
          application_id: application_id,
          merchant_id: merchant_id,
          ...payload,
        }),
      },
    );
    const result = await response.json();
    if (!response.ok) {
      throw result;
    }

    dispatch({
      type: Types.SUCCESS_ISSUER_ACTION,
      payload: result,
    });

    return result;
  } catch (e) {
    dispatch({
      type: Types.ERROR,
      error: null,
    });
    return false;
  }
};
