import {
  Box,
  Button,
  Card,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { css, jsx } from "@emotion/core";
import {
  currencyFormatterComp,
  formatterToCurrency,
} from "shared/utils/formatter.util";

import AppsTwoToneIcon from "@material-ui/icons/AppsTwoTone";
import AttendantReferenceField from "app/components/Fields/AttendantReferenceField";
import ExternalReferenceField from "app/components/Fields/ExternalReferenceField";
import ForwardPaymentField from "app/components/Fields/ForwardPaymentField";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import Header from "app/components/Header/Header.container";
import LastTransactions from "./components/LastTransactions";
import NumberFormat from "react-number-format";
import PaymentFailed from "app/components/Transaction/PaymentFailed";
import PaymentInfo from "app/components/Transaction/PaymentInfo";
import TicketFields from "app/components/Fields/TicketFields";
import TransactionError from "app/components/Transaction/TransactionError";
import TransactionPending from "./components/TransactionPending";
import TransactionTotalValue from "app/components/TransactionTotalValue";
import UsePayFields from "app/components/Fields/UsePayFields";
import { getIntegrationTypeFlags } from "../../components/getAppTypeFlags";
import { screenPropsType } from "./Transactions.type";
import { useDispatch } from "react-redux";

/** @jsx jsx */

const QRCode = require("qrcode.react");

enum StepFormEnum {
  TicketLogDetails,
  UsePayDetails,
  Final,
}

const TransactionScreen: React.FC<screenPropsType> = ({
  handleNewTransaction,
  handleListTransactions,
  getTransactionItems,
  application,
  transactions,
  history,
  handleCheckPayment,
  currentTransaction,
  paymentConfirmed,
  paymentStatus,
  clearState,
  handleCancelTransaction,
  merchantParams,
}) => {
  const dispatch = useDispatch();

  const backToAppsSelection = () => {
    history.push("/app");
  };
  const [stateRefresh, doStateRefresh] = useState(0);

  const [qrCodeValue, setQrCodeValue] = useState("");
  const [transactionItemValue, setTransactionItemsValue] = useState(null);
  const [transactionItemQuantity, setTransactionItemQuantity] = useState(0);
  const [transactionDetailsItems, setTransactionDetailsItems] = useState<any>(
    null,
  );
  const [
    transactionDetailsSingleItem,
    setTransactionDetailsSingleItem,
  ] = useState<any>(null);
  const [
    transactionItemsDescription,
    setTransactionItemsDescription,
  ] = useState("");

  const [transactionValue, setTransactionValue] = useState("");
  const [transactionValueError, setTransactionValueError] = useState(false);
  const [directValueNotAllowed, setDirectValueNotAllowed] = useState(false);

  const [hasError, setHasError] = useState(false);
  const [isPaid, setIsPaid] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState<any>({
    reference_id: "",
    authorization_number: "",
    authorization_date: null,
    nsu: "",
    transaction_amount: 0,
    status_description: "",
  });
  const [paymentFailed, setPaymentFailed] = useState(false);
  const [requestExternalReference, setRequestExternalReference] = useState(
    false,
  );
  const [externalReference, setExternalReference] = useState("");

  const [requestAttendantReference, setRequestAttendantReference] = useState(
    false,
  );
  const [attendantCode, setAttendantCode] = useState("");

  const [transactionInstallments, setTransactionInstallments] = useState(2);
  const [paymentType, setPaymentType] = useState(1);

  const [transactionPending, setTransactionPending] = useState(false);
  const [invalidExtraFields, setInvalidExtraFields] = useState(false);
  const [formStep, setFormStep] = useState(StepFormEnum.Final);
  const [disableNextStep, setDisableNextStep] = useState(true);
  const [transactionWithoutQrCode, setTransactionWithoutQrCode] = useState(
    false,
  );
  const [useAppRootReference, setUseAppRootReference] = useState(false);

  const {
    loading,
    error,
    transactions: lastTransactions,
    qrCodeDynamic,
    transactionItems,
  } = transactions;

  const handleClearState = () => {
    setTransactionValue("");
    setQrCodeValue("");
    setExternalReference("");
    setAttendantCode("");
    setTransactionInstallments(2);
    setPaymentType(1);
    setPaymentFailed(false);
    setTransactionItemsValue(null);
    setTransactionItemQuantity(0);
    setTransactionDetailsItems(null);
    setTransactionDetailsSingleItem(null);
    doStateRefresh(r => r + 1);
    clearState();
  };

  const handleTransactionCheckPayment = () => handleCheckPayment();

  const handleTimeoutCheckPayment = () => {
    setTransactionPending(false);
    handleClearState();
    setPaymentFailed(true);
  };

  const handleCheckPaymentCancel = () => {
    setTransactionPending(false);
    handleClearState();
    handleCancelTransaction();
  };
  const getLastTransactions = () => {
    setHasError(false);
    handleListTransactions();
  };

  useEffect(() => {
    handleClearState();
  }, []);

  useEffect(() => {
    let requireExternalCode = false;
    let requireAttendantCode = false;
    if (merchantParams?.merchant_group) {
      requireExternalCode =
        merchantParams.merchant_group.enable_external_reference;
      requireAttendantCode =
        merchantParams.merchant_group.enable_attendant_reference;
    }
    setRequestExternalReference(requireExternalCode);
    setRequestAttendantReference(requireAttendantCode);
  }, [merchantParams]);

  useEffect(() => {
    if (paymentConfirmed) {
      setPaymentInfo(paymentConfirmed);
      setTransactionPending(false);
      setIsPaid(true);
      handleClearState();
      getLastTransactions();
    }
    if (paymentStatus && paymentStatus?.status_description) {
      setTransactionPending(false);
      setPaymentFailed(true);
      setPaymentInfo(paymentStatus);
      handleClearState();
      getLastTransactions();
    }
  }, [paymentConfirmed, paymentStatus]);

  useEffect(() => {
    if (error) setHasError(true);
  }, [error]);

  const isStaticQrCode = application && application.qrcode_type_id === 1;
  const isUrlImageQr =
    qrCodeValue.startsWith("http") && qrCodeValue.endsWith(".png");
  const isBase64Qr = new RegExp(
    "^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{4})$",
  ).test(qrCodeValue);

  const isDynamicWithoutValue = application && application.qrcode_type_id === 3;
  const allowForwardPayment = application && application.payment_type === 2;
  const isForwardPayment = paymentType === 2;

  const {
    isPinbankApp,
    isAmeApp,
    isPayInsightsApp,
    isUsePayApp,
    isTicketApp,
    isBancoOriginal,
  } = getIntegrationTypeFlags(application?.issuer_entity?.identifier_code);

  useEffect(() => {
    if (isStaticQrCode) setQrCodeValue(application.static_qrcode);
    if (application?.enable_detail_items) getTransactionItems();
  }, [application]);

  useEffect(() => {
    if (isTicketApp && application?.enable_detail_items)
      setFormStep(StepFormEnum.TicketLogDetails);
  }, [application, stateRefresh]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [qrCodeValue]);

  if (!application) {
    backToAppsSelection();
    return <div></div>;
  }

  if (qrCodeDynamic && qrCodeDynamic !== qrCodeValue)
    setQrCodeValue(qrCodeDynamic);

  const handleAddNewTransaction = async () => {
    if (transactionInvalid) return;

    setIsPaid(false);
    let extraDetails: any = {};
    if (transactionItemValue) {
      const { code, value } = transactionItemValue || {
        code: null,
        value: null,
      };
      extraDetails = {
        singleItem: {
          description: transactionItemsDescription,
          code: code,
          quantity: transactionItemQuantity / 1000,
          value: value,
          ...(transactionDetailsSingleItem || {}),
        },
      };
    }
    if (requestAttendantReference) {
      extraDetails = {
        ...extraDetails,
        attendant: {
          code: attendantCode,
        },
      };
    }

    if (transactionDetailsItems) {
      const { driverInfo, reference } = transactionDetailsItems;
      extraDetails = {
        ...extraDetails,
        items: transactionDetailsItems,
      };

      if (driverInfo) {
        extraDetails = {
          ...extraDetails,
          reference: {
            ...extraDetails?.reference,
            ...reference,
            ...driverInfo,
          },
        };
      }
    }

    let selectedApp: any = {
      application,
    };
    // allow apps to choose to use root app
    if (useAppRootReference && application?.sub_applications?.length > 0) {
      selectedApp = {
        application_root_id: application.id,
        application: {
          id: application.sub_applications[0].application_id,
        },
      };
    }

    const result = await dispatch(
      handleNewTransaction({
        ...selectedApp,
        valor:
          decimalScale === 3
            ? `${Number(transactionValue) / 10}`
            : transactionValue,
        external_reference: externalReference,
        installments: isForwardPayment ? transactionInstallments : 1,
        details: {
          ...extraDetails,
        },
      }),
    );
    if (!isStaticQrCode && result) setTransactionPending(true);
  };

  // const decimalScale = isUsePayApp ? 3 : 2;
  let decimalScale = 2;

  const transactionInvalid =
    loading ||
    Number(transactionValue) <= 0 ||
    transactionPending ||
    (requestExternalReference && externalReference === "") ||
    (requestAttendantReference && attendantCode === "") ||
    (isUsePayApp && transactionItemValue === null) ||
    (isUsePayApp && transactionItemQuantity <= 0) ||
    invalidExtraFields ||
    transactionValueError;

  return (
    <Fragment>
      <Grid container justify="center" alignItems="center" direction="row">
        <Grid item xs={12} lg={12}>
          <Header
            authentication={null}
            loading={loading || transactionPending}
          />
        </Grid>

        <Grid
          item
          xs={12}
          css={css`
            && {
              margin-top: 24px;
            }
          `}
        >
          <GridList>
            <GridListTile key="Subheader" cols={2} style={{ height: "auto" }}>
              <Grid item>
                <Typography variant="h6" align={"center"}>
                  Nova transação com o aplicativo
                </Typography>
              </Grid>
              <Grid
                container
                justify="center"
                alignItems="center"
                direction="row"
              >
                <img
                  src={application.logo_path}
                  alt={application.name}
                  style={{ width: "150px" }}
                />
              </Grid>
            </GridListTile>
          </GridList>
        </Grid>

        <Grid container direction={"row"}>
          {/* QrCode */}
          <Grid item xs={12} sm={6} lg={6}>
            {formStep === StepFormEnum.Final && (
              <Grid
                container
                justify="center"
                alignItems="center"
                direction="row"
              >
                <Card
                  css={css`
                    && {
                      max-width: 300px !important;
                      max-height: 300px !important;
                    }
                  `}
                >
                  {isStaticQrCode || !!qrCodeValue ? (
                    <Grid item xs={12} lg={12}>
                      {!transactionWithoutQrCode && (
                        <Fragment>
                          {isUrlImageQr && (
                            <img
                              src={qrCodeValue}
                              alt={application.name}
                              width="230"
                            />
                          )}

                          {isBase64Qr && (
                            <img
                              src={`data:image/jpeg;base64,${qrCodeValue}`}
                              alt={application.name}
                              width="230"
                            />
                          )}

                          {!isBase64Qr && !isUrlImageQr && (
                            <QRCode
                              value={qrCodeValue}
                              size={230}
                              renderAs={"canvas"}
                              level={"H"}
                            />
                          )}
                        </Fragment>
                      )}
                    </Grid>
                  ) : (
                    <img
                      src="/assets/default_qrcode_250x250.png"
                      alt={application.name}
                    />
                  )}
                </Card>
              </Grid>
            )}

            <TransactionPending
              enable={transactionPending}
              handleTimeoutCheckPayment={handleTimeoutCheckPayment}
              handleTransactionCheckPayment={handleTransactionCheckPayment}
              handleCheckPaymentCancel={handleCheckPaymentCancel}
              currentTransaction={currentTransaction}
              application={application}
            />

            {formStep === StepFormEnum.TicketLogDetails && (
              <TicketFields
                enable={isTicketApp && application.enable_detail_items}
                refresh={stateRefresh}
                setDirectValueNotAllowed={setDirectValueNotAllowed}
                transactionItems={transactionItems}
                transactionPending={transactionPending}
                loading={loading}
                setInvalidExtraFields={setInvalidExtraFields}
                transactionDetailsItems={transactionDetailsItems}
                setTransactionDetailsItems={setTransactionDetailsItems}
                currencyFormatter={currencyFormatterComp}
                setTotalAmount={setTransactionValue}
                setDisableNextStep={setDisableNextStep}
              />
            )}

            {formStep === StepFormEnum.Final && (
              <Grid>
                <UsePayFields
                  enable={isUsePayApp}
                  stateRefresh={stateRefresh}
                  handleAddNewTransaction={handleAddNewTransaction}
                  transactionItems={transactionItems}
                  setTransactionItemsDescription={
                    setTransactionItemsDescription
                  }
                  setTransactionItemsValue={setTransactionItemsValue}
                  transactionItemValue={transactionItemValue}
                  transactionItemQuantity={transactionItemQuantity}
                  setTransactionItemQuantity={setTransactionItemQuantity}
                  transactionPending={transactionPending}
                  loading={loading}
                  transactionDetailsItems={transactionDetailsItems}
                  setTransactionDetailsItems={setTransactionDetailsItems}
                  transactionDetailsSingleItem={transactionDetailsSingleItem}
                  setTransactionDetailsSingleItem={
                    setTransactionDetailsSingleItem
                  }
                  setDirectValueNotAllowed={setDirectValueNotAllowed}
                  setTransactionValue={setTransactionValue}
                  setInvalidExtraFields={setInvalidExtraFields}
                  setTransactionWithoutQrCode={setTransactionWithoutQrCode}
                  setUseAppRootReference={setUseAppRootReference}
                >
                  <TransactionTotalValue
                    loading={loading || transactionPending}
                    isStaticQrCode={isStaticQrCode}
                    isDynamicWithoutValue={isDynamicWithoutValue}
                    decimalScale={decimalScale}
                    transactionValueError={transactionValueError}
                    setTransactionValueError={setTransactionValueError}
                    application={application}
                    transactionValue={transactionValue}
                    setTransactionValue={setTransactionValue}
                    directValueNotAllowed={directValueNotAllowed}
                    handleAddNewTransaction={handleAddNewTransaction}
                  />
                </UsePayFields>

                {!isUsePayApp && (
                  <TransactionTotalValue
                    loading={loading || transactionPending}
                    isStaticQrCode={isStaticQrCode}
                    isDynamicWithoutValue={isDynamicWithoutValue}
                    decimalScale={decimalScale}
                    transactionValueError={transactionValueError}
                    setTransactionValueError={setTransactionValueError}
                    application={application}
                    transactionValue={transactionValue}
                    setTransactionValue={setTransactionValue}
                    directValueNotAllowed={directValueNotAllowed}
                    handleAddNewTransaction={handleAddNewTransaction}
                  />
                )}

                <ForwardPaymentField
                  enable={allowForwardPayment}
                  loading={loading}
                  isForwardPayment={isForwardPayment}
                  setPaymentType={setPaymentType}
                  transactionPending={transactionPending}
                  transactionInstallments={transactionInstallments}
                  setTransactionInstallments={setTransactionInstallments}
                  transactionValue={transactionValue}
                  currencyFormatter={currencyFormatterComp}
                />

                <ExternalReferenceField
                  enable={requestExternalReference}
                  setExternalReference={setExternalReference}
                  externalReference={externalReference}
                  transactionPending={transactionPending}
                  loading={loading}
                />

                <AttendantReferenceField
                  enable={requestAttendantReference}
                  setAttendantCode={setAttendantCode}
                  attendantCode={attendantCode}
                  transactionPending={transactionPending}
                  loading={loading}
                />
              </Grid>
            )}

            <Grid
              container
              justify="center"
              css={css`
                && {
                  margin-top: 10px;
                  margin-bottom: 10px;
                }
              `}
            >
              <Grid item xs={8} sm={7} lg={7}>
                {!isStaticQrCode && formStep !== StepFormEnum.TicketLogDetails && (
                  <Button
                    onClick={handleAddNewTransaction}
                    fullWidth
                    variant="contained"
                    color="secondary"
                    disabled={transactionInvalid}
                    css={css`
                      && {
                        border-radius: 15px;
                      }
                    `}
                  >
                    {transactionWithoutQrCode ? "Confirmar" : "Gerar QRCode"}
                  </Button>
                )}
              </Grid>
              <Grid item xs={8} sm={7} lg={7}>
                {isTicketApp &&
                  application.enable_detail_items &&
                  formStep === StepFormEnum.Final && (
                    <Button
                      onClick={() => {
                        setFormStep(StepFormEnum.TicketLogDetails);
                      }}
                      disabled={loading || transactionPending}
                      fullWidth
                      size="small"
                      variant="contained"
                      color="primary"
                      css={css`
                        && {
                          border-radius: 15px;
                          margin-top: 10px;
                        }
                      `}
                    >
                      Voltar
                    </Button>
                  )}
              </Grid>

              <Grid item xs={8} sm={6} lg={6}>
                {formStep === StepFormEnum.TicketLogDetails && (
                  <Button
                    onClick={() => {
                      setFormStep(StepFormEnum.Final);
                    }}
                    fullWidth
                    variant="contained"
                    color="secondary"
                    disabled={disableNextStep}
                    css={css`
                      && {
                        border-radius: 15px;
                      }
                    `}
                  >
                    Avançar
                  </Button>
                )}
              </Grid>
            </Grid>

            <TransactionError
              enable={hasError && error}
              setHasError={setHasError}
              error={error}
            />

            <PaymentInfo
              enable={isPaid}
              setIsPaid={setIsPaid}
              paymentInfo={paymentInfo}
              application={application}
              currencyFormatter={currencyFormatterComp}
            />

            <PaymentFailed
              enable={!isStaticQrCode && paymentFailed}
              setPaymentFailed={setPaymentFailed}
              paymentFailed={paymentFailed}
              transaction={paymentInfo}
            />
          </Grid>

          <Grid item xs={12} sm={6} lg={6}>
            <LastTransactions
              transactionPending={transactionPending}
              loading={loading}
              getLastTransactions={getLastTransactions}
              lastTransactions={lastTransactions}
              isPinbankApp={isPinbankApp}
              isAmeApp={isAmeApp}
              isPayInsightsApp={isPayInsightsApp}
              isBancoOriginal={isBancoOriginal}
            />
          </Grid>
        </Grid>

        <Grid item xs={3}>
          <Button
            onClick={backToAppsSelection}
            fullWidth
            color="secondary"
            startIcon={<AppsTwoToneIcon />}
            css={css`
              && {
                margin-top: 10px;
                font-weight: bold;
              }
            `}
          >
            IR PARA APLICATIVOS
          </Button>
        </Grid>

        <Grid
          container
          justify="flex-end"
          css={css`
            && {
              margin-top: 24px;
            }
          `}
        ></Grid>
      </Grid>
    </Fragment>
  );
};

export default TransactionScreen;
