import { TablePagination, createMuiTheme, makeStyles } from "@material-ui/core";
import { css, jsx } from "@emotion/core";

import React from "react";
import { colors } from "shared/configs/styles.config";
import { ptBR } from "@material-ui/core/locale";

/** @jsx jsx */

export const CustomMuiTheme = createMuiTheme(
  {
    palette: {
      primary: {
        main: colors.primary,
      },
      secondary: {
        main: colors.secondary,
      },
    },
    overrides: {
      MuiTableCell: {
        paddingNone: {
          padding: "1px",
        },
        root: {
          "@media (max-width:959.95px)": {
            padding: "1px",
          },
          // "&:last-child": {
          //   marginBottom: "2px",
          //   padding: "1px",
          // },
        },
      },
      MuiTablePagination: {
        input: {
          marginLeft: "5px",
          marginRight: "15px",
        },
      },
    },
  },
  ptBR,
);

const textLabels = {
  body: {
    noMatch: "Nenhuma transação encontrada",
    toolTip: "Ordenar",
    // columnHeaderTooltip: column => `Ordenar por ${column.label}`,
  },
  pagination: {
    next: "Próxima página",
    previous: "Página anterior",
    rowsPerPage: "Reg por página:",
    displayRows: "de",
  },
  toolbar: {
    search: "Buscar",
    downloadCsv: "Download CSV",
    print: "Imprimir",
    viewColumns: "Exibir colunas",
    filterTable: "Filtrar",
  },
  filter: {
    all: "Todos",
    title: "Filtros",
    reset: "LImpar",
  },
  viewColumns: {
    title: "Exibir colunas",
    titleAria: "Exibe/Oculta colunas",
  },
};

export const useTableCustomStyles = makeStyles({
  formSelectControl: {
    minWidth: 120,
  },
  tableTitle: {
    fontSize: "16px",
    padding: "5px",
    fontWeight: "bold",
  },
  tableTotalTitle: {
    fontSize: "16px",
    padding: "5px",
    fontWeight: "bold",
    color: "rgba(0, 0, 0, 0.87)",
    backgroundColor: "#f5f5f5",
  },
  tableHeader: {
    fontSize: "13px",
    color: "rgba(0, 0, 0, 0.87)",
    fontWeight: "bold",
    padding: "5px",
  },
  tableRowTotal: {
    fontSize: "13px",
    color: "rgba(0, 0, 0, 0.87)",
    fontWeight: "bold",
  },
  heading: {
    fontSize: CustomMuiTheme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: CustomMuiTheme.typography.pxToRem(15),
    color: CustomMuiTheme.palette.text.secondary,
  },
});

export const CustomTablePaggination = ({
  rowCount,
  rowsPerPage,
  page,
  changePage,
  changeRowsPerPage,
}: any) => {
  return (
    <TablePagination
      className=".datatables-noprint"
      count={rowCount}
      rowsPerPage={rowsPerPage}
      page={page}
      onChangePage={(_, page) => changePage(page)}
      onChangeRowsPerPage={event => changeRowsPerPage(event.target.value)}
      rowsPerPageOptions={[10, 15, 100]}
      labelRowsPerPage={""}
      labelDisplayedRows={({ from, to, count }) =>
        `${from}-${to}/${count !== -1 ? count : `Mais de ${to}`}`
      }
    />
  );
};

export const MuiDefaultOptions: any = {
  enableNestedDataAccess: ".",
  responsive: "simple",
  filterType: "multiselect",
  selectableRows: "none",
  search: false,
  textLabels,
  onSearchChange: (searchText: string | null) => {},
  // setRowProps: (row: any) => {
  //   return {
  //     style: { padding: "none" },
  //   };
  // },
  setTableProps: () => {
    return {
      // padding: "none",
      size: "small",
    };
  },
};

/*
import {
  CustomMuiTheme,
  CustomTablePaggination,
  textLabels,
  useTableCustomStyles,
} from "app/components/Grid/MuiTable";

 <CustomTablePaggination
                            rowCount={rowCount}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            changePage={changePage}
                            changeRowsPerPage={changeRowsPerPage}
                          />

*/
