import React, { useCallback, useEffect } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import {
  login as loginFn,
  recoverPassword as recoverPasswordFn,
  resetError as resetErrorFn
} from "shared/ducks/authentication.duck";

import LoginScreen from "./Login.screen";
import { Redirect } from "react-router-dom";
import { stateReduxType } from "shared/types/authentication.type";

const useTypedSelector: TypedUseSelectorHook<stateReduxType> = useSelector;

const LoginContainer: React.FC<any> = ({ history }) => {
  const dispatch = useDispatch();

  const { authentication } = useTypedSelector(state => state.shared) || {};
  const { signedIn } = authentication;

  const resetError = useCallback(() => dispatch(resetErrorFn()), [dispatch]);

  useEffect(() => {
    resetError();
  }, [resetError]);

  const handleRecoverPassword = (email: string) =>
    dispatch(recoverPasswordFn(email));

  if (signedIn) {
    return <Redirect to="/app" />;
  }

  return (
    <LoginScreen
      authentication={authentication}
      handleLogin={data => dispatch(loginFn(data))}
      history={history}
      handleRecoverPassword={handleRecoverPassword}
    />
  );
};

export default LoginContainer;
