import { API_URL_WITH_PORT, PORTS } from "shared/configs/api.config";

import { Dispatch } from "redux";
import fetch from "shared/utils/fetch.util";

type Actions = {
  type: string;
  payload?: object;
  error?: object;
};

export const Types = {
  INIT_SEARCH_TRANSACTION: "public/transactions/INIT_SEARCH_TRANSACTION",
  SUCCESS_SEARCH_TRANSACTION: "public/transactions/SUCCESS_SEARCH_TRANSACTION",
  ERROR_SEARCH_TRANSACTION: "public/transactions/ERROR_SEARCH_TRANSACTION",
};

const initialState: any = {
  transaction_reference: null,
  loading: false,
  error: null,
};

export default (state = initialState, action: Actions) => {
  const { type, payload, error } = action;

  const reducers = {
    [Types.INIT_SEARCH_TRANSACTION]: {
      ...state,
      error: null,
      loading: true,
    },
    [Types.ERROR_SEARCH_TRANSACTION]: {
      loading: false,
      transaction_reference: null,
      error,
    },
    [Types.SUCCESS_SEARCH_TRANSACTION]: {
      ...state,
      loading: false,
      transaction_reference: payload,
      error: false,
    },
  };

  return reducers[type] || state;
};

export const getTransactionInformation = ({
  reference_id,
}: {
  reference_id: string;
}) => async (dispatch: Dispatch<any>) => {
  try {
    dispatch({
      type: Types.INIT_SEARCH_TRANSACTION,
    });
    const response = await fetch(
      `${API_URL_WITH_PORT(
        PORTS.TRANSACTION,
      )}/transactions/${reference_id}/qrcode`,
      {
        customUrl: true,
        method: "GET",
      },
    );
    const result = await response.json();
    if (!response.ok) {
      throw result;
    }
    dispatch({
      type: Types.SUCCESS_SEARCH_TRANSACTION,
      payload: result,
    });
    return result;
  } catch (e) {
    dispatch({
      type: Types.ERROR_SEARCH_TRANSACTION,
      error: {
        visible: true,
        ...e,
      },
    });
    return false;
  }
};
