import "moment/locale/pt-br";

import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
/** @jsx jsx */
import React, { Fragment, useEffect, useState } from "react";
import { css, jsx } from "@emotion/core";

import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import Header from "app/components/Header/Header.container";
import MomentUtils from "@date-io/moment";
import Receivables from "./components/Receivables";
import Registers from "./components/Registers";
import SearchIcon from "@material-ui/icons/Search";
import { Skeleton } from "@material-ui/lab";
import SwipeableViews from "react-swipeable-views";
import { formatterToBrlCurrency } from "shared/utils/formatter.util";
import moment from "moment";
import { screenPropsType } from "./ReportReceivable.type";

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Screen: React.FC<screenPropsType> = ({
  history,
  getTransactions,
  transactionsExtState,
  getRegisterStatus,
  getRegisters,
  registerState,
}) => {
  const {
    loading: loadingRegisters,
    registers: registersData,
    register_status,
  } = registerState;

  const [registersGroup, setRegistersGroup] = useState([]);

  const {
    loading: loadingTransactions,
    receivables: transactionsData,
  } = transactionsExtState;

  const [transactionsGroup, setTransactionsGroup] = useState([]);
  const [transactionsFutureGroup, setTransactionsFutureGroup] = useState([]);

  // Dates
  const [selectedDateBegin, setSelectedDateBegin] = useState(
    new Date(new Date()).setDate(new Date().getDate() - 1),
  );
  const [selectedDateEnd, setSelectedDateEnd] = useState(
    new Date().setDate(new Date().getDate() - 1),
  );

  const handleDateBeginChange = (date: any) => {
    if (!date)
      setSelectedDateBegin(
        new Date(new Date()).setDate(new Date().getDate() - 1),
      );
    else setSelectedDateBegin(date);
  };

  const handleDateEndChange = (date: any) => {
    if (!date) setSelectedDateEnd(new Date().setDate(new Date().getDate() - 1));
    else setSelectedDateEnd(date);
  };

  // Search Transactions
  const handleGetTransactions = () => {
    getTransactions({
      dateBegin: selectedDateBegin,
      dateEnd: selectedDateEnd,
    });
  };

  useEffect(() => {
    handleGetTransactions();
  }, []);

  useEffect(() => {
    if (!transactionsData) return;

    var groupedData: any = Object.values(
      [...transactionsData].reduce((r: any, e: any) => {
        var key = moment(e.Dt_Transacao).format("YYYY-MM-DD");
        var valorBruto = e.vlr_bruto;
        var valorLiquido = e.vlr_liquido;
        var valorEncargos = e.vlr_Encargos;

        if (!r[key])
          r[key] = {
            DataCaptura: key,
            valorBruto: valorBruto,
            valorLiquido: valorLiquido,
            valorEncargos: valorEncargos,
            total: 1,
            items: [e],
          };
        else {
          r[key].total++;
          r[key].valorBruto += valorBruto;
          r[key].valorLiquido += valorLiquido;
          r[key].valorEncargos += valorEncargos;
          r[key].items.push(e);
        }

        return r;
      }, {}),
    );
    setTransactionsGroup(groupedData);
    setTransactionsFutureGroup(
      groupedData.filter((f: any) => f.DataCaptura > Date.now),
    );
  }, [transactionsExtState]);

  // Get registers
  const handleGetRegisters = () => {
    getRegisters({
      dateBegin: selectedDateBegin,
      dateEnd: selectedDateEnd,
    });
  };
  useEffect(() => {
    handleGetRegisters();
  }, []);

  useEffect(() => {
    if (!registersData) return;

    var groupedData: any = Object.values(
      [...registersData].reduce((r: any, e: any) => {
        var key = moment(e.Dt_movimentacao).format("YYYY-MM-DD");
        var valorMovimentado = e.VLR_movimentado;
        if (!r[key])
          r[key] = {
            DataCaptura: key,
            valorMovimentado: valorMovimentado,
            total: 1,
            items: [e],
          };
        else {
          r[key].total++;
          r[key].valorMovimentado += valorMovimentado;
          r[key].items.push(e);
        }

        return r;
      }, {}),
    );
    setRegistersGroup(groupedData);
  }, [registerState]);

  // Get registers
  const handleGetRegisterStatus = () => {
    getRegisterStatus({});
  };
  useEffect(() => {
    handleGetRegisterStatus();
  }, []);

  const isValid = () => {
    return (
      moment(selectedDateBegin).isValid() && moment(selectedDateEnd).isValid()
    );
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  return (
    <Fragment>
      <Header
        authentication={null}
        loading={loadingTransactions || loadingRegisters}
      />
      <Grid
        container
        spacing={2}
        justify="center"
        alignItems="center"
        direction="row"
      >
        <Grid item xs={8} lg={12}>
          <GridList>
            <GridListTile key="Subheader" cols={2} style={{ height: "auto" }}>
              <Grid
                container
                justify="flex-end"
                css={css`
                  && {
                    margin-top: 24px;
                  }
                `}
              ></Grid>
              <Typography
                variant="h6"
                align={"center"}
                css={css`
                  margin-bottom: 35px;
                `}
              >
                Gestão OndaPay
              </Typography>
            </GridListTile>
          </GridList>

          {/* Filters */}

          <Grid container spacing={3}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Grid item>
                <KeyboardDatePicker
                  size={"small"}
                  label="Data início"
                  cancelLabel="Cancelar"
                  value={selectedDateBegin}
                  format="DD/MM/YYYY"
                  onChange={handleDateBeginChange}
                  maxDate={selectedDateEnd}
                  maxDateMessage="Data deve ser menor ou igual a data final"
                  invalidDateMessage="Data inválida"
                />
              </Grid>
              <Grid item>
                <KeyboardDatePicker
                  size={"small"}
                  label="Data final"
                  cancelLabel="Cancelar"
                  value={selectedDateEnd}
                  format="DD/MM/YYYY"
                  onChange={handleDateEndChange}
                  minDate={selectedDateBegin}
                  minDateMessage="Data deve ser maior ou igual a data inicial"
                  invalidDateMessage="Data inválida"
                />
              </Grid>
            </MuiPickersUtilsProvider>
            <Grid item>
              <Button
                onClick={() => {
                  handleGetTransactions();
                  handleGetRegisters();
                }}
                fullWidth
                color="secondary"
                startIcon={<SearchIcon />}
                disabled={!isValid()}
                css={css`
                  && {
                    margin-top: 10px;
                    font-weight: bold;
                  }
                `}
              >
                PESQUISAR
              </Button>
            </Grid>
          </Grid>

          {loadingTransactions ? (
            <Grid container spacing={6}>
              <Grid item xs={12} sm={4}>
                <Skeleton variant="rect" height={150} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Skeleton variant="rect" height={150} />
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={6}>
              <Grid item xs={12} sm={4}>
                <Card
                  css={css`
                    background-color: #f5f5f5;
                    margin-top: 10px;
                  `}
                >
                  <CardContent>
                    <Typography variant="subtitle1" align={"center"}>
                      <small>Líquido recebido no período</small>
                    </Typography>
                    <Typography variant="subtitle1" align={"center"}>
                      <strong>
                        {transactionsGroup.length === 0
                          ? 0
                          : formatterToBrlCurrency(
                              transactionsGroup
                                .map((m: any) => m.valorLiquido)
                                .reduce((a: any, b: any) => a + b),
                            )}
                      </strong>
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={4} alignContent={"flex-start"}>
                <Card
                  css={css`
                    background-color: #f5f5f5;
                    margin-top: 10px;
                  `}
                >
                  <CardContent>
                    <Typography variant="subtitle1" align={"center"}>
                      <small>Saldo Disponível</small>
                    </Typography>
                    <Typography variant="subtitle1" align={"center"}>
                      <strong>
                        {register_status
                          ? formatterToBrlCurrency(register_status.amount)
                          : 0}
                      </strong>
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )}

          <Paper
            square
            css={css`
              && {
                margin-top: 15px;
              }
            `}
          >
            <Tabs
              value={value}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChange}
            >
              <Tab label="Recebidos" />
              <Tab label="Futuros" />
              <Tab label="Extrato" />
            </Tabs>
            <SwipeableViews
              axis={"x"}
              index={value}
              onChangeIndex={handleChangeIndex}
              css={css`
                .react-swipeable-view-container {
                  min-height: 180px;
                }
              `}
            >
              <TabPanel
                value={value}
                index={0}
                css={css`
                  background-color: #f5f5f5;
                `}
              >
                <Receivables
                  transactions={transactionsGroup}
                  loading={loadingTransactions}
                  filters={{
                    selectedDateBegin,
                    selectedDateEnd,
                  }}
                  title={
                    transactionsGroup.length === 1
                      ? `1 depósito`
                      : `${transactionsGroup.length} depósitos no período de ${
                          moment(selectedDateBegin).isValid()
                            ? moment(selectedDateBegin).format("DD MMM YYYY")
                            : ""
                        }${" - "}
                      ${
                        moment(selectedDateEnd).isValid()
                          ? moment(selectedDateEnd).format("DD MMM YYYY")
                          : "-"
                      }`
                  }
                />
              </TabPanel>
              <TabPanel
                value={value}
                index={1}
                css={css`
                  background-color: #f5f5f5;
                `}
              >
                <Receivables
                  transactions={transactionsFutureGroup}
                  loading={loadingTransactions}
                  filters={{
                    selectedDateBegin,
                    selectedDateEnd,
                  }}
                  title={
                    transactionsFutureGroup.length === 1
                      ? `1 transação`
                      : `${
                          transactionsFutureGroup.length
                        } transações no período de ${
                          moment(selectedDateBegin).isValid()
                            ? moment(selectedDateBegin).format("DD MMM YYYY")
                            : ""
                        }${" - "}
                      ${
                        moment(selectedDateEnd).isValid()
                          ? moment(selectedDateEnd).format("DD MMM YYYY")
                          : "-"
                      }`
                  }
                  customSummary={
                    <Grid container>
                      <Grid item sm={12} xs={12} lg={12}>
                        <Grid container>
                          <Grid item xs={12} sm={6}>
                            <Typography variant="caption">
                              Lançamentos Previstos
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item sm={12} xs={12} lg={12}>
                        <Grid container>
                          <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle2" align={"left"}>
                              {transactionsFutureGroup.length > 1
                                ? `${transactionsFutureGroup.length} transações`
                                : `${transactionsFutureGroup.length} transação`}{" "}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  }
                />
              </TabPanel>
              <TabPanel
                value={value}
                index={2}
                css={css`
                  background-color: #f5f5f5;
                `}
              >
                <Registers
                  transactions={registersGroup}
                  loading={loadingRegisters}
                  filters={{
                    selectedDateBegin,
                    selectedDateEnd,
                  }}
                  title={
                    registersData.length === 1
                      ? `1 movimento`
                      : `${registersData.length} movimentos no período de ${
                          moment(selectedDateBegin).isValid()
                            ? moment(selectedDateBegin).format("DD MMM YYYY")
                            : ""
                        }${" - "}
                      ${
                        moment(selectedDateEnd).isValid()
                          ? moment(selectedDateEnd).format("DD MMM YYYY")
                          : "-"
                      }`
                  }
                />
              </TabPanel>
            </SwipeableViews>
          </Paper>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default Screen;
