import * as Yup from "yup";

import {
  TextField as TextFieldMaterial,
  Button,
  Grid,
  Typography,
  Divider,
  CircularProgress,
} from "@material-ui/core";
/** @jsx jsx */
import React, { Fragment, useCallback, useMemo, useState } from "react";
import { css, jsx } from "@emotion/core";
import { TextField } from "formik-material-ui";
import { Field } from "formik";
import NumberFormat from "react-number-format";
import { Form, Formik } from "formik";
import validateCPF from "shared/utils/validateCPF.util";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import {
  currencyFormatterComp,
  formatterToPercent,
} from "shared/utils/formatter.util";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { getBanks } from "public/ducks/domainData.duck";
import { useDispatch } from "react-redux";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

const document_numberSchema = Yup.string().when(
  "type",
  (type: string, schema: any) => {
    const document = "CPF";

    return schema.test(
      "document_number",
      `Você deve digitar um ${document} válido`,
      (value: string) => {
        const documentLength = !!value && String(value).length;

        if (!documentLength) {
          return false;
        }

        if (document === "CPF") {
          if (documentLength < 11 || !validateCPF(value)) {
            return false;
          }
        }

        return true;
      },
    );
  },
);

const merchantSchema = Yup.object().shape({
  fullname: Yup.string().required("Campo obrigatório"),
  mother_name: Yup.string().required("Campo obrigatório"),
  birth_date: Yup.date().required("Campo obrigatório"),
  document_legal: document_numberSchema,
  phone_prefix: Yup.number()
    .nullable()
    .required("Campo obrigatório"),
  phone_number: Yup.string().required("Campo obrigatório"),
  bank_number: Yup.string().required("Campo obrigatório"),
  bank_agency: Yup.string().required("Campo obrigatório"),
  bank_account_number: Yup.string().required("Campo obrigatório"),
  bank_account_number_digit: Yup.string().required("Campo obrigatório"),
  month_revenue: Yup.number().required("Campo obrigatório"),
  constitution_type: Yup.string().required("Campo obrigatório"),
  patrimony: Yup.number().required("Campo obrigatório"),
  attribute: Yup.string().required("Campo obrigatório"),
  capital_total: Yup.number().required("Campo obrigatório"),
  number_of_employees: Yup.number().required("Campo obrigatório"),
  email: Yup.string().required("Campo obrigatório"),
});

const formSchema = merchantSchema;
const useGenericSelector: TypedUseSelectorHook<any> = useSelector;

export const capitalTotalValue = () => (
  values: import("react-number-format").NumberFormatValues,
): boolean => {
  const { formattedValue, floatValue } = values;
  return formattedValue === "" || (floatValue > 0 && floatValue <= 100);
};

const OnboardingOriginalFields: React.FC<any> = ({
  loading,
  merchant,
  handleFinalization,
}) => {
  const dispatch = useDispatch();

  const [isFormSubmiting, setSubmitForm] = useState(false);
  const { domainData } = useGenericSelector(state => state.public) || {};
  const { banksList } = domainData;
  const handleGetBanks = useCallback(() => dispatch(getBanks()), [dispatch]);

  useMemo(() => {
    handleGetBanks();
  }, [handleGetBanks]);

  const constitutionList = [
    "Sociedade Resp.Limitada - Ltda",
    "Sociedade anônima - S/A",
    "Sociedade simples - S/S",
    "Sociedade cooperativas",
    "Microempreendedor individual - MEI",
    "Individual Resp.Limitada - EIRELI",
    "Empresa sem fins Lucrativos",
    "Organização internacional",
    "Outras Instituições Extraterritoriais",
  ];

  const attributesList = [
    {
      code: "(i)",
      desc: "Representante Legal (p.ex. Diretor, Administrador e outros)",
    },
    {
      code: "(ii)",
      desc: "Procurador",
    },
    {
      code: "(III)",
      desc: "Beneficiário Final",
    },
  ];

  return (
    <Fragment>
      <Grid>
        <Formik
          initialValues={{
            constitution_type: merchant?.merchant?.constitution_type || "",
            month_revenue: (merchant?.merchant?.month_revenue || "").replace(
              ".",
              "",
            ),
            patrimony: (merchant?.merchant?.patrimony || "").replace(".", ""),
            number_of_employees: merchant?.merchant?.number_of_employees || "",

            fullname: merchant?.partner?.name || "",
            document_legal: merchant?.partner?.document_number || "",
            phone_prefix: merchant?.partner?.phone_prefix || "",
            phone_number: merchant?.partner?.phone_number || "",
            email: merchant?.partner?.email || "",
            mother_name: merchant?.partner?.mother_name || "",
            birth_date: merchant?.partner?.birth_date || null,
            capital_total: Number(merchant?.partner?.capital_total || ""),
            attribute: merchant?.partner?.attribute || "",

            bank_number: merchant?.merchant?.bank_account?.bank_number || "",
            bank_agency: merchant?.merchant?.bank_account?.branch_number || "",
            bank_account_number:
              merchant?.merchant?.bank_account?.account_number || "",
            bank_account_number_digit:
              merchant?.merchant?.bank_account?.account_digit || "",
          }}
          onSubmit={async (values, {}) => {
            setSubmitForm(true);
            await handleFinalization(values);
            setSubmitForm(false);
          }}
          validationSchema={formSchema}
          validateOnMount
          enableReinitialize
        >
          {({ errors, submitForm, dirty }) => {
            return (
              <Form>
                <fieldset
                  disabled={isFormSubmiting}
                  css={css`
                    && {
                      border: none;
                    }
                  `}
                >
                  <Grid container spacing={3}>
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                      css={css`
                        && {
                          margin-bottom: 10px;
                        }
                      `}
                    >
                      <p>
                        Para seguir com a abertura da conta Pix Original
                        complete seu cadastro preenchendo os dados:
                      </p>
                    </Grid>

                    <Divider
                      css={css`
                        && {
                          margin-top: 10px;
                          margin-bottom: 10px;
                        }
                      `}
                    />
                    <Grid item xs={12}>
                      <Typography
                        variant="h1"
                        align="left"
                        css={css`
                          && {
                            font-size: 16px;
                            font-weight: 800;
                            color: #1d1d6d;
                          }
                        `}
                      >
                        Dados Bancários
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Field name="bank_number">
                        {({
                          field: { value, name },
                          form: { setFieldValue, setFieldTouched },
                          meta: { error, touched },
                        }: any) => {
                          const selectedBank = banksList?.find(
                            (f: any) => f.bank_code === value,
                          );
                          return (
                            <Autocomplete
                              key={`${selectedBank?.bank_code}-key`}
                              options={banksList}
                              disableClearable
                              defaultValue={selectedBank}
                              getOptionSelected={(option, value) =>
                                option.bank_code === value.bank_code
                              }
                              onChange={(a: any, value: any) => {
                                if (value)
                                  setFieldValue(
                                    "bank_number",
                                    value?.bank_code,
                                  );
                              }}
                              onBlur={() => {
                                setFieldTouched(name, true);
                              }}
                              getOptionLabel={(bank: any) =>
                                `${bank.bank_code} - ${bank.bank_name}`
                              }
                              renderInput={params => (
                                <TextFieldMaterial
                                  {...params}
                                  label="Banco"
                                  required
                                  disabled={loading || isFormSubmiting}
                                  error={touched && !!error}
                                  helperText={touched && error}
                                  variant="outlined"
                                  fullWidth
                                />
                              )}
                            />
                          );
                        }}
                      </Field>
                    </Grid>

                    <Grid item xs={5}>
                      <Field name="bank_agency">
                        {() => {
                          return (
                            <TextField
                              name="bank_agency"
                              label="Agência (sem dígito)"
                              autoComplete="nope"
                              required
                              disabled={loading || isFormSubmiting}
                              variant="outlined"
                              fullWidth
                            />
                          );
                        }}
                      </Field>
                    </Grid>

                    <Grid item xs={5}>
                      <Field name="bank_account_number">
                        {() => {
                          return (
                            <TextField
                              name="bank_account_number"
                              label="Número da Conta"
                              autoComplete="nope"
                              required
                              disabled={loading || isFormSubmiting}
                              variant="outlined"
                              fullWidth
                            />
                          );
                        }}
                      </Field>
                    </Grid>

                    <Grid item xs={2}>
                      <Field name="bank_account_number_digit">
                        {() => {
                          return (
                            <TextField
                              name="bank_account_number_digit"
                              label="Digíto"
                              autoComplete="nope"
                              required
                              disabled={loading || isFormSubmiting}
                              variant="outlined"
                              fullWidth
                            />
                          );
                        }}
                      </Field>
                    </Grid>
                  </Grid>

                  <Divider
                    css={css`
                      && {
                        margin-top: 10px;
                        margin-bottom: 10px;
                      }
                    `}
                  />
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography
                        variant="h1"
                        align="left"
                        css={css`
                          && {
                            font-size: 16px;
                            font-weight: 800;
                            color: #1d1d6d;
                          }
                        `}
                      >
                        Informações do Negócio
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Field name="constitution_type">
                        {({
                          field: { value, name },
                          form: { setFieldValue, setFieldTouched },
                          meta: { error, touched },
                        }: any) => {
                          const selectedConstitution = constitutionList?.find(
                            (f: any) => f === value,
                          );
                          return (
                            <Autocomplete
                              key={`${selectedConstitution}-key`}
                              options={constitutionList}
                              disableClearable
                              defaultValue={selectedConstitution}
                              getOptionSelected={(option, value) =>
                                option === value
                              }
                              onChange={(a: any, value: any) => {
                                if (value)
                                  setFieldValue("constitution_type", value);
                              }}
                              onBlur={() => {
                                setFieldTouched(name, true);
                              }}
                              getOptionLabel={(value: any) => `${value}`}
                              renderInput={params => (
                                <TextFieldMaterial
                                  {...params}
                                  label="Forma de Constituição"
                                  required
                                  disabled={loading || isFormSubmiting}
                                  error={touched && !!error}
                                  helperText={touched && error}
                                  variant="outlined"
                                  fullWidth
                                />
                              )}
                            />
                          );
                        }}
                      </Field>
                    </Grid>

                    <Grid item xs={4}>
                      <Field name="month_revenue">
                        {({
                          field: { value, name },
                          form: { setFieldValue, setFieldTouched },
                          meta: { error, touched },
                        }: any) => (
                          <NumberFormat
                            customInput={TextFieldMaterial}
                            fullWidth
                            required
                            format={currencyFormatterComp}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            thousandSeparator="."
                            decimalSeparator=","
                            allowNegative={false}
                            isNumericString={true}
                            label={"Receita Mensal R$"}
                            variant="outlined"
                            disabled={loading || isFormSubmiting}
                            InputProps={{
                              inputProps: { min: 1, max: 9999999 },
                            }}
                            value={value}
                            error={touched && !!error}
                            helperText={touched && error}
                            onBlur={async ({
                              target: { value },
                            }: React.ChangeEvent<HTMLInputElement>) => {
                              setFieldTouched(name, true, false);
                            }}
                            onValueChange={(values: any) =>
                              setFieldValue(name, values.value)
                            }
                          />
                        )}
                      </Field>
                    </Grid>

                    <Grid item xs={4}>
                      <Field name="patrimony">
                        {({
                          field: { value, name },
                          form: { setFieldValue, setFieldTouched },
                          meta: { error, touched },
                        }: any) => (
                          <NumberFormat
                            customInput={TextFieldMaterial}
                            fullWidth
                            required
                            decimalScale={2}
                            format={currencyFormatterComp}
                            fixedDecimalScale={true}
                            thousandSeparator="."
                            decimalSeparator=","
                            allowNegative={false}
                            label={"Patrimônio R$"}
                            variant="outlined"
                            InputProps={{
                              inputProps: { min: 1, max: 9999999 },
                            }}
                            disabled={loading || isFormSubmiting}
                            value={value}
                            error={touched && !!error}
                            helperText={touched && error}
                            onBlur={async ({
                              target: { value },
                            }: React.ChangeEvent<HTMLInputElement>) => {
                              setFieldTouched(name, true, false);
                            }}
                            onValueChange={(values: any) =>
                              setFieldValue(name, values.value)
                            }
                          />
                        )}
                      </Field>
                    </Grid>

                    <Grid item xs={4}>
                      <Field name="number_of_employees">
                        {() => {
                          return (
                            <TextField
                              name="number_of_employees"
                              label="Número de Funcionários"
                              autoComplete="nope"
                              type="number"
                              required
                              disabled={loading || isFormSubmiting}
                              variant="outlined"
                              fullWidth
                            />
                          );
                        }}
                      </Field>
                    </Grid>
                  </Grid>

                  <Divider
                    css={css`
                      && {
                        margin-top: 10px;
                        margin-bottom: 10px;
                      }
                    `}
                  />

                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography
                        variant="h1"
                        align="left"
                        css={css`
                          && {
                            font-size: 16px;
                            font-weight: 800;
                            color: #1d1d6d;
                          }
                        `}
                      >
                        Representante Legal
                      </Typography>
                    </Grid>

                    <Grid item xs={4}>
                      <Field name="document_legal">
                        {() => {
                          return (
                            <TextField
                              name="document_legal"
                              label="CPF"
                              autoComplete="nope"
                              required
                              disabled={loading || isFormSubmiting}
                              variant="outlined"
                              fullWidth
                            />
                          );
                        }}
                      </Field>
                    </Grid>

                    <Grid item xs={5}>
                      <Field name="fullname">
                        {() => {
                          return (
                            <TextField
                              name="fullname"
                              label="Nome Completo"
                              autoComplete="nope"
                              required
                              disabled={loading || isFormSubmiting}
                              variant="outlined"
                              fullWidth
                            />
                          );
                        }}
                      </Field>
                    </Grid>

                    <Grid item xs={3}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <Field name="birth_date">
                          {({
                            field: { name, value },
                            form: { setFieldValue },
                            meta: { error, touched },
                          }: any) => {
                            return (
                              <KeyboardDatePicker
                                size={"small"}
                                label="Data Nascimento"
                                cancelLabel="Limpar"
                                value={value || null}
                                format="DD/MM/YYYY"
                                maxDate={new Date()}
                                disabled={loading || isFormSubmiting}
                                maxDateMessage="Data não pode ser futura"
                                invalidDateMessage="Data inválida"
                                error={touched && !!error}
                                helperText={touched && error}
                                disableFuture
                                css={{
                                  maxWidth: 250,
                                }}
                                onChange={(valueDate: any) => {
                                  if (!valueDate) return;
                                  setFieldValue(name, valueDate);
                                }}
                              />
                            );
                          }}
                        </Field>
                      </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid item xs={4}>
                      <Field name="capital_total">
                        {({
                          field: { value, name },
                          form: { setFieldValue, setFieldTouched },
                          meta: { error, touched },
                        }: any) => (
                          <NumberFormat
                            customInput={TextFieldMaterial}
                            fullWidth
                            required
                            format={formatterToPercent}
                            decimalScale={0}
                            isAllowed={capitalTotalValue()}
                            // fixedDecimalScale={true}
                            allowNegative={false}
                            label={"% do Capital Total"}
                            variant="outlined"
                            InputProps={{
                              inputProps: { min: 1, max: 100 },
                            }}
                            disabled={loading || isFormSubmiting}
                            value={value}
                            error={touched && !!error}
                            helperText={touched && error}
                            onBlur={async ({
                              target: { value },
                            }: React.ChangeEvent<HTMLInputElement>) => {
                              setFieldTouched(name, true, false);
                            }}
                            onValueChange={(values: any) =>
                              setFieldValue(name, values.value)
                            }
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={8}>
                      <Field name="attribute">
                        {({
                          field: { value, name },
                          form: { setFieldValue, setFieldTouched },
                          meta: { error, touched },
                        }: any) => {
                          const selectedAttribute = attributesList?.find(
                            (f: any) => f.code === value,
                          );
                          return (
                            <Autocomplete
                              key={`${selectedAttribute}-key`}
                              options={attributesList}
                              disableClearable
                              defaultValue={selectedAttribute}
                              getOptionSelected={(option, value) =>
                                option.code === value.code
                              }
                              onChange={(a: any, value: any) => {
                                if (value)
                                  setFieldValue("attribute", value.code);
                              }}
                              onBlur={() => {
                                setFieldTouched(name, true);
                              }}
                              getOptionLabel={(value: any) =>
                                `${value.code} - ${value.desc}`
                              }
                              renderInput={params => (
                                <TextFieldMaterial
                                  {...params}
                                  label="Atributos"
                                  required
                                  disabled={loading || isFormSubmiting}
                                  error={touched && !!error}
                                  helperText={touched && error}
                                  variant="outlined"
                                  fullWidth
                                />
                              )}
                            />
                          );
                        }}
                      </Field>
                    </Grid>

                    <Grid item xs={2}>
                      <Field name="phone_prefix">
                        {({
                          field: { value, name },
                          form: {
                            setFieldValue,
                            setFieldTouched,
                            isSubmitting,
                          },
                          meta: { error, touched },
                        }: any) => (
                          <NumberFormat
                            customInput={TextFieldMaterial}
                            format="(##)"
                            fullWidth
                            required
                            autoComplete="nope"
                            label="DDD celular"
                            variant="outlined"
                            value={value}
                            disabled={loading || isFormSubmiting}
                            error={touched && !!error}
                            helperText={touched && error}
                            onBlur={({
                              target: { value },
                            }: React.ChangeEvent<HTMLInputElement>) => {
                              setFieldTouched(name, true, false);
                            }}
                            onValueChange={(values: any) =>
                              setFieldValue(name, values.value)
                            }
                          />
                        )}
                      </Field>
                    </Grid>

                    <Grid item xs={4}>
                      <Field name="phone_number">
                        {({
                          field: { value, name },
                          form: {
                            setFieldValue,
                            setFieldTouched,
                            isSubmitting,
                          },
                          meta: { error, touched },
                        }: any) => (
                          <NumberFormat
                            customInput={TextFieldMaterial}
                            format={
                              value?.length === 8 ? "####-#####" : "#####-####"
                            }
                            fullWidth
                            autoComplete="nope"
                            required
                            label="Telefone celular"
                            variant="outlined"
                            value={value}
                            disabled={loading || isFormSubmiting}
                            error={touched && !!error}
                            helperText={touched && error}
                            onBlur={({
                              target: { value },
                            }: React.ChangeEvent<HTMLInputElement>) => {
                              setFieldTouched(name, true, false);
                            }}
                            onValueChange={(values: any) =>
                              setFieldValue(name, values.value)
                            }
                          />
                        )}
                      </Field>
                    </Grid>

                    <Grid item xs={6}>
                      <Field name="email">
                        {() => {
                          return (
                            <TextField
                              name="email"
                              label="E-mail"
                              type="email"
                              disabled={loading || isFormSubmiting}
                              required
                              autoComplete="nope"
                              variant="outlined"
                              fullWidth
                            />
                          );
                        }}
                      </Field>
                    </Grid>

                    <Grid item xs={12}>
                      <Field name="mother_name">
                        {({
                          field: { value, name },
                          meta: { error, touched },
                        }: any) => {
                          return (
                            <TextField
                              name="mother_name"
                              label="Nome da Mãe"
                              autoComplete="nope"
                              required
                              InputProps={{
                                value: value,
                                error: touched && !!error,
                              }}
                              disabled={loading || isFormSubmiting}
                              variant="outlined"
                              fullWidth
                            />
                          );
                        }}
                      </Field>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    css={css`
                      && {
                        margin-top: 24px;
                      }
                    `}
                  >
                    <Grid item xs={9} sm={5}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        css={css`
                          && {
                            margin-top: 20px;
                            border-radius: 15px;
                            width: 100%;
                          }
                        `}
                        onClick={() => {
                          submitForm();
                        }}
                        disabled={
                          loading ||
                          isFormSubmiting ||
                          Object.keys(errors).length > 0
                        }
                      >
                        <div>Continuar</div>
                        {(loading || isFormSubmiting) && (
                          <CircularProgress
                            size={24}
                            color="secondary"
                            css={css`
                              && {
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                margin-top: -12px;
                                margin-left: -12px;
                              }
                            `}
                          />
                        )}
                      </Button>
                    </Grid>
                    {/* <code>{Object.keys(errors)}</code> */}
                  </Grid>

                  <Grid
                    container
                    justify="flex-end"
                    css={css`
                      && {
                        margin-top: 24px;
                      }
                    `}
                  ></Grid>
                </fieldset>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </Fragment>
  );
};

export default OnboardingOriginalFields;
