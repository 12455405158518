import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import {
  getApplications,
  handleSelectApplication,
} from "app/ducks/applications.duck";

import ApplicationsScreen from "./Apps.screen";
import React from "react";
import { Redirect } from "react-router-dom";
import { stateReduxType as appsStateReduxType } from "./Apps.type";
import { stateReduxType as merchantStateReduxType } from "../Merchant/Merchant.type";
import { stateReduxType } from "shared/types/authentication.type";

const useTypedSelector: TypedUseSelectorHook<stateReduxType> = useSelector;
const useTypedSelectorApps: TypedUseSelectorHook<appsStateReduxType> = useSelector;
const useTypedSelectorMerchant: TypedUseSelectorHook<merchantStateReduxType> = useSelector;

const ApplicationsContainer: React.FC<any> = ({ history }) => {
  const dispatch = useDispatch();

  const { applications } = useTypedSelectorApps(state => state.app) || {};
  const { authentication } = useTypedSelector(state => state.shared) || {};
  const { signedIn } = authentication;
  const { data: auth } = authentication;
  const { merchant } = useTypedSelectorMerchant(state => state.app) || {};

  if (!signedIn) {
    return <Redirect to="/login" />;
  }

  const handleSelectedApp = (app: any) =>
    dispatch(handleSelectApplication(app));

  return (
    <ApplicationsScreen
      handleGetApplications={getApplications}
      handleSelectApplication={handleSelectedApp}
      history={history}
      applications={applications}
      merchantState={merchant}
    />
  );
};

export default ApplicationsContainer;
