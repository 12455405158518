import { API_URL_WITH_PORT, PORTS } from "shared/configs/api.config";

import { Dispatch } from "redux";
import fetch from "shared/utils/fetch.util";

type Actions = {
  type: string;
  payload?: object;
  error?: object;
};

export const Types = {
  INIT: "app/issuers/INIT",
  SUCCESS: "app/issuers/SUCCESS",
  ERROR: "app/issuers/ERROR",
};

const initialState: any = {
  issuers: [],
  loading: false,
  error: null,
};

export default (state = initialState, action: Actions) => {
  const { type, payload, error } = action;

  const reducers = {
    [Types.INIT]: {
      ...state,
      error: null,
      loading: true,
    },
    [Types.ERROR]: {
      loading: false,
      issuers: [],
      error,
    },
    [Types.SUCCESS]: {
      ...state,
      issuers: payload,
      loading: false,
      error: false,
    },
  };

  return reducers[type] || state;
};

export const getIssuers = ({ offset, limit }: any) => async (
  dispatch: Dispatch<any>,
) => {
  try {
    dispatch({
      type: Types.INIT,
    });
    const response = await fetch(
      `${API_URL_WITH_PORT(PORTS.APPLICATION)}/issuers?offset=${
        offset ? offset : 0
      }&limit=${limit ? limit : 25}`,
      {
        customUrl: true,
        method: "GET",
        auth: true,
      },
    );

    const result = await response.json();
    if (!response.ok) {
      throw result;
    }

    const { results } = result;

    dispatch({
      type: Types.SUCCESS,
      payload: results,
    });

    return results;
  } catch (e) {
    dispatch({
      type: Types.ERROR,
      error: {
        visible: true,
        ...e,
      },
    });
    return false;
  }
};
