import { Grid, TextField } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { css, jsx } from "@emotion/core";

/** @jsx jsx */

const AttendantReferenceField: React.FC<any> = ({
  enable,
  setAttendantCode,
  attendantCode,
  transactionPending,
  loading,
  props,
}) => {
  if (!enable) return <div></div>;

  return (
    <Grid
      container
      justify="center"
      css={css`
        && {
          margin-top: 24px;
        }
      `}
    >
      <Grid item xs={8} sm={6} lg={6} {...props?.grid}>
        <TextField
          fullWidth
          required
          label={"Digite o código do atendente"}
          variant="outlined"
          disabled={loading || transactionPending}
          value={attendantCode}
          onChange={({ target: { value } }: any) => {
            setAttendantCode(value.trim().toUpperCase());
          }}
        />
      </Grid>
    </Grid>
  );
};

export default AttendantReferenceField;
