import {
  Box,
  Button,
  CircularProgress,
  Grid,
  makeStyles,
} from "@material-ui/core";
/** @jsx jsx */
import React, { Fragment, useEffect, useState } from "react";
import { css, jsx } from "@emotion/core";

const useStyles = makeStyles({
  eventDialogDelete: {
    position: "relative",
    paddingTop: "10px",
    "& .label-seconds": {
      position: "absolute",
      top: 15,
      right: 28,
      color: "#1b900e",
      fontSize: "13px",
      fontWeight: "bold",
    },
  },
});

const LoadingPayment: React.FC<any> = ({
  handleTimeout,
  handleCheckPayment,
  handleCancel,
  children,
  secondsLeft,
}) => {
  const classes = useStyles();

  useEffect(() => {
    const intervalId = setInterval(async () => {
      await handleCheckPayment();
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  if (secondsLeft <= 0) {
    handleTimeout();
    return <div></div>;
  }

  return (
    <Fragment>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={0}
      >
        <Box
          display="flex"
          width={250}
          height={90}
          bgcolor="#f5f5f5"
          borderRadius={10}
          padding={1}
          marginTop={1}
        >
          <Box
            textAlign="center"
            fontSize="13px"
            fontWeight="bold"
            padding="5px"
          >
            {"Aguardando confirmação do pagamento"}
            {children}
          </Box>
          <Box
            textAlign="center"
            alignContent="center"
            alignItems="center"
            alignSelf="center"
            paddingTop="10px"
          >
            <Grid>
              <Button
                onClick={handleCancel}
                size={"small"}
                variant="contained"
                css={css`
                  && {
                    color: #fff;
                    background-color: rgb(220, 0, 78);
                  }
                `}
              >
                Cancelar
              </Button>
            </Grid>
            <div className={classes.eventDialogDelete}>
              <div className="label-seconds">{secondsLeft}</div>
              <CircularProgress size={30} color="secondary" />
            </div>
          </Box>
        </Box>
      </Grid>
    </Fragment>
  );
};

export default LoadingPayment;
