/** @jsx jsx */

import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import { css, jsx } from "@emotion/core";

import { CheckboxWithLabel } from "formik-material-ui";
import FormCreateAccountMerchant from "./CreateAccountMerchant";
import FormCreateAccountUser from "./CreateAccountUser";
import { componentPropTypes } from "./FormCreateAccount.type";
import { currentConfigOptions } from "shared/configs/styles.config";
import { useState } from "react";

const CreateAccountScreen: React.FC<componentPropTypes> = ({
  initialSchema,
  handleCreateAccount,
  formSchema,
  userIsTaken,
}) => {
  const [isFormSubmiting, setSubmitForm] = useState(false);
  const isUpdating = initialSchema.id && initialSchema.id.length > 0;
  return (
    <div>
      <Formik
        initialValues={initialSchema}
        onSubmit={async (values, {}) => {
          setSubmitForm(true);
          await handleCreateAccount(values);
          setSubmitForm(false);
        }}
        validationSchema={formSchema}
        validateOnMount
        enableReinitialize
      >
        {({ values, errors, submitForm, dirty }) => {
          return (
            <Form>
              <fieldset
                disabled={isFormSubmiting}
                css={css`
                  && {
                    border: none;
                  }
                `}
              >
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  css={css`
                    && {
                      margin-bottom: 50px;
                    }
                  `}
                >
                  {isUpdating ? (
                    <Grid item xs={12}>
                      <Typography
                        variant="h1"
                        align="center"
                        css={css`
                          && {
                            font-size: 18px;
                            font-weight: 800;
                            color: #1d1d6d;
                          }
                        `}
                      >
                        MEU CADASTRO
                      </Typography>
                    </Grid>
                  ) : (
                    <p>
                      Preencha os campos abaixo para fazer o seu cadastro e
                      <br />
                      aproveite todos os benefícios de receber com QRCode.
                    </p>
                  )}
                </Grid>

                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item xs={9}>
                    <FormCreateAccountUser isUpdating={isUpdating} />
                  </Grid>
                </Grid>

                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item xs={9}>
                    <Divider
                      css={css`
                        && {
                          margin-top: 24px;
                          margin-bottom: 24px;
                        }
                      `}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item xs={9}>
                    <FormCreateAccountMerchant
                      isUpdating={isUpdating}
                      isPJ={values.merchant.type === "PJ"}
                    />
                  </Grid>
                </Grid>

                {!isUpdating ? (
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    css={css`
                      && {
                        margin-top: 24px;
                      }
                    `}
                  >
                    <Grid item xs={9}>
                      <CheckboxWithLabel
                        id="acceptsTerms"
                        name="merchant.accept_terms"
                        Label={{
                          label: (
                            <label htmlFor="acceptsTerms">
                              Declaro que li e aceito os{" "}
                              <Link
                                href={currentConfigOptions.termsOfUse}
                                target="_blank"
                                css={css`
                                  && {
                                    text-decoration: underline;
                                  }
                                `}
                              >
                                Termos de Uso
                              </Link>
                            </label>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    css={css`
                      && {
                        margin-top: 24px;
                      }
                    `}
                  >
                    <Link
                      href={currentConfigOptions.termsOfUse}
                      target="_blank"
                      css={css`
                        && {
                          text-decoration: underline;
                        }
                      `}
                    >
                      Visualizar Termos de Uso
                    </Link>
                  </Grid>
                )}

                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  css={css`
                    && {
                      margin-top: 24px;
                    }
                  `}
                >
                  <Grid item xs={9} sm={5}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="secondary"
                      css={css`
                        && {
                          margin-top: 20px;
                          border-radius: 15px;
                          width: 100%;
                        }
                      `}
                      onClick={submitForm}
                      disabled={
                        Object.keys(errors).length > 0 || !dirty || userIsTaken
                      }
                    >
                      {isUpdating ? <div>ATUALIZAR</div> : <div>CADASTRAR</div>}
                      {isFormSubmiting && (
                        <CircularProgress
                          size={24}
                          color="secondary"
                          css={css`
                            && {
                              position: absolute;
                              top: 50%;
                              left: 50%;
                              margin-top: -12px;
                              margin-left: -12px;
                            }
                          `}
                        />
                      )}
                    </Button>
                  </Grid>
                </Grid>

                <Grid
                  container
                  justify="flex-end"
                  css={css`
                    && {
                      margin-top: 24px;
                    }
                  `}
                ></Grid>
              </fieldset>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default CreateAccountScreen;
