import * as Yup from "yup";

export const terminalCreate = Yup.object().shape({
  model: Yup.string().required("Campo obrigatório"),
  manufacturer: Yup.string().required("Campo obrigatório"),
  number: Yup.string()
    .nullable()
    .required("Campo obrigatório"),
});

export const terminalEdit = Yup.object().shape({
  model: Yup.string().required("Campo obrigatório"),
  manufacturer: Yup.string().required("Campo obrigatório"),
  number: Yup.string()
    .nullable()
    .required("Campo obrigatório"),
  active: Yup.boolean()
    .default(true)
    .required("Campo obrigatório"),
});
