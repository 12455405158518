import { Grid, Slide, Snackbar } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { css, jsx } from "@emotion/core";

import { TransitionProps } from "@material-ui/core/transitions";

/** @jsx jsx */

function TransitionDown(props: TransitionProps) {
  return <Slide {...props} direction="down" />;
}
// /autoHideDuration={6000}
const SnackNotification: React.FC<any> = ({
  children,
  open,
  onClose,
  snackProps,
}) => {
  return (
    <Grid item xs={12} sm={12}>
      <Grid
        container
        justify="flex-end"
        css={css`
          && {
            margin-top: 24px;
          }
        `}
      ></Grid>
      <Snackbar
        {...snackProps}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        TransitionComponent={TransitionDown}
        open={open}
        onClose={() => onClose(false)}
      >
        {children}
      </Snackbar>
    </Grid>
  );
};

export default SnackNotification;
