import * as Yup from "yup";

export default Yup.object().shape({
  password: Yup.string().required("Campo obrigatório"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), null], "As senhas não conferem")
    .required("Campo obrigatório")
});

export const acceptTermsSchema = Yup.object().shape({
  accept_terms: Yup.boolean()
  .oneOf([true, null], "Você precisa aceitar os termos")
  .required("Campo obrigatório")
});
