import { Grid, ThemeProvider } from "@material-ui/core";
/** @jsx jsx */
import React, { Fragment, useEffect, useState } from "react";
import { css, jsx } from "@emotion/core";
import { Skeleton } from "@material-ui/lab";
import {
  CustomMuiTheme,
  MuiDefaultOptions,
  useTableCustomStyles,
} from "app/components/Grid/MuiTable";
import MUIDataTable from "mui-datatables";

const TableDefault: React.FC<any> = ({
  loading,
  title,
  subtitle,
  data,
  columns,
  tableOptions,
}) => {
  const classes = useTableCustomStyles();

  return (
    <Grid item xs={12} sm={12} lg={12}>
      {loading ? (
        <Grid item>
          <Skeleton variant="rect" height={30} />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
        </Grid>
      ) : (
        <Grid item xs={12} sm={12} lg={12}>
          <ThemeProvider theme={CustomMuiTheme}>
            <MUIDataTable
              title={
                <Fragment>
                  <Grid className={classes.tableTitle}>{title}</Grid>
                  <Grid>
                    <small>{subtitle}</small>
                  </Grid>
                </Fragment>
              }
              data={data}
              css={css`
                && {
                  margin-top: 15px;
                }
              `}
              columns={columns}
              options={{
                ...MuiDefaultOptions,
                ...tableOptions,
              }}
            />
          </ThemeProvider>
        </Grid>
      )}
    </Grid>
  );
};

export default TableDefault;
