import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  IconButton,
  Paper,
  Slide,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  createMuiTheme,
  makeStyles,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { css, jsx } from "@emotion/core";
import {
  decimalFormatter4,
  formatterToCurrency,
} from "shared/utils/formatter.util";

import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PaymentReceipt from "./PaymentReceipt";
import Print from "@material-ui/icons/Print";
import { TransitionProps } from "@material-ui/core/transitions";
import { colors } from "shared/configs/styles.config";
import moment from "moment";
import { ptBR } from "@material-ui/core/locale";
import { getIntegrationTypeFlags } from "../getAppTypeFlags";

/** @jsx jsx */

function TransitionDown(props: TransitionProps) {
  return <Slide {...props} direction="down" />;
}

const theme = createMuiTheme(
  {
    palette: {
      primary: {
        main: colors.primary,
      },
      secondary: {
        main: colors.secondary,
      },
    },
    overrides: {
      MuiTableCell: {
        paddingNone: {
          padding: "5px",
        },
      },
    },
  },
  ptBR,
);

const useStyles = makeStyles({
  paymentInfoContainer: {
    minWidth: 275,
  },
  containerCardPayment: {
    backgroundColor: "#4caf50",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
});

const PaymentInfo: React.FC<any> = ({
  enable,
  setIsPaid,
  paymentInfo,
  currencyFormatter,
  application,
}) => {
  const {
    isPinbankApp,
    isAmeApp,
    isPayInsightsApp,
    isUsePayApp,
    isTicketApp,
  } = getIntegrationTypeFlags(application?.issuer_entity?.identifier_code);

  const classes = useStyles();
  const [enablePrint, setEnablePrint] = useState(false);
  if (!enable) return <div></div>;

  let authorization_number: string = "";
  if (paymentInfo?.authorization_number)
    authorization_number = paymentInfo?.authorization_number.toString();

  return (
    <Grid item xs={12} sm={12}>
      <Grid
        container
        justify="flex-end"
        css={css`
          && {
            margin-top: 24px;
          }
        `}
      ></Grid>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        TransitionComponent={TransitionDown}
        open={true && !enablePrint}
        onClose={() => setIsPaid(false)}
      >
        <Card>
          <CardHeader className={classes.containerCardPayment} />
          <CardHeader
            titleTypographyProps={{
              variant: "h6",
              color: "secondary",
            }}
            avatar={
              <CheckCircleOutlineIcon
                color="secondary"
                style={{ fontSize: 50 }}
              />
            }
            title="Pagamento Confirmado!"
            subheader={moment(paymentInfo.authorization_date).format("LLLL")}
            subheaderTypographyProps={{
              variant: "subtitle2",
            }}
          />

          <CardContent>
            <Paper variant="outlined" square>
              <Grid style={{ padding: "10px" }}>
                {paymentInfo.status_description &&
                  paymentInfo.status_description.length > 1 && (
                    <Typography
                      variant="caption"
                      display="block"
                      color="textSecondary"
                    >
                      Desc. Pagamento: {paymentInfo.status_description}
                    </Typography>
                  )}
                {paymentInfo?.transaction_amount > 0 && (
                  <Typography
                    variant="caption"
                    display="block"
                    color="textSecondary"
                  >
                    Valor:{" "}
                    {currencyFormatter(paymentInfo.transaction_amount * 100)}
                  </Typography>
                )}

                {paymentInfo?.transaction_amount > 0 &&
                  paymentInfo?.transaction_amount !==
                    paymentInfo?.original_amount && (
                    <Fragment>
                      <Typography
                        variant="caption"
                        display="block"
                        color="textSecondary"
                      >
                        <Fragment>
                          Valor Original:{" "}
                          {currencyFormatter(paymentInfo.original_amount * 100)}
                        </Fragment>
                      </Typography>
                      <Typography
                        variant="caption"
                        display="block"
                        color="textSecondary"
                      >
                        <Fragment>
                          Desconto:{" "}
                          {currencyFormatter(
                            (paymentInfo.original_amount -
                              paymentInfo.transaction_amount) *
                              100,
                          )}
                        </Fragment>
                      </Typography>
                    </Fragment>
                  )}

                <Typography
                  variant="caption"
                  color="textSecondary"
                  display="block"
                >
                  Nº Autorização:{" "}
                  {authorization_number?.length <= 10
                    ? authorization_number
                    : `${authorization_number.substring(
                        0,
                        5,
                      )}...${authorization_number.slice(-5)}`}
                </Typography>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  display="block"
                >
                  Referência: {paymentInfo.reference_id}
                </Typography>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  display="block"
                >
                  NSU: {paymentInfo.nsu}
                </Typography>
                {paymentInfo.attendant_description &&
                  paymentInfo.attendant_description.length > 1 && (
                    <Typography
                      variant="caption"
                      display="block"
                      color="textSecondary"
                      css={css`
                        && {
                          margin-top: 15px;
                        }
                      `}
                    >
                      Atendente: {paymentInfo.attendant_description}
                    </Typography>
                  )}
                {(paymentInfo?.details_metadata?.items ||
                  paymentInfo?.details_metadata?.singleItem) && (
                  <ExpansionPanel>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography className={classes.heading}>Itens</Typography>
                      <Typography className={classes.secondaryHeading}>
                        Lista de produtos ou serviços
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Descrição</TableCell>
                            <TableCell align="right">Quantidade</TableCell>
                            <TableCell align="right">
                              {isUsePayApp ? "Preço Unitário" : "Valor"}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {[
                            paymentInfo?.details_metadata?.singleItem,
                            ...(Array.isArray(
                              paymentInfo?.details_metadata?.items,
                            )
                              ? paymentInfo?.details_metadata?.items
                              : [paymentInfo?.details_metadata?.items] || []),
                          ]
                            .filter(f => f !== null && f?.quantity > 0)
                            .map((item: any) => (
                              <TableRow key={Math.random()}>
                                <TableCell component="th" scope="row">
                                  {item.description}
                                </TableCell>
                                <TableCell align="right">
                                  {item.quantity}
                                </TableCell>
                                <TableCell align="right">
                                  {isUsePayApp
                                    ? decimalFormatter4(item.value)
                                    : formatterToCurrency(
                                        (item.value |
                                          paymentInfo.transaction_amount) /
                                          100,
                                      )}
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                )}
              </Grid>
            </Paper>
          </CardContent>
          <CardActions>
            <Grid
              container
              justify="center"
              alignItems="center"
              direction="row"
            >
              <Grid container justify="center" alignItems="center">
                <Grid item xs={12} sm={12} lg={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    css={css`
                      && {
                        border-radius: 15px;
                      }
                    `}
                    onClick={() => {
                      setEnablePrint(true);
                    }}
                  >
                    Imprimir comprovante
                    <Print
                      fontSize={"small"}
                      css={css`
                        && {
                          margin-left: 5px;
                        }
                      `}
                    />
                  </Button>
                </Grid>
              </Grid>
              <Grid
                container
                justify="center"
                css={css`
                  && {
                    margin-top: 10px;
                    margin-bottom: 2px;
                  }
                `}
              >
                <Grid item xs={12} sm={12} lg={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    css={css`
                      && {
                        border-radius: 15px;
                      }
                    `}
                    onClick={() => {
                      setIsPaid(false);
                    }}
                  >
                    OK
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </Snackbar>
      <Dialog open={enablePrint} onClose={() => setEnablePrint(false)}>
        <DialogContent>
          <PaymentReceipt
            enable={enablePrint}
            handleClose={() => setEnablePrint(false)}
            transactionInfo={paymentInfo}
            application={application}
          />
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default PaymentInfo;
