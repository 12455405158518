import { Button, Grid, TextField, Typography } from "@material-ui/core";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { css, jsx } from "@emotion/core";
import {
  currencyFormatterComp,
  formatterToCurrency,
} from "shared/utils/formatter.util";
import {
  getTransactionValueMax,
  getTransactionValueMin,
  transactionValueIsAllowed,
  transactionValueOnBlur,
} from "app/components/TransactionTotalValue";

import AppsTwoToneIcon from "@material-ui/icons/AppsTwoTone";
import AttendantReferenceField from "app/components/Fields/AttendantReferenceField";
import { Autocomplete } from "@material-ui/lab";
import ExternalReferenceField from "app/components/Fields/ExternalReferenceField";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import Header from "app/components/Header/Header.container";
import LastTransactionsPaymentLink from "./components/LastTransactionsPaymentLink";
import NumberFormat from "react-number-format";
import TransactionError from "app/components/Transaction/TransactionError";
import TransactionInfo from "./components/TransactionCreatedInfo";
import { screenPropsType } from "./CustomPaymentLink.type";
import { useDispatch } from "react-redux";
import AppSelector from "../Apps/components/AppSelector";

/** @jsx jsx */

enum ContactType {
  "E-mail" = "E-mail",
  SMS = "SMS",
  Whatsapp = "Whatsapp",
}

const useFocus = () => {
  const htmlElRef = useRef<any>(null);
  const setFocus = () => {
    setTimeout(() => {
      htmlElRef.current && htmlElRef.current.focus();
    }, 0);
  };

  return [htmlElRef, setFocus] as const;
};

const TransactionScreen: React.FC<screenPropsType> = ({
  handleNewTransaction,
  getTransactions,
  application,
  apps,
  transactions,
  reports,
  merchantParams,
  history,
  availableMerchants,
  activeMerchant,
}) => {
  const dispatch = useDispatch();

  const backToAppsSelection = () => {
    history.push("/app");
  };

  const {
    loading: defLoading,
    error,
    // transactions: lastTransactions,
  } = transactions;

  const {
    loading: loadingReport,
    transactionsDetails: lastTransactions,
  } = reports || {
    transactionsDetails: [],
  };

  const [actionLoading, setActionLoading] = useState(false);
  let loading = defLoading || loadingReport || actionLoading;
  const [transactionDetailsItems, setTransactionDetailsItems] = useState<any>(
    null,
  );

  const [externalReference, setExternalReference] = useState("");
  const [transactionValue, setTransactionValue] = useState("");
  const [transactionInvalid, setTransactionInvalid] = useState(true);
  const [hasError, setHasError] = useState(false);

  const [attendantCode, setAttendantCode] = useState("");

  const [displayTransactionInfo, setDisplayTransactionInfo] = useState(false);
  const [transactionInfo, setTransactionInfo] = useState(null);

  const [requestExternalReference, setRequestExternalReference] = useState(
    false,
  );

  const [requestAttendantReference, setRequestAttendantReference] = useState(
    false,
  );
  const initialReference = {
    aplicativo: "",
    contato: "",
    tipo_contato: ContactType.Whatsapp,
    prefixo_telefone: "",
    telefone: "",
    numero_pedido: "",
  };
  const [referenceInformation, setReferenceInformation] = useState(
    initialReference,
  );

  const [errors, setErrors] = useState<any>({});
  const [transactionValueError, setTransactionValueError] = useState(false);
  const [displayTransactions, setDisplayTransactions] = useState(false);

  useEffect(() => {
    let errorsList: any = {};

    if (!referenceInformation.aplicativo) {
      errorsList["aplicativo"] = "Obrigatório";
    }

    if (
      referenceInformation.tipo_contato === ContactType["E-mail"] &&
      (!referenceInformation.contato ||
        !!!referenceInformation.contato.match(/.+@.+/))
    ) {
      errorsList[referenceInformation.contato] = "Obrigatório";
    }
    if (
      (referenceInformation.tipo_contato === ContactType.SMS ||
        referenceInformation.tipo_contato === ContactType.Whatsapp) &&
      (!referenceInformation.prefixo_telefone || !referenceInformation.telefone)
    ) {
      errorsList[referenceInformation.telefone] = "Obrigatório";
    }
    if (Number(transactionValue) <= 0) {
      errorsList["transactionValue"] = "Obrigatório";
    }
    if (requestExternalReference && externalReference === "") {
      errorsList["externalReference"] = "Obrigatório";
    }
    if (requestAttendantReference && attendantCode === "") {
      errorsList["attendantCode"] = "Obrigatório";
    }
    if (transactionValueError) {
      errorsList["transactionValueError"] = "Valor inválido";
    }

    const invalid = errorsList && Object.keys(errorsList).length > 0;
    setErrors(errorsList);
    setTransactionInvalid(invalid);
  }, [
    referenceInformation,
    transactionValue,
    externalReference,
    attendantCode,
    transactionValueError,
    transactionDetailsItems,
  ]);

  useEffect(() => {
    let requireExternalCode = false;
    let requireAttendantCode = false;
    if (merchantParams?.merchant_group) {
      requireExternalCode =
        merchantParams.merchant_group.enable_external_reference;
      requireAttendantCode =
        merchantParams.merchant_group.enable_attendant_reference;
    }
    setRequestExternalReference(requireExternalCode);
    setRequestAttendantReference(requireAttendantCode);
  }, [merchantParams]);

  const handleClearState = () => {
    setTransactionValue("");
    setExternalReference("");
    setAttendantCode("");
    setTransactionDetailsItems(null);
    setReferenceInformation(initialReference);
  };
  useEffect(() => {
    if (error) setHasError(true);
  }, [error]);

  useEffect(() => {
    setActionLoading(true);
    setTimeout(() => {
      setActionLoading(false);
    }, 400);
  }, [displayTransactions]);

  // focus handlers
  const [refInputTelefone, setTelefoneFocus] = useFocus();
  useEffect(() => {
    if (referenceInformation.prefixo_telefone.length >= 2) setTelefoneFocus();
  }, [referenceInformation.prefixo_telefone]);

  const [refInputApplication, setApplicationFocus] = useFocus();
  useEffect(() => {
    if (referenceInformation.telefone.length >= 9) setApplicationFocus();
  }, [referenceInformation.telefone]);

  const [refValueInput, setValueFocus] = useFocus();
  useEffect(() => {
    if (referenceInformation.aplicativo) setValueFocus();
  }, [referenceInformation.aplicativo]);

  const handleAddNewTransaction = async () => {
    if (transactionInvalid) return;

    let contato = "";
    let tipo_contato = "";

    if (referenceInformation.tipo_contato === ContactType.SMS) {
      tipo_contato = "Telefone";
      contato = `${referenceInformation.prefixo_telefone}${referenceInformation.telefone}`;
    } else if (referenceInformation.tipo_contato === ContactType["E-mail"]) {
      tipo_contato = "Email";
      contato = referenceInformation.contato;
    } else if (referenceInformation.tipo_contato === ContactType.Whatsapp) {
      tipo_contato = "Whatsapp";
      contato = `55${referenceInformation.prefixo_telefone}${referenceInformation.telefone}`;
    }

    let extraDetails: any = {
      reference: {
        ...referenceInformation,
        contato,
        tipo_contato,
        aplicativo: referenceInformation.aplicativo,
      },
    };
    if (requestAttendantReference) {
      extraDetails = {
        ...extraDetails,
        attendant: {
          code: attendantCode,
        },
      };
    }

    const result = await dispatch(
      handleNewTransaction({
        application_root_id: application.id,
        application: {
          id: referenceInformation.aplicativo,
        },
        valor: transactionValue,
        external_reference: externalReference,
        installments: 1,
        details: {
          ...extraDetails,
        },
      }),
    );
    if (result) {
      setTransactionInfo(result);
      setDisplayTransactionInfo(true);
      handleClearState();
    }
  };

  if (!application) {
    history.push("/app");
    return <div></div>;
  }

  const appsList = application.sub_applications
    ?.map((appRef: any) => {
      const appRes: any = apps.find((f: any) => f.id === appRef.application_id);
      if (appRes)
        return {
          id: appRes.id,
          name: appRes.name,
          logo_path: appRes.logo_path,
        };
    })
    .filter((f: any) => f);

  return (
    <Fragment>
      <Grid container justify="center" alignItems="center" direction="row">
        <Grid item xs={12} lg={12}>
          <Header authentication={null} loading={loading} />
        </Grid>
        <TransactionInfo
          enable={displayTransactionInfo}
          setEnabled={(value: boolean) => {
            if (!value) setTransactionInfo(null);
            setDisplayTransactionInfo(value);
          }}
          transactionInfo={transactionInfo}
          application={application}
        />
        <Grid
          item
          xs={12}
          css={css`
            && {
              margin-top: 24px;
            }
          `}
        >
          <GridList>
            <GridListTile key="Subheader" cols={2} style={{ height: "auto" }}>
              <Grid item>
                <Typography variant="h6" align={"center"}>
                  {displayTransactions
                    ? "Posição dos Pagamentos por Link"
                    : "Nova transação com o aplicativo"}
                </Typography>
              </Grid>
              <Grid
                container
                justify="center"
                alignItems="center"
                direction="row"
              >
                <img
                  src={application?.logo_path}
                  alt={application?.name}
                  style={{ width: "150px" }}
                />
              </Grid>
            </GridListTile>
          </GridList>
        </Grid>

        {/* QrCode */}
        {!displayTransactions && (
          <Grid container direction={"row"}>
            <Grid item xs={12} sm={7} lg={7}>
              <Grid
                container
                justify="center"
                css={css`
                  && {
                    margin-top: 10px;
                  }
                `}
              >
                <Grid item xs={8} sm={7} lg={7}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                      <Autocomplete
                        disableClearable={true}
                        autoComplete
                        autoSelect
                        style={{ width: "100%" }}
                        loading={loading}
                        disabled={loading}
                        value={referenceInformation.tipo_contato}
                        // options={Object.keys(ContactType)}
                        options={[ContactType["E-mail"], ContactType.Whatsapp]}
                        onChange={(event: any, value: any) => {
                          setReferenceInformation({
                            ...referenceInformation,
                            tipo_contato: value as ContactType,
                          });
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            style={{ width: "100%" }}
                            variant="outlined"
                            label="Tipo Contato"
                            placeholder="Tipo Contato"
                          />
                        )}
                      />
                    </Grid>
                    {referenceInformation.tipo_contato ===
                      ContactType["E-mail"] && (
                      <Grid item xs>
                        <TextField
                          fullWidth
                          required
                          inputProps={{
                            type: "email",
                          }}
                          label={"E-mail Pagador"}
                          variant="outlined"
                          // error={!!errors[referenceInformation.contato]}
                          disabled={loading}
                          value={referenceInformation.contato}
                          onChange={({ target: { value } }: any) => {
                            setReferenceInformation({
                              ...referenceInformation,
                              contato: value,
                            });
                          }}
                        />
                      </Grid>
                    )}
                    {(referenceInformation.tipo_contato === ContactType.SMS ||
                      referenceInformation.tipo_contato ===
                        ContactType.Whatsapp) && (
                      <Grid item xs>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={3}>
                            <NumberFormat
                              customInput={TextField}
                              format="(##)"
                              fullWidth
                              required
                              autoComplete="nope"
                              disabled={loading}
                              label="DDD"
                              variant="outlined"
                              value={referenceInformation.prefixo_telefone}
                              onValueChange={(values: any) => {
                                setReferenceInformation({
                                  ...referenceInformation,
                                  prefixo_telefone: values.value,
                                });
                              }}
                            />
                          </Grid>
                          <Grid item xs>
                            <NumberFormat
                              customInput={TextField}
                              format={
                                referenceInformation.contato.length === 8
                                  ? "####-#####"
                                  : "#####-####"
                              }
                              inputRef={refInputTelefone}
                              fullWidth
                              autoComplete="nope"
                              required
                              disabled={loading}
                              label="Celular Pagador"
                              variant="outlined"
                              value={referenceInformation.telefone}
                              onValueChange={(values: any) =>
                                setReferenceInformation({
                                  ...referenceInformation,
                                  telefone: values.value,
                                })
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                justify="center"
                css={css`
                  && {
                    margin-top: 10px;
                  }
                `}
              >
                <Grid item xs={8} sm={7} lg={7}>
                  <AppSelector
                    loading={loading}
                    appsList={appsList}
                    appId={referenceInformation.aplicativo}
                    onChange={(event: any, value: any) => {
                      setReferenceInformation({
                        ...referenceInformation,
                        aplicativo: value.id,
                      });
                    }}
                    inputRef={refInputApplication}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                justify="center"
                css={css`
                  && {
                    margin-top: 10px;
                  }
                `}
              >
                <Grid item xs={8} sm={7} lg={7}>
                  <NumberFormat
                    customInput={TextField}
                    inputRef={refValueInput}
                    fullWidth
                    required
                    format={currencyFormatterComp}
                    onBlur={transactionValueOnBlur({
                      application,
                      transactionValue,
                      decimalScale: 2,
                      setTransactionValueError,
                    })}
                    isAllowed={transactionValueIsAllowed({
                      getTransactionValueMax,
                    })}
                    error={transactionValueError}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator="."
                    decimalSeparator=","
                    allowNegative={false}
                    isNumericString={true}
                    prefix={"R$ "}
                    disabled={loading}
                    label={"Digite o valor da transação"}
                    variant="outlined"
                    value={transactionValue}
                    onValueChange={(values: any) => {
                      setTransactionValue(values.value);
                    }}
                  />
                  {transactionValueError && (
                    <small>
                      <Typography
                        variant="caption"
                        color={"error"}
                        align={"center"}
                      >
                        Valor inválido. Minímo:{" "}
                        {formatterToCurrency(
                          getTransactionValueMin(application),
                        )}{" "}
                        e Máximo:{" "}
                        {formatterToCurrency(
                          getTransactionValueMax(application),
                        )}
                      </Typography>
                    </small>
                  )}
                </Grid>
              </Grid>

              <Grid
                container
                justify="center"
                css={css`
                  && {
                    margin-top: 24px;
                  }
                `}
              >
                <Grid item xs={8} sm={7} lg={7}>
                  <TextField
                    fullWidth
                    label={"Número do Pedido"}
                    variant="outlined"
                    disabled={loading}
                    value={referenceInformation.numero_pedido}
                    onChange={({ target: { value } }: any) => {
                      setReferenceInformation({
                        ...referenceInformation,
                        numero_pedido: value?.toUpperCase(),
                      });
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container>
                <ExternalReferenceField
                  enable={requestExternalReference}
                  // merchantParams={merchantParams}
                  setExternalReference={setExternalReference}
                  externalReference={externalReference}
                  transactionPending={loading}
                  loading={loading}
                />
                <AttendantReferenceField
                  enable={requestAttendantReference}
                  setAttendantCode={setAttendantCode}
                  attendantCode={attendantCode}
                  transactionPending={loading}
                  loading={loading}
                  props={{
                    grid: { xs: 8, sm: 7, lg: 7 },
                  }}
                />
              </Grid>

              <Grid
                container
                justify="center"
                css={css`
                  && {
                    margin-top: 10px;
                    margin-bottom: 10px;
                  }
                `}
              >
                <Grid item xs={8} sm={7} lg={7}>
                  <Button
                    onClick={handleAddNewTransaction}
                    fullWidth
                    variant="contained"
                    color="secondary"
                    disabled={loading || transactionInvalid}
                    css={css`
                      && {
                        margin-top: 10px;
                        border-radius: 15px;
                      }
                    `}
                  >
                    Enviar Link de Pagamento{" "}
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={5} lg={5}>
              <TransactionError
                enable={!!error && hasError}
                setHasError={setHasError}
                error={error}
              />
              <Grid item xs={12} sm={12} lg={12}>
                <Grid
                  css={css`
                    && {
                      margin-bottom: 10px;
                    }
                  `}
                  item
                  xs={12}
                  sm={12}
                  lg={12}
                >
                  <Button
                    onClick={() => {
                      setDisplayTransactions(true);
                      setActionLoading(true);
                    }}
                    fullWidth
                    variant="outlined"
                    color="primary"
                    disabled={loading}
                    css={css`
                      && {
                        border-radius: 15px;
                      }
                    `}
                  >
                    Ver Posição dos Pagamentos por Link
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}

        {displayTransactions && (
          <Grid container direction={"row"}>
            <LastTransactionsPaymentLink
              transactionPending={loading}
              loading={loading}
              getTransactions={getTransactions}
              lastTransactions={lastTransactions}
              meta={{ ...referenceInformation }}
              setDisplayTransactions={setDisplayTransactions}
              merchantParams={merchantParams}
              availableMerchants={availableMerchants}
              activeMerchant={activeMerchant}
              setActionLoading={setActionLoading}
            />
          </Grid>
        )}

        {!displayTransactions && (
          <Grid item xs={6}>
            <Button
              onClick={backToAppsSelection}
              fullWidth
              color="secondary"
              disabled={loading}
              startIcon={<AppsTwoToneIcon />}
              css={css`
                && {
                  margin-top: 30px;
                  font-weight: bold;
                }
              `}
            >
              IR PARA APLICATIVOS
            </Button>
          </Grid>
        )}

        <Grid
          container
          justify="flex-end"
          css={css`
            && {
              margin-top: 24px;
            }
          `}
        ></Grid>
      </Grid>
    </Fragment>
  );
};

export default TransactionScreen;
