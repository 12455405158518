import {
  AppBar,
  Avatar,
  Divider,
  Grid,
  Hidden,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { css, jsx } from "@emotion/core";

import AppsIcon from "@material-ui/icons/Apps";
import BusinessIcon from "@material-ui/icons/Business";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Logo from "shared/components/Logo";
import Messages from "./Messages";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import PeopleIcon from "@material-ui/icons/People";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";

import { PeopleOutline } from "@material-ui/icons";
import TodayIcon from "@material-ui/icons/Today";
import { colors } from "shared/configs/styles.config";
import packageJson from "../../../../package.json";
import { useDispatch } from "react-redux";
import TabletAndroidIcon from "@material-ui/icons/TabletAndroid";

/** @jsx jsx */

const Header: React.FC<any> = ({
  handleLogout,
  authentication,
  history,
  loading,
  applications,
  merchantParams,
  messages,
  setReadAllMessages,
  loadNotifications,
}) => {
  const dispatch = useDispatch();
  const [open, setMenuOpen] = useState(false);

  const { data } = authentication;
  const { user } = data;

  const isAdmin = user?.profile?.key === "AdminEC";
  var matches = user.name.match(/\b(\w)/g);
  const avatarAcronym = matches.join("");

  const handleLogOff = () => {
    setMenuOpen(false);
    dispatch(handleLogout());
  };
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const PayInsightCode = "99";
  const hasPayInsights =
    applications.filter(
      (f: any) => f.issuer_entity.identifier_code === PayInsightCode,
    ).length > 0;

  useEffect(() => {
    if (!user) return;
    loadNotifications();
  }, [user]);

  const messagesToRead =
    messages && messages.filter((f: any) => !f.read).length;

  const IsInternalAdm = user?.email === "vb.sistemas@gmail.com";
  return (
    <div
      css={css`
        flex-grow: 1;
        margin-bottom: 70px;
      `}
    >
      <AppBar position="fixed" color="default">
        <Toolbar>
          <Logo
            css={css`
              && {
                max-width: 80px;
                cursor: pointer;
              }
            `}
          />
          <Hidden only={["xs"]}>
            <Typography
              css={css`
                margin-left: 80px;
                flex-grow: 1;
                text-align: right;
              `}
              noWrap
            >
              {user?.merchants[0]?.name || "Sem Estabelecimento"} |
            </Typography>
          </Hidden>
          <div
            css={css`
              && {
                position: absolute;
                bottom: 0px;
                font-size: xx-small;
              }
            `}
          >
            <small> {`v.${packageJson.version}`}</small>
          </div>

          <Messages
            messagesToRead={messagesToRead}
            messages={messages}
            setReadAllMessages={setReadAllMessages}
          />

          <div>
            <IconButton aria-controls="menu-appbar" onClick={handleMenu}>
              <Avatar
                css={css`
                  && {
                    background-color: ${colors.primary};
                    height: 32px;
                    width: 32px;
                    font-size: small;
                  }
                `}
              >
                {avatarAcronym}
              </Avatar>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={open}
              onClose={() => setMenuOpen(false)}
            >
              {isAdmin && (
                <div>
                  <MenuItem onClick={() => history.push("/app/profile")}>
                    <BusinessIcon /> &nbsp;&nbsp;Meu cadastro
                  </MenuItem>
                  <MenuItem onClick={() => history.push("/app/users")}>
                    <PeopleIcon />
                    &nbsp;&nbsp;Usuário caixa
                  </MenuItem>
                  <MenuItem onClick={() => history.push("/app/terminals")}>
                    <TabletAndroidIcon />
                    &nbsp;&nbsp;Terminais POS
                  </MenuItem>
                  <MenuItem onClick={() => history.push("/app/attendants")}>
                    <PeopleOutline />
                    &nbsp;&nbsp;Atendentes
                  </MenuItem>

                  <Divider />
                  <MenuItem
                    onClick={() => history.push("/app/report-matching")}
                  >
                    <EventAvailableIcon /> &nbsp;&nbsp;Extrato de Conciliação
                  </MenuItem>

                  {IsInternalAdm && (
                    <MenuItem onClick={() => history.push("/app/refunds")}>
                      <AccountBalanceIcon />
                      &nbsp;&nbsp;Consulta de Reembolso
                    </MenuItem>
                  )}

                  {hasPayInsights && (
                    <MenuItem
                      onClick={() => history.push("/app/report-receivables")}
                    >
                      <MonetizationOnIcon /> &nbsp;&nbsp;Gestão OndaPay
                    </MenuItem>
                  )}
                </div>
              )}

              <Divider />
              <MenuItem onClick={() => history.push("/app/report-closing")}>
                <TodayIcon /> &nbsp;&nbsp;Fechar caixa
              </MenuItem>
              <Divider />

              <MenuItem
                onClick={() => history.push("/app")}
                style={{ color: colors.secondary }}
              >
                <AppsIcon /> &nbsp;&nbsp;Aplicativos
              </MenuItem>
              <Divider />
              <MenuItem onClick={handleLogOff}>
                <ExitToAppIcon />
                &nbsp;&nbsp;Sair
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      {loading && !!loading && (
        <Grid>
          <LinearProgress
            color="secondary"
            css={css`
              && {
                margin-top: 70px;
                width: 100%;
                top: 0;
                left: auto;
                right: 0;
                position: fixed;
              }
            `}
          />
        </Grid>
      )}
    </div>
  );
};

export default Header;
