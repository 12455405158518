import * as Yup from "yup";

export const userCreate = Yup.object().shape({
  name: Yup.string().required("Campo obrigatório"),
  email: Yup.string()
    .email("E-mail deve ser válido")
    .required("Campo obrigatório")
});

export const userEdit = Yup.object().shape({
  name: Yup.string().required("Campo obrigatório")
});
