import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
  createMuiTheme,
  makeStyles,
} from "@material-ui/core";
import { css, jsx } from "@emotion/core";

import AlarmOff from "@material-ui/icons/AlarmOff";
import CheckIcon from "@material-ui/icons/Check";
import Paper from "@material-ui/core/Paper";
import React, { useEffect } from "react";
import ScheduleIcon from "@material-ui/icons/Schedule";
import { Skeleton } from "@material-ui/lab";
import { colors } from "shared/configs/styles.config";
import { formatterToBrlCurrency } from "shared/utils/formatter.util";
import moment from "moment";
import TransactionsPaymentLink from "./TransactionsPaymentLink";

/** @jsx jsx */

const rowStyleFormat = (row: any, rowIdx: any) => {
  return {
    backgroundColor: rowIdx % 2 === 0 ? "rgba(0, 0, 0, 0.04)" : "#ffffff",
  };
};

const theme = createMuiTheme({
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
  },
  overrides: {
    MuiTableCell: {
      paddingNone: {
        padding: "2px 2px 2px 2px",
      },
    },
  },
});

const useStyles = makeStyles({
  tableTitle: {
    fontSize: "14px",
    backgroundColor: colors.tableTitleBack,
  },
  tableHeader: {
    fontSize: "11px",
  },
  tableRow: {
    fontSize: "12px",
  },
});

const LastTransactionsPaymentLink: React.FC<any> = ({
  transactionPending,
  loading,
  getTransactions,
  lastTransactions,
  meta,
  setDisplayTransactions,
  merchantParams,
  availableMerchants,
  activeMerchant,
  setActionLoading,
}) => {
  const classes = useStyles();

  const getRows = (row: any, index: any) => {
    const defaultExpiration = 30;
    const dateTran = moment(moment(row.date).add(defaultExpiration, "minutes"));
    const expired = dateTran.isBefore(moment());

    return (
      <TableRow key={index} style={rowStyleFormat(row, index)}>
        <TableCell className={classes.tableRow} align="center">
          {moment(row.date).format("DD/MM/YYYY HH:mm:ss")}
        </TableCell>
        <TableCell className={classes.tableRow} align="center">
          {row.nsu}
        </TableCell>
        <TableCell className={classes.tableRow} align="center">
          {row.details_metadata?.reference?.numero_pedido || ""}
        </TableCell>
        <TableCell className={classes.tableRow} align="center">
          {`${row?.details_metadata?.reference.contato || ""}`.replace(
            "55",
            "",
          )}
        </TableCell>
        <TableCell className={classes.tableRow} align="center">
          {formatterToBrlCurrency(row.amount)}
        </TableCell>

        <TableCell className={classes.tableRow} align="center">
          {row.status === "PAID" && (
            <div>
              <CheckIcon
                titleAccess={"Confirmado"}
                color="secondary"
                style={{ margin: "-7px 2px" }}
              />
            </div>
          )}
          {expired && row.status !== "PAID" && (
            <div>
              <AlarmOff
                titleAccess={"Expirado"}
                color={"error"}
                style={{ margin: "-7px 2px" }}
              />
            </div>
          )}
          {!expired && row.status !== "PAID" && (
            <div title="Pendente">
              <ScheduleIcon
                titleAccess={`Expira ${dateTran.fromNow()}`}
                style={{ margin: "-7px 2px" }}
              />
            </div>
          )}
        </TableCell>
      </TableRow>
    );
  };
  useEffect(() => {
    setActionLoading(false);
  }, []);

  return (
    <Grid item xs={12} sm={12} lg={12}>
      {loading && !transactionPending ? (
        <Grid item>
          <Skeleton variant="rect" height={30} />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
        </Grid>
      ) : (
        <Grid item>
          <Grid
            css={css`
              && {
                margin-bottom: 10px;
              }
            `}
            item
            xs={4}
            sm={4}
            lg={4}
          >
            <Button
              onClick={() => {
                setDisplayTransactions(false);
                setActionLoading(true);
              }}
              fullWidth
              variant="outlined"
              color="primary"
              disabled={loading}
              css={css`
                && {
                  border-radius: 15px;
                }
              `}
            >
              Voltar
            </Button>
          </Grid>
          <TransactionsPaymentLink
            getTransactions={getTransactions}
            lastTransactions={lastTransactions}
            loading={loading}
            merchantParams={merchantParams}
            availableMerchants={availableMerchants}
            activeMerchant={activeMerchant}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default LastTransactionsPaymentLink;
