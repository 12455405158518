/** @jsx jsx */

import { jsx } from '@emotion/core';
import Container from '@material-ui/core/Container';

const PublicLayout: React.FC = ({ children }) => (
  <Container style={{ width: '100%' }}>{children}</Container>
);

export default PublicLayout;
