import {
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { css, jsx } from "@emotion/core";

import LoadingPayment from "./LoadingPayment";
import Print from "@material-ui/icons/Print";
import TransactionQrPrint from "app/components/Transaction/TransactionQrPrint";
import moment from "moment";

/** @jsx jsx */

const TransactionPending: React.FC<any> = ({
  enable,
  handleTimeoutCheckPayment,
  handleTransactionCheckPayment,
  handleCheckPaymentCancel,
  currentTransaction,
  application,
}) => {
  const [enablePrint, setEnablePrint] = useState(false);
  const [secondsLeft, setSecondsLeft] = useState(120);

  useEffect(() => {
    setEnablePrint(false);
  }, [enable]);

  useEffect(() => {
    if (!enable) {
      setSecondsLeft(120);
      return;
    }

    const interval = setInterval(() => {
      setSecondsLeft(secondsLeft => secondsLeft - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [enable]);

  if (!enable) {
    return <div></div>;
  }

  return (
    <div>
      <Grid container justify="center" alignItems="center" direction="row">
        <Typography variant="caption" align={"center"}>
          Data solicitação:
          {moment(currentTransaction?.transaction_date).format(
            "DD/MM/YYYY HH:mm:ss",
          )}
        </Typography>
      </Grid>
      <Fragment>
        <LoadingPayment
          handleTimeout={handleTimeoutCheckPayment}
          handleCheckPayment={handleTransactionCheckPayment}
          handleCancel={handleCheckPaymentCancel}
          secondsLeft={secondsLeft}
        >
          <Grid container justify="center" alignItems="center">
            <Grid item>
              <IconButton
                aria-label="Recibo"
                color="primary"
                title="Imprimir"
                onClick={() => {
                  setEnablePrint(true);
                }}
              >
                <Print />
              </IconButton>
            </Grid>
          </Grid>
        </LoadingPayment>
      </Fragment>
      <Dialog open={enablePrint} onClose={() => setEnablePrint(false)}>
        <DialogContent>
          <TransactionQrPrint
            enable={enablePrint}
            currentTransaction={currentTransaction}
            application={application}
            secondsLeft={secondsLeft}
            handleClose={() => setEnablePrint(false)}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default TransactionPending;
