import { Button, Grid, Link, Typography } from "@material-ui/core";
import { CheckboxWithLabel, TextField } from "formik-material-ui";
import { Form, Formik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import Schema, { acceptTermsSchema } from "./ConfirmEmailPassword.schema";
import { css, jsx } from "@emotion/core";

import Logo from "shared/components/Logo";
import { currentConfigOptions } from "shared/configs/styles.config";

/** @jsx jsx */

const ConfirmEmailPasswordScreen: React.FC<any> = ({
  handleConfirmUser,
  showTermsOfService,
  handleValidateCode,
}) => {
  let schema = Schema;
  if (showTermsOfService) schema = Schema.concat(acceptTermsSchema);

  const [codeIsValid, setCodeIsValid] = useState(true);
  useEffect(() => {
    (async () => {
      const resp = await handleValidateCode();
      setCodeIsValid(resp);
    })();
  }, []);

  if (!codeIsValid)
    return (
      <Fragment>
        <Grid
          container
          direction="column"
          alignItems="center"
          css={css`
            && {
              min-height: 100vh;
            }
          `}
        >
          <Grid item md={6} sm={6}>
            <Grid container justify="center" style={{ cursor: "pointer" }}>
              <Grid item xs="auto">
                <Logo
                  css={css`
                    && {
                      max-width: 200px;
                    }
                  `}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" align={"center"}>
                  Este link para troca de senha expirou, faça uma nova
                  solicitação de troca de senha.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Fragment>
    );

  return (
    <Fragment>
      <Formik
        initialValues={{
          password: "",
        }}
        onSubmit={handleConfirmUser}
        validationSchema={schema}
      >
        {({ submitForm, isSubmitting, errors, dirty }) => (
          <Form>
            <Grid
              container
              direction="column"
              alignItems="center"
              css={css`
                && {
                  min-height: 100vh;
                }
              `}
            >
              <Grid item md={5} sm={5}>
                <Grid container justify="center" style={{ cursor: "pointer" }}>
                  <Grid item xs="auto">
                    <Logo
                      css={css`
                        && {
                          max-width: 200px;
                        }
                      `}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6" align={"center"}>
                      Crie sua nova senha
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      name="password"
                      type="password"
                      label="Senha"
                      required
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="confirm_password"
                      type="password"
                      label="Confirmar senha"
                      required
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  {showTermsOfService && (
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                      css={css`
                        && {
                          margin-top: 24px;
                        }
                      `}
                    >
                      <Grid item xs={9}>
                        <CheckboxWithLabel
                          id="acceptsTerms"
                          name="accept_terms"
                          Label={{
                            label: (
                              <label htmlFor="acceptsTerms">
                                Declaro que li e aceito os{" "}
                                <Link
                                  href={currentConfigOptions.termsOfUse}
                                  target="_blank"
                                  css={css`
                                    && {
                                      text-decoration: underline;
                                    }
                                  `}
                                >
                                  Termos de Uso
                                </Link>
                              </label>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <Button
                  onClick={submitForm}
                  disabled={
                    isSubmitting || Object.keys(errors).length > 0 || !dirty
                  }
                  fullWidth
                  variant="contained"
                  color="secondary"
                  css={css`
                    && {
                      margin-top: 20px;
                      border-radius: 15px;
                    }
                  `}
                >
                  Confirmar
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
};

export default ConfirmEmailPasswordScreen;
