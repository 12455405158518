import React, { useEffect } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import {
  handleListTransactions,
  initState,
  newTransaction,
} from "app/ducks/transactions.duck";

import Screen from "./CustomPaymentLink.screen";
import { stateReduxType as appsStateReduxType } from "app/screens/Apps/Apps.type";
import { stateReduxType as authStateReduxType } from "shared/types/authentication.type";
import { stateReduxType as reportStateReduxType } from "../ReportClosing/ReportClosing.type";
import { stateReduxType as transactionStateReduxType } from "./CustomPaymentLink.type";
import { getTransactions } from "app/ducks/reports.duck";

const useTypedSelectorAuth: TypedUseSelectorHook<authStateReduxType> = useSelector;
const useTypedSelector: TypedUseSelectorHook<transactionStateReduxType> = useSelector;
const useTypedSelectorApps: TypedUseSelectorHook<appsStateReduxType> = useSelector;
const useTypedSelectorReports: TypedUseSelectorHook<reportStateReduxType> = useSelector;

const CustomPaymentLink: React.FC<any> = ({ history }) => {
  const { applications } = useTypedSelectorApps(state => state.app) || {};
  const { app, apps } = applications;

  const { transactions } = useTypedSelector(state => state.app) || {};
  const { reports } = useTypedSelectorReports(state => state.app) || {};

  const { authentication } = useTypedSelectorAuth(state => state.shared) || {};
  const { data: auth, params: merchantParams } = authentication;
  const { user } = auth;
  const merchant = user?.merchants[0];
  const availableMerchants: any[] = user?.merchants || [];
  const activeMerchant: any = user?.activeMerchant || {};

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initState());
  }, []);

  const handleGetTransactions = (params: any) =>
    dispatch(
      getTransactions({
        ...params,
        sort: "DESC",
        application_id: app.id,
      }),
    );

  return (
    <Screen
      handleNewTransaction={(data: any) =>
        newTransaction({
          data,
          auth,
        })
      }
      getTransactions={handleGetTransactions}
      transactions={transactions}
      history={history}
      application={app}
      apps={apps}
      reports={reports}
      merchantParams={merchantParams}
      availableMerchants={availableMerchants}
      activeMerchant={activeMerchant}
    />
  );
};

export default CustomPaymentLink;
