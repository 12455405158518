export const qrwayConfig = {
  colors: {
    black: "#0D1421",
    primary: "#486e8d",
    secondary: "#1b900e",
    gray: "#B4B4B9",
    purple: "#3269C3",
    success: "#40C7A1",
    tableTitle: "black",
    tableTitleBack: "rgba(27, 144, 14, 0.5)",
  },
  applogo: require("shared/assets/qrway_logo.png"),
  termsOfUse: "/Termo_de_Aceite_QRWAY.v1.pdf",
};
