import { API_URL_WITH_PORT, PORTS } from "shared/configs/api.config";

import { Dispatch } from "redux";
import fetch from "shared/utils/fetch.util";

type Actions = {
  type: string;
  payload?: object;
  error?: object;
};

export const Types = {
  INIT_CNAE: "app/cnaes/INIT",
  SUCCESS_CNAE: "app/cnaes/SUCCESS",
  ERROR_CNAE: "app/cnaes/ERROR",
  INIT_BANK: "app/banks/INIT",
  SUCCESS_BANK: "app/banks/SUCCESS",
  ERROR_BANK: "app/banks/ERROR",
};

const initialState: any = {
  cnaesList: [],
  banksList: [],
  loading: false,
  error: null,
};

export default (state = initialState, action: Actions) => {
  const { type, payload, error } = action;

  const reducers = {
    [Types.INIT_CNAE]: {
      ...state,
      error: null,
      loading: true,
    },
    [Types.ERROR_CNAE]: {
      loading: false,
      cnaesList: [],
      error,
    },
    [Types.SUCCESS_CNAE]: {
      ...state,
      loading: false,
      cnaesList: payload,
      error: false,
    },
    [Types.INIT_BANK]: {
      ...state,
      error: null,
      loading: true,
    },
    [Types.ERROR_BANK]: {
      loading: false,
      banksList: [],
      error,
    },
    [Types.SUCCESS_BANK]: {
      ...state,
      loading: false,
      banksList: payload,
      error: false,
    },
  };

  return reducers[type] || state;
};

export const getCnaes = () => async (dispatch: Dispatch<any>) => {
  try {
    dispatch({
      type: Types.INIT_CNAE,
    });
    const response = await fetch(
      `${API_URL_WITH_PORT(PORTS.DOMAIN)}/domains/cnaes`,
      {
        customUrl: true,
        method: "GET",
      },
    );
    const result = await response.json();
    if (!response.ok) {
      throw result;
    }

    dispatch({
      type: Types.SUCCESS_CNAE,
      payload: result,
    });

    return result;
  } catch (e) {
    dispatch({
      type: Types.ERROR_CNAE,
      error: {
        visible: true,
        ...e,
      },
    });
    return false;
  }
};

export const getBanks = () => async (dispatch: Dispatch<any>) => {
  try {
    dispatch({
      type: Types.INIT_BANK,
    });
    const response = await fetch(
      `${API_URL_WITH_PORT(PORTS.DOMAIN)}/domains/banks`,
      {
        customUrl: true,
        method: "GET",
      },
    );

    const result = await response.json();
    if (!response.ok) {
      throw result;
    }

    dispatch({
      type: Types.SUCCESS_BANK,
      payload: result,
    });

    return result;
  } catch (e) {
    dispatch({
      type: Types.ERROR_BANK,
      error: {
        visible: true,
        ...e,
      },
    });
    return false;
  }
};
