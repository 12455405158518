/** @jsx jsx */
import { jsx } from "@emotion/core";
import { logos } from "shared/configs/styles.config";
import { useHistory } from "react-router-dom";

const Logo: React.FC = props => {
  const history = useHistory();
  const defaultProps = {
    ...props,
  };
  return (
    <img
      {...defaultProps}
      src={logos.applogo}
      alt="logo"
      onClick={() => history.push("/app")}
    />
  );
};

export default Logo;
