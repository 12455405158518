import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
  createMuiTheme,
  makeStyles,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { css, jsx } from "@emotion/core";

import AlarmOff from "@material-ui/icons/AlarmOff";
import CheckIcon from "@material-ui/icons/Check";
import Paper from "@material-ui/core/Paper";
import ScheduleIcon from "@material-ui/icons/Schedule";
import { Skeleton } from "@material-ui/lab";
import { colors } from "shared/configs/styles.config";
import { formatterToBrlCurrency } from "shared/utils/formatter.util";
import moment from "moment";
import { useDispatch } from "react-redux";

/** @jsx jsx */

const rowStyleFormat = (row: any, rowIdx: any) => {
  return {
    backgroundColor: rowIdx % 2 === 0 ? "rgba(0, 0, 0, 0.04)" : "#ffffff",
  };
};

const theme = createMuiTheme({
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
  },
  overrides: {
    MuiTableCell: {
      paddingNone: {
        padding: "2px 2px 2px 2px",
      },
    },
  },
});

const useStyles = makeStyles({
  tableTitle: {
    fontSize: "14px",
    backgroundColor: colors.tableTitleBack,
  },
  tableHeader: {
    fontSize: "11px",
  },
  tableRow: {
    fontSize: "12px",
  },
});

const LastTransactions: React.FC<any> = ({
  transactionPending,
  loading,
  getLastTransactions,
  lastTransactions,
  meta,
}) => {
  const classes = useStyles();

  const getRowsByIssuer = (row: any, index: any) => {
    const defaultExpirationHours = 2;
    const dateTran = moment(
      moment(row.date).add(defaultExpirationHours, "hours"),
    );
    const expired = dateTran.isBefore(moment());

    return (
      <TableRow key={index} style={rowStyleFormat(row, index)}>
        <TableCell className={classes.tableRow} align="center">
          {moment(row.date).format("DD/MM/YYYY HH:mm:ss")}
        </TableCell>
        <TableCell className={classes.tableRow} align="center">
          {row.metadata?.placa || ""}
        </TableCell>
        {/* <TableCell className={classes.tableRow} align="center">
          {row.nsu}
        </TableCell> */}
        <TableCell className={classes.tableRow} align="center">
          {row.nsu}
        </TableCell>
        <TableCell className={classes.tableRow} align="center">
          {row.metadata?.contato?.toLowerCase() || ""}
        </TableCell>
        <TableCell className={classes.tableRow} align="center">
          {formatterToBrlCurrency(row.amount)}
        </TableCell>

        <TableCell className={classes.tableRow} align="center">
          {row.metadata?.status === "Confirmado" && (
            <div>
              <CheckIcon
                titleAccess={"Confirmado"}
                color="secondary"
                style={{ margin: "-7px 2px" }}
              />
            </div>
          )}
          {expired && row.metadata?.status !== "Confirmado" && (
            <div>
              <AlarmOff
                titleAccess={"Expirado"}
                color={"error"}
                style={{ margin: "-7px 2px" }}
              />
            </div>
          )}
          {!expired && row.metadata?.status !== "Confirmado" && (
            <div title="Pendente">
              <ScheduleIcon
                titleAccess={`Expira ${dateTran.fromNow()}`}
                style={{ margin: "-7px 2px" }}
              />
            </div>
          )}
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Grid item xs={12} sm={12} lg={12}>
      {loading && !transactionPending ? (
        <Grid item>
          <Skeleton variant="rect" height={30} />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
        </Grid>
      ) : (
        <Grid item>
          <Grid
            css={css`
              && {
                margin-bottom: 10px;
              }
            `}
            item
            xs={12}
            sm={12}
            lg={12}
          >
            <Button
              onClick={() => getLastTransactions(meta)}
              fullWidth
              variant="outlined"
              color="primary"
              disabled={loading}
              css={css`
                && {
                  border-radius: 15px;
                }
              `}
            >
              Ver Últimas Transações
            </Button>
          </Grid>
          <ThemeProvider theme={theme}>
            <TableContainer component={Paper}>
              <Table
                size="small"
                padding={"none"}
                css={css`
                  && {
                  }
                `}
                aria-label="Últimas 10 transações"
              >
                {lastTransactions?.length === 0 && (
                  <caption>Não há transações para este aplicativo</caption>
                )}
                {lastTransactions?.length > 0 && (
                  <TableHead>
                    <TableRow>
                      <TableCell
                        colSpan={7}
                        align="center"
                        className={classes.tableTitle}
                      >
                        <div style={{ color: colors.tableTitle }}>
                          Exibindo últimas 10 transações
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      style={{ backgroundColor: "rgba(0, 0, 0, 0.26)" }}
                    >
                      <TableCell align="center" className={classes.tableHeader}>
                        Data
                      </TableCell>
                      <TableCell align="center" className={classes.tableHeader}>
                        Placa
                      </TableCell>
                      {/* <TableCell align="center" className={classes.tableHeader}>
                        Nsu
                      </TableCell> */}
                      <TableCell align="center" className={classes.tableHeader}>
                        NSU
                      </TableCell>
                      <TableCell align="center" className={classes.tableHeader}>
                        Pagador
                      </TableCell>
                      <TableCell align="center" className={classes.tableHeader}>
                        Valor
                      </TableCell>
                      <TableCell align="center" className={classes.tableHeader}>
                        Status
                      </TableCell>
                    </TableRow>
                  </TableHead>
                )}

                {lastTransactions?.length > 0 && (
                  <TableBody>
                    {lastTransactions
                      ?.slice(0, 10)
                      .map((row: any, index: any) => {
                        return getRowsByIssuer(row, index);
                      })}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </ThemeProvider>
        </Grid>
      )}
    </Grid>
  );
};

export default LastTransactions;
