import React, { useCallback, useState } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import CreateAccountMerchantComponent from "./CreateAccountMerchant.component";
import { checkUserAvailability } from "../../../ducks/createAccount/createAccount.duck";
import { containerPropTypes } from "./CreateAccountMerchant.type";
import getAddressByZip from "public/services/getAddressByZip.service";
import getCNPJInfos from "public/services/getCNPJInfos.service";
import { useFormikContext } from "formik";

const useTypedSelector: TypedUseSelectorHook<any> = useSelector;

const CreateAccountMerchantContainer: React.FC<containerPropTypes> = ({
  isPJ,
  isUpdating,
}) => {
  const dispatch = useDispatch();
  const [loadingCep, setLoadingCep] = useState(false);
  const [loadingCnpj, setLoadingCnpj] = useState(false);

  const { values, setValues } = useFormikContext<any>();

  const handleGetAddressByZip = useCallback(
    async zip => {
      setLoadingCep(true);
      const response = await getAddressByZip(zip);

      const { logradouro, bairro, localidade, uf } = await response.json();

      setValues({
        ...values,
        merchant: {
          ...values.merchant,
          address: {
            ...values.merchant.address,
            street: logradouro,
            neighborhood: bairro,
            city: localidade,
            state: uf,
          },
        },
      });
      setLoadingCep(false);
    },
    [values, setValues],
  );

  const handleGetCNPJInfos = useCallback(
    async document => {
      setLoadingCnpj(true);
      const documentOnlyDigits = document.replace(/\D/g, "");
      const response = await getCNPJInfos(documentOnlyDigits);
      const { nome, fantasia } = await response.json();

      setValues({
        ...values,
        merchant: {
          ...values.merchant,
          trading_name: fantasia?.length === 0 ? nome : fantasia,
          company_name: nome,
        },
      });
      setLoadingCnpj(false);
    },
    [setValues, values],
  );

  const { domainData } = useTypedSelector(state => state.public) || {};
  const { cnaesList, banksList } = domainData;

  const handleVerifyDocument = async (document: string) => {
    return await dispatch(checkUserAvailability(2, document));
  };

  const { createAccount } = useTypedSelector(state => state.public) || {};

  const { loading } = createAccount;

  return (
    <CreateAccountMerchantComponent
      isUpdating={isUpdating}
      isPJ={isPJ}
      cnaes={cnaesList}
      banks={banksList}
      handleGetAddressByZip={handleGetAddressByZip}
      handleGetCNPJInfos={handleGetCNPJInfos}
      handleVerifyDocument={handleVerifyDocument}
      loading={loading}
      loadingCep={loadingCep}
      loadingCnpj={loadingCnpj}
    />
  );
};

export default CreateAccountMerchantContainer;
