import { combineReducers } from "redux";

type listOfReducersType = {
  name: string;
  path: string;
};

const reducers = {};

const listOfReducers: Array<listOfReducersType> = [
  {
    name: "applications",
    path: "applications.duck",
  },
  {
    name: "transactions",
    path: "transactions.duck",
  },
  {
    name: "usersEc",
    path: "usersEc.duck",
  },
  {
    name: "merchant",
    path: "merchant.duck",
  },
  {
    name: "reports",
    path: "reports.duck",
  },
  {
    name: "issuer",
    path: "issuer.duck",
  },
  {
    name: "receivable",
    path: "receivables.duck",
  },
  {
    name: "register",
    path: "registers.duck",
  },
  {
    name: "attendants",
    path: "attendants.duck",
  },
  {
    name: "terminals",
    path: "terminals.duck",
  },
  {
    name: "conciliations",
    path: "conciliations.duck",
  },
];

listOfReducers.map(elm =>
  Object.assign(reducers, {
    ...reducers,
    [elm.name]: require(`./${elm.path}`).default,
  }),
);

export default combineReducers({
  ...reducers,
});
