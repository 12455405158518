import "moment/locale/pt-br";

import { Button, Grid, Typography } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { css, jsx } from "@emotion/core";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import Header from "app/components/Header/Header.container";
import TransactionDetails from "./components/TransactionsDetails";
import TransactionGroup from "./components/TransactionsGroup";
import { screenPropsType } from "./ReportClosing.type";

/** @jsx jsx */

const Screen: React.FC<screenPropsType> = ({
  getTransactions,
  checkPayment,
  reports,
  history,
  transactionLoading,
  handleRequestReversal,
  merchantParams,
  resendNotification,
  availableMerchants,
  activeMerchant,
}) => {
  const { loading, transactions, transactionsDetails } = reports || {};
  const transactionsGroup: any = transactions;

  const [expand, handleExpand] = useState(false);
  const [rowExpanded, setRowExpanded] = useState<any>(null);

  return (
    <Fragment>
      <Header authentication={null} loading={loading || transactionLoading} />
      <Grid
        container
        spacing={2}
        justify="center"
        alignItems="center"
        direction="row"
      >
        <Grid item xs={8} lg={12}>
          <GridList>
            <GridListTile key="Subheader" cols={2} style={{ height: "auto" }}>
              <Grid
                container
                justify="flex-end"
                css={css`
                  && {
                    margin-top: 24px;
                  }
                `}
              ></Grid>
              <Typography
                variant="h6"
                align={"center"}
                css={css`
                  margin-bottom: 25px;
                `}
              >
                Fechamento de caixa
              </Typography>
            </GridListTile>
          </GridList>
          {expand && (
            <Grid container justify="space-between">
              <Grid item>
                <Button
                  onClick={() => handleExpand(false)}
                  fullWidth
                  color="secondary"
                  startIcon={<ArrowBackIcon />}
                  css={css`
                    && {
                      margin-top: 10px;
                      font-weight: bold;
                    }
                  `}
                >
                  VOLTAR
                </Button>
              </Grid>
              {/* <Grid item>
                <Button
                  onClick={() => handleExpand(false)}
                  fullWidth
                  variant="contained"
                  color="secondary"
                  css={css`
                    && {
                      border-radius: 15px;
                    }
                  `}
                >
                  Exportar
                </Button>
              </Grid> */}
            </Grid>
          )}

          {/* Transações */}
          <TransactionGroup
            getTransactions={getTransactions}
            transactionsGroup={transactionsGroup}
            loading={loading}
            expand={expand}
            handleExpand={handleExpand}
            setRowExpanded={setRowExpanded}
            merchantParams={merchantParams}
            availableMerchants={availableMerchants}
            activeMerchant={activeMerchant}
          />

          {/* Detalhes Transações */}
          <TransactionDetails
            getTransactions={getTransactions}
            transactionLoading={transactionLoading}
            loading={loading}
            expand={expand}
            rowExpanded={rowExpanded}
            checkPayment={checkPayment}
            resendNotification={resendNotification}
            transactionsDetails={transactionsDetails}
            handleRequestReversal={handleRequestReversal}
            merchantParams={merchantParams}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default Screen;
