import { API_URL } from "shared/configs/api.config";
import getToken from "./getToken.util";

const fetch = (url: string, config: any = {}) => {
  const { auth, customUrl, ...restConfig } = config;

  const token = getToken();

  const newUrl = customUrl ? url : `${API_URL}/${url}`;

  let opts = {
    headers: {
      "content-type": "application/json",
    },
    ...restConfig,
  };
  if (auth)
    opts = {
      ...restConfig,
      headers: {
        ...restConfig.headers,
        "content-type": "application/json",
        Authorization: token,
      },
    };

  return window.fetch(newUrl, opts);
};

export default fetch;
