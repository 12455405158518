import { API_URL_WITH_PORT, PORTS } from "shared/configs/api.config";

import fetch from "shared/utils/fetch.util";

export default (document_number: string) =>
  fetch(
    `${API_URL_WITH_PORT(
      PORTS.EXTERNAL
    )}/externals/merchant/${document_number}`,
    {
      customUrl: true
    }
  );
