import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Paper,
  Slide,
  Snackbar,
  createMuiTheme,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import { css, jsx } from "@emotion/core";
import { formatterToBrlCurrency } from "shared/utils/formatter.util";

import { TransitionProps } from "@material-ui/core/transitions";
import { colors } from "shared/configs/styles.config";
import moment from "moment";
import { ptBR } from "@material-ui/core/locale";
import TableDefault from "app/components/TableDefault";

/** @jsx jsx */

function TransitionDown(props: TransitionProps) {
  return <Slide {...props} direction="down" />;
}

const theme = createMuiTheme(
  {
    palette: {
      primary: {
        main: colors.primary,
      },
      secondary: {
        main: colors.secondary,
      },
    },
    overrides: {
      MuiTableCell: {
        paddingNone: {
          padding: "5px",
        },
      },
    },
  },
  ptBR,
);

const useStyles = makeStyles({
  paymentInfoContainer: {
    minWidth: 275,
  },
  containerCardPayment: {
    backgroundColor: "#4caf50",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
});

const ConciliationInformation: React.FC<any> = ({
  loading,
  enable,
  onClose,
  info,
}) => {
  if (!enable) return <div></div>;

  return (
    <Grid item xs={12} sm={12}>
      <Grid
        container
        justify="flex-end"
        css={css`
          && {
            margin-top: 24px;
          }
        `}
      ></Grid>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        TransitionComponent={TransitionDown}
        open={enable}
        onClose={() => onClose(false)}
      >
        <Card>
          <CardContent>
            <Paper variant="outlined" square>
              <TableDefault
                loading={loading}
                title={"Cobranças"}
                subtitle={""}
                data={info}
                columns={[
                  {
                    name: "txid",
                    label: "ID",
                    options: {
                      filter: true,
                      sort: true,
                    },
                  },
                  {
                    name: "documentNumber",
                    label: "Estabelecimento",
                    options: {
                      filter: true,
                      sort: true,
                      customBodyRender: (
                        value: any,
                        tableMeta: any,
                        updateValue: any,
                      ) => {
                        const document_number = (value || "").replace(
                          /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                          "$1.$2.$3/$4-$5",
                        );
                        return document_number;
                      },
                    },
                  },
                  {
                    name: "transactionPaymentDate",
                    label: "Data Pagamento",
                    options: {
                      filter: true,
                      sort: true,
                      customBodyRender: (
                        value: any,
                        tableMeta: any,
                        updateValue: any,
                      ) => {
                        return moment(value).format("DD/MM/YYYY HH:mm");
                      },
                    },
                  },
                  {
                    name: "transactionAmount",
                    label: "Valor",
                    options: {
                      filter: true,
                      sort: true,
                      customBodyRender: (
                        value: any,
                        tableMeta: any,
                        updateValue: any,
                      ) => {
                        return formatterToBrlCurrency(value);
                      },
                    },
                  },
                  {
                    name: "status",
                    label: "Status",
                    options: {
                      filter: true,
                      sort: true,
                    },
                  },
                  {
                    name: "statusOrder",
                    label: "Status Ordem",
                    options: {
                      filter: true,
                      sort: true,
                    },
                  },
                ]}
              />
            </Paper>
          </CardContent>
          <CardActions>
            <Grid
              container
              justify="center"
              alignItems="center"
              direction="row"
            >
              <Grid
                container
                justify="center"
                css={css`
                  && {
                    margin-top: 10px;
                    margin-bottom: 2px;
                  }
                `}
              >
                <Grid item xs={12} sm={12} lg={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    css={css`
                      && {
                        border-radius: 15px;
                      }
                    `}
                    onClick={() => {
                      onClose(false);
                    }}
                  >
                    OK
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </Snackbar>
    </Grid>
  );
};

export default ConciliationInformation;
