import { Redirect, Route, Switch } from "react-router-dom";

import ConfirmEmail from "./screens/ConfirmEmail";
import ConfirmEmailPassword from "./screens/ConfirmEmailPassword";
import CreateAccount from "./screens/CreateAccount";
import Login from "./screens/Login";
import LoginTerminal from "./screens/LoginTerminal";
import PublicLayout from "./layouts/Public.layout";
import React from "react";
import TransactionPayLink from "./screens/TransactionPayLink";

const Public = () => (
  <PublicLayout>
    <Switch>
      <Route exact path={"/login"} component={Login} />
      <Route exact path={"/confirm-email"} component={ConfirmEmail} />
      <Route
        exact
        path={"/confirm-email-pass"}
        component={ConfirmEmailPassword}
      />
      <Route exact path={"/create-account"} component={CreateAccount} />
      <Route exact path={"/onboarding"} component={CreateAccount} />
      <Route
        exact
        path={"/link-pagamento/:code"}
        component={TransactionPayLink}
      />
      <Route
        exact
        path={"/recover-password"}
        component={ConfirmEmailPassword}
      />
      <Route
        exact
        path={"/forgot-password"}
        render={() => <span>forgot-password</span>}
      />
      <Route
        exact
        path={"/terms-of-use"}
        render={() => <span>Termos de uso da plataforma</span>}
      />
      <Route exact path={"/terminal"} component={LoginTerminal} />
      <Redirect to={"/login"} />
    </Switch>
  </PublicLayout>
);

export default Public;
