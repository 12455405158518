export const banco24horasConfig = {
  colors: {
    black: "#0D1421",
    primary: "#3b3030",
    secondary: "#d91422",
    gray: "#B4B4B9",
    purple: "#3269C3",
    success: "#40C7A1",
    tableTitle: "white",
    tableTitleBack: "rgb(87, 88, 140)",
  },
  applogo: require("shared/assets/banco24horas_cobrand_logo.png"),
  termsOfUse: "/Termo_de_Aceite_b24.v1.pdf",
};
