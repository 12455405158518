import {
  Button,
  Grid,
  Link,
  TextField,
  CircularProgress,
  Typography,
} from "@material-ui/core";
/** @jsx jsx */
import React, { Fragment, useCallback, useState } from "react";
import { css, jsx } from "@emotion/core";
import { useSnackbar } from "notistack";
import { getApplications } from "app/ducks/applications.duck";
import { useDispatch } from "react-redux";

const OnboardingOriginalConfirmation: React.FC<any> = ({
  handleFinalization,
  handleConfirmation,
  handleResendConfirmation,
  formDataValues,
}) => {
  const [validationInProgress, setValitionInProgress] = useState(false);
  const [tokenCode, setTokenCode] = useState("");
  const [isFormSubmiting, setSubmitForm] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const getApps = useCallback(() => dispatch(getApplications({})), [
    dispatch,
    getApplications,
  ]);

  const handleConfirm = useCallback(async () => {
    setSubmitForm(true);
    const response: any = await handleConfirmation({ tokenCode });
    if (response.error) {
      setSubmitForm(false);
      return enqueueSnackbar("Código inválido ou expirado", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      });
    }

    await getApps();

    setSubmitForm(false);
    setValitionInProgress(true);
  }, [enqueueSnackbar, handleConfirmation, tokenCode]);

  const handleResendConfirm = useCallback(async () => {
    const response: any = await handleResendConfirmation();
    if (response.error) {
      setSubmitForm(false);
      return;
    }

    setSubmitForm(false);
    setValitionInProgress(false);
  }, [handleResendConfirmation]);

  return (
    <Fragment>
      <Grid>
        {!validationInProgress && (
          <Grid container>
            <Grid container direction="row" alignItems="center">
              <Grid item xs={8}>
                <label>
                  <strong>
                    Um token foi enviado para seu E-mail / Celular, preencha no
                    campo ao lado
                  </strong>
                </label>
                <br></br>
                <br></br>
                <label>
                  Caso não tenha recebido verifique a caixa de Spam do seu
                  provedor de e-mail, você poderá solicitar um novo Token
                  clicando no botão reenviar logo abaixo.
                </label>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  name="token"
                  label="Token"
                  InputProps={{
                    inputProps: {
                      minLength: 4,
                      maxLength: 6,
                    },
                  }}
                  autoComplete="nope"
                  required
                  variant="outlined"
                  fullWidth
                  disabled={isFormSubmiting}
                  onChange={({ target: { value } }: any) => {
                    setTokenCode(value.trim());
                  }}
                />
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={12}>
                <Typography>
                  <Link
                    href="#"
                    onClick={() => {
                      if (isFormSubmiting) return;
                      setSubmitForm(true);
                      handleResendConfirm();
                    }}
                    css={css`
                      && {
                        padding: 0;
                        text-decoration: underline;
                      }
                    `}
                  >
                    Reenviar token para{" "}
                    <i>{`(${formDataValues.phone_prefix}) ${formDataValues.phone_number}`}</i>
                  </Link>
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              css={css`
                && {
                  margin-top: 24px;
                }
              `}
            >
              <Grid item xs={9} sm={5}>
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  css={css`
                    && {
                      margin-top: 20px;
                      border-radius: 15px;
                      width: 100%;
                    }
                  `}
                  disabled={
                    isFormSubmiting || !tokenCode || tokenCode.length < 4
                  }
                  onClick={handleConfirm}
                >
                  <div>Validar</div>
                  {isFormSubmiting && (
                    <CircularProgress
                      size={24}
                      color="secondary"
                      css={css`
                        && {
                          position: absolute;
                          top: 50%;
                          left: 50%;
                          margin-top: -12px;
                          margin-left: -12px;
                        }
                      `}
                    />
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
        {validationInProgress && (
          <Grid container>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item xs={6}>
                <label>
                  Parabéns ! Sua conta <b>Original</b> esta{" "}
                  <b>em processo de abertura</b>.
                </label>
                <br></br>
                <br></br>
                <label>
                  Em breve você pode aproveitar todos os benefícios de receber
                  com QRCode.
                </label>
                <br></br>
                <br></br>
                <label>
                  O prazo para resposta da abertura da conta é de até 3 dias
                  úteis, fique atento ao seu e-mail ou diretamente aqui no
                  portal QRWay.
                </label>
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              css={css`
                && {
                  margin-top: 24px;
                }
              `}
            >
              <Grid item xs={9} sm={5}>
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  css={css`
                    && {
                      margin-top: 20px;
                      border-radius: 15px;
                      width: 100%;
                    }
                  `}
                  onClick={handleFinalization}
                >
                  <div>Sair</div>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Fragment>
  );
};

export default OnboardingOriginalConfirmation;
