import { Close, Print } from "@material-ui/icons";
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  createMuiTheme,
  makeStyles,
} from "@material-ui/core";
import React, { Fragment, useRef } from "react";
import { css, jsx } from "@emotion/core";

import ReactToPrint from "react-to-print";
import { colors } from "shared/configs/styles.config";
import { formatterToCurrency } from "shared/utils/formatter.util";
import moment from "moment";
import { ptBR } from "@material-ui/core/locale";

const QRCode = require("qrcode.react");

/** @jsx jsx */

const theme = createMuiTheme(
  {
    palette: {
      primary: {
        main: colors.primary,
      },
      secondary: {
        main: colors.secondary,
      },
    },
    overrides: {
      MuiTableCell: {
        paddingNone: {
          padding: "5px",
        },
      },
    },
  },
  ptBR,
);

const useStyles = makeStyles({
  container: {
    fontSize: "12px",
    fontFamily: "Times New Roman",
    width: "210px",
    maxWidth: "210px",
  },
  centered: {
    textAlign: "center",
    alignContent: "center",
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
});

const TransactionQrPrint: React.FC<any> = ({
  enable,
  currentTransaction,
  application,
  secondsLeft,
  handleClose,
}) => {
  const classes = useStyles();
  const componentRef = useRef<any>();
  const linkToPrint = () => {
    return (
      <IconButton
        className="hidden-print"
        aria-label="Imprimir"
        color="primary"
        title="Imprimir"
      >
        <Print />
      </IconButton>
    );
  };

  if (!enable) return <div></div>;

  const { qrcode } = currentTransaction;
  const { value: qrcodeValue } = qrcode;

  const isUrlImageQr =
    qrcodeValue.startsWith("http") && qrcodeValue.endsWith(".png");
  const isBase64Qr = new RegExp(
    "^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{4})$",
  ).test(qrcodeValue);

  return (
    <div className="root" ref={componentRef}>
      <div>
        <div className={classes.container}>
          <style>{`.root span {
    margin-left: 10px;
    font-size: 12px;
    font-family: 'Times New Roman';
    border-bottom-style: inset;
}

.centered {
    text-align: center;
    align-content: center;
}

.img {    
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

@media print {
    .root span {color:black;},
    .hidden-print,
    .hidden-print * {
        display: none !important;
    }
}`}</style>
          <div className={classes.centered}>
            <div className={classes.heading}>
              Nova transação com o aplicativo
            </div>
            <div className={classes.secondaryHeading}>
              <strong>{application?.name.toUpperCase()}</strong>
            </div>
          </div>
          <br />
          <Grid item xs={12} lg={12}>
            {isUrlImageQr && (
              <img
                src={qrcodeValue}
                className={"img"}
                alt={"qrcode"}
                width="230"
              />
            )}

            {isBase64Qr && (
              <img
                className={"img"}
                src={`data:image/jpeg;base64,${qrcodeValue}`}
                alt={"qrcode"}
                width="230"
              />
            )}

            {!isBase64Qr && !isUrlImageQr && (
              <QRCode
                value={qrcodeValue}
                size={210}
                className={"img"}
                renderAs={"canvas"}
                level={"H"}
              />
            )}
          </Grid>
          <Grid container justify="center" alignItems="center" direction="row">
            <Typography variant="caption" align={"center"}>
              Data solicitação:
              {moment(currentTransaction?.transaction_date).format(
                "DD/MM/YYYY HH:mm:ss",
              )}
            </Typography>
          </Grid>
          <br />
          <div className={classes.centered}>
            <div className={classes.secondaryHeading}>
              <strong>ATENÇÂO:</strong> Este QRCode é válido por {secondsLeft}{" "}
              segundos.
            </div>
          </div>
        </div>
      </div>
      <div className={classes.centered}>
        <ReactToPrint
          trigger={linkToPrint}
          content={() => componentRef.current}
        />
        <IconButton
          className="hidden-print"
          aria-label="Fechar"
          color="primary"
          title="Fechar"
          onClick={handleClose}
        >
          <Close />
        </IconButton>
      </div>
    </div>
  );
};

export default TransactionQrPrint;
