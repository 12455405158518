export const formatterToCurrency = (text: number) => {
  return Number(text).toLocaleString("pt-br", {
    style: "currency",
    currency: "BRL",
  });
};

export const currencyFormatterComp = (value: any) => {
  if (!Number(value)) return "";
  const amount = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value / 100);

  return `${amount}`;
};

export const formatterToBrlCurrency = (text: number) => {
  return Number(text).toLocaleString("pt-br", {
    // style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
  });
};

export const formatterToPercent = (text: any) =>
  (Number(text) / 100).toLocaleString("pt-br", {
    style: "percent",
    maximumSignificantDigits: 4,
  });

export const countDecimals = (value: number) => {
  if (Math.floor(value.valueOf()) === value.valueOf()) return 2;
  const decimals = value.toString().split(".")[1];
  if (decimals.lastIndexOf("0") > -1) return decimals.length - 1 || 2;
  return decimals.length || 2;
};

export const decimalFormatter3 = (value: any) => {
  if (!Number(value)) return "";
  const amount = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    maximumFractionDigits: 3,
    minimumFractionDigits: 3,
  }).format(value / 1000);
  return `${amount}`.replace("R$", "");
};

export const decimalFormatter4 = (value: any) => {
  if (!Number(value)) return "";
  const amount = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    maximumFractionDigits: 4,
    minimumFractionDigits: 4,
  }).format(value / 10000);
  return `${amount}`.replace("R$", "");
};

export const formatDocumentNumber = (number: string) => {
  if (!number) return "";
  return number.replace(
    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
    "$1.$2.$3/$4-$5",
  );
};
