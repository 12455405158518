import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField as TextFieldMaterial,
} from "@material-ui/core";
import { Form, Field, Formik } from "formik";
/** @jsx jsx */
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { css, jsx } from "@emotion/core";
import { attendantCreate, attendantEdit } from "./Attendants.schema";

import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import CreateIcon from "@material-ui/icons/Create";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Header from "app/components/Header/Header.container";
import Skeleton from "@material-ui/lab/Skeleton";
import { TextField } from "formik-material-ui";
import { colors } from "shared/configs/styles.config";
import { screenPropsType } from "./Attendants.type";
import { useDispatch } from "react-redux";
import NumberFormat from "react-number-format";

const rowStyleFormat = (row: any, rowIdx: any) => {
  return {
    backgroundColor: rowIdx % 2 === 0 ? "rgba(0, 0, 0, 0.04)" : "#ffffff",
  };
};

const Screen: React.FC<screenPropsType> = ({
  handleGet,
  handleUpdate,
  handleAdd,
  attendantsList,
}) => {
  const dispatch = useDispatch();

  const [modalEdit, setModalEdit] = useState(false);
  const [modalCreate, setModalCreate] = useState(false);
  const [modalStatus, setModalStatus] = useState(false); // eslint-disable-next-line
  const [hasError, setHasError] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [userSelected, setUserSelected] = useState({
    name: "",
    code: "",
    phone_prefix: "",
    phone_number: "",
    document_number: "",
    active: true,
  });

  const { loading, attendants, error } = attendantsList || {
    attendants: [],
  };
  const { message } = error || {};

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    handleGet();
  }, []);

  useEffect(() => {
    if (message) setHasError(true);
  }, [message]);

  return (
    <Fragment>
      <Grid
        container
        justify="center"
        alignItems="center"
        direction="row"
        spacing={3}
      >
        <Grid item xs={12} lg={12}>
          <Header authentication={null} loading={loading} />
        </Grid>
        <Grid
          container
          justify="flex-end"
          css={css`
            && {
              margin-top: 24px;
            }
          `}
        ></Grid>
        <Grid
          item
          xs={12}
          sm={12}
          container
          justify="space-between"
          alignItems="center"
          direction="row"
        >
          <Button
            onClick={() => setModalCreate(true)}
            color="primary"
            variant="outlined"
            css={css`
              && {
                border-radius: 15px;
              }
            `}
          >
            Adicionar novo atendente
          </Button>
          <Grid
            container
            justify="flex-end"
            css={css`
              && {
                margin-top: 24px;
              }
            `}
          ></Grid>
        </Grid>

        {!loading ? (
          <Grid item sm={12} xs={12}>
            <TableContainer component={Paper}>
              <Table size="small" aria-label="Não há atendentes cadastrados">
                {attendants?.length === 0 && (
                  <caption>Não há atendentes cadastrados</caption>
                )}
                <TableHead>
                  <TableRow>
                    <TableCell
                      colSpan={6}
                      align="center"
                      style={{ backgroundColor: colors.tableTitleBack }}
                    >
                      <div style={{ color: colors.tableTitle }}>
                        LISTA DE ATENDENTES
                      </div>
                    </TableCell>
                  </TableRow>
                  <TableRow style={{ backgroundColor: "rgba(0, 0, 0, 0.26)" }}>
                    <TableCell align="left">Código</TableCell>
                    <TableCell align="left">Nome</TableCell>
                    <TableCell align="left">Documento</TableCell>
                    <TableCell align="left">Telefone</TableCell>
                    <TableCell align="center">Ativo</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {attendants
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage,
                    )
                    .map((user: any, index: any) => (
                      <TableRow
                        key={user.id}
                        style={rowStyleFormat(user, index)}
                      >
                        <TableCell>{user.code}</TableCell>
                        <TableCell component="th" scope="row">
                          {user.name}
                        </TableCell>
                        <TableCell>{user.document_number}</TableCell>
                        <TableCell>{`(${user.phone_prefix}) ${user.phone_number}`}</TableCell>
                        <TableCell align="center">
                          {user.active ? (
                            <div>
                              <CheckIcon
                                color="secondary"
                                style={{ margin: "-7px 2px" }}
                              />
                              Sim
                            </div>
                          ) : (
                            <div>
                              <ClearIcon
                                color="error"
                                style={{ margin: "-7px 2px" }}
                              />
                              Não
                            </div>
                          )}
                        </TableCell>
                        <TableCell>
                          <Grid
                            container
                            direction="row"
                            justify="flex-end"
                            alignItems="flex-end"
                          >
                            <Grid item sm={"auto"} xs={"auto"} lg={"auto"}>
                              <IconButton
                                title={user.active ? "Inativar" : "Ativar"}
                                onClick={() => {
                                  setModalStatus(true);
                                  setUserSelected({
                                    ...user,
                                  });
                                }}
                                color="inherit"
                              >
                                {!user.active ? (
                                  <CheckCircleOutlineIcon color="secondary" />
                                ) : (
                                  <HighlightOffIcon />
                                )}
                              </IconButton>
                              <IconButton
                                title="Editar"
                                disabled={!user.active}
                                onClick={() => {
                                  setModalEdit(true);
                                  setUserSelected({
                                    ...user,
                                  });
                                }}
                                color="inherit"
                              >
                                <CreateIcon />
                              </IconButton>
                              {/* <IconButton
                                title="Excluir"
                                disabled={!user.active}
                                onClick={() => {
                                  setModalEdit(true);
                                  setUserSelected({
                                    ...user,
                                  });
                                }}
                                color="inherit"
                              >
                                <DeleteIcon />
                              </IconButton> */}
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={attendants.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Grid>
        ) : (
          <Grid item sm={12} xs={12}>
            <Skeleton variant="rect" height={30} />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
          </Grid>
        )}
      </Grid>
      {/* Modal Edit */}
      <Dialog
        fullWidth
        open={modalEdit}
        onClose={() => setModalEdit(false)}
        aria-labelledby="form-dialog-title"
      >
        <Formik
          initialValues={userSelected}
          onSubmit={data => {
            handleUpdate(data);
            setModalEdit(false);
          }}
          validationSchema={attendantEdit}
        >
          {({ submitForm, isSubmitting, errors, dirty, setValues }) => (
            <Form>
              <DialogTitle id="form-dialog-title">
                Edição de Atendente
              </DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  name="code"
                  required
                  label="Código"
                  variant="outlined"
                  fullWidth
                />
                <TextField
                  name="name"
                  required
                  label="Nome"
                  variant="outlined"
                  fullWidth
                  css={css`
                    && {
                      margin-top: 24px;
                    }
                  `}
                />
                <TextField
                  name="document_number"
                  required
                  label="Documento (CPF/RG/CNH)"
                  variant="outlined"
                  fullWidth
                  css={css`
                    && {
                      margin-top: 24px;
                    }
                  `}
                />

                <Grid
                  item
                  xs={12}
                  css={css`
                    && {
                      margin-top: 24px;
                    }
                  `}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={2}>
                      <Field name="phone_prefix">
                        {({
                          field: { value, name },
                          form: {
                            setFieldValue,
                            setFieldTouched,
                            isSubmitting,
                          },
                          meta: { error, touched },
                        }: any) => (
                          <NumberFormat
                            customInput={TextFieldMaterial}
                            format="(##)"
                            fullWidth
                            required
                            autoComplete="nope"
                            disabled={isSubmitting}
                            label="DDD"
                            variant="outlined"
                            value={value}
                            error={touched && !!error}
                            helperText={touched && error}
                            onBlur={({
                              target: { value },
                            }: React.ChangeEvent<HTMLInputElement>) => {
                              setFieldTouched(name, true, false);
                            }}
                            onValueChange={(values: any) =>
                              setFieldValue(name, values.value)
                            }
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs>
                      <Field name="phone_number">
                        {({
                          field: { value, name },
                          form: {
                            setFieldValue,
                            setFieldTouched,
                            isSubmitting,
                          },
                          meta: { error, touched },
                        }: any) => (
                          <NumberFormat
                            customInput={TextFieldMaterial}
                            format={
                              value.length === 8 ? "####-#####" : "#####-####"
                            }
                            fullWidth
                            autoComplete="nope"
                            required
                            disabled={isSubmitting}
                            label="Telefone"
                            variant="outlined"
                            value={value}
                            error={touched && !!error}
                            helperText={touched && error}
                            onBlur={({
                              target: { value },
                            }: React.ChangeEvent<HTMLInputElement>) => {
                              setFieldTouched(name, true, false);
                            }}
                            onValueChange={(values: any) =>
                              setFieldValue(name, values.value)
                            }
                          />
                        )}
                      </Field>
                    </Grid>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setModalEdit(false)}
                  variant="outlined"
                  css={css`
                    && {
                      margin-top: 15px;
                      border-radius: 15px;
                    }
                  `}
                >
                  Cancelar
                </Button>
                <Button
                  onClick={submitForm}
                  disabled={isSubmitting || Object.keys(errors).length > 0}
                  color="secondary"
                  variant="contained"
                  css={css`
                    && {
                      margin-top: 15px;
                      border-radius: 15px;
                    }
                  `}
                >
                  SALVAR
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>

      {/* Modal Create */}
      <Dialog
        fullWidth
        open={modalCreate}
        onClose={() => setModalCreate(false)}
        aria-labelledby="form-dialog-title"
      >
        <Formik
          initialValues={{
            name: "",
            code: "",
            document_number: "",
            phone_prefix: "",
            phone_number: "",
          }}
          onSubmit={data => {
            handleAdd(data);
            setModalCreate(false);
          }}
          validationSchema={attendantCreate}
        >
          {({ submitForm, isSubmitting, errors, dirty }) => (
            <Form>
              <DialogTitle id="form-dialog-title">Novo Atendente</DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  name="code"
                  required
                  label="Código"
                  variant="outlined"
                  fullWidth
                />
                <TextField
                  name="name"
                  required
                  label="Nome"
                  variant="outlined"
                  fullWidth
                  css={css`
                    && {
                      margin-top: 24px;
                    }
                  `}
                />
                <TextField
                  name="document_number"
                  required
                  label="Documento (CPF/RG/CNH)"
                  variant="outlined"
                  fullWidth
                  css={css`
                    && {
                      margin-top: 24px;
                    }
                  `}
                />
                <Grid
                  item
                  xs={12}
                  css={css`
                    && {
                      margin-top: 24px;
                    }
                  `}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={2}>
                      <Field name="phone_prefix">
                        {({
                          field: { value, name },
                          form: {
                            setFieldValue,
                            setFieldTouched,
                            isSubmitting,
                          },
                          meta: { error, touched },
                        }: any) => (
                          <NumberFormat
                            customInput={TextFieldMaterial}
                            format="(##)"
                            fullWidth
                            required
                            autoComplete="nope"
                            disabled={isSubmitting}
                            label="DDD"
                            variant="outlined"
                            value={value}
                            error={touched && !!error}
                            helperText={touched && error}
                            onBlur={({
                              target: { value },
                            }: React.ChangeEvent<HTMLInputElement>) => {
                              setFieldTouched(name, true, false);
                            }}
                            onValueChange={(values: any) =>
                              setFieldValue(name, values.value)
                            }
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs>
                      <Field name="phone_number">
                        {({
                          field: { value, name },
                          form: {
                            setFieldValue,
                            setFieldTouched,
                            isSubmitting,
                          },
                          meta: { error, touched },
                        }: any) => (
                          <NumberFormat
                            customInput={TextFieldMaterial}
                            format={
                              value.length === 8 ? "####-#####" : "#####-####"
                            }
                            fullWidth
                            autoComplete="nope"
                            required
                            disabled={isSubmitting}
                            label="Telefone"
                            variant="outlined"
                            value={value}
                            error={touched && !!error}
                            helperText={touched && error}
                            onBlur={({
                              target: { value },
                            }: React.ChangeEvent<HTMLInputElement>) => {
                              setFieldTouched(name, true, false);
                            }}
                            onValueChange={(values: any) =>
                              setFieldValue(name, values.value)
                            }
                          />
                        )}
                      </Field>
                    </Grid>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setModalCreate(false)}
                  variant="outlined"
                  css={css`
                    && {
                      margin-top: 15px;
                      border-radius: 15px;
                    }
                  `}
                >
                  CANCELAR
                </Button>
                <Button
                  onClick={submitForm}
                  disabled={
                    isSubmitting || Object.keys(errors).length > 0 || !dirty
                  }
                  color="secondary"
                  variant="contained"
                  css={css`
                    && {
                      margin-top: 15px;
                      border-radius: 15px;
                    }
                  `}
                >
                  SALVAR
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>

      {/* Modal Confirm Change Status */}
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        aria-labelledby="confirmation-change-status"
        open={modalStatus}
      >
        <DialogTitle id="confirmation-change-status">
          Editar Atendente
        </DialogTitle>
        <DialogContent dividers>
          {userSelected.active ? (
            <DialogContentText>
              <b>Tem certeza que deseja inativar este atendente?</b>
              <br />
              <br />
              Atendentes inativos não podem gerar transações na plataforma
            </DialogContentText>
          ) : (
            <DialogContentText>
              <b>Tem certeza que deseja ativar o atendente ?</b>
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => setModalStatus(false)}
            variant="outlined"
            css={css`
              && {
                margin-top: 15px;
                border-radius: 15px;
              }
            `}
          >
            CANCELAR
          </Button>
          <Button
            onClick={() => {
              handleUpdate({
                ...userSelected,
                active: !userSelected.active,
              });
              setModalStatus(false);
            }}
            color="secondary"
            variant="contained"
            css={
              userSelected.active
                ? css`
                    && {
                      margin-top: 15px;
                      border-radius: 15px;
                      background-color: #9a0036;
                      color: #ffffff;
                    }
                  `
                : css`
                    && {
                      margin-top: 15px;
                      border-radius: 15px;
                    }
                  `
            }
          >
            CONFIRMAR
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default Screen;
