import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
  Typography,
  createMuiTheme,
  makeStyles,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { css, jsx } from "@emotion/core";
import {
  formatDocumentNumber,
  formatterToBrlCurrency,
  formatterToCurrency,
} from "shared/utils/formatter.util";

import CheckIcon from "@material-ui/icons/Check";
import InfoIcon from "@material-ui/icons/Info";
import Paper from "@material-ui/core/Paper";
import ScheduleIcon from "@material-ui/icons/Schedule";
import { Skeleton } from "@material-ui/lab";
import { colors } from "shared/configs/styles.config";
import moment from "moment";
import { useDispatch } from "react-redux";

/** @jsx jsx */

const rowStyleFormat = (row: any, rowIdx: any) => {
  return {
    backgroundColor: rowIdx % 2 === 0 ? "rgba(0, 0, 0, 0.04)" : "#ffffff",
  };
};

const theme = createMuiTheme({
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
  },
  overrides: {
    MuiTableCell: {
      paddingNone: {
        padding: "2px 2px 2px 2px",
      },
    },
  },
});

const useStyles = makeStyles({
  tableTitle: {
    fontSize: "14px",
    backgroundColor: colors.tableTitleBack,
  },
  tableHeader: {
    fontSize: "11px",
  },
  tableRow: {
    fontSize: "12px",
  },
});

const LastTransactions: React.FC<any> = ({
  transactionPending,
  loading,
  getLastTransactions,
  lastTransactions,
  isPinbankApp,
  isAmeApp,
  isPayInsightsApp,
  isBancoOriginal,
}) => {
  const classes = useStyles();
  const [modalInfo, setModalInfo] = useState(false);
  const [transactionInfo, setTransactionInfo] = useState<any>();

  const handleTransactionInfo = (dataRow: any) => {
    setTransactionInfo(dataRow);
    setModalInfo(true);
  };

  const getRowsByIssuer = (row: any, index: any) => {
    let dataRow = {
      ...row,
    };

    if (isPinbankApp) {
      dataRow = {
        ...dataRow,
        date: row.DataCaptura,
        nsu: row.NsuToken,
        authorization: row.IdPinsafeTransacao,
        name: row.NomePagador,
        amount: row.ValorTransacao,
        pending: !row.ConfirmacaoRecebimento,
      };
    }
    if (isAmeApp) {
      dataRow = {
        ...dataRow,
        date: moment(row.date).utc(true),
        nsu: row.nsu,
        authorization: row.id,
        name: row.peer.name,
        amount: row.amount / 100,
      };
    }
    if (isPayInsightsApp) {
      dataRow = {
        ...dataRow,
        date: row.Dt_movimentacao,
        nsu: row.NSU,
        authorization: row.AuthorizationCode,
        name: row.payee_name,
        amount: row.VLR_movimentado,
      };
    }

    return (
      <TableRow key={index} style={rowStyleFormat(dataRow, index)}>
        <TableCell className={classes.tableRow} align="center">
          {isBancoOriginal
            ? moment(
                moment(dataRow.date)
                  .utc(false)
                  .format("YYYY/MM/DD HH:mm:ss"),
              ).fromNow()
            : moment(dataRow.date).fromNow()}
        </TableCell>
        <TableCell className={classes.tableRow} align="center">
          {isBancoOriginal
            ? moment(dataRow.date)
                .utc(false)
                .format("DD/MM/YYYY HH:mm:ss")
            : moment(dataRow.date).format("DD/MM/YYYY HH:mm:ss")}
        </TableCell>
        <TableCell className={classes.tableRow} align="center">
          {formatterToBrlCurrency(dataRow.amount)}
        </TableCell>

        <TableCell className={classes.tableRow} align="center">
          {row.pending ? (
            <div>
              <ScheduleIcon color="primary" style={{ margin: "-7px 2px" }} />
            </div>
          ) : (
            <div>
              <CheckIcon color="secondary" style={{ margin: "-7px 2px" }} />
            </div>
          )}
        </TableCell>
        <TableCell className={classes.tableRow} align="center">
          <div
            css={css`
              && {
                cursor: pointer;
              }
            `}
          >
            <InfoIcon
              color="primary"
              onClick={() => handleTransactionInfo(dataRow)}
            />
          </div>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Grid item xs={12} sm={12} lg={12}>
      {loading && !transactionPending ? (
        <Grid item>
          <Skeleton variant="rect" height={30} />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
        </Grid>
      ) : (
        <Grid item>
          <Grid
            css={css`
              && {
                margin-bottom: 10px;
              }
            `}
            item
            xs={12}
            sm={6}
            lg={6}
          >
            <Button
              onClick={getLastTransactions}
              fullWidth
              variant="outlined"
              color="primary"
              disabled={loading}
              css={css`
                && {
                  border-radius: 15px;
                }
              `}
            >
              Ver Últimas Transações
            </Button>
          </Grid>
          <ThemeProvider theme={theme}>
            <TableContainer component={Paper}>
              <Table
                size="small"
                padding={"none"}
                css={css`
                  && {
                  }
                `}
                aria-label="Últimas 10 transações"
              >
                {lastTransactions.length === 0 && (
                  <caption>Não há transações para este aplicativo</caption>
                )}

                {lastTransactions.length > 0 && (
                  <TableHead>
                    <TableRow>
                      <TableCell
                        colSpan={7}
                        align="center"
                        className={classes.tableTitle}
                      >
                        <div style={{ color: colors.tableTitle }}>
                          Exibindo últimas 10 transações
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      style={{ backgroundColor: "rgba(0, 0, 0, 0.26)" }}
                    >
                      <TableCell align="center" className={classes.tableHeader}>
                        Tempo
                      </TableCell>
                      <TableCell align="center" className={classes.tableHeader}>
                        Data
                      </TableCell>
                      <TableCell align="center" className={classes.tableHeader}>
                        Valor
                      </TableCell>
                      <TableCell align="center" className={classes.tableHeader}>
                        Recebido
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.tableHeader}
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                )}

                {lastTransactions.length > 0 && (
                  <TableBody>
                    {lastTransactions
                      ?.slice(0, 10)
                      .map((row: any, index: any) => {
                        return getRowsByIssuer(row, index);
                      })}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </ThemeProvider>
        </Grid>
      )}

      <Dialog
        fullWidth
        open={modalInfo}
        onClose={() => setModalInfo(false)}
        maxWidth="xs"
      >
        <DialogContent>
          {transactionInfo && (
            <Card elevation={0}>
              <Grid container direction="column" alignItems="center">
                <Grid item>
                  <CardHeader
                    avatar={
                      <CardMedia
                        css={css`
                          && {
                            height: 0;
                            width: 150px;
                            padding-top: 100% !important;
                          }
                        `}
                        image={
                          transactionInfo.application_root?.logo_path ||
                          transactionInfo.application.logo_path
                        }
                        title={transactionInfo.application.name}
                      />
                    }
                  />
                </Grid>
                <Grid item>
                  <Typography variant="subtitle2">
                    {`Transação com ${transactionInfo.application_root?.name ||
                      ""} ${transactionInfo.application.name}`}
                  </Typography>
                </Grid>
              </Grid>

              <CardContent>
                <div>
                  <Typography
                    variant="caption"
                    display="block"
                    color="textPrimary"
                  >
                    <Fragment>
                      Data:{" "}
                      <strong>
                        {moment(transactionInfo.date).format(
                          "DD/MM/YYYY HH:mm:ss",
                        )}{" "}
                      </strong>
                      <br />
                      <small>{moment(transactionInfo.date).fromNow()}</small>
                    </Fragment>
                  </Typography>

                  <Typography
                    variant="caption"
                    display="block"
                    color="textPrimary"
                    css={css`
                      && {
                        margin-top: 10px;
                      }
                    `}
                  >
                    <strong>{transactionInfo.merchant.trading_name}</strong>
                  </Typography>

                  <Typography
                    variant="caption"
                    display="block"
                    color="textPrimary"
                  >
                    CNPJ:{" "}
                    {formatDocumentNumber(
                      transactionInfo.merchant.document_number,
                    )}
                  </Typography>

                  <Typography
                    variant="caption"
                    display="block"
                    color="textPrimary"
                    css={css`
                      && {
                        margin-top: 10px;
                      }
                    `}
                  >
                    Valor:{" "}
                    <strong>
                      {formatterToCurrency(transactionInfo.amount)}
                    </strong>
                  </Typography>

                  <Typography
                    variant="caption"
                    display="block"
                    color="textPrimary"
                  >
                    Pagador: {transactionInfo.name}
                  </Typography>

                  <Typography
                    variant="caption"
                    display="block"
                    color="textPrimary"
                  >
                    Autorização: {transactionInfo.authorization}
                  </Typography>

                  <Typography
                    variant="caption"
                    display="block"
                    color="textPrimary"
                  >
                    NSU: {transactionInfo.nsu}
                  </Typography>
                  {transactionInfo.reference_id && (
                    <Typography
                      variant="caption"
                      display="block"
                      color="textPrimary"
                    >
                      REF: {transactionInfo.reference_id}
                    </Typography>
                  )}
                </div>
              </CardContent>
              <CardActions disableSpacing></CardActions>
            </Card>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setModalInfo(false)}
            variant="outlined"
            css={css`
              && {
                border-radius: 15px;
              }
            `}
          >
            FECHAR
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default LastTransactions;
