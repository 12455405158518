import { Grid, TextField } from "@material-ui/core";
/** @jsx jsx */
import React from "react";
import { css, jsx } from "@emotion/core";

import { Autocomplete } from "@material-ui/lab";

const AppSelector: React.FC<any> = ({
  loading,
  appsList,
  appId,
  onChange,
  inputRef,
  ...props
}) => {
  return (
    <Autocomplete
      disableClearable={true}
      autoComplete
      autoSelect
      style={{ width: "100%" }}
      loading={loading}
      disabled={loading}
      value={appsList.find((d: any) => d.id === appId) ?? null}
      options={appsList}
      getOptionLabel={(option: any) => option.name}
      renderOption={(option: any) => (
        <React.Fragment>
          <span>
            <img
              src={option?.logo_path}
              alt={option?.name}
              style={{ width: "40px" }}
            />
          </span>
          {option.name}
        </React.Fragment>
      )}
      onChange={onChange}
      renderInput={params => (
        <TextField
          {...params}
          inputRef={inputRef}
          style={{ width: "100%" }}
          variant="outlined"
          label="Aplicativo"
          placeholder="Aplicativo"
        />
      )}
      {...props}
    />
  );
};

export default AppSelector;
