/** @jsx jsx */

import { Redirect, Route, Switch } from "react-router-dom";
import { TypedUseSelectorHook, useSelector } from "react-redux";

import Applications from "./screens/Apps/Apps.container";
import Attendants from "./screens/Attendants/Attendants.container";
import Terminals from "./screens/Terminals/Terminals.container";
import CustomPaymentLink from "./screens/CustomFlow/CustomPaymentLink.container";
import CustomUsePay from "./screens/CustomFlow/UsePay.container";
import Merchant from "./screens/Merchant/Merchant.container";
import PublicLayout from "public/layouts/Public.layout";
import ReportClosing from "./screens/ReportClosing/ReportClosing.container";
import ReportMatching from "./screens/ReportMatching/ReportMatching.container";
import ReportRefund from "./screens/ReportRefund/ReportRefund.container";

import ReportReceivable from "./screens/ReportReceivable/ReportReceivable.container";
import Transactions from "./screens/Transactions/Transactions.container";
import Users from "./screens/Users/Users.container";
import { jsx } from "@emotion/core";
import { stateReduxType } from "shared/types/authentication.type";

const useTypedSelector: TypedUseSelectorHook<stateReduxType> = useSelector;

const App = () => {
  const { signedIn } = useTypedSelector(({ shared }) => shared.authentication);

  if (!signedIn) {
    return <Redirect to="/login" />;
  }

  return (
    <PublicLayout>
      <Switch>
        <Route exact path={"/app"} component={Applications} />
        <Route exact path={"/app/new-transaction"} component={Transactions} />
        <Route exact path={"/app/usepay"} component={CustomUsePay} />
        <Route exact path={"/app/link"} component={CustomPaymentLink} />
        <Route exact path={"/app/users"} component={Users} />
        <Route exact path={"/app/attendants"} component={Attendants} />
        <Route exact path={"/app/terminals"} component={Terminals} />
        <Route exact path={"/app/profile"} component={Merchant} />
        <Route exact path={"/app/report-closing"} component={ReportClosing} />
        <Route exact path={"/app/report-matching"} component={ReportMatching} />
        <Route exact path={"/app/refunds"} component={ReportRefund} />
        <Route
          exact
          path={"/app/report-receivables"}
          component={ReportReceivable}
        />
      </Switch>
    </PublicLayout>
  );
};

export default App;
