import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TableCell,
  TableFooter,
  TableRow,
  ThemeProvider,
} from "@material-ui/core";
import {
  CustomMuiTheme,
  CustomTablePaggination,
  MuiDefaultOptions,
  useTableCustomStyles,
} from "app/components/Grid/MuiTable";
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React, { Fragment, useEffect, useState } from "react";
import { css, jsx } from "@emotion/core";

import ListAltIcon from "@material-ui/icons/ListAlt";
import MUIDataTable from "mui-datatables";
import MomentUtils from "@date-io/moment";
import SearchIcon from "@material-ui/icons/Search";
import { Skeleton } from "@material-ui/lab";
import { formatterToBrlCurrency } from "shared/utils/formatter.util";
import moment from "moment";

/** @jsx jsx */

const Screen: React.FC<any> = ({
  getTransactions,
  transactionsGroup,
  loading,
  expand,
  handleExpand,
  setRowExpanded,
  availableMerchants,
  activeMerchant,
}) => {
  const classes = useTableCustomStyles();

  const [selectedDateBegin, setSelectedDateBegin] = useState(
    new Date(new Date().setHours(0, 0, 0)),
  );
  const [selectedDateEnd, setSelectedDateEnd] = useState(
    new Date(new Date().setHours(23, 59, 59)),
  );
  const [transactionsGroupFiltered, setTransactionsGroupFiltered] = useState(
    [],
  );
  const [filterList, setFilterList] = useState<any>([]);
  const [onlyConfirmed, setOnlyConfirmed] = useState(true);

  const [selectedMerchants, setSelectedMerchants] = useState<any>([]);

  const handleDateBeginChange = (date: any) => {
    if (!date) setSelectedDateBegin(new Date(new Date().setHours(0, 0, 0)));
    else setSelectedDateBegin(date);
  };

  const handleDateEndChange = (date: any) => {
    if (!date) setSelectedDateEnd(new Date(new Date().setHours(23, 59, 59)));
    else setSelectedDateEnd(date);
  };

  const handleMerchantChange = (event: React.ChangeEvent<{ value: any }>) => {
    const value = event.target.value;
    if (value) setSelectedMerchants(value);
  };

  const isValid = () => {
    return (
      moment(selectedDateBegin).isValid() && moment(selectedDateEnd).isValid()
    );
  };

  const handleGetTransactions = () => {
    getTransactions({
      dateBegin: selectedDateBegin,
      dateEnd: selectedDateEnd,
      grouped: true,
      offset: 0,
      limit: 2000,
      onlyConfirmed: onlyConfirmed,
      merchant_ids: selectedMerchants.map((it: any) => it.id),
    });
  };

  useEffect(() => {
    handleGetTransactions();
  }, []);

  useEffect(() => {
    if (activeMerchant)
      setSelectedMerchants(
        availableMerchants.filter((m: any) => m.id === activeMerchant.id),
      );
  }, [activeMerchant]);

  useEffect(() => {
    setTransactionsGroupFiltered(transactionsGroup);
  }, [transactionsGroup]);

  return (
    <Grid item xs={12} sm={12} lg={12}>
      {loading ? (
        <Grid item>
          <Skeleton variant="rect" height={30} />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
        </Grid>
      ) : (
        <Grid item xs={12} sm={12} lg={12}>
          {!expand ? (
            <ThemeProvider theme={CustomMuiTheme}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <Grid container justify="space-evenly">
                  <Grid item>
                    <KeyboardDatePicker
                      size={"small"}
                      label="Data início"
                      cancelLabel="Cancelar"
                      value={selectedDateBegin}
                      format="DD/MM/YYYY"
                      onChange={handleDateBeginChange}
                      maxDate={selectedDateEnd}
                      maxDateMessage="Data deve ser menor ou igual a data final"
                      invalidDateMessage="Data inválida"
                      css={{
                        maxWidth: 150,
                      }}
                      disableFuture
                    />
                    <KeyboardTimePicker
                      size={"small"}
                      label="Hora"
                      ampm={false}
                      cancelLabel="Cancelar"
                      value={selectedDateBegin}
                      format="HH:mm:ss"
                      onChange={handleDateBeginChange}
                      views={["hours", "minutes", "seconds"]}
                      invalidDateMessage="Horário inválido"
                      invalidLabel="Horário inválido"
                      css={{
                        maxWidth: 150,
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <KeyboardDatePicker
                      size={"small"}
                      label="Data final"
                      cancelLabel="Cancelar"
                      value={selectedDateEnd}
                      format="DD/MM/YYYY"
                      onChange={handleDateEndChange}
                      minDate={selectedDateBegin}
                      minDateMessage="Data deve ser maior ou igual a data inicial"
                      invalidDateMessage="Data inválida"
                      disableFuture
                      css={{
                        maxWidth: 150,
                      }}
                    />
                    <KeyboardTimePicker
                      size={"small"}
                      label="Hora"
                      ampm={false}
                      cancelLabel="Cancelar"
                      value={selectedDateEnd}
                      format="HH:mm:ss"
                      onChange={handleDateEndChange}
                      views={["hours", "minutes", "seconds"]}
                      maxDateMessage="Horário deve ser menor ou igual a data final"
                      invalidDateMessage="Horário inválido"
                      css={{
                        maxWidth: 150,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} lg={3}>
                    <FormControl>
                      <InputLabel>Estabelecimento</InputLabel>
                      <Select
                        onChange={handleMerchantChange}
                        value={selectedMerchants}
                        renderValue={(selected: any) =>
                          selected.map((it: any) => it.name).join(", ")
                        }
                        autoWidth
                        multiple
                        css={{
                          minWidth: 150,
                          maxWidth: 350,
                        }}
                      >
                        {availableMerchants.map((row: any, index: any) => (
                          <MenuItem key={row.id} value={row}>
                            <Checkbox
                              checked={
                                selectedMerchants.filter(
                                  (it: any) => it.name === row.name,
                                )?.length > 0
                              }
                            />
                            {row.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={() => {
                        handleGetTransactions();
                      }}
                      fullWidth
                      color="secondary"
                      startIcon={<SearchIcon />}
                      disabled={!isValid()}
                      css={css`
                        && {
                          margin-top: 10px;
                          font-weight: bold;
                        }
                      `}
                    >
                      PESQUISAR
                    </Button>
                  </Grid>
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={onlyConfirmed}
                        onChange={() => setOnlyConfirmed(v => !v)}
                        color="secondary"
                      />
                    }
                    label="Somente confirmados"
                  />
                </Grid>
              </MuiPickersUtilsProvider>

              <MUIDataTable
                title={<Grid className={classes.tableTitle}>Transações</Grid>}
                data={transactionsGroup}
                css={css`
                  && {
                    margin-top: 15px;
                  }
                `}
                columns={[
                  {
                    name: "merchant",
                    label: "Estabelecimento",
                    options: {
                      filter: true,
                      sort: true,
                      filterList: filterList[0] ? filterList[0] : undefined,
                      customBodyRender: (value, tableMeta, updateValue) => {
                        const document_number = (
                          value?.document_number || ""
                        ).replace(
                          /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                          "$1.$2.$3/$4-$5",
                        );
                        if (value && value.trading_name)
                          return `${document_number} - ${value?.trading_name}`;
                        return "";
                      },
                    },
                  },
                  {
                    name: "application.name",
                    label: "Aplicativo",
                    options: {
                      filter: true,
                      sort: true,
                      filterList: filterList[1] ? filterList[1] : undefined,
                      customBodyRender: (value, tableMeta, updateValue) => {
                        const row = transactionsGroup[tableMeta.rowIndex];
                        if (row.application_root?.name)
                          return `${row.application_root?.name} / ${value}`;
                        return value;
                      },
                    },
                  },
                  {
                    name: "user.name",
                    label: "Operador",
                    options: {
                      filter: true,
                      sort: true,
                      filterList: filterList[2] ? filterList[2] : undefined,
                    },
                  },
                  {
                    name: "transaction_date",
                    label: "Data",
                    options: {
                      filter: true,
                      sort: true,
                      filterList: filterList[3] ? filterList[3] : undefined,
                      customBodyRender: (value, tableMeta, updateValue) => {
                        return moment(value).format("DD/MM/YYYY");
                      },
                    },
                  },
                  {
                    name: "total",
                    label: "Quantidade",
                    options: {
                      filter: true,
                      sort: true,
                      filterList: filterList[4] ? filterList[4] : undefined,
                    },
                  },
                  {
                    name: "total_amount",
                    label: "Valor total",
                    options: {
                      filter: true,
                      sort: true,
                      filterList: filterList[5] ? filterList[5] : undefined,
                      customBodyRender: (value, tableMeta, updateValue) => {
                        return formatterToBrlCurrency(value);
                      },
                    },
                  },
                  {
                    name: "",
                    options: {
                      filter: false,
                      viewColumns: false,
                      sort: false,
                      empty: true,
                      print: false,
                      download: false,
                      customBodyRender: (value, tableMeta, updateValue) => {
                        const { rowData } = tableMeta;
                        const row: any = transactionsGroup.find(
                          (f: any) =>
                            f.transaction_date === rowData[3] &&
                            f.total === rowData[4] &&
                            f.total_amount === rowData[5],
                        );

                        return (
                          <IconButton
                            aria-label="detalhes"
                            color="primary"
                            title="Ver detalhes"
                            onClick={() => {
                              getTransactions({
                                dateBegin: row.date_min,
                                dateEnd: row.date_max,
                                grouped: false,
                                user_id: row.user.id,
                                application_id: row.application.id,
                                offset: 0,
                                limit: 2000,
                                onlyConfirmed: onlyConfirmed,
                                merchant_ids: [row.merchant.id],
                              });
                              handleExpand(true);
                              setRowExpanded({
                                ...row,
                                onlyConfirmed,
                                selectedDateBegin,
                                selectedDateEnd,
                                date_min: row.date_min,
                                date_max: row.date_max,
                              });
                            }}
                          >
                            <ListAltIcon />
                          </IconButton>
                        );
                      },
                    },
                  },
                ]}
                options={{
                  ...MuiDefaultOptions,
                  onFilterChange: (
                    changedColumn: any,
                    filterList: any,
                    type: any,
                  ) => {
                    setFilterList(filterList);

                    const hasField = (index: any, elementValue: any) => {
                      if (filterList[index].length === 0) return true;
                      return filterList[index].includes(elementValue);
                    };

                    const filtered: any = [];
                    transactionsGroup.forEach((element: any) => {
                      let elementValid = false;

                      elementValid =
                        hasField(1, element["application"]["name"]) &&
                        hasField(2, element["user"]["name"]) &&
                        hasField(
                          3,
                          moment(element["transaction_date"]).format(
                            "DD/MM/YYYY",
                          ),
                        ) &&
                        hasField(4, element["total"]) &&
                        hasField(
                          5,
                          formatterToBrlCurrency(element["total_amount"]),
                        );

                      if (elementValid) filtered.push(element);
                    });

                    setTransactionsGroupFiltered(filtered);
                  },
                  downloadOptions: {
                    filename: "transacoes.csv",
                    separator: ";",
                    filterOptions: {
                      useDisplayedColumnsOnly: true,
                      useDisplayedRowsOnly: true,
                    },
                  },
                  customFooter: (
                    rowCount: number,
                    page: number,
                    rowsPerPage: number,
                    changeRowsPerPage: (page: string | number) => void,
                    changePage: (newPage: number) => void,
                  ) => {
                    if (
                      !transactionsGroupFiltered ||
                      transactionsGroupFiltered.length === 0
                    )
                      return <Fragment />;
                    return (
                      <TableFooter>
                        <TableRow>
                          <TableCell
                            colSpan={2}
                            align="center"
                            className={classes.tableTotalTitle}
                          >
                            Totais
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            align="center"
                            className={classes.tableHeader}
                          >
                            Quantidade
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableHeader}
                          >
                            Valor
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            align="center"
                            className={classes.tableRowTotal}
                          >
                            <b>
                              {transactionsGroupFiltered
                                .map((m: any) => m.total)
                                .reduce((a: any, b: any) => a + b)}
                            </b>
                          </TableCell>

                          <TableCell
                            align="center"
                            className={classes.tableRowTotal}
                          >
                            <b>
                              {formatterToBrlCurrency(
                                transactionsGroupFiltered
                                  .map((m: any) => Number(m.total_amount))
                                  .reduce((a: any, b: any) => a + b),
                              )}
                            </b>
                          </TableCell>
                        </TableRow>
                        <TableRow className="datatables-noprint">
                          <CustomTablePaggination
                            rowCount={rowCount}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            changePage={changePage}
                            changeRowsPerPage={changeRowsPerPage}
                          />
                        </TableRow>
                      </TableFooter>
                    );
                  },
                }}
              />
            </ThemeProvider>
          ) : (
            <Grid />
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default Screen;
