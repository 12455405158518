import React, { useEffect } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import {
  cancelTransaction,
  getTransactionItems,
  handleCheckPayment,
  handleListTransactions,
  initState,
  newTransaction,
} from "app/ducks/transactions.duck";

import TransactionScreen from "./Transactions.screen";
import { stateReduxType as appsStateReduxType } from "app/screens/Apps/Apps.type";
import { stateReduxType as authStateReduxType } from "shared/types/authentication.type";
import { loadMerchantParams } from "shared/ducks/authentication.duck";
import { stateReduxType as transactionStateReduxType } from "./Transactions.type";

const useTypedSelectorAuth: TypedUseSelectorHook<authStateReduxType> = useSelector;
const useTypedSelector: TypedUseSelectorHook<transactionStateReduxType> = useSelector;
const useTypedSelectorApps: TypedUseSelectorHook<appsStateReduxType> = useSelector;

const TransactionsContainer: React.FC<any> = ({ history }) => {
  const { applications } = useTypedSelectorApps(state => state.app) || {};
  const { transactions } = useTypedSelector(state => state.app) || {};
  const { authentication } = useTypedSelectorAuth(state => state.shared) || {};
  const { data: auth, params: merchantParams } = authentication;
  const { user } = auth;
  const merchant = user?.merchants[0];
  const { app } = applications;
  const dispatch = useDispatch();
  const { currentTransaction, paymentConfirmed, paymentStatus } = transactions;

  useEffect(() => {
    dispatch(initState());
  }, []);

  useEffect(() => {
    if (!merchantParams) dispatch(loadMerchantParams(auth));
  }, []);

  return (
    <TransactionScreen
      handleNewTransaction={(data: any) =>
        newTransaction({
          data,
          auth,
        })
      }
      handleListTransactions={() => {
        dispatch(
          handleListTransactions({
            issuer_id: app && app.issuer_entity_id,
            application_id: app && app.id,
            merchant_id: merchant && merchant.id,
          }),
        );
      }}
      getTransactionItems={() => {
        dispatch(
          getTransactionItems({
            issuer_id: app && app.issuer_entity_id,
            application_id: app && app.id,
            merchant_id: merchant && merchant.id,
          }),
        );
      }}
      history={history}
      application={app}
      transactions={transactions}
      merchantParams={merchantParams}
      handleCheckPayment={() => {
        if (!currentTransaction) return;
        dispatch(
          handleCheckPayment({
            currentTransaction,
          }),
        );
      }}
      currentTransaction={currentTransaction}
      paymentConfirmed={paymentConfirmed}
      paymentStatus={paymentStatus}
      clearState={() => {
        dispatch(initState());
      }}
      handleCancelTransaction={() => {
        if (!currentTransaction) return;
        dispatch(
          cancelTransaction({
            id: currentTransaction.id,
          }),
        );
      }}
    />
  );
};

export default TransactionsContainer;
