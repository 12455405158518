import {
  CircularProgress,
  Divider,
  Grid,
  Switch,
  TextField as TextFieldMaterial,
  Typography,
} from "@material-ui/core";
/** @jsx jsx */
import { Fragment, useCallback, useMemo, useState } from "react";
import { css, jsx } from "@emotion/core";
import { getBanks, getCnaes } from "public/ducks/domainData.duck";

import { Alert } from "@material-ui/lab";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Field } from "formik";
import NumberFormat from "react-number-format";
import React from "react";
import { TextField } from "formik-material-ui";
import { componentPropTypes } from "./CreateAccountMerchant.type";
import { useDispatch } from "react-redux";

const CreateAccountMerchant: React.FC<componentPropTypes> = ({
  isPJ,
  handleGetAddressByZip,
  handleGetCNPJInfos,
  cnaes,
  banks,
  isUpdating,
  handleVerifyDocument,
  loading,
  loadingCep,
  loadingCnpj,
}) => {
  const dispatch = useDispatch();
  const handleGetCnaes = useCallback(() => dispatch(getCnaes()), [dispatch]);
  const handleGetBanks = useCallback(() => dispatch(getBanks()), [dispatch]);
  const [documentIsTaken, setDocumentIsTaken] = useState(false);

  useMemo(() => {
    handleGetCnaes();
  }, [handleGetCnaes]);
  useMemo(() => {
    handleGetBanks();
  }, [handleGetBanks]);

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography
                variant="h1"
                align="left"
                css={css`
                  && {
                    font-size: 20px;
                    font-weight: 800;
                    color: #1d1d6d;
                  }
                `}
              >
                Dados do estabelecimento
              </Typography>
            </Grid>
            {!isUpdating && (
              <Grid
                item
                xs={12}
                css={css`
                  && {
                    margin-top: 18px;
                    margin-bottom: 18px;
                  }
                `}
              >
                <Grid container alignItems="center">
                  <Grid item xs="auto">
                    Pessoa Física?
                  </Grid>
                  <Grid item xs="auto">
                    <Field name="merchant.type">
                      {({
                        field,
                        form: { setFieldValue, isSubmitting },
                      }: any) => (
                        <Switch
                          disabled={isSubmitting || isUpdating}
                          checked={field.value === "PF"}
                          color="primary"
                          onChange={({ target: { checked } }: any) => {
                            setFieldValue(
                              "merchant.type",
                              checked ? "PF" : "PJ",
                            );
                            setFieldValue("merchant.company_name", "");
                            setFieldValue("merchant.trading_name", "");
                            setFieldValue("merchant.cnae", "");
                            setFieldValue("merchant.mcc", "");
                          }}
                        />
                      )}
                    </Field>
                  </Grid>
                </Grid>
              </Grid>
            )}

            <Grid item xs={12}>
              <Field name="merchant.document_number">
                {({
                  field: { value, name },
                  form: { setFieldValue, setFieldTouched, isSubmitting },
                  meta: { error, touched },
                }: any) => (
                  <NumberFormat
                    customInput={TextFieldMaterial}
                    format={isPJ ? "##.###.###/####-##" : "###.###.###-##"}
                    fullWidth
                    required
                    autoComplete="nope"
                    disabled={isSubmitting || isUpdating}
                    label={isPJ ? "CNPJ" : "CPF"}
                    variant="outlined"
                    value={value}
                    error={touched && !!error}
                    helperText={touched && error}
                    onBlur={async ({
                      target: { value },
                    }: React.ChangeEvent<HTMLInputElement>) => {
                      setFieldTouched(name, true, false);
                      if (value && value.length > 0 && !error) {
                        const documentUsed = await handleVerifyDocument(value);
                        setDocumentIsTaken(documentUsed);

                        if (isPJ && !documentUsed) {
                          if (value && value.length > 0)
                            handleGetCNPJInfos(value);
                        } else {
                          setFieldValue("merchant.company_name", "");
                          setFieldValue("merchant.trading_name", "");
                        }
                      } else setDocumentIsTaken(false);
                    }}
                    onValueChange={(values: any) =>
                      setFieldValue(name, values.value)
                    }
                  />
                )}
              </Field>
              {(loading || loadingCnpj) && (
                <CircularProgress
                  size={24}
                  color="secondary"
                  css={css`
                    && {
                      color: #1d1d6d;
                      position: absolute;
                      margin-left: -12px;
                      margin-top: 15px;
                      right: 72%;
                    }
                  `}
                />
              )}
              {documentIsTaken && (
                <Alert
                  severity="error"
                  css={css`
                    && {
                      margin-top: 5px;
                    }
                  `}
                >
                  {isPJ ? "CNPJ" : "CPF"} já esta em uso
                </Alert>
              )}
            </Grid>

            {isPJ && (
              <Grid item xs={12}>
                <Field name="merchant.company_name">
                  {() => {
                    return (
                      <TextField
                        name="merchant.company_name"
                        label="Razão social"
                        autoComplete="nope"
                        required
                        variant="outlined"
                        fullWidth
                      />
                    );
                  }}
                </Field>
              </Grid>
            )}

            {isPJ && (
              <Grid item xs={12}>
                <TextField
                  name="merchant.trading_name"
                  label="Nome Fantasia"
                  required
                  autoComplete="nope"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            )}

            {isPJ && (
              <Grid item xs={12}>
                <Field name="merchant.cnae">
                  {({
                    field: { value, name },
                    form: { setFieldValue, setFieldTouched },
                    meta: { error, touched },
                  }: any) => {
                    const selectedCnae = cnaes?.find(
                      (f: any) => f.cnae === value,
                    );
                    return (
                      <Autocomplete
                        options={cnaes}
                        key={`${selectedCnae?.cnae}-key`}
                        disableClearable
                        defaultValue={selectedCnae}
                        disabled={isUpdating}
                        onChange={(a: any, value: any) => {
                          setFieldValue("merchant.cnae", value?.cnae);
                          setFieldValue("merchant.mcc", value?.mcc);
                          setFieldTouched(name, false);
                        }}
                        onBlur={() => setFieldTouched(name, true)}
                        getOptionLabel={(cnae: any) =>
                          `${cnae.cnae} - ${cnae.description}`
                        }
                        renderInput={params => (
                          <TextFieldMaterial
                            {...params}
                            label="CNAE / Atividade principal"
                            required
                            error={touched && !!error}
                            helperText={touched && error}
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />
                    );
                  }}
                </Field>
              </Grid>
            )}

            <Grid item xs={12}>
              <TextField
                name="merchant.contact_name"
                label={isPJ ? "Nome responsável" : "Nome Completo"}
                required
                autoComplete="nope"
                variant="outlined"
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={2}>
                  <Field name="merchant.phone_prefix">
                    {({
                      field: { value, name },
                      form: { setFieldValue, setFieldTouched, isSubmitting },
                      meta: { error, touched },
                    }: any) => (
                      <NumberFormat
                        customInput={TextFieldMaterial}
                        format="(##)"
                        fullWidth
                        required
                        autoComplete="nope"
                        disabled={isSubmitting}
                        label="DDD comercial"
                        variant="outlined"
                        value={value}
                        error={touched && !!error}
                        helperText={touched && error}
                        onBlur={({
                          target: { value },
                        }: React.ChangeEvent<HTMLInputElement>) => {
                          setFieldTouched(name, true, false);
                        }}
                        onValueChange={(values: any) =>
                          setFieldValue(name, values.value)
                        }
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs>
                  <Field name="merchant.phone_number">
                    {({
                      field: { value, name },
                      form: { setFieldValue, setFieldTouched, isSubmitting },
                      meta: { error, touched },
                    }: any) => (
                      <NumberFormat
                        customInput={TextFieldMaterial}
                        format={
                          value.length === 8 ? "####-#####" : "#####-####"
                        }
                        fullWidth
                        autoComplete="nope"
                        required
                        disabled={isSubmitting}
                        label="Telefone comercial"
                        variant="outlined"
                        value={value}
                        error={touched && !!error}
                        helperText={touched && error}
                        onBlur={({
                          target: { value },
                        }: React.ChangeEvent<HTMLInputElement>) => {
                          setFieldTouched(name, true, false);
                        }}
                        onValueChange={(values: any) =>
                          setFieldValue(name, values.value)
                        }
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider
            css={css`
              && {
                margin-top: 24px;
                margin-bottom: 24px;
              }
            `}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              css={css`
                && {
                  margin-bottom: 14px;
                }
              `}
            >
              <Typography
                variant="h1"
                align="left"
                css={css`
                  && {
                    font-size: 20px;
                    font-weight: 800;
                    color: #1d1d6d;
                  }
                `}
              >
                Endereço
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Field name="merchant.address.zip_code">
                {({
                  field: { value, name },
                  form: { setFieldValue, setFieldTouched, isSubmitting },
                  meta: { error, touched },
                }: any) => {
                  return (
                    <NumberFormat
                      customInput={TextFieldMaterial}
                      format="#####-###"
                      fullWidth
                      required
                      autoComplete="nope"
                      disabled={isSubmitting}
                      label="CEP"
                      variant="outlined"
                      value={value}
                      error={touched && !!error}
                      helperText={touched && error}
                      onBlur={({
                        target: { value },
                      }: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldTouched(name, true, false);
                        if (value && value.length > 0)
                          handleGetAddressByZip(value);
                      }}
                      onValueChange={(values: any) =>
                        setFieldValue(name, values.value)
                      }
                    />
                  );
                }}
              </Field>
              {loadingCep && (
                <CircularProgress
                  size={24}
                  color="secondary"
                  css={css`
                    && {
                      color: #1d1d6d;
                      position: absolute;
                      margin-left: -12px;
                      margin-top: 15px;
                      right: 72%;
                    }
                  `}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="merchant.address.street"
                label="Endereço"
                variant="outlined"
                autoComplete="nope"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} md>
                  <Field name="merchant.address.number">
                    {({
                      field: { value, name },
                      form: { setFieldValue, setFieldTouched, isSubmitting },
                      meta: { error, touched },
                    }: any) => {
                      return (
                        <NumberFormat
                          customInput={TextFieldMaterial}
                          fullWidth
                          required
                          label="Número"
                          autoComplete="nope"
                          variant="outlined"
                          value={value}
                          error={touched && !!error}
                          helperText={touched && error}
                          onBlur={({
                            target: { value },
                          }: React.ChangeEvent<HTMLInputElement>) => {
                            setFieldTouched(name, true, false);
                          }}
                          onValueChange={(values: any) =>
                            setFieldValue(name, values.value)
                          }
                        />
                      );
                    }}
                  </Field>
                </Grid>
                <Grid item xs={12} md>
                  <TextField
                    name="merchant.address.complement"
                    label="Complemento"
                    autoComplete="nope"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="merchant.address.neighborhood"
                label="Bairro"
                variant="outlined"
                autoComplete="nope"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} md>
                  <TextField
                    name="merchant.address.city"
                    label="Cidade"
                    variant="outlined"
                    autoComplete="nope"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} md>
                  <TextField
                    name="merchant.address.state"
                    label="Estado"
                    variant="outlined"
                    autoComplete="nope"
                    fullWidth
                    required
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider
            css={css`
              && {
                margin-top: 24px;
                margin-bottom: 24px;
              }
            `}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            css={css`
              && {
                margin-bottom: 14px;
              }
            `}
          >
            <Typography
              variant="h1"
              align="left"
              css={css`
                && {
                  font-size: 20px;
                  font-weight: 800;
                  color: #1d1d6d;
                }
              `}
            >
              Dados bancários
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Field name="merchant.bank_account.bank_number">
              {({
                field: { value, name },
                form: { setFieldValue, setFieldTouched },
                meta: { error, touched },
              }: any) => {
                const selectedBank = banks?.find(
                  (f: any) => f.bank_code === value,
                );
                return (
                  <Autocomplete
                    key={`${selectedBank?.bank_code}-key`}
                    options={banks}
                    disableClearable
                    defaultValue={selectedBank}
                    getOptionSelected={(option, value) =>
                      option.bank_code === value.bank_code
                    }
                    onChange={(a: any, value: any) => {
                      if (value)
                        setFieldValue(
                          "merchant.bank_account.bank_number",
                          value?.bank_code,
                        );
                    }}
                    onBlur={() => {
                      setFieldTouched(name, true);
                    }}
                    getOptionLabel={(bank: any) =>
                      `${bank.bank_code} - ${bank.bank_name}`
                    }
                    renderInput={params => (
                      <TextFieldMaterial
                        {...params}
                        label="Banco"
                        required
                        error={touched && !!error}
                        helperText={touched && error}
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                );
              }}
            </Field>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} md>
                <TextField
                  name="merchant.bank_account.branch_number"
                  label="Agência"
                  required
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md>
                <TextField
                  name="merchant.bank_account.account_number"
                  label="Número conta"
                  required
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField
                  name="merchant.bank_account.account_digit"
                  label="Dígito conta"
                  required
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default CreateAccountMerchant;
