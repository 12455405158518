import { Grid, Slide, Snackbar } from "@material-ui/core";
import { css, jsx } from "@emotion/core";

import { Alert } from "@material-ui/lab";
import React from "react";
import { TransitionProps } from "@material-ui/core/transitions";

/** @jsx jsx */

function TransitionDown(props: TransitionProps) {
  return <Slide {...props} direction="down" />;
}
const DefaultDisabledApp: React.FC<any> = ({ app, enable, handleClose }) => {
  if (!enable) {
    return <div></div>;
  }

  return (
    <Grid item xs={12} sm={12}>
      <Grid
        container
        justify="flex-end"
        css={css`
          && {
            margin-top: 24px;
          }
        `}
      ></Grid>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        TransitionComponent={TransitionDown}
        open={true}
        autoHideDuration={6000}
        onClose={() => handleClose(false)}
      >
        <Alert onClose={() => handleClose(false)} severity="error">
          APP não habilitado para este estabelecimento:
          <br />
          Entre em contato para se credenciar nesse aplicativo:
          contato@qrway.com.br
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default DefaultDisabledApp;
