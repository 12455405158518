import * as Yup from "yup";

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  CircularProgress,
  Dialog,
  Grid,
  Typography,
} from "@material-ui/core";
import {
  EMerchantApplicationStatus,
  handleUpdateMerchantApplication,
} from "app/ducks/applications.duck";
import React, { useCallback, useEffect, useState } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { css, jsx } from "@emotion/core";
import { getMerchantById, handleUpdateUser } from "app/ducks/merchant.duck";

import OnboardingOriginal from "./OnboardingOriginal";

import { getIntegrationTypeFlags } from "../../../components/getAppTypeFlags";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import validateCNPJ from "shared/utils/validateCNPJ.util";
import validateCPF from "shared/utils/validateCPF.util";

/** @jsx jsx */

const useGenericSelector: TypedUseSelectorHook<any> = useSelector;

const Onboarding: React.FC<any> = ({ app, enable, setStartOnboarding }) => {
  const { isBancoOriginal } = getIntegrationTypeFlags(
    app?.issuer_entity?.identifier_code,
  );

  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [isFormSubmiting, setSubmitForm] = useState(false);

  const { authentication } = useGenericSelector(state => state.shared) || {};
  const { data } = authentication;
  const { user } = data;

  const { merchant: merchantState } =
    useGenericSelector(state => state.app) || {};

  const { merchant, loading, error } = merchantState || {};

  const getMerchant = useCallback(async () => {
    dispatch(getMerchantById(user.merchants[0].id));
  }, [dispatch, getMerchantById]);

  useEffect(() => {
    if (!enable) return;
    getMerchant();
  }, [getMerchant]);

  const initialSchema = useSelector(
    (state: any) => state.public.createAccount.form,
  );
  const initialData = !merchant ? initialSchema : merchant;

  const document_numberSchema = Yup.string().when(
    "type",
    (type: string, schema: any) => {
      const document = type === "PJ" ? "CNPJ" : "CPF";

      return schema.test(
        "document_number",
        `Você deve digitar um ${document} válido`,
        (value: string) => {
          const documentLength = !!value && String(value).length;

          if (!documentLength) {
            return false;
          }

          if (document === "CNPJ") {
            if (documentLength < 14 || !validateCNPJ(value)) {
              return false;
            }
          }

          if (document === "CPF") {
            if (documentLength < 11 || !validateCPF(value)) {
              return false;
            }
          }

          return true;
        },
      );
    },
  );

  const merchantSchema = Yup.object().shape({
    id: Yup.string().notRequired(),
    contact_name: Yup.string().required("Campo obrigatório"),
    document_number: document_numberSchema,
    phone_prefix: Yup.number()
      .nullable()
      .required("Campo obrigatório"),
    phone_number: Yup.string().required("Campo obrigatório"),
    cnae: Yup.string().when("type", (type: string, schema: any) =>
      type === "PJ"
        ? schema.required("Campo obrigatório")
        : schema.notRequired(),
    ),
    mcc: Yup.string().notRequired(),
    constitution_date: Yup.string().notRequired(),
    trading_name: Yup.string().when("type", (type: string, schema: any) =>
      type === "PJ"
        ? schema.required("Campo obrigatório")
        : schema.notRequired(),
    ),
    company_name: Yup.string().when("type", (type: string, schema: any) =>
      type === "PJ"
        ? schema.required("Campo obrigatório")
        : schema.notRequired(),
    ),
  });

  const handleUpdateMerchantApplicationWrapper = useCallback(
    async (app, merchant, payload: any) => {
      const response: any = await dispatch(
        handleUpdateMerchantApplication({
          merchant_id: merchant.id,
          application_id: app.id,
          payload,
        }),
      );
      if (response.error) {
        return enqueueSnackbar(response.message, {
          autoHideDuration: 3000,
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
      }
      history.push("/app");
      return enqueueSnackbar(
        "Solicitação realizada, aguarde o processo de ativação.",
        {
          autoHideDuration: 3000,
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        },
      );
    },
    [dispatch, handleUpdateMerchantApplication],
  );

  useEffect(() => {
    if (!enable) return;
  }, [handleUpdateMerchantApplicationWrapper, isBancoOriginal, app, merchant]);

  const handleRequestActvation = () => {
    if (app?.id && merchant?.id && !isBancoOriginal) {
      handleUpdateMerchantApplicationWrapper(app, merchant, {
        status: EMerchantApplicationStatus.AtivacaoSolicitada,
      });
      setStartOnboarding(false);
    }
  };

  if (!enable) {
    return <div></div>;
  }

  return (
    <Dialog
      onClose={() => setStartOnboarding(false)}
      aria-labelledby="simple-dialog-title"
      open={true}
      fullWidth
      maxWidth={"md"}
      disableBackdropClick
      // disableEscapeKeyDown
    >
      <Card elevation={0} style={{ overflow: "auto" }}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item xs={12} sm={12}>
            <CardHeader
              avatar={
                <CardMedia
                  css={css`
                    && {
                      height: 0;
                      width: 150px;
                      padding-top: 80%;
                    }
                  `}
                  image={app.logo_path || app.logo_path}
                  title={app.name}
                />
              }
              title={
                <Typography
                  variant="h1"
                  align="left"
                  css={css`
                    && {
                      font-size: 16px;
                      font-weight: 400;
                      color: #1d1d6d;
                    }
                  `}
                >
                  Solicitação de Cadastro com {app.name}
                </Typography>
              }
              // title={`Solicitação de Cadastro com ${app.name}`}
            />
          </Grid>
        </Grid>
        <CardContent>
          {isBancoOriginal && (
            <OnboardingOriginal
              app={app}
              handleFinalization={() => {
                setStartOnboarding(false);
              }}
            />
          )}

          {!isBancoOriginal && (
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              css={css`
                && {
                  margin-bottom: 50px;
                }
              `}
            >
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <p>
                  Clique em "Confirmar" para iniciar o processo de cadastramento
                  para uso do aplicativo {app.name}.
                </p>
              </Grid>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                css={css`
                  && {
                    margin-top: 24px;
                  }
                `}
              >
                <Grid item xs={9} sm={5}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    css={css`
                      && {
                        margin-top: 20px;
                        border-radius: 15px;
                        width: 100%;
                      }
                    `}
                    onClick={handleRequestActvation}
                  >
                    <div>Confirmar</div>
                    {isFormSubmiting && (
                      <CircularProgress
                        size={24}
                        color="secondary"
                        css={css`
                          && {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            margin-top: -12px;
                            margin-left: -12px;
                          }
                        `}
                      />
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>
    </Dialog>
  );
};

export default Onboarding;
