import { Grid, TextField } from "@material-ui/core";
/** @jsx jsx */
import React from "react";
import { css, jsx } from "@emotion/core";

import { Autocomplete } from "@material-ui/lab";

const MerchantSelector: React.FC<any> = ({
  loading,
  merchantList,
  merchantIds,
  onChange,
  inputRef,
  ...props
}) => {
  return (
    <Autocomplete
      disableClearable={false}
      autoComplete
      autoSelect
      style={{ width: "100%" }}
      multiple={true}
      loading={loading}
      disabled={loading}
      value={merchantIds}
      options={merchantList}
      getOptionLabel={(option: any) => option.name}
      renderOption={(option: any) => (
        <React.Fragment>{option.name}</React.Fragment>
      )}
      onChange={onChange}
      renderInput={params => (
        <TextField
          {...params}
          inputRef={inputRef}
          style={{ width: "100%" }}
          variant="outlined"
          label="Estabelecimento"
          placeholder="Estabelecimento"
        />
      )}
      {...props}
    />
  );
};

export default MerchantSelector;
