import { Grid, Slide, Snackbar } from "@material-ui/core";
/** @jsx jsx */
import React, { Fragment, useEffect, useState } from "react";
import { css, jsx } from "@emotion/core";
import { TransitionProps } from "@material-ui/core/transitions";
import { Alert } from "@material-ui/lab";

function TransitionDown(props: TransitionProps) {
  return <Slide {...props} direction="down" />;
}

const TransactionError: React.FC<any> = ({ enable, setHasError, error }) => {
  if (!enable) return <div></div>;

  return (
    <Grid item xs={12} sm={12}>
      <Grid
        container
        justify="flex-end"
        css={css`
          && {
            margin-top: 24px;
          }
        `}
      ></Grid>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        TransitionComponent={TransitionDown}
        open={true}
        autoHideDuration={6000}
        onClose={() => setHasError(false)}
      >
        <Alert onClose={() => setHasError(false)} severity="error">
          {error.message}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default TransactionError;
