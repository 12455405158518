import {
  CustomMuiTheme,
  CustomTablePaggination,
  MuiDefaultOptions,
  useTableCustomStyles,
} from "app/components/Grid/MuiTable";
import {
  Grid,
  TableCell,
  TableFooter,
  TableRow,
  ThemeProvider,
} from "@material-ui/core";
import MUIDataTable, { MUIDataTableColumnDef } from "mui-datatables";
import React, { Fragment, useEffect, useState } from "react";
import { css, jsx } from "@emotion/core";

import { Skeleton } from "@material-ui/lab";
import { formatterToBrlCurrency } from "shared/utils/formatter.util";
import moment from "moment";

/** @jsx jsx */

const Screen: React.FC<any> = ({ transactions, loading, filters }) => {
  const classes = useTableCustomStyles();

  const [transactionsFiltered, setTransactionsFiltered] = useState([]);
  const [filterList, setFilterList] = useState<any>([]);

  useEffect(() => {
    setTransactionsFiltered(transactions);
  }, [transactions]);

  const getColumns = (): MUIDataTableColumnDef[] => {
    return [
      {
        name: "Dt_movimentacao",
        label: "Dt. Transação",
        options: {
          filter: true,
          sort: true,
          sortDirection: "asc",
          filterList: filterList[0] ? filterList[0] : undefined,
          customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
            return moment(value).format("DD/MM/YYYY HH:mm");
          },
        },
      },
      {
        name: "Descricao_Movimento",
        label: "Descrição",
        options: {
          filter: true,
          sort: true,
          filterList: filterList[1] ? filterList[1] : undefined,
        },
      },
      {
        name: "Tipo_Movimento",
        label: "Tipo Movimento",
        options: {
          filter: true,
          sort: true,
          filterList: filterList[2] ? filterList[2] : undefined,
        },
      },
      {
        name: "VLR_movimentado",
        label: "Valor",
        options: {
          filter: true,
          sort: true,
          filterList: filterList[3] ? filterList[3] : undefined,
          customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
            return formatterToBrlCurrency(value);
          },
        },
      },
    ];
  };

  return (
    <Grid
      container
      css={css`
        margin-bottom: 20px;
      `}
    >
      {loading ? (
        <Grid item>
          <Skeleton variant="rect" height={30} />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
        </Grid>
      ) : (
        <Grid item xs={12} sm={12} lg={12}>
          <ThemeProvider theme={CustomMuiTheme}>
            <MUIDataTable
              title={
                <Fragment>
                  <Grid>
                    <small>
                      Movimentos de{" "}
                      {moment(transactions[0].Dt_movimentacao).isValid()
                        ? moment(transactions[0].Dt_movimentacao).format(
                            "DD MMM YYYY",
                          )
                        : "-"}{" "}
                    </small>
                  </Grid>
                </Fragment>
              }
              data={transactions}
              columns={getColumns()}
              options={{
                ...MuiDefaultOptions,
                elevation: 0,
                onFilterChange: (
                  changedColumn: any,
                  filterList: any,
                  type: any,
                ) => {
                  setFilterList(filterList);

                  const hasField = (
                    index: number,
                    elementValue: any,
                  ): boolean => {
                    const filter = filterList[index++];
                    if (!filter || filter.length === 0) return true;
                    return filter.includes(elementValue);
                  };
                  const filtered: any = [];
                  transactions.forEach((element: any) => {
                    let elementValid = false;
                    elementValid =
                      hasField(
                        0,
                        moment(element["Dt_movimentacao"]).format(
                          "DD/MM/YYYY HH:mm",
                        ),
                      ) &&
                      hasField(1, element["Descricao_Movimento"]) &&
                      hasField(2, element["Tipo_Movimento"]) &&
                      hasField(
                        3,
                        formatterToBrlCurrency(element["VLR_movimentado"]),
                      );
                    if (elementValid) filtered.push(element);
                  });

                  setTransactionsFiltered(filtered);
                },
                downloadOptions: {
                  filename: "extrato-emissor-detalhado.csv",
                  separator: ";",
                  filterOptions: {
                    useDisplayedColumnsOnly: true,
                    useDisplayedRowsOnly: true,
                  },
                },
                customFooter: (
                  rowCount: number,
                  page: number,
                  rowsPerPage: number,
                  changeRowsPerPage: (page: string | number) => void,
                  changePage: (newPage: number) => void,
                ) => {
                  return (
                    <TableFooter>
                      <TableRow>
                        <TableCell
                          colSpan={3}
                          align="center"
                          className={classes.tableTotalTitle}
                        >
                          Totais
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          align="center"
                          className={classes.tableHeader}
                        >
                          Quantidade
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tableHeader}
                        >
                          Crédito
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tableHeader}
                        >
                          Débito
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell
                          align="center"
                          className={classes.tableRowTotal}
                        >
                          <b> {transactionsFiltered.length}</b>
                        </TableCell>

                        <TableCell
                          align="center"
                          className={classes.tableRowTotal}
                        >
                          <b>
                            {formatterToBrlCurrency(
                              transactionsFiltered
                                ?.filter(
                                  (f: any) => f.Tipo_Movimento === "Credito",
                                )
                                .map((m: any) => Number(m.VLR_movimentado))
                                .reduce((a: any, b: any) => a + b, 0),
                            )}
                          </b>
                        </TableCell>

                        <TableCell
                          align="center"
                          className={classes.tableRowTotal}
                        >
                          <b>
                            {formatterToBrlCurrency(
                              transactionsFiltered
                                ?.filter(
                                  (f: any) => f.Tipo_Movimento !== "Credito",
                                )
                                .map((m: any) => Number(m.VLR_movimentado))
                                .reduce((a: any, b: any) => a + b, 0),
                            )}
                          </b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <CustomTablePaggination
                          rowCount={rowCount}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          changePage={changePage}
                          changeRowsPerPage={changeRowsPerPage}
                        />
                      </TableRow>
                    </TableFooter>
                  );
                },
              }}
            />
          </ThemeProvider>
        </Grid>
      )}
    </Grid>
  );
};

export default Screen;
