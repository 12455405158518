import { API_URL_WITH_PORT, PORTS } from "shared/configs/api.config";

import { Dispatch } from "redux";
import fetch from "shared/utils/fetch.util";

type Actions = {
  type: string;
  payload?: object;
  error?: object;
};

export const Types = {
  INIT: "app/conciliations/INIT",
  SUCCESS: "app/conciliations/SUCCESS",
  ERROR: "app/conciliations/ERROR",
};

type stateConciliations = {
  stateConciliations: any;
  loading: boolean;
  error: any;
};

const initialState: stateConciliations = {
  stateConciliations: {},
  loading: false,
  error: null,
};

export default (state = initialState, action: Actions) => {
  const { type, payload, error } = action;

  const reducers = {
    [Types.INIT]: {
      ...initialState,
      ...state,
      error: null,
      loading: true,
    },
    [Types.SUCCESS]: {
      ...state,
      loading: false,
      stateConciliations: {
        ...(state?.stateConciliations || {}),
        ...(payload || {}),
      },
      error: false,
    },
    [Types.ERROR]: {
      ...initialState,
      error,
    },
  };

  return reducers[type] || state;
};

export const runConciliationActionReport = ({
  merchant_id,
  merchant_ids,
  application_id,
  dateBegin,
  dateEnd,
  details,
}: any) => async (dispatch: Dispatch<any>) => {
  try {
    dispatch({
      type: Types.INIT,
    });

    let begin = new Date(dateBegin).toISOString();
    let end = new Date(dateEnd).toISOString();
    let queryParams = `application_id=${application_id}`;
    queryParams += `&dateBegin=${begin}`;
    queryParams += `&dateEnd=${end}`;
    if (merchant_ids?.length > 0) {
      queryParams += merchant_ids
        .map((it: any) => `&merchant_ids=${it}`)
        .join("");
    }
    if (merchant_id) {
      queryParams += `&merchant_ids=${merchant_id}`;
    }

    if (details) {
      queryParams += `&details=${JSON.stringify(details)}`;
    }
    const response = await fetch(
      `${API_URL_WITH_PORT(
        PORTS.TRANSACTION,
      )}/conciliations?${queryParams}`,
      {
        customUrl: true,
        method: "GET",
        auth: true,
      },
    );
    const result = await response.json();
    if (!response.ok) {
      throw result;
    }
    const { results } = result;
    const { type } = details;

    dispatch({
      type: Types.SUCCESS,
      payload: { [type]: results },
    });

    return results;
  } catch (e) {
    dispatch({
      type: Types.ERROR,
      error: e,
    });
    return false;
  }
};
