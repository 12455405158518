/** @jsx jsx */

import { css, jsx } from "@emotion/core";

import FormCreateAccount from "../../components/FormCreateAccount";
import { Fragment } from "react";
import { Grid } from "@material-ui/core";
import Logo from "shared/components/Logo";
import { useLocation } from "react-router-dom";

const CreateAccountScreen = () => {
  const location = useLocation();
  const isOnboarding = location.pathname === "/onboarding";

  // const params = new URLSearchParams(location.search);
  // const apps = params.getAll("app");

  return (
    <Fragment>
      {!isOnboarding && (
        <Grid container justify="center" style={{ cursor: "pointer" }}>
          <Grid item xs="auto">
            <Logo
              css={css`
                && {
                  max-width: 200px;
                }
              `}
            />
          </Grid>
        </Grid>
      )}

      <Grid
        container
        css={css`
          && {
            margin-top: 24px;
          }
        `}
      >
        <Grid item xs={12}>
          <FormCreateAccount />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default CreateAccountScreen;
