import * as Yup from "yup";

import { Alert, Skeleton } from "@material-ui/lab";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import {
  CustomMuiTheme,
  CustomTablePaggination,
  MuiDefaultOptions,
  useTableCustomStyles,
} from "app/components/Grid/MuiTable";
import { Form, Formik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { css, jsx } from "@emotion/core";
import {
  decimalFormatter4,
  formatterToBrlCurrency,
  formatterToCurrency,
} from "shared/utils/formatter.util";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FindReplaceIcon from "@material-ui/icons/FindReplace";
import InfoIcon from "@material-ui/icons/Info";
import MUIDataTable from "mui-datatables";
import PaymentReceipt from "app/components/Transaction/PaymentReceipt";
import Print from "@material-ui/icons/Print";
import Replay from "@material-ui/icons/Replay";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
import SnackNotification from "app/components/SnackNotification";
import { TextField } from "formik-material-ui";
import { getIntegrationTypeFlags } from "app/components/getAppTypeFlags";
import moment from "moment";

/** @jsx jsx */

moment.locale("pt-br");

const requestReversalSchema = Yup.object().shape({
  reason: Yup.string().required("Campo obrigatório"),
});
const appHasReversalAuto = (app: any): boolean =>
  app?.reversal_url === "api-auto";

const Screen: React.FC<any> = ({
  getTransactions,
  transactionsDetails,
  loading,
  expand,
  rowExpanded,
  checkPayment,
  resendNotification,
  transactionLoading,
  handleRequestReversal,
  merchantParams,
}) => {
  const classes = useTableCustomStyles();

  const [modalEdit, setModalEdit] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [modalInfo, setModalInfo] = useState(false);
  const [transactionInfo, setTransactionInfo] = useState<any>();
  const [enablePrint, setEnablePrint] = useState(false);
  const [displayResendSnack, setDisplayResendSnack] = useState(false);

  const [
    transactionsDetailsFiltered,
    setTransactionsDetailsFiltered,
  ] = useState([]);
  const [filterList, setFilterList] = useState<any>([]);

  useEffect(() => {
    if (transactionsDetails && transactionsDetails?.length > 0) {
      const data: any = [...transactionsDetails];
      setTransactionsDetailsFiltered(data);
    }
  }, [transactionsDetails]);

  const requestReversal = (data: any) => {
    setModalEdit(true);
    setCurrentId(data.id);
  };

  const displayInfo = (row: any) => {
    setTransactionInfo(row);
    setModalInfo(true);
  };

  const appHasReversalApi = appHasReversalAuto(rowExpanded?.application);
  const { merchant_group } = merchantParams || {};
  const enableAttendantsMenu = merchant_group?.enable_attendant_reference;
  const defaultExpirationHours = 2;
  const isExpired = (transactionInfo: any) =>
    moment(transactionInfo?.transaction_date)
      .add(defaultExpirationHours, "hours")
      .isBefore(moment());

  const isUsePayApp = (app: any) => {
    const { isUsePayApp } = getIntegrationTypeFlags(
      app?.issuer_entity?.identifier_code,
    );
    return isUsePayApp;
  };

  const isPagamentoLink = (app: any) => {
    const { isPagamentoLink } = getIntegrationTypeFlags(
      app?.issuer_entity?.identifier_code,
    );
    return isPagamentoLink;
  };

  return (
    <Grid item xs={12} sm={12} lg={12}>
      {loading ? (
        <Grid item>
          <Skeleton variant="rect" height={30} />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
        </Grid>
      ) : (
        <Grid item>
          <Dialog
            fullWidth
            open={modalEdit}
            onClose={() => setModalEdit(false)}
            aria-labelledby="form-dialog-title"
          >
            <Formik
              initialValues={{
                reason: "",
              }}
              onSubmit={data => {
                handleRequestReversal({
                  reversal: {
                    id: currentId,
                    ...data,
                  },
                  request: {
                    dateBegin: new Date(rowExpanded.date_min).toISOString(),
                    dateEnd: new Date(rowExpanded.date_max).toISOString(),
                    grouped: false,
                    user_id: rowExpanded.user.id,
                    application_id: rowExpanded.application.id,
                    offset: 0,
                    limit: 1000,
                    onlyConfirmed: rowExpanded.onlyConfirmed,
                    merchant_ids: [rowExpanded.merchant.id],
                  },
                });
                setModalEdit(false);
              }}
              validationSchema={requestReversalSchema}
            >
              {({ submitForm, isSubmitting, errors, dirty, setValues }) => (
                <Form>
                  <DialogTitle id="form-dialog-title">
                    Registrar solicitação de estorno
                  </DialogTitle>
                  <DialogContent>
                    <TextField
                      autoFocus
                      name="reason"
                      required
                      label="Motivo do estorno"
                      variant="outlined"
                      fullWidth
                    />
                    <Grid
                      container
                      justify="flex-end"
                      css={css`
                        && {
                          margin-top: 24px;
                        }
                      `}
                    ></Grid>
                    <Alert severity={appHasReversalApi ? "info" : "warning"}>
                      {" "}
                      {appHasReversalApi ? (
                        <i>
                          A transação será estornada automaticamente no
                          aplicativo {rowExpanded.application.name}.
                        </i>
                      ) : (
                        <Fragment>
                          <i>
                            A transação também deverá ser estornada no portal ou
                            aplicativo do emissor do{" "}
                            {rowExpanded.application.name}.
                          </i>
                          <br />
                          <strong>
                            Aplicativo: {rowExpanded.application.name}
                            {rowExpanded.application.reversal_url ? (
                              !appHasReversalApi && (
                                <a
                                  target="_blank"
                                  href={rowExpanded.application.reversal_url}
                                >
                                  Abrir aplicação{" "}
                                </a>
                              )
                            ) : (
                              <i>
                                <br />
                                link para estorno não informado, entre em
                                contato com o suporte do aplicativo
                              </i>
                            )}
                          </strong>
                        </Fragment>
                      )}
                      <br />
                    </Alert>
                    <DialogContentText></DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => setModalEdit(false)}
                      variant="outlined"
                      css={css`
                        && {
                          margin-top: 15px;
                          border-radius: 15px;
                        }
                      `}
                    >
                      CANCELAR
                    </Button>
                    <Button
                      onClick={submitForm}
                      disabled={isSubmitting || Object.keys(errors).length > 0}
                      color="primary"
                      variant="contained"
                      css={css`
                        && {
                          margin-top: 15px;
                          border-radius: 15px;
                        }
                      `}
                    >
                      CONFIRMAR
                    </Button>
                  </DialogActions>
                </Form>
              )}
            </Formik>
          </Dialog>
          <Dialog open={enablePrint} onClose={() => setEnablePrint(false)}>
            <DialogContent>
              <PaymentReceipt
                enable={enablePrint}
                handleClose={() => setEnablePrint(false)}
                transactionInfo={transactionInfo}
                application={transactionInfo?.application}
              />
            </DialogContent>
          </Dialog>
          <Dialog
            fullWidth
            open={modalInfo}
            onClose={() => setModalInfo(false)}
          >
            <DialogContent>
              {transactionInfo && (
                <Card elevation={0}>
                  <CardHeader
                    avatar={
                      <CardMedia
                        css={css`
                          && {
                            height: 0;
                            width: 150px;
                            padding-top: 80%;
                          }
                        `}
                        image={
                          transactionInfo.application_root?.logo_path ||
                          transactionInfo.application.logo_path
                        }
                        title={transactionInfo.application.name}
                      />
                    }
                    title={`Transação com ${
                      transactionInfo.application_root?.name
                        ? `${transactionInfo.application_root?.name} / ${transactionInfo.application.name}`
                        : transactionInfo.application.name
                    }`}
                  />

                  <CardContent>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      <Fragment>
                        Criado por: {transactionInfo.user.name}
                        {" - "}
                        <small>({transactionInfo.user.email})</small>
                      </Fragment>
                    </Typography>

                    {transactionInfo?.details_metadata?.attendant &&
                      transactionInfo?.details_metadata?.attendant?.code && (
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                        >
                          <Fragment>
                            Atendente:{" "}
                            {transactionInfo.details_metadata?.attendant?.code}
                            {" - "}
                            <small>
                              (
                              {
                                transactionInfo.details_metadata?.attendant
                                  ?.name
                              }
                              )
                            </small>
                          </Fragment>
                        </Typography>
                      )}

                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      <Fragment>
                        Data:{" "}
                        {moment(transactionInfo.transaction_date).format(
                          "DD/MM/YYYY HH:mm:ss",
                        )}{" "}
                        {" - "}
                        <small>
                          {moment(transactionInfo.transaction_date).fromNow()}
                        </small>
                      </Fragment>
                    </Typography>

                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      css={css`
                        && {
                          margin-top: 10px;
                        }
                      `}
                    >
                      <Fragment>
                        Valor:{" "}
                        {formatterToBrlCurrency(
                          transactionInfo.transaction_amount,
                        )}
                      </Fragment>
                    </Typography>

                    {transactionInfo?.transaction_amount > 0 &&
                      transactionInfo?.transaction_amount !==
                        transactionInfo?.original_amount && (
                        <Fragment>
                          <Typography
                            variant="caption"
                            display="block"
                            color="textSecondary"
                          >
                            <Fragment>
                              Valor Original:{" "}
                              {formatterToCurrency(
                                transactionInfo.original_amount * 100,
                              )}
                            </Fragment>
                          </Typography>
                          <Typography
                            variant="caption"
                            display="block"
                            color="textSecondary"
                          >
                            <Fragment>
                              Desconto:{" "}
                              {formatterToCurrency(
                                (transactionInfo.original_amount -
                                  transactionInfo.transaction_amount) *
                                  100,
                              )}
                            </Fragment>
                          </Typography>
                        </Fragment>
                      )}

                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      <Fragment>
                        Parcelas: {transactionInfo.installments}x
                      </Fragment>
                    </Typography>

                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      css={css`
                        && {
                          margin-top: 10px;
                        }
                      `}
                    >
                      <Fragment>
                        Data Autorização:{" "}
                        {transactionInfo.authorization_date &&
                          moment(transactionInfo.authorization_date).format(
                            "DD/MM/YYYY HH:mm:ss",
                          )}
                      </Fragment>
                    </Typography>

                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      <Fragment>
                        Número Autorização:{" "}
                        {transactionInfo.authorization_number}
                      </Fragment>
                    </Typography>

                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      <Fragment>NSU: {transactionInfo.nsu}</Fragment>
                    </Typography>

                    {(transactionInfo?.details_metadata?.items ||
                      transactionInfo?.details_metadata?.singleItem) && (
                      <ExpansionPanel>
                        <ExpansionPanelSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography className={classes.heading}>
                            Itens
                          </Typography>
                          <Typography className={classes.secondaryHeading}>
                            Lista de produtos ou serviços
                          </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                          <Table size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell>Descrição</TableCell>
                                <TableCell align="right">Quantidade</TableCell>
                                <TableCell align="right">
                                  {" "}
                                  {isUsePayApp(
                                    transactionInfo.application_root ||
                                      transactionInfo.application,
                                  )
                                    ? "Preço Unitário"
                                    : "Valor"}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {[
                                transactionInfo?.details_metadata?.singleItem,
                                ...(Array.isArray(
                                  transactionInfo?.details_metadata?.items,
                                )
                                  ? transactionInfo?.details_metadata?.items
                                  : [
                                      transactionInfo?.details_metadata?.items,
                                    ] || []),
                              ]
                                .filter(f => f !== null && f?.quantity > 0)
                                .map((item: any) => (
                                  <TableRow key={Math.random()}>
                                    <TableCell component="th" scope="row">
                                      {item.description}
                                    </TableCell>
                                    <TableCell align="right">
                                      {item.quantity}
                                    </TableCell>
                                    <TableCell align="right">
                                      {isUsePayApp(
                                        transactionInfo.application_root ||
                                          transactionInfo.application,
                                      )
                                        ? decimalFormatter4(item.value)
                                        : formatterToCurrency(
                                            (item.value |
                                              transactionInfo.transaction_amount) /
                                              100,
                                          )}
                                    </TableCell>
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    )}

                    {transactionInfo?.details_metadata?.reference && (
                      <div>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                        >
                          {transactionInfo?.details_metadata?.reference
                            ?.placa && (
                            <Fragment>
                              Placa:{" "}
                              {
                                transactionInfo?.details_metadata?.reference
                                  ?.placa
                              }
                            </Fragment>
                          )}
                          {transactionInfo?.details_metadata?.reference
                            ?.numero_pedido && (
                            <Fragment>
                              Número do Pedido:{" "}
                              {
                                transactionInfo?.details_metadata?.reference
                                  ?.numero_pedido
                              }
                            </Fragment>
                          )}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                        >
                          <Fragment>
                            Contato:{" "}
                            {
                              transactionInfo?.details_metadata?.reference
                                ?.contato
                            }
                          </Fragment>
                        </Typography>
                      </div>
                    )}

                    {transactionInfo.status === "REVERSAL" && (
                      <Alert
                        severity="info"
                        css={css`
                          && {
                            margin-top: 24px;
                          }
                        `}
                      >
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                        >
                          <Fragment>
                            O <strong>estorno</strong> desta transação foi
                            registrado na QRWay em{" "}
                            {moment(transactionInfo.reversal_date).format(
                              "DD/MM/YYYY HH:mm:ss",
                            )}
                            {" - "}
                            {moment(transactionInfo.reversal_date).fromNow()}
                            <br />
                            Motivo: <i>{transactionInfo.reversal_reason}</i>
                            <br />
                            <br />
                            {!appHasReversalAuto(
                              transactionInfo.application,
                            ) && (
                              <div>
                                <strong>
                                  A operação de estorno deve ser realizada no
                                  aplicativo emissor
                                </strong>
                                <br />
                                <br />
                                <a
                                  target="_blank"
                                  href={
                                    transactionInfo.application.reversal_url
                                  }
                                >
                                  Ir para aplicativo
                                </a>
                              </div>
                            )}
                          </Fragment>
                        </Typography>
                      </Alert>
                    )}
                    {transactionInfo.status === "PAID" && (
                      <Alert
                        severity="success"
                        css={css`
                          && {
                            margin-top: 24px;
                          }
                        `}
                      >
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                        >
                          <Fragment>
                            Esta operação foi <strong>Confirmada</strong>{" "}
                            {moment(
                              transactionInfo.authorization_date,
                            ).fromNow()}
                            .
                          </Fragment>
                        </Typography>
                        {transactionInfo.status_description && (
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                          >
                            <Fragment>
                              Pagador: {transactionInfo.status_description}
                            </Fragment>
                          </Typography>
                        )}
                      </Alert>
                    )}
                    {transactionInfo.status === "PENDING" &&
                      isExpired(transactionInfo) && (
                        <Alert
                          severity="warning"
                          css={css`
                            && {
                              margin-top: 24px;
                            }
                          `}
                        >
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                          >
                            <Fragment>
                              Esta operação <strong>Expirou</strong>{" "}
                              {moment(transactionInfo.transaction_date)
                                .add(defaultExpirationHours, "hours")
                                .fromNow()}
                            </Fragment>
                          </Typography>
                        </Alert>
                      )}
                    {transactionInfo.status === "PENDING" &&
                      !isExpired(transactionInfo) && (
                        <Alert
                          severity="warning"
                          css={css`
                            && {
                              margin-top: 24px;
                            }
                          `}
                        >
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                          >
                            <Fragment>
                              Esta operação esta <strong>Pendente</strong> .
                            </Fragment>
                          </Typography>
                        </Alert>
                      )}
                    {transactionInfo.status === "CANCELLED" && (
                      <Alert
                        severity="error"
                        css={css`
                          && {
                            margin-top: 24px;
                          }
                        `}
                      >
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                        >
                          <Fragment>
                            Esta operação foi <strong>Cancelada</strong>{" "}
                            {moment(transactionInfo.canceled_at).fromNow()}.
                          </Fragment>
                        </Typography>
                        {transactionInfo.status_description && (
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                          >
                            <Fragment>
                              Status: {transactionInfo.status_description}
                            </Fragment>
                          </Typography>
                        )}
                      </Alert>
                    )}

                    {transactionInfo.status === "REJECTED" && (
                      <Alert
                        severity="error"
                        css={css`
                          && {
                            margin-top: 24px;
                          }
                        `}
                      >
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                        >
                          <Fragment>
                            Esta operação foi <strong>Negada</strong>{" "}
                            {moment(transactionInfo.canceled_at).fromNow()}.
                          </Fragment>
                        </Typography>
                        {transactionInfo.status_description && (
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                          >
                            <Fragment>
                              Motivo: {transactionInfo.status_description}
                            </Fragment>
                          </Typography>
                        )}
                      </Alert>
                    )}
                    <Paper
                      variant="outlined"
                      css={css`
                        && {
                          margin-top: 15px;
                        }
                      `}
                    >
                      <Grid
                        css={css`
                          && {
                            padding: 15px;
                          }
                        `}
                        color={"primary"}
                      >
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                        >
                          <Fragment>ID: {transactionInfo.id}</Fragment>
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                        >
                          <Fragment>
                            Referência: {transactionInfo.reference_id}
                          </Fragment>
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                        >
                          <Fragment>
                            Referência Externa:{" "}
                            {transactionInfo.external_reference}
                          </Fragment>
                        </Typography>
                      </Grid>
                    </Paper>
                  </CardContent>
                  <CardActions disableSpacing></CardActions>
                </Card>
              )}

              <DialogContentText></DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setModalInfo(false)}
                variant="outlined"
                css={css`
                  && {
                    margin-top: 15px;
                    border-radius: 15px;
                  }
                `}
              >
                FECHAR
              </Button>
            </DialogActions>
          </Dialog>
          {expand ? (
            <ThemeProvider theme={CustomMuiTheme}>
              <MUIDataTable
                title={
                  <Fragment>
                    <Grid className={classes.tableTitle}>
                      Data:{" "}
                      {moment(rowExpanded?.transaction_date).format(
                        "DD/MM/YYYY",
                      )}{" "}
                      de{" "}
                      {moment(rowExpanded?.selectedDateBegin).format(
                        "HH:mm:ss",
                      )}{" "}
                      até{" "}
                      {moment(rowExpanded?.selectedDateEnd).format("HH:mm:ss")}
                      <Grid>
                        <Grid item>
                          Estabelecimento:{" "}
                          {rowExpanded?.merchant?.trading_name || ""}
                        </Grid>
                      </Grid>
                      <Grid container justify="space-between">
                        <Grid item>
                          Aplicativo:{" "}
                          {rowExpanded?.application_root?.name
                            ? `${rowExpanded?.application_root?.name} / ${rowExpanded?.application?.name}`
                            : rowExpanded?.application?.name}
                        </Grid>
                        <Grid item>
                          Operador: {rowExpanded?.user?.name}{" "}
                          <small>({rowExpanded?.user?.email})</small>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Fragment>
                }
                data={transactionsDetails}
                css={css`
                  && {
                    margin-top: 15px;
                  }
                `}
                columns={[
                  {
                    name: "transaction_date",
                    label: "Data",
                    options: {
                      filter: true,
                      sort: false,
                      filterList: filterList[0] ? filterList[0] : undefined,
                      customBodyRender: (value, tableMeta, updateValue) => {
                        return moment(value).format("DD/MM/YYYY");
                      },
                    },
                  },
                  {
                    name: "transaction_date",
                    label: "Hora",
                    options: {
                      filter: true,
                      sort: true,
                      download: true,
                      filterList: filterList[1] ? filterList[1] : undefined,
                      customBodyRender: (value, tableMeta, updateValue) => {
                        return moment(value).format("HH:mm:ss");
                      },
                    },
                  },
                  {
                    name: "details_metadata.attendant",
                    label: "Atendente",
                    options: {
                      filter: true,
                      sort: true,
                      filterList: filterList[2] ? filterList[2] : undefined,
                      customBodyRender: (value, tableMeta, updateValue) => {
                        if (value && value.code)
                          return `${value?.code} ${value?.name}`;
                        return "";
                      },
                    },
                  },
                  {
                    name: "nsu",
                    label: "NSU",
                    options: {
                      filter: true,
                      sort: true,
                      filterList: filterList[3] ? filterList[3] : undefined,
                    },
                  },
                  {
                    name: "authorization_number",
                    label: "Autorização",
                    options: {
                      filter: true,
                      sort: true,
                      filterList: filterList[4] ? filterList[4] : undefined,
                      setCellHeaderProps: value => ({
                        style: { maxWidth: "200px" },
                      }),
                      setCellProps: value => ({
                        style: {
                          maxWidth: "200px",
                          overflowWrap: "break-word",
                        },
                      }),
                    },
                  },
                  {
                    name: "transaction_amount",
                    label: "Valor",
                    options: {
                      filter: true,
                      sort: true,
                      filterList: filterList[5] ? filterList[5] : undefined,
                      customBodyRender: (value, tableMeta, updateValue) => {
                        return formatterToBrlCurrency(value);
                      },
                    },
                  },
                  {
                    name: "row.details_metadata.metodo_pagamento",
                    label: "Tipo Transação",
                    options: {
                      filter: true,
                      sort: true,
                      customBodyRender: (value, tableMeta, updateValue) => {
                        const row = transactionsDetails[tableMeta.rowIndex];
                        if (
                          isUsePayApp(row.application_root || row.application)
                        ) {
                          if (
                            row.details_metadata?.reference?.metodo_pagamento
                          ) {
                            const isPix =
                              (
                                row.details_metadata?.reference
                                  ?.metodo_pagamento || ""
                              )
                                .toLowerCase()
                                .indexOf("pix") > 0;
                            if (isPix) return "À Vista - Pix";
                          }
                          return "Faturado / À Prazo";
                        } else if (
                          isPagamentoLink(
                            row.application_root || row.application,
                          )
                        ) {
                          return "Link de Pagamento";
                        } else return "Padrão";
                      },
                    },
                  },
                  {
                    name: "status",
                    label: "Status",
                    options: {
                      filter: true,
                      sort: true,
                      filterList: filterList[6] ? filterList[6] : undefined,

                      print: true,
                      download: true,
                      customBodyRender: (value, tableMeta, updateValue) => {
                        switch (value) {
                          case "CANCELLED":
                            return "Cancelado";
                          case "REJECTED":
                            return "Negado";
                          case "PENDING":
                            const row = transactionsDetails[tableMeta.rowIndex];
                            if (
                              isUsePayApp(
                                row.application_root || row.application,
                              )
                            ) {
                              const dateTran = moment(
                                moment(row.transaction_date).add(
                                  defaultExpirationHours,
                                  "hours",
                                ),
                              );
                              const expired = dateTran.isBefore(moment());
                              if (expired) return "Expirado";
                            }

                            return "Pendente";
                          case "PAID":
                            return "Confirmado";
                          case "REVERSAL":
                            return "Estornado";
                          default:
                            break;
                        }
                        return "";
                      },
                    },
                  },
                  {
                    name: "",
                    options: {
                      filter: false,
                      viewColumns: false,
                      sort: false,
                      empty: true,
                      print: false,
                      download: false,
                      customBodyRender: (value, tableMeta, updateValue) => {
                        const { rowData } = tableMeta;
                        const row: any = transactionsDetails.find(
                          (f: any) =>
                            f.transaction_date === rowData[0] &&
                            f.transaction_date === rowData[1] &&
                            f.nsu === rowData[3] &&
                            f.authorization_number === rowData[4] &&
                            f.transaction_amount === rowData[5],
                        );
                        if (row.reversal_user_id)
                          return (
                            <Fragment>
                              <IconButton
                                aria-label="informacoes"
                                color="primary"
                                title="Exibir informações extras"
                                disabled={transactionLoading}
                                onClick={() => {
                                  displayInfo(row);
                                }}
                              >
                                <InfoIcon />
                              </IconButton>
                            </Fragment>
                          );

                        if (row.authorization_date)
                          return (
                            <Fragment>
                              {!row.reversal_user_id && (
                                <IconButton
                                  aria-label="estorno"
                                  color="primary"
                                  title="Solicitar Estorno"
                                  disabled={transactionLoading}
                                  onClick={() => {
                                    requestReversal(row);
                                  }}
                                >
                                  <SettingsBackupRestoreIcon />
                                </IconButton>
                              )}
                              <IconButton
                                aria-label="Recibo"
                                color="primary"
                                title="Imprimir Recibo"
                                disabled={transactionLoading}
                                onClick={() => {
                                  setTransactionInfo(row);
                                  setEnablePrint(true);
                                }}
                              >
                                <Print />
                              </IconButton>

                              <IconButton
                                aria-label="informacoes"
                                color="primary"
                                title="Exibir informações extras"
                                disabled={transactionLoading}
                                onClick={() => {
                                  displayInfo(row);
                                }}
                              >
                                <InfoIcon />
                              </IconButton>
                            </Fragment>
                          );

                        return (
                          <Fragment>
                            {isUsePayApp(
                              row.application_root || row.application,
                            ) &&
                              !isExpired(row) && (
                                <IconButton
                                  aria-label="reenviar"
                                  color="primary"
                                  title="Reenviar notificação"
                                  disabled={transactionLoading}
                                  onClick={async () => {
                                    await resendNotification({
                                      currentTransaction: {
                                        id: row.id,
                                      },
                                    });
                                    setDisplayResendSnack(true);
                                  }}
                                >
                                  <Replay />
                                </IconButton>
                              )}
                            <IconButton
                              aria-label="verificar"
                              color="primary"
                              title="Verificar recebimento"
                              disabled={transactionLoading}
                              onClick={async () => {
                                await checkPayment({
                                  currentTransaction: {
                                    id: row.id,
                                  },
                                });
                                getTransactions({
                                  dateBegin: rowExpanded.date_min,
                                  dateEnd: rowExpanded.date_max,
                                  grouped: false,
                                  user_id: rowExpanded.user.id,
                                  application_id: rowExpanded.application.id,
                                  offset: 0,
                                  limit: 1000,
                                  onlyConfirmed: rowExpanded.onlyConfirmed,
                                  merchant_ids: [row.merchant.id],
                                });
                              }}
                            >
                              <FindReplaceIcon />
                            </IconButton>
                            <IconButton
                              aria-label="informacoes"
                              color="primary"
                              title="Exibir informações extras"
                              disabled={transactionLoading}
                              onClick={() => {
                                displayInfo(row);
                              }}
                            >
                              <InfoIcon />
                            </IconButton>
                          </Fragment>
                        );
                      },
                    },
                  },
                ]}
                options={{
                  ...MuiDefaultOptions,
                  onFilterChange: (
                    changedColumn: any,
                    filterList: any,
                    type: any,
                    changedColumnIndex: any,
                    displayData: any,
                  ) => {
                    setFilterList(filterList);

                    const hasField = (index: any, elementValue: any) => {
                      if (filterList[index].length === 0) return true;
                      return filterList[index].includes(elementValue);
                    };

                    const filtered: any = [];
                    transactionsDetails.forEach((element: any) => {
                      let elementValid = false;
                      const attendantTag = element?.details_metadata?.attendant
                        ?.code
                        ? `${element?.details_metadata?.attendant?.code} ${element?.details_metadata?.attendant?.name}`
                        : "";

                      elementValid =
                        hasField(
                          0,
                          moment(element["transaction_date"]).format(
                            "DD/MM/YYYY",
                          ),
                        ) &&
                        hasField(
                          1,
                          moment(element["transaction_date"]).format(
                            "HH:mm:ss",
                          ),
                        ) &&
                        hasField(2, attendantTag) &&
                        hasField(3, element["nsu"]) &&
                        hasField(4, element["authorization_number"]) &&
                        hasField(
                          5,
                          formatterToBrlCurrency(element["transaction_amount"]),
                        ) &&
                        hasField(6, element["status"]);

                      if (elementValid) filtered.push(element);
                    });

                    setTransactionsDetailsFiltered(filtered);
                  },
                  downloadOptions: {
                    filename: "transacoes-detalhes.csv",
                    separator: ";",
                    filterOptions: {
                      useDisplayedColumnsOnly: true,
                      useDisplayedRowsOnly: true,
                    },
                  },
                  customFooter: (
                    rowCount: number,
                    page: number,
                    rowsPerPage: number,
                    changeRowsPerPage: (page: string | number) => void,
                    changePage: (newPage: number) => void,
                  ) => {
                    if (
                      !transactionsDetailsFiltered ||
                      transactionsDetailsFiltered.length === 0
                    )
                      return <Fragment />;
                    return (
                      <TableFooter>
                        <TableRow>
                          <TableCell
                            colSpan={2}
                            align="center"
                            className={classes.tableTotalTitle}
                          >
                            Totais
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            align="center"
                            className={classes.tableHeader}
                          >
                            Quantidade
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableHeader}
                          >
                            Valor
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell
                            align="center"
                            className={classes.tableRowTotal}
                          >
                            <b>{transactionsDetailsFiltered.length}</b>
                          </TableCell>

                          <TableCell
                            align="center"
                            className={classes.tableRowTotal}
                          >
                            <b>
                              {formatterToBrlCurrency(
                                transactionsDetailsFiltered
                                  .map((m: any) => Number(m.transaction_amount))
                                  .reduce((a: any, b: any) => a + b),
                              )}
                            </b>
                          </TableCell>
                        </TableRow>
                        <TableRow className="datatables-noprint">
                          <CustomTablePaggination
                            rowCount={rowCount}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            changePage={changePage}
                            changeRowsPerPage={changeRowsPerPage}
                          />
                        </TableRow>
                      </TableFooter>
                    );
                  },
                }}
              />
            </ThemeProvider>
          ) : (
            <Fragment></Fragment>
          )}
        </Grid>
      )}
      <SnackNotification
        open={displayResendSnack}
        onClose={() => setDisplayResendSnack(false)}
      >
        <Alert onClose={() => setDisplayResendSnack(false)} severity="info">
          Solicitação de reenvio processada.
        </Alert>
      </SnackNotification>
    </Grid>
  );
};

export default Screen;
