import {
  Grid,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { css, jsx } from "@emotion/core";

import AboutInfo from "./components/AboutInfo";
import AppCard from "./components/AppCard";
import AppMenu from "./components/AppMenu";
import Card from "@material-ui/core/Card";
import DefaultDisabledApp from "./components/DefaultDisabledApp";
import { EMerchantApplicationStatus } from "app/ducks/applications.duck";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import Header from "app/components/Header/Header.container";
import Onboarding from "./components/Onboarding";
import ProcessInfo from "./components/ProcessInfo";
import Skeleton from "@material-ui/lab/Skeleton";
import { screenPropsType } from "./Apps.type";
import { useDispatch } from "react-redux";

/** @jsx jsx */

const AppScreen: React.FC<screenPropsType> = ({
  handleGetApplications,
  handleSelectApplication,
  applications,
  history,
  merchantState,
}) => {
  const dispatch = useDispatch();

  const { loading: loadingApps, apps } = applications || {};
  const [appDisabledDisplayError, setAppDisabledDisplayError] = useState(false);
  const [appDisplayAboutInfo, setAppDisplayAboutInfo] = useState(false);
  const [startOnboarding, setStartOnboarding] = useState(false);
  const { loading: loadingMerchant } = merchantState || {};
  let loading = loadingApps || loadingMerchant;

  const [application, setSelectedApp] = useState({
    name: "",
    enable_process_html: "",
    about_html: "",
    enable_onboarding: false,
    logo_path: "",
    merchant_application: [{ code: "", channel_code: "" }],
    domain_qrcode_type: {
      code: "",
    },
  });

  const [anchorEl, setAnchorEl] = React.useState(null);

  const getApps = useCallback(() => dispatch(handleGetApplications({})), [
    dispatch,
    handleGetApplications,
  ]);

  useEffect(() => {
    getApps();
  }, [getApps]);

  let i = 0;

  var defaultHtml = application?.enable_process_html;
  var useEnableProcessHtml = defaultHtml && defaultHtml.length > 10;
  var useEnableIntegration = application?.enable_onboarding;

  const appIsDisabled = (app: any) => {
    if (app.domain_qrcode_type.code !== "EV") return false;
    return (
      !app.merchant_application[0] ||
      !(app.merchant_application[0].status === EMerchantApplicationStatus.Ativo)
    );
  };

  const appOnboardingIsAvailable = (app: any) => {
    return !!(
      app?.merchant_application?.length &&
      app?.merchant_application[0]?.status ===
        EMerchantApplicationStatus.Disponivel
    );
  };

  const openAppMenu = (event: any, app: any) => {
    setSelectedApp(app);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <Header authentication={null} loading={loading} />

      <AppMenu
        app={application}
        handleClose={handleClose}
        anchorEl={anchorEl}
        setAppDisplayAboutInfo={setAppDisplayAboutInfo}
        setAppDisabledDisplayError={setAppDisabledDisplayError}
        setStartOnboarding={setStartOnboarding}
        appIsDisabled={appIsDisabled}
        appOnboardingIsAvailable={appOnboardingIsAvailable}
        useEnableIntegration={useEnableIntegration}
        useEnableProcessHtml={useEnableProcessHtml}
      />

      <ProcessInfo
        app={application}
        enable={useEnableProcessHtml && appDisabledDisplayError}
        handleClose={setAppDisabledDisplayError}
      />

      <AboutInfo
        app={application}
        enable={appDisplayAboutInfo}
        appIsDisabled={appIsDisabled}
        handleClose={setAppDisplayAboutInfo}
      />

      <Onboarding
        app={application}
        enable={startOnboarding}
        appIsDisabled={appIsDisabled}
        setStartOnboarding={setStartOnboarding}
      />

      <DefaultDisabledApp
        app={application}
        enable={!useEnableProcessHtml && appDisabledDisplayError}
        handleClose={setAppDisabledDisplayError}
      />

      <Grid
        container
        spacing={2}
        justify="center"
        alignItems="center"
        direction="row"
      >
        <Grid item xs={8} lg={12}>
          <GridList>
            <GridListTile key="Subheader" cols={2} style={{ height: "auto" }}>
              <Grid
                container
                justify="flex-end"
                css={css`
                  && {
                    margin-top: 24px;
                  }
                `}
              ></Grid>
              <Typography
                variant="h6"
                align={"center"}
                css={css`
                  margin-bottom: 15px;
                `}
              >
                Selecione o APP de pagamento
              </Typography>
            </GridListTile>
            <GridListTile key="Items" cols={2} style={{ height: "auto" }}>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                {apps
                  .filter((f: any) => {
                    const { merchant_application } = f;
                    const [first] = merchant_application || [];
                    return !!!first?.hidden;
                  })
                  .sort((a: any, b: any) => {
                    const { organizations_application: org_a } = a;
                    const [firsta] = org_a || [];
                    const { organizations_application: org_b } = b;
                    const [firstb] = org_b || [];
                    const aa = firsta?.order || 100;
                    const bb = firstb?.order || 100; // not set go last
                    return aa < bb ? -1 : aa > bb ? 1 : 0;
                  })
                  .map((app: any) => {
                    return (
                      <AppCard
                        key={app.id}
                        app={app}
                        setSelectedApp={setSelectedApp}
                        appIsDisabled={appIsDisabled}
                        appOnboardingIsAvailable={appOnboardingIsAvailable}
                        handleSelectApplication={handleSelectApplication}
                        setStartOnboarding={setStartOnboarding}
                        openAppMenu={openAppMenu}
                        loading={loading}
                        history={history}
                      />
                    );
                  })}

                {loading && (
                  <Fragment>
                    {new Array(10).fill(null).map((tile: any) => (
                      <Card
                        key={i++}
                        css={css`
                          && {
                            width: 200px !important;
                            height: 180px !important;
                            margin-bottom: 15px;
                            margin-left: 15px;
                            cursor: pointer;
                          }
                        `}
                      >
                        <Skeleton
                          animation="wave"
                          variant="rect"
                          width={210}
                          height={118}
                        />
                      </Card>
                    ))}
                  </Fragment>
                )}
              </Grid>
            </GridListTile>
          </GridList>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default AppScreen;
