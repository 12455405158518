import { banco24horasConfig } from "./styles.banco24horas";
import { puntoConfig } from "./styles.punto";
import { qrwayConfig } from "./styles.qrway";

const siteStyle = process.env.REACT_APP_SITE_STYLE;
var config = qrwayConfig;
var isPunto = siteStyle === "puntoo";
var isBanco24horasConfig = siteStyle === "banco24horas";

if (isPunto) config = puntoConfig;
if (isBanco24horasConfig) config = banco24horasConfig;

export const colors = config.colors;
export const logos = {
  applogo: config.applogo,
  termsOfUse: config.applogo,
};

export const currentConfigOptions = {
  ...config,
};
