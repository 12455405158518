import { Button, Card, Grid, TextField, Typography } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { css, jsx } from "@emotion/core";
import {
  currencyFormatterComp,
  formatterToCurrency,
} from "shared/utils/formatter.util";
import {
  getTransactionValueMax,
  getTransactionValueMin,
  transactionValueIsAllowed,
  transactionValueOnBlur,
} from "app/components/TransactionTotalValue";

import AppsTwoToneIcon from "@material-ui/icons/AppsTwoTone";
import AttendantReferenceField from "app/components/Fields/AttendantReferenceField";
import { Autocomplete } from "@material-ui/lab";
import ExternalReferenceField from "app/components/Fields/ExternalReferenceField";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import Header from "app/components/Header/Header.container";
import LastTransactions from "./components/LastTransactions";
import NumberFormat from "react-number-format";
import TransactionError from "app/components/Transaction/TransactionError";
import TransactionInfo from "./components/TransactionCreatedInfo";
import { screenPropsType } from "./UsePay.type";
import { useDispatch } from "react-redux";

/** @jsx jsx */
enum StepSelection {
  Payment = 1,
  Transaction = 2,
  TransactionOutros = 3,
}

enum PaymentType {
  Pix = "Pix",
  Outros = "Outros",
}

enum ContactType {
  "E-mail" = "E-mail",
  SMS = "SMS",
  Whatsapp = "Whatsapp",
}

const TransactionScreen: React.FC<screenPropsType> = ({
  handleNewTransaction,
  handleListTransactions,
  application,
  transactions,
  reports,
  merchantParams,
  history,
}) => {
  const dispatch = useDispatch();

  const backToAppsSelection = () => {
    history.push("/app");
  };

  const { loading: defLoading, error } = transactions;
  const {
    loading: loadingReport,
    transactions: lastTransactions,
  } = transactions || {
    transactions: [],
  };

  let loading = defLoading || loadingReport;
  const [transactionDetailsItems, setTransactionDetailsItems] = useState<any>(
    null,
  );

  const [stepSelection, setStepSelection] = useState<StepSelection | any>(
    StepSelection.Payment,
  );
  const [externalReference, setExternalReference] = useState("");
  const [transactionValue, setTransactionValue] = useState("");
  const [transactionInvalid, setTransactionInvalid] = useState(true);
  const [hasError, setHasError] = useState(false);

  const [attendantCode, setAttendantCode] = useState("");

  const [displayTransactionInfo, setDisplayTransactionInfo] = useState(false);
  const [transactionInfo, setTransactionInfo] = useState(null);

  const [requestExternalReference, setRequestExternalReference] = useState(
    false,
  );

  const [requestDriverInfoEnabled, setRequestDriverInfoEnabled] = useState(
    false,
  );

  const [requestAttendantReference, setRequestAttendantReference] = useState(
    false,
  );
  const initialReference = {
    placa: "",
    contato: "",
    tipo_contato: ContactType["E-mail"],
    tipo_pagamento: PaymentType.Pix,
    metodo_pagamento: "AVista",
    prefixo_telefone: "",
    telefone: "",
  };
  const [referenceInformation, setReferenceInformation] = useState(
    initialReference,
  );
  const [errors, setErrors] = useState<any>({});
  const [transactionValueError, setTransactionValueError] = useState(false);

  useEffect(() => {
    let errorsList: any = {};

    if (!referenceInformation.placa) {
      errorsList[referenceInformation.placa] = "Obrigatório";
    }
    if (
      referenceInformation.tipo_contato === ContactType["E-mail"] &&
      (!referenceInformation.contato ||
        !!!referenceInformation.contato.match(/.+@.+/))
    ) {
      errorsList[referenceInformation.contato] = "Obrigatório";
    }
    if (
      referenceInformation.tipo_contato === ContactType.SMS &&
      (!referenceInformation.prefixo_telefone || !referenceInformation.telefone)
    ) {
      errorsList[referenceInformation.telefone] = "Obrigatório";
    }
    if (Number(transactionValue) <= 0) {
      errorsList["transactionValue"] = "Obrigatório";
    }
    if (requestExternalReference && externalReference === "") {
      errorsList["externalReference"] = "Obrigatório";
    }
    if (requestAttendantReference && attendantCode === "") {
      errorsList["attendantCode"] = "Obrigatório";
    }
    if (transactionValueError) {
      errorsList["transactionValueError"] = "Valor inválido";
    }

    //driver info
    if (requestDriverInfoEnabled) {
      const { driverInfo } = transactionDetailsItems || {};
      if (!driverInfo?.cpf) {
        errorsList["driverInfo.cpf"] = "Obrigatório";
      }
      if (!driverInfo?.controle) {
        errorsList["driverInfo.controle"] = "Obrigatório";
      }
      if (!driverInfo?.senha) {
        errorsList["driverInfo.senha"] = "Obrigatório";
      }
    }
    const invalid = errorsList && Object.keys(errorsList).length > 0;
    setErrors(errorsList);
    setTransactionInvalid(invalid);
  }, [
    referenceInformation,
    transactionValue,
    externalReference,
    attendantCode,
    transactionValueError,
    transactionDetailsItems,
  ]);

  useEffect(() => {
    let requireExternalCode = false;
    let requireAttendantCode = false;
    if (merchantParams?.merchant_group) {
      requireExternalCode =
        merchantParams.merchant_group.enable_external_reference;
      requireAttendantCode =
        merchantParams.merchant_group.enable_attendant_reference;
    }
    setRequestExternalReference(requireExternalCode);
    setRequestAttendantReference(requireAttendantCode);
  }, [merchantParams]);

  const handleClearState = () => {
    setTransactionValue("");
    setExternalReference("");
    setAttendantCode("");
    setTransactionDetailsItems(null);
    setReferenceInformation(initialReference);
  };
  useEffect(() => {
    if (error) setHasError(true);
  }, [error]);

  const handleAddNewTransaction = async () => {
    if (transactionInvalid) return;

    let contato = "";
    let tipo_contato = "";

    if (referenceInformation.tipo_contato === ContactType.SMS) {
      tipo_contato = "Telefone";
      contato = `${referenceInformation.prefixo_telefone}${referenceInformation.telefone}`;
    } else if (referenceInformation.tipo_contato === ContactType["E-mail"]) {
      tipo_contato = "Email";
      contato = referenceInformation.contato;
    } else if (referenceInformation.tipo_contato === ContactType.Whatsapp) {
      tipo_contato = "Whatsapp";
      contato = `55${referenceInformation.prefixo_telefone}${referenceInformation.telefone}`;
    }

    let extraDetails: any = {
      reference: {
        placa: referenceInformation.placa,
        contato,
        tipo_contato,
        tipo_pagamento: referenceInformation.tipo_pagamento,
      },
    };
    if (requestAttendantReference) {
      extraDetails = {
        ...extraDetails,
        attendant: {
          code: attendantCode,
        },
      };
    }

    if (requestDriverInfoEnabled) {
      const { driverInfo } = transactionDetailsItems;
      extraDetails = {
        ...extraDetails,
        reference: {
          ...extraDetails?.reference,
          ...(driverInfo || {}),
          placa: driverInfo?.placa || referenceInformation.placa,
        },
      };
    }

    const result = await dispatch(
      handleNewTransaction({
        application_root_id: application.id,
        application: {
          id: application.sub_applications[0].application_id,
        },
        valor: transactionValue,
        external_reference: externalReference,
        installments: 1,
        details: {
          ...extraDetails,
        },
      }),
    );
    if (result) {
      setTransactionInfo(result);
      setDisplayTransactionInfo(true);
      setStepSelection(StepSelection.Payment);
      handleClearState();
    }
  };

  const isExtrata = (app: any) => {
    const { external_code } = app || "";
    return external_code?.indexOf("extratta") >= 0;
  };

  if (!application) {
    history.push("/app");
    return <div></div>;
  }

  return (
    <Fragment>
      <Grid container justify="center" alignItems="center" direction="row">
        <Grid item xs={12} lg={12}>
          <Header authentication={null} loading={loading} />
        </Grid>
        <TransactionInfo
          enable={displayTransactionInfo}
          setEnabled={(value: boolean) => {
            if (!value) setTransactionInfo(null);
            setDisplayTransactionInfo(value);
          }}
          transactionInfo={transactionInfo}
          application={application}
        />
        <Grid
          item
          xs={12}
          css={css`
            && {
              margin-top: 24px;
            }
          `}
        >
          <GridList>
            <GridListTile key="Subheader" cols={2} style={{ height: "auto" }}>
              <Grid item>
                <Typography variant="h6" align={"center"}>
                  Nova transação com o aplicativo
                </Typography>
              </Grid>
              <Grid
                container
                justify="center"
                alignItems="center"
                direction="row"
              >
                <img
                  src={application?.logo_path}
                  alt={application?.name}
                  style={{ width: "150px" }}
                />
              </Grid>
            </GridListTile>
          </GridList>
        </Grid>

        {stepSelection === StepSelection.Payment && (
          <Grid container direction={"column"} alignItems={"center"}>
            {/* Select Payment Method */}
            <Grid item xs={6} sm={6} lg={6}>
              <Button
                onClick={() => {
                  setReferenceInformation({
                    ...referenceInformation,
                    tipo_pagamento: PaymentType.Pix,
                  });
                  setStepSelection(StepSelection.Transaction);
                }}
                fullWidth
                variant="contained"
                color="primary"
                disabled={isExtrata(application)}
                css={css`
                  && {
                    height: 80px;
                    border-radius: 15px;
                    margin-bottom: 15px;
                  }
                `}
              >
                Enviar link Pix{" "}
              </Button>
              <Button
                onClick={() => {
                  history.push("/app/new-transaction");
                }}
                fullWidth
                variant="contained"
                color="primary"
                disabled={false}
                css={css`
                  && {
                    height: 80px;
                    border-radius: 15px;
                  }
                `}
              >
                Pagar no Posto{" "}
              </Button>
            </Grid>
          </Grid>
        )}

        {stepSelection === StepSelection.Transaction && (
          <Grid container direction={"row"}>
            {/* QrCode */}
            <Grid item xs={12} sm={7} lg={7}>
              <Grid
                container
                justify="center"
                css={css`
                  && {
                    margin-top: 10px;
                  }
                `}
              >
                <Grid item xs={8} sm={7} lg={7}>
                  <TextField
                    fullWidth
                    required
                    label={"Placa"}
                    // inputProps={{ maxLength: 12 }}
                    InputProps={{ inputProps: { maxLength: 7 } }}
                    variant="outlined"
                    // error={!!errors[referenceInformation.placa]}
                    disabled={loading}
                    value={referenceInformation.placa}
                    onChange={({ target: { value } }: any) => {
                      setReferenceInformation({
                        ...referenceInformation,
                        placa: value.toUpperCase(),
                      });
                    }}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                justify="center"
                css={css`
                  && {
                    margin-top: 10px;
                  }
                `}
              >
                <Grid item xs={8} sm={7} lg={7}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                      <Autocomplete
                        disableClearable={true}
                        autoComplete
                        autoSelect
                        style={{ width: "100%" }}
                        loading={loading}
                        disabled={loading}
                        value={referenceInformation.tipo_contato}
                        options={Object.keys(ContactType)}
                        onChange={(event: any, value: any) => {
                          setReferenceInformation({
                            ...referenceInformation,
                            tipo_contato: value as ContactType,
                          });
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            style={{ width: "100%" }}
                            variant="outlined"
                            label="Tipo contato"
                            placeholder="Tipo contato"
                          />
                        )}
                      />
                    </Grid>
                    {referenceInformation.tipo_contato ===
                      ContactType["E-mail"] && (
                      <Grid item xs>
                        <TextField
                          fullWidth
                          required
                          inputProps={{
                            type: "email",
                          }}
                          label={"E-mail Pagador"}
                          variant="outlined"
                          // error={!!errors[referenceInformation.contato]}
                          disabled={loading}
                          value={referenceInformation.contato}
                          onChange={({ target: { value } }: any) => {
                            setReferenceInformation({
                              ...referenceInformation,
                              contato: value,
                            });
                          }}
                        />
                      </Grid>
                    )}
                    {[ContactType.SMS, ContactType.Whatsapp].includes(
                      referenceInformation.tipo_contato,
                    ) && (
                      <Grid item xs>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={3}>
                            <NumberFormat
                              customInput={TextField}
                              format="(##)"
                              fullWidth
                              required
                              autoComplete="nope"
                              disabled={loading}
                              label="DDD"
                              variant="outlined"
                              // value={value}
                              // error={touched && !!error}
                              // helperText={touched && error}
                              // onBlur={({
                              //   target: { value },
                              // }: React.ChangeEvent<HTMLInputElement>) => {
                              //   setFieldTouched(name, true, false);
                              // }}
                              onValueChange={(values: any) =>
                                setReferenceInformation({
                                  ...referenceInformation,
                                  prefixo_telefone: values.value,
                                })
                              }
                            />
                          </Grid>
                          <Grid item xs>
                            <NumberFormat
                              customInput={TextField}
                              format={
                                referenceInformation.contato.length === 8
                                  ? "####-#####"
                                  : "#####-####"
                              }
                              fullWidth
                              autoComplete="nope"
                              required
                              disabled={loading}
                              label="Celular Pagador"
                              variant="outlined"
                              // value={value}
                              // error={touched && !!error}
                              // helperText={touched && error}
                              // onBlur={({
                              //   target: { value },
                              // }: React.ChangeEvent<HTMLInputElement>) => {
                              //   setFieldTouched(name, true, false);
                              // }}
                              onValueChange={(values: any) =>
                                setReferenceInformation({
                                  ...referenceInformation,
                                  telefone: values.value,
                                })
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                justify="center"
                css={css`
                  && {
                    margin-top: 10px;
                  }
                `}
              >
                <Grid item xs={8} sm={7} lg={7}>
                  <NumberFormat
                    customInput={TextField}
                    fullWidth
                    required
                    format={currencyFormatterComp}
                    onBlur={transactionValueOnBlur({
                      application,
                      transactionValue,
                      decimalScale: 2,
                      setTransactionValueError,
                    })}
                    isAllowed={transactionValueIsAllowed({
                      getTransactionValueMax,
                    })}
                    error={transactionValueError}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator="."
                    decimalSeparator=","
                    allowNegative={false}
                    isNumericString={true}
                    prefix={"R$ "}
                    disabled={loading}
                    label={"Digite o valor da transação"}
                    variant="outlined"
                    value={transactionValue}
                    onValueChange={(values: any) => {
                      setTransactionValue(values.value);
                    }}
                  />
                  {transactionValueError && (
                    <small>
                      <Typography
                        variant="caption"
                        color={"error"}
                        align={"center"}
                      >
                        Valor inválido. Minímo:{" "}
                        {formatterToCurrency(
                          getTransactionValueMin(application),
                        )}{" "}
                        e Máximo:{" "}
                        {formatterToCurrency(
                          getTransactionValueMax(application),
                        )}
                      </Typography>
                    </small>
                  )}
                </Grid>
              </Grid>

              <Grid container>
                <ExternalReferenceField
                  enable={requestExternalReference}
                  // merchantParams={merchantParams}
                  setExternalReference={setExternalReference}
                  externalReference={externalReference}
                  transactionPending={loading}
                  loading={loading}
                />
                <AttendantReferenceField
                  enable={requestAttendantReference}
                  setAttendantCode={setAttendantCode}
                  attendantCode={attendantCode}
                  transactionPending={loading}
                  loading={loading}
                  props={{
                    grid: { xs: 8, sm: 7, lg: 7 },
                  }}
                />
              </Grid>

              <Grid
                container
                justify="center"
                css={css`
                  && {
                    margin-top: 10px;
                    margin-bottom: 10px;
                  }
                `}
              >
                <Grid item xs={8} sm={7} lg={7}>
                  <Button
                    onClick={handleAddNewTransaction}
                    fullWidth
                    variant="contained"
                    color="secondary"
                    disabled={loading || transactionInvalid}
                    css={css`
                      && {
                        margin-top: 10px;
                        border-radius: 15px;
                      }
                    `}
                  >
                    Enviar Código Pix{" "}
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={5} lg={5}>
              <TransactionError
                enable={!!error && hasError}
                setHasError={setHasError}
                error={error}
              />
              <LastTransactions
                transactionPending={loading}
                loading={loading}
                getLastTransactions={handleListTransactions}
                lastTransactions={lastTransactions}
                meta={{ ...referenceInformation }}
              />
            </Grid>
          </Grid>
        )}

        <Grid item xs={6}>
          <Button
            onClick={backToAppsSelection}
            fullWidth
            color="secondary"
            disabled={loading}
            startIcon={<AppsTwoToneIcon />}
            css={css`
              && {
                margin-top: 30px;
                font-weight: bold;
              }
            `}
          >
            IR PARA APLICATIVOS
          </Button>
        </Grid>

        <Grid
          container
          justify="flex-end"
          css={css`
            && {
              margin-top: 24px;
            }
          `}
        ></Grid>
      </Grid>
    </Fragment>
  );
};

export default TransactionScreen;
