import { Dialog, Grid } from "@material-ui/core";
import { css, jsx } from "@emotion/core";

import React from "react";

/** @jsx jsx */
var parseHtml = require("html-react-parser");

const ProcessInfo: React.FC<any> = ({ app, enable, handleClose }) => {
  if (!enable) {
    return <div></div>;
  }

  return (
    <Grid item xs={12} sm={12}>
      <Grid
        container
        justify="flex-end"
        css={css`
          && {
            margin-top: 24px;
          }
        `}
      ></Grid>
      <Dialog
        onClose={() => handleClose(false)}
        aria-labelledby="simple-dialog-title"
        open={true}
        fullWidth={true}
      >
        {parseHtml(app?.enable_process_html)}
      </Dialog>
    </Grid>
  );
};

export default ProcessInfo;
