import { API_URL_WITH_PORT, PORTS } from "shared/configs/api.config";

import { Dispatch } from "redux";
import fetch from "shared/utils/fetch.util";
import { stateReduxUsers } from "../screens/Terminals/Terminals.type";

type Actions = {
  type: string;
  payload?: object;
  error?: object;
};

export const Types = {
  INIT: "app/terminals/INIT",
  SUCCESS: "app/terminals/SUCCESS",
  ERROR: "app/terminals/ERROR",
  SUCCESS_UPDATE_TERMINAL: "app/terminals/SUCCESS_UPDATE_TERMINAL",
};

const initialState: stateReduxUsers = {
  terminals: [],
  terminal: null,
  loading: false,
  error: null,
};

export default (state = initialState, action: Actions) => {
  const { type, payload, error } = action;

  const reducers = {
    [Types.INIT]: {
      ...state,
      error: null,
      loading: true,
    },
    [Types.ERROR]: {
      ...state,
      loading: false,
      error,
    },
    [Types.SUCCESS]: {
      loading: false,
      terminals: payload,
      error: false,
    },
    [Types.SUCCESS_UPDATE_TERMINAL]: {
      loading: false,
      terminals: getUpdatedState(state.terminals, payload),
      error: false,
    },
  };

  return reducers[type] || state;
};

const getUpdatedState = (users: any, payload: any) => {
  if (!payload?.id) return users;
  return users.map((user: any) =>
    user.id === payload.id ? { ...user, ...payload } : user,
  );
};

export const handleGetTerminals = (data: any) => async (
  dispatch: Dispatch<any>,
) => {
  try {
    dispatch({
      type: Types.INIT,
    });

    const response = await fetch(
      `${API_URL_WITH_PORT(PORTS.LOGIN)}/accounts/terminals?merchant_id=${data.merchant_id
      }`,
      {
        customUrl: true,
        method: "GET",
        auth: true,
      },
    );

    const result = await response.json();
    if (!response.ok) {
      throw result;
    }

    const { results } = result;
    dispatch({
      type: Types.SUCCESS,
      payload: results,
    });

    return results;
  } catch (e) {
    const error = {
      visible: true,
      ...e,
    };
    dispatch({
      type: Types.ERROR,
      error,
    });
    return error;
  }
};

export const handleUpdateTerminals = (data: any) => async (
  dispatch: Dispatch<any>,
) => {
  try {
    dispatch({
      type: Types.INIT,
    });

    const { id, created_at, updated_at, merchant_id, ...dataPayload } = data;
    const response = await fetch(
      `${API_URL_WITH_PORT(PORTS.LOGIN)}/accounts/terminals/${data.id}`,
      {
        customUrl: true,
        method: "PATCH",
        auth: true,
        body: JSON.stringify({
          ...dataPayload,
        }),
      },
    );

    const result = await response.json();
    if (!response.ok) {
      throw result;
    }

    dispatch({
      type: Types.SUCCESS_UPDATE_TERMINAL,
      payload: data,
    });

    return result;
  } catch (e) {
    const error = {
      visible: true,
      ...e,
    };
    dispatch({
      type: Types.ERROR,
      error,
    });
    return error;
  }
};

export const handleAddAttendant = (data: any) => async (
  dispatch: Dispatch<any>,
) => {
  try {
    dispatch({
      type: Types.INIT,
    });

    const response = await fetch(
      `${API_URL_WITH_PORT(PORTS.LOGIN)}/accounts/terminals`,
      {
        customUrl: true,
        method: "POST",
        auth: true,
        body: JSON.stringify({
          ...data,
        }),
      },
    );

    const result = await response.json();
    if (!response.ok) {
      throw result;
    }

    dispatch(
      handleGetTerminals({
        merchant_id: data.merchant_id,
      }),
    );
    return result;
  } catch (e) {
    const error = {
      visible: true,
      ...e,
    };
    dispatch({
      type: Types.ERROR,
      error,
    });
    return error;
  }
};
