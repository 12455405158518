import { Close, Print } from "@material-ui/icons";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  createMuiTheme,
  makeStyles,
  Grid,
} from "@material-ui/core";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { css, jsx } from "@emotion/core";
import {
  decimalFormatter4,
  formatDocumentNumber,
  formatterToCurrency,
} from "shared/utils/formatter.util";

import ReactToPrint from "react-to-print";
import { colors } from "shared/configs/styles.config";
import moment from "moment";
import { ptBR } from "@material-ui/core/locale";
import { useSnackbar } from "notistack";
import Logo from "shared/components/Logo";
import { getIntegrationTypeFlags } from "../getAppTypeFlags";

/** @jsx jsx */

const theme = createMuiTheme(
  {
    palette: {
      primary: {
        main: colors.primary,
      },
      secondary: {
        main: colors.secondary,
      },
    },
    overrides: {
      MuiTableCell: {
        paddingNone: {
          padding: "5px",
        },
      },
    },
  },
  ptBR,
);

const useStyles = makeStyles({
  container: {
    fontSize: "12px",
    fontFamily: "Times New Roman",
    width: "210px",
    maxWidth: "210px",
  },
  centered: {
    textAlign: "center",
    alignContent: "center",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
});
declare const window: any;
declare const document: any;

const PaymentReceipt: React.FC<any> = ({
  enable,
  transactionInfo,
  application,
  handleClose,
}) => {
  const { printerInstance } = window; // ref to webview bridge printerInstance
  const [printerPosFailed, setPrinterPosFailed] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (printerPosFailed) {
      enqueueSnackbar("Não foi possível imprimir, verifique a bobina.", {
        autoHideDuration: 5000,
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      });
    }
  }, [printerPosFailed]);

  const {
    isPinbankApp,
    isAmeApp,
    isPayInsightsApp,
    isUsePayApp,
    isTicketApp,
  } = getIntegrationTypeFlags(application?.issuer_entity?.identifier_code);

  const classes = useStyles();
  const componentRef = useRef<any>();
  const linkToPrint = () => {
    return (
      <IconButton
        className="hidden-print"
        aria-label="Recibo"
        color="primary"
        title="Imprimir Recibo"
      >
        <Print />
      </IconButton>
    );
  };

  const handleWebViewPrinter = () => {
    /*
A página terá acesso ao objeto "printerInstance" para fazer bridge com o webview do POS.
Esta versão inclui a impressão padronizada entre os dois terminais, usando uma fonte monoespaçada que caberá 38 caracteres por linha, suportando a seguinte formatação:

Altura dupla = "^A"
Negrito = "^D"
Centralizar = "^C"
Alinhar a direita = "^E"

Estes símbolos de controle devem estar no início de cada linha, e podem ser combinados, exemplo: a linha "^A^D^CTeste" imprimiria centralizado na linha, com negrito e altura dupla, o texto "Teste". Caso desejem adicionar um logo no início do comprovante, podem me enviar a arte do logo. Ajustei também o nome do pacote, o nome do app e o ícone dele.

Depois .close() chama a função .getStatus(), se retornar 0 impressão ta OK, se retornar 1 deu erro e ai mostrar a mensagem: "Não foi possível imprimir, verifique a bobina."

*/

    const { printerInstance } = window;
    console.error("window - printerInstance:", printerInstance);

    if (printerInstance) {
      /*
    Recibo Pagamento

Transação com Banco 24Horas

Desc. Pagamento: Saque confirmado
Valor: R$ 150,00
Valor Original: R$ 32,11
Desconto: -R$ 117,89
Contato: wfmellado@gmail.com
Nº Autorização: 00121860-3b98-42e4-8487-0fd7cf39f7de
Referência: sIX5xKxfRqjiSj8Xfh_
NSU: 123456

Obrigado pela Confiança!
    */
      printerInstance.open();
      printerInstance.println("^A^D^CRECIBO PAGAMENTO");
      printerInstance.println(" ");
      printerInstance.println(
        `^DTRANSAÇÃO COM ${application.name.toUpperCase()}`,
      );
      if (
        transactionInfo.status_description &&
        transactionInfo.status_description.length > 1
      ) {
        printerInstance.println(
          `^D${transactionInfo.status_description
            .substring(0, 25)
            .toUpperCase()}`,
        );
      }

      printerInstance.println(
        `^D${transactionInfo.merchant.trading_name.toUpperCase()}`,
      );
      printerInstance.println(
        `CNPJ: ${formatDocumentNumber(
          transactionInfo.merchant.document_number,
        )}`,
      );
      printerInstance.println(" ");

      if (
        transactionInfo?.transaction_amount > 0 &&
        transactionInfo?.transaction_amount !== transactionInfo?.original_amount
      ) {
        printerInstance.println(
          `VALOR ORIGINAL: ${formatterToCurrency(
            transactionInfo.original_amount ||
              transactionInfo?.transaction_amount,
          )}`,
        );
        printerInstance.println(
          `DESCONTO: ${formatterToCurrency(
            (transactionInfo.original_amount ||
              transactionInfo?.transaction_amount) -
              transactionInfo.transaction_amount,
          )}`,
        );
      }

      printerInstance.println(`AUTORIZAÇÃO: ${authorization_number}`);
      printerInstance.println(`NSU: ${transactionInfo.nsu}`);

      if (transactionInfo?.details_metadata?.reference?.placa) {
        printerInstance.println(
          `PLACA: ${transactionInfo?.details_metadata?.reference?.placa.toUpperCase()}`,
        );
      }

      if (transactionInfo?.details_metadata?.reference?.contato) {
        printerInstance.println(
          `CONTATO: ${transactionInfo?.details_metadata?.reference?.contato.toUpperCase()}`,
        );
      }

      if (
        transactionInfo.attendant_description &&
        transactionInfo.attendant_description.length > 1
      ) {
        printerInstance.println(
          `ATENDENTE: ${transactionInfo.attendant_description.toUpperCase()}`,
        );
      }

      if (
        transactionInfo?.details_metadata?.items ||
        transactionInfo?.details_metadata?.singleItem
      ) {
        printerInstance.println(" ");
        printerInstance.println("^CLISTA DE PRODUTOS OU SERVIÇOS");
        for (const item of items.filter(f => f !== null && f?.quantity > 0)) {
          // max: 38caracteres em uso: 1+20+1+6+ N
          printerInstance.println(
            `-${formatSizedLine(
              item.description?.toString(),
              20,
            ).toUpperCase()} ${formatSizedLine(
              item.quantity,
              6,
            )} ${formatterToCurrency(
              (item.value || transactionInfo.transaction_amount) / 100,
            )}`,
          );
        }
        printerInstance.println(" ");
      }

      printerInstance.println(`DATA ^EVALOR`);
      printerInstance.println(
        `^D${moment(transactionInfo.transaction_date).format(
          "DD/MM/YYYY",
        )} ^E^D${formatterToCurrency(transactionInfo.transaction_amount)}`,
      );
      printerInstance.println(
        `${moment(transactionInfo.transaction_date).format("HH:mm")}`,
      );

      printerInstance.println(" ");
      printerInstance.println(`^CREF.: ${transactionInfo.reference_id}`);

      // close
      printerInstance.eject();
      printerInstance.close();

      const status = printerInstance.getStatus();
      setPrinterPosFailed(status);
      //Depois .close() chama a função .getStatus(), se retornar 0 impressão ta OK, se retornar 1 deu erro e ai mostrar a mensagem: "Não foi possível imprimir, verifique a bobina."
    }
  };

  const formatSizedLine = (value: string, size: number) => {
    if (!value) return " ".repeat(size);
    let formated = value.toString().substring(0, size);
    if (formated.length < size)
      formated = formated + " ".repeat(size - formated.length);
    return formated;
  };

  if (!enable) return <div></div>;
  const items = [
    transactionInfo?.details_metadata?.singleItem,
    ...(Array.isArray(transactionInfo?.details_metadata?.items)
      ? transactionInfo?.details_metadata?.items
      : [transactionInfo?.details_metadata?.items] || []),
  ].filter(f => f !== undefined);

  let authorization_number: string = transactionInfo?.authorization_number.toString();
  if (!authorization_number) authorization_number = "";

  return (
    <div>
      <div className="root" ref={componentRef}>
        <div>
          <div className={classes.container}>
            <style>{`.root span {
    margin-left: 10px;
    font-size: 12px;
    font-family: 'Times New Roman';
    border-bottom-style: inset;
}

.MuiTableCell-sizeSmall {
  padding: 0px 15px 0px 15px;
}

.root >
td,
th,
tr,
table {    
    border-collapse: collapse;
}

.centered {
    text-align: center;
    align-content: center;
}

.img {
    text-align: center;
    align-content: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 30%;
}
@media print {
    .root span {color:black;},
    .hidden-print,
    .hidden-print * {
        display: none !important;
    }
}`}</style>
            <Grid container justify="center" style={{ cursor: "pointer" }}>
              <Grid item xs="auto">
                <Logo
                  css={css`
                    && {
                      max-width: 80px;
                      cursor: pointer;
                    }
                  `}
                />
              </Grid>
            </Grid>

            <div className={classes.centered}>
              <div className={classes.heading}>Recibo Pagamento</div>
              <br />
              <div className={classes.secondaryHeading}>
                {`Transação com ${application?.name}`}
              </div>
              <br />
            </div>
            <Typography variant="caption" display="block" color="textPrimary">
              Data:{" "}
              {moment(transactionInfo.transaction_date).format(
                "DD/MM/YYYY HH:mm",
              )}
            </Typography>
            {transactionInfo.status_description &&
              transactionInfo.status_description.length > 1 && (
                <Typography
                  variant="caption"
                  display="block"
                  color="textPrimary"
                >
                  Desc. Pagamento:{" "}
                  {transactionInfo.status_description.substring(0, 25)}
                </Typography>
              )}

            {transactionInfo?.transaction_amount > 0 && (
              <Typography variant="caption" display="block" color="textPrimary">
                Valor: {formatterToCurrency(transactionInfo.transaction_amount)}
              </Typography>
            )}
            {transactionInfo?.transaction_amount > 0 &&
              transactionInfo?.transaction_amount !==
                transactionInfo?.original_amount && (
                <Fragment>
                  <Typography
                    variant="caption"
                    display="block"
                    color="textPrimary"
                  >
                    <Fragment>
                      Valor Original:{" "}
                      {formatterToCurrency(
                        transactionInfo.original_amount ||
                          transactionInfo?.transaction_amount,
                      )}
                    </Fragment>
                  </Typography>
                  <Typography
                    variant="caption"
                    display="block"
                    color="textPrimary"
                  >
                    <Fragment>
                      Desconto:{" "}
                      {formatterToCurrency(
                        (transactionInfo.original_amount ||
                          transactionInfo?.transaction_amount) -
                          transactionInfo.transaction_amount,
                      )}
                    </Fragment>
                  </Typography>
                </Fragment>
              )}

            {transactionInfo?.details_metadata?.reference?.placa && (
              <Typography variant="caption" display="block" color="textPrimary">
                Placa: {transactionInfo?.details_metadata?.reference?.placa}
              </Typography>
            )}
            {transactionInfo?.details_metadata?.reference?.contato && (
              <Typography variant="caption" display="block" color="textPrimary">
                Contato:{" "}
                {`${transactionInfo?.details_metadata?.reference.contato ||
                  ""}`.replace("55", "")}
              </Typography>
            )}
            <Typography variant="caption" color="textPrimary" display="block">
              Nº Autorização: {authorization_number}
            </Typography>
            <Typography variant="caption" color="textPrimary" display="block">
              Referência: {transactionInfo.reference_id}
            </Typography>
            <Typography variant="caption" color="textPrimary" display="block">
              NSU: {transactionInfo.nsu}
            </Typography>
            {transactionInfo.attendant_description &&
              transactionInfo.attendant_description.length > 1 && (
                <Typography
                  variant="caption"
                  display="block"
                  color="textPrimary"
                  css={css`
                    && {
                      margin-top: 15px;
                    }
                  `}
                >
                  Atendente: {transactionInfo.attendant_description}
                </Typography>
              )}
            {(transactionInfo?.details_metadata?.items ||
              transactionInfo?.details_metadata?.singleItem) && (
              <div>
                <br />
                <Typography
                  className={classes.secondaryHeading}
                  variant="caption"
                  color="textPrimary"
                  display="block"
                >
                  Lista de produtos ou serviços
                </Typography>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell component={"span"}>Descrição</TableCell>
                      <TableCell component={"span"} align="right">
                        Qtd
                      </TableCell>
                      <TableCell component={"span"} align="right">
                        Valor
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {items
                      .filter(f => f !== null && f?.quantity > 0)
                      .map((item: any) => (
                        <TableRow key={Math.random()}>
                          <TableCell component={"span"} scope="row">
                            <small>{item.description}</small>
                          </TableCell>
                          <TableCell component={"span"} align="right">
                            {item.quantity}
                          </TableCell>
                          <TableCell component={"span"} align="right">
                            {isUsePayApp
                              ? decimalFormatter4(item.value)
                              : formatterToCurrency(
                                  (item.value |
                                    transactionInfo.transaction_amount) /
                                    100,
                                )}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </div>
            )}
            <br />
            <div className={classes.centered}>
              <div className={classes.secondaryHeading}>
                Obrigado pela Confiança!
              </div>
            </div>
          </div>
        </div>
        <div className={classes.centered}>
          {printerInstance !== undefined ? (
            <IconButton
              className="hidden-print"
              aria-label="Imprimir"
              color="primary"
              title="Imprimir"
              onClick={handleWebViewPrinter}
            >
              <Print />
            </IconButton>
          ) : (
            <ReactToPrint
              trigger={linkToPrint}
              content={() => componentRef.current}
            />
          )}

          <IconButton
            className="hidden-print"
            aria-label="Fechar"
            color="primary"
            title="Fechar"
            onClick={handleClose}
          >
            <Close />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default PaymentReceipt;
