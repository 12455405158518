import { combineReducers } from "redux";

type listOfReducersType = {
  name: string;
  path: string;
};

const reducers = {};

const listOfReducers: Array<listOfReducersType> = [
  {
    name: "authentication",
    path: "authentication.duck"
  }
];

listOfReducers.map(elm =>
  Object.assign(reducers, {
    ...reducers,
    [elm.name]: require(`./${elm.path}`).default
  })
);

export default combineReducers({
  ...reducers
});
