import {
  CircularProgress,
  Grid,
  TextField as TextFieldMaterial,
  Typography,
} from "@material-ui/core";
/** @jsx jsx */
import { Fragment, useState } from "react";
import { css, jsx } from "@emotion/core";

import Alert from "@material-ui/lab/Alert";
import { Field } from "formik";
import NumberFormat from "react-number-format";
import React from "react";
import { TextField } from "formik-material-ui";

const CreateAccountUser: React.FC<any> = ({
  handleVerifyEmail,
  isUpdating,
  loading,
}) => {
  const [emailIsTaken, setEmailIsTaken] = useState(false);

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography
            variant="h1"
            align="left"
            css={css`
              && {
                font-size: 20px;
                font-weight: 800;
                color: #1d1d6d;
                margin-bottom: 18px;
              }
            `}
          >
            Dados de acesso
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Field name="user.email">
                {({ meta: { error, touched } }: any) => {
                  return (
                    <TextField
                      name="user.email"
                      label="E-mail"
                      required
                      disabled={isUpdating}
                      variant="outlined"
                      fullWidth
                      autoComplete="username"
                      onBlurCapture={async (event: any) => {
                        const { target } = event;
                        const { value } = target;
                        if (value && value.length > 0 && !error) {
                          const emailUsed = await handleVerifyEmail(value);
                          setEmailIsTaken(emailUsed);
                        } else {
                          setEmailIsTaken(false);
                        }
                      }}
                    />
                  );
                }}
              </Field>
              {loading && (
                <CircularProgress
                  size={24}
                  color="secondary"
                  css={css`
                    && {
                      color: #1d1d6d;
                      position: absolute;
                      margin-left: -12px;
                      margin-top: 15px;
                      right: 72%;
                    }
                  `}
                />
              )}
              {emailIsTaken && (
                <Alert
                  severity="error"
                  css={css`
                    && {
                      margin-top: 5px;
                    }
                  `}
                >
                  E-mail já esta em uso
                </Alert>
              )}
            </Grid>
            <Grid item xs={12}>
              <Field name="user.document_number">
                {({
                  field: { value, name },
                  form: { setFieldValue, setFieldTouched, isSubmitting },
                  meta: { error, touched },
                }: any) => (
                  <NumberFormat
                    customInput={TextFieldMaterial}
                    format={"###.###.###-##"}
                    fullWidth
                    required
                    autoComplete="nope"
                    disabled={isSubmitting || isUpdating}
                    label={"CPF"}
                    variant="outlined"
                    value={value}
                    error={touched && !!error}
                    helperText={touched && error}
                    onBlur={async ({
                      target: { value },
                    }: React.ChangeEvent<HTMLInputElement>) => {
                      setFieldTouched(name, true, false);
                    }}
                    onValueChange={(values: any) =>
                      setFieldValue(name, values.value)
                    }
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={2}>
                  <Field name="user.phone_prefix">
                    {({
                      field: { value, name },
                      form: { setFieldValue, setFieldTouched, isSubmitting },
                      meta: { error, touched },
                    }: any) => (
                      <NumberFormat
                        customInput={TextFieldMaterial}
                        format="(##)"
                        fullWidth
                        required
                        autoComplete="nope"
                        disabled={isSubmitting || isUpdating}
                        label="DDD celular"
                        variant="outlined"
                        value={value}
                        error={touched && !!error}
                        helperText={touched && error}
                        onBlur={({
                          target: { value },
                        }: React.ChangeEvent<HTMLInputElement>) => {
                          setFieldTouched(name, true, false);
                        }}
                        onValueChange={(values: any) =>
                          setFieldValue(name, values.value)
                        }
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs>
                  <Field name="user.phone_number">
                    {({
                      field: { value, name },
                      form: { setFieldValue, setFieldTouched, isSubmitting },
                      meta: { error, touched },
                    }: any) => (
                      <NumberFormat
                        customInput={TextFieldMaterial}
                        format={
                          value.length === 8 ? "####-#####" : "#####-####"
                        }
                        fullWidth
                        autoComplete="nope"
                        required
                        disabled={isSubmitting || isUpdating}
                        label="Telefone celular"
                        variant="outlined"
                        value={value}
                        error={touched && !!error}
                        helperText={touched && error}
                        onBlur={({
                          target: { value },
                        }: React.ChangeEvent<HTMLInputElement>) => {
                          setFieldTouched(name, true, false);
                        }}
                        onValueChange={(values: any) =>
                          setFieldValue(name, values.value)
                        }
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
            </Grid>
            {!isUpdating && (
              <Grid item xs={12}>
                <TextField
                  name="user.password"
                  type="password"
                  label="Senha"
                  required
                  variant="outlined"
                  fullWidth
                  autoComplete="new-password"
                />
              </Grid>
            )}
            {!isUpdating && (
              <Grid item xs={12}>
                <TextField
                  name="user.confirm_password"
                  type="password"
                  label="Confirmar senha"
                  required
                  variant="outlined"
                  fullWidth
                  autoComplete="new-password"
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default CreateAccountUser;
