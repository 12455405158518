import React from "react";
import { Switch, Route } from "react-router-dom";

import App from "app/App";
import Public from "public/Public";

type RoutesProps = {
  match: {
    path: string;
  };
};

const Routes: React.FC<RoutesProps> = () => (
  <Switch>
    <Route exact path="/" component={Public} />
    <Route path="/app" component={App} />
    <Route path="/:page" component={Public} />
  </Switch>
);

export default Routes;
