import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Paper,
  Slide,
  Snackbar,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { Fragment, useEffect } from "react";
import { css, jsx } from "@emotion/core";

import { Alert } from "@material-ui/lab";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { TransitionProps } from "@material-ui/core/transitions";
import moment from "moment";

/** @jsx jsx */







function TransitionDown(props: TransitionProps) {
  return <Slide {...props} direction="down" />;
}

const useStyles = makeStyles({
  paymentInfoContainer: {
    minWidth: 275,
  },
  containerCardPayment: {
    backgroundColor: "#f44336",
  },
});

const PaymentFailed: React.FC<any> = ({
  enable,
  setPaymentFailed,
  paymentFailed,
  transaction,
}) => {
  const classes = useStyles();
  if (!enable) return <div></div>;

  return (
    <Grid item xs={12} sm={12}>
      <Grid
        container
        justify="flex-end"
        css={css`
          && {
            margin-top: 24px;
          }
        `}
      ></Grid>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        TransitionComponent={TransitionDown}
        open={paymentFailed}
        // autoHideDuration={6000}
        onClose={() => setPaymentFailed(false)}
      >
        <Card>
          <CardHeader className={classes.containerCardPayment} />
          <CardHeader
            titleTypographyProps={{
              variant: "h6",
              color: "error",
            }}
            avatar={<HighlightOffIcon color="error" style={{ fontSize: 50 }} />}
            title="Pagamento Não Confirmado."
            subheader={moment(new Date()).format("LLLL")}
            subheaderTypographyProps={{
              variant: "subtitle2",
            }}
          />

          <CardContent>
            <Paper variant="outlined" square>
              <Grid style={{ padding: "10px" }}>
                {transaction?.status_description &&
                  transaction?.status_description?.length > 1 && (
                    <Typography
                      variant="caption"
                      display="block"
                      color="textSecondary"
                    >
                      Descrição: {transaction.status_description}
                    </Typography>
                  )}
                <Typography
                  variant="caption"
                  color="textSecondary"
                  display="block"
                >
                  Referência: {transaction?.reference_id}
                </Typography>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  display="block"
                >
                  NSU: {transaction?.nsu}
                </Typography>
              </Grid>
            </Paper>
          </CardContent>
          <CardActions>
            <Button
              fullWidth
              variant="contained"
              color="default"
              css={css`
                && {
                  border-radius: 15px;
                }
              `}
              onClick={() => {
                setPaymentFailed(false);
              }}
            >
              OK
            </Button>
          </CardActions>
        </Card>
      </Snackbar>
    </Grid>
  );
};

export default PaymentFailed;
