import React, { useCallback, useEffect } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import Screen from "./TransactionPayLink.screen";
import { getTransactionInformation } from "public/ducks/transaction.duck";
import { useParams } from "react-router-dom";

const useTypedSelector: TypedUseSelectorHook<any> = useSelector;

const Container: React.FC = () => {
  const dispatch = useDispatch();
  const params: any = useParams();
  const { code } = params;

  const handleGetTransactionInfo = useCallback(
    () => dispatch(getTransactionInformation({ reference_id: code })),
    [dispatch, getTransactionInformation],
  );

  useEffect(() => {
    handleGetTransactionInfo();
  }, [handleGetTransactionInfo]);

  const { transaction } = useTypedSelector(state => state.public) || {};
  const { transaction_reference, loading } = transaction;

  return (
    <Screen
      reference_id={code}
      transactionData={transaction_reference}
      loading={loading}
    />
  );
};

export default Container;
