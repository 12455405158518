import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import CreateAccountUserComponent from "./CreateAccountUser.component";
import React from "react";
import { checkUserAvailability } from "../../../ducks/createAccount/createAccount.duck";

const useTypedSelector: TypedUseSelectorHook<any> = useSelector;

const CreateAccountUserContainer: React.FC<any> = ({ isUpdating }) => {
  const dispatch = useDispatch();

  const handleVerifyEmail = async (email: string) => {
    return await dispatch(checkUserAvailability(1, email));
  };

  const { createAccount } = useTypedSelector(state => state.public) || {};

  const { loading } = createAccount;

  return (
    <CreateAccountUserComponent
      isUpdating={isUpdating}
      handleVerifyEmail={handleVerifyEmail}
      loading={loading}
    />
  );
};

export default CreateAccountUserContainer;
