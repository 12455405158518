import { API_URL_WITH_PORT, PORTS } from "shared/configs/api.config";

import { Dispatch } from "redux";
import fetch from "shared/utils/fetch.util";

type Actions = {
  type: string;
  payload?: object;
  error?: object;
};

export const Types = {
  ERROR: "app/registers/ERROR",
  INIT_GET_REGISTERS: "app/registers/INIT_GET_REGISTERS",
  SUCCESS_GET_REGISTERS: "app/registers/SUCCESS_GET_REGISTERS",
  INIT_GET_STATUS: "app/registers/INIT_GET_STATUS",
  SUCCESS_GET_STATUS: "app/registers/SUCCESS_GET_STATUS",
};

type stateRegisters = {
  registers: [];
  register_status: null;
  loading: boolean;
  error: any;
};

const initialState: stateRegisters = {
  registers: [],
  register_status: null,
  loading: false,
  error: null,
};

export default (state = initialState, action: Actions) => {
  const { type, payload, error } = action;

  const reducers = {
    [Types.ERROR]: {
      ...initialState,
      error,
    },
    [Types.INIT_GET_REGISTERS]: {
      ...initialState,
      ...state,
      error: null,
      loading: true,
    },
    [Types.SUCCESS_GET_REGISTERS]: {
      ...state,
      loading: false,
      registers: payload,
      error: false,
    },
    [Types.INIT_GET_STATUS]: {
      ...initialState,
      ...state,
      register_status: null,
      error: null,
      loading: true,
    },
    [Types.SUCCESS_GET_STATUS]: {
      ...state,
      loading: false,
      register_status: payload,
      error: false,
    },
  };

  return reducers[type] || state;
};

export const getReportRegisters = ({
  merchant_id,
  dateBegin,
  dateEnd,
}: any) => async (dispatch: Dispatch<any>) => {
  try {
    dispatch({
      type: Types.INIT_GET_REGISTERS,
    });

    let begin = "";
    let end = "";
    if (dateBegin && dateEnd) {
      begin = new Date(dateBegin).toISOString();
      end = new Date(dateEnd).toISOString();
    } else {
      begin = new Date().toISOString();
      end = begin;
    }
    const response = await fetch(
      `${API_URL_WITH_PORT(
        PORTS.TRANSACTION,
      )}/registers?dateBegin=${begin}&dateEnd=${end}&merchant_id=${merchant_id}`,
      {
        customUrl: true,
        method: "GET",
        auth: true,
      },
    );
    const result = await response.json();
    if (!response.ok) {
      throw result;
    }
    const { results } = result;

    dispatch({
      type: Types.SUCCESS_GET_REGISTERS,
      payload: results,
    });

    return results;
  } catch (e) {
    dispatch({
      type: Types.ERROR,
      error: null,
    });
    return false;
  }
};

export const getRegisterStatus = ({ merchant_id }: any) => async (
  dispatch: Dispatch<any>,
) => {
  try {
    dispatch({
      type: Types.INIT_GET_STATUS,
    });

    const response = await fetch(
      `${API_URL_WITH_PORT(
        PORTS.TRANSACTION,
      )}/registers/status?merchant_id=${merchant_id}`,
      {
        customUrl: true,
        method: "GET",
        auth: true,
      },
    );
    const result = await response.json();
    if (!response.ok) {
      throw result;
    }
    dispatch({
      type: Types.SUCCESS_GET_STATUS,
      payload: result,
    });

    return result;
  } catch (e) {
    dispatch({
      type: Types.ERROR,
      error: null,
    });
    return false;
  }
};
