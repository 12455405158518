import { Alert, Autocomplete } from "@material-ui/lab";
import {
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { css, jsx } from "@emotion/core";
import {
  decimalFormatter3,
  decimalFormatter4,
} from "shared/utils/formatter.util";
import { executeIssuerAction, initState } from "app/ducks/transactions.duck";

import NumberFormat from "react-number-format";
import UsePayExtraDriverInfo from "app/components/Fields/UsePayExtraDriverInfo";
import { stateReduxType as appsStateReduxType } from "../../screens/Apps/Apps.type";
import { stateReduxType as authStateReduxType } from "shared/types/authentication.type";
import { stateReduxType as transactionStateReduxType } from "../../screens/Transactions/Transactions.type";
import validateCPF from "shared/utils/validateCPF.util";

/** @jsx jsx */

const useTypedSelectorApps: TypedUseSelectorHook<appsStateReduxType> = useSelector;
const useTypedSelectorAuth: TypedUseSelectorHook<authStateReduxType> = useSelector;
const useTypedSelector: TypedUseSelectorHook<transactionStateReduxType> = useSelector;

enum PaymentMethod {
  // AVista = "À Vista - Pix",
  Faturado = "Faturado / À Prazo",
}

const UsePayFields: React.FC<any> = ({
  children,
  enable,
  stateRefresh,
  handleAddNewTransaction,
  transactionItems,
  setTransactionItemsValue,
  transactionItemValue,
  transactionItemQuantity,
  setTransactionItemQuantity,
  transactionPending,
  setTransactionItemsDescription,
  loading,
  transactionDetailsItems,
  setTransactionDetailsItems,
  setDirectValueNotAllowed,
  setTransactionValue,
  setInvalidExtraFields,
  setTransactionWithoutQrCode,
  setUseAppRootReference,
  transactionDetailsSingleItem,
  setTransactionDetailsSingleItem,
}) => {
  const dispatch = useDispatch();

  const { applications } = useTypedSelectorApps(state => state.app) || {};
  const { transactions } = useTypedSelector(state => state.app) || {};
  const { authentication } = useTypedSelectorAuth(state => state.shared) || {};
  const { data: auth, params: merchantParams } = authentication;
  const { user } = auth;
  const merchant = user?.merchants[0];
  const { app } = applications;
  const { error, issuerActions } = transactions;
  const { metadata } = issuerActions || {};
  const { motorista, placa, desconto, preco_update } = metadata || [];

  const [requestDriverInfoEnabled, setRequestDriverInfoEnabled] = useState(
    false,
  );
  const initialDriverInfo = {
    cpf: "",
    senha: "",
    controle: null,
    frotaId: null,
    metodo_pagamento: PaymentMethod.Faturado,
  };
  const [driverInfo, setDriverInfo] = useState<any>(initialDriverInfo);
  const [plateInfo, setPlateInfo] = useState("");
  const [plateInfoError, setPlateInfoError] = useState("");
  const [unitPriceBase, setUnitPriceBase] = useState(0);
  const [unitPrice, setUnitPrice] = useState(0);
  const [fetchDiscount, setFetchDiscount] = useState(false);
  const [itemsFleet, setItemsFleet] = useState<any>([]);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [priceUpdated, setPriceUpdated] = useState(false);

  useEffect(() => {
    if (driverInfo.metodo_pagamento) {
      // setUseAppRootReference(
      //   (driverInfo.metodo_pagamento as PaymentMethod) === PaymentMethod.AVista,
      // );
      setUseAppRootReference(false);
    }
  }, []);

  useEffect(() => {
    if (
      fetchDiscount &&
      transactionItemValue &&
      transactionItemQuantity &&
      transactionItemQuantity > 0
    ) {
      if (!driverInfo?.frotaId) return;

      const { code } = transactionItemValue;
      dispatch(
        executeIssuerAction({
          payload: {
            details: {
              reference: {
                frotaId: driverInfo.frotaId,
              },
              meioPagamentoId:
                (driverInfo.metodo_pagamento as PaymentMethod) ===
                PaymentMethod.Faturado
                  ? 1
                  : 5,
              singleItem: {
                code: code,
                quantity: transactionItemQuantity / 100,
              },
            },
            action: "DESCONTO",
          },
          application_id: app && app.id,
          merchant_id: merchant && merchant.id,
        }),
      );
    }
    setFetchDiscount(false);
  }, [fetchDiscount]);

  useEffect(() => {
    if (plateInfo && plateInfo.length === 7) {
      dispatch(
        executeIssuerAction({
          payload: {
            details: {
              reference: {
                placa: plateInfo,
              },
            },
            action: "PLACA",
          },
          application_id: app && app.id,
          merchant_id: merchant && merchant.id,
        }),
      );

      setTransactionDetailsItems({
        ...transactionDetailsItems,
        reference: {
          ...(transactionDetailsItems?.reference || {}),
          placa: plateInfo,
          metodo_pagamento: driverInfo?.metodo_pagamento,
        },
      });
    }
  }, [plateInfo]);

  useEffect(() => {
    let items = [];
    if (motorista && motorista.length > 0) {
      items = motorista.map((m: any) => {
        return { ...m };
      });
    }
    setPlateInfoError("");
    if (placa && placa?.data && placa?.data.length > 0) {
      items = placa.data.map((m: any) => {
        return {
          frotaId: m.frotaId,
          frotaNome: m.client.razaoSocial,
          frotaCnpj: m.client.cnpj,
        };
      });
    }

    if (placa && placa.error && !placa.isValid) {
      setPlateInfoError(placa.message);
    }

    if (items.length > 0) {
      setItemsFleet(items);
      setDriverInfo({
        ...driverInfo,
        frotaId: items.length > 0 ? items[0].frotaId : null,
      });
    }
  }, [motorista, placa]);

  const handleUpdatePrice = () => {
    setPriceUpdated(true);
    Promise.all([
      dispatch(
        executeIssuerAction({
          payload: {
            details: {
              singleItem: {
                code: transactionItemValue.code,
                price: unitPriceBase / 10000,
              },
            },
            action: "ATUALIZA_PRECO",
          },
          application_id: app && app.id,
          merchant_id: merchant && merchant.id,
        }),
      ),
    ]).then((respPromArr: any) => {
      const [firstProm] = respPromArr;
      if (firstProm?.metadata?.preco_update?.isValid) setFetchDiscount(true);
    });
  };

  useEffect(() => {
    if (!enable) return;

    if (
      unitPrice &&
      unitPrice > 0 &&
      transactionItemQuantity &&
      transactionItemQuantity > 0
    ) {
      const total = (transactionItemQuantity / 100) * (unitPrice / 1000);
      setTransactionItemsValue({
        ...transactionItemValue,
        value: unitPrice.toString(),
      });

      setTransactionValue(total.toString());
      setPriceUpdated(false);
    }
  }, [unitPrice, transactionItemQuantity]);

  useEffect(() => {
    if (desconto && desconto.data) {
      const discountValue = desconto.data.desconto * -1;
      let multiplierDec = 1000;
      if (desconto?.data?.desconto?.toString().length === 6)
        multiplierDec = 10000;

      const discountAmount = discountValue * multiplierDec;
      setDiscountAmount(discountAmount);
      setUnitPriceBase(desconto.data.precoBase * 10000);
      setUnitPrice(desconto.data.precoNegociado * 10000);

      setTransactionDetailsSingleItem({
        ...(transactionDetailsSingleItem || {}),
        desconto: discountValue,
        precoBase: desconto.data.precoBase,
        precoNegociado: desconto.data.precoNegociado,
      });
    }
  }, [desconto]);

  useEffect(() => {
    if (!enable) return;

    let driverInvalid = false;
    if (requestDriverInfoEnabled)
      driverInvalid =
        !driverInfo.cpf ||
        !driverInfo.senha ||
        !driverInfo.controle ||
        !validateCPF(driverInfo?.cpf);

    let updatePriceInvalid = false;
    if (
      preco_update?.error &&
      desconto?.data?.precoBase * 10000 !== unitPriceBase &&
      unitPriceBase > 0
    ) {
      updatePriceInvalid = true;
    }

    const invalidFields =
      !transactionItemValue ||
      !transactionItemValue?.code ||
      !transactionItemQuantity ||
      transactionItemQuantity < 0 ||
      !unitPriceBase ||
      unitPriceBase < 0 ||
      !plateInfo ||
      driverInvalid ||
      !!plateInfoError ||
      updatePriceInvalid;

    setInvalidExtraFields(invalidFields);
  }, [
    transactionItemValue,
    transactionItemQuantity,
    unitPriceBase,
    driverInfo,
    plateInfo,
  ]);

  useEffect(() => {
    setDriverInfo(initialDriverInfo);
    setPlateInfo("");
    setUnitPriceBase(0);
    setUnitPrice(0);
    setItemsFleet([]);
    setDiscountAmount(0);
    setPriceUpdated(false);
  }, [stateRefresh]);

  if (!enable) return <div></div>;

  setDirectValueNotAllowed(true);

  return (
    <div>
      <Grid
        container
        justify="center"
        css={css`
          && {
            margin-top: 24px;
          }
        `}
      >
        <Grid item xs={8} sm={6} lg={6}>
          <TextField
            fullWidth
            required
            label={"Placa"}
            InputProps={{ inputProps: { maxLength: 7 } }}
            variant="outlined"
            disabled={loading || transactionPending}
            value={plateInfo}
            error={!!plateInfoError}
            helperText={plateInfoError}
            onChange={({ target: { value } }: any) => {
              setPlateInfo(value?.toUpperCase());
            }}
            onBlur={() => {
              setFetchDiscount(true);
            }}
          />
        </Grid>
      </Grid>
      {itemsFleet && itemsFleet.length > 0 && (
        <Grid
          container
          justify="center"
          css={css`
            && {
              margin-top: 24px;
            }
          `}
        >
          <Grid item xs={8} sm={6} lg={6}>
            <Autocomplete
              disableClearable
              autoComplete
              autoSelect
              style={{ width: "100%" }}
              size="medium"
              loading={loading}
              disabled={loading || transactionPending}
              options={itemsFleet || []}
              value={
                itemsFleet.length <= 1
                  ? itemsFleet[0]
                  : itemsFleet.find(
                      (f: any) => f.frotaId === driverInfo?.frotaId,
                    )
              }
              getOptionLabel={(item: any) => {
                if (!item?.frotaCnpj) return "";
                return `${item?.frotaCnpj} - ${item?.frotaNome}`;
              }}
              onChange={(event: any, value: any) => {
                if (value) {
                  setDriverInfo({
                    ...driverInfo,
                    frotaId: value.frotaId,
                  });
                }
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  style={{ width: "100%" }}
                  variant="outlined"
                  label="Selecione a Frota"
                  placeholder="Frota"
                />
              )}
            />
          </Grid>
        </Grid>
      )}

      <Grid
        container
        justify="center"
        css={css`
          && {
            margin-top: 24px;
          }
        `}
      >
        <Grid item xs={8} sm={6} lg={6}>
          <Autocomplete
            disableClearable
            autoComplete
            autoSelect
            style={{ width: "100%" }}
            size="small"
            loading={loading}
            disabled={loading || transactionPending}
            options={Object.values(PaymentMethod)}
            value={
              driverInfo.metodo_pagamento
                ? (driverInfo.metodo_pagamento as PaymentMethod)
                : (initialDriverInfo.metodo_pagamento as PaymentMethod)
            }
            onChange={(event: any, value: any) => {
              if (value) {
                const valEnum = value as PaymentMethod;
                const isPixUseSubApp = false; //valEnum === PaymentMethod.AVista;
                setUseAppRootReference(isPixUseSubApp);
                setDriverInfo({
                  ...driverInfo,
                  metodo_pagamento: valEnum,
                });
              }
            }}
            renderInput={params => (
              <TextField
                {...params}
                style={{ width: "100%" }}
                variant="outlined"
                label="Forma de Pagamento"
                placeholder="Forma de Pagamento"
              />
            )}
          />
        </Grid>
      </Grid>

      <Grid
        container
        justify="center"
        css={css`
          && {
            margin-top: 24px;
          }
        `}
      >
        <Grid item xs={8} sm={6} lg={6}>
          <Autocomplete
            disableClearable
            autoComplete
            autoSelect
            style={{ width: "100%" }}
            size="small"
            loading={loading || transactionPending}
            disabled={loading || transactionPending}
            options={transactionItems || []}
            value={transactionItemValue}
            getOptionLabel={(item: any) => {
              if (!item?.code) return "";
              return `${item?.code} - ${item?.description}`;
            }}
            onChange={(event: any, value: any) => {
              if (value) {
                setTransactionItemsValue(value);
                setTransactionItemsDescription(value.description);
              }
            }}
            onBlur={() => {
              setFetchDiscount(true);
            }}
            renderInput={params => (
              <TextField
                {...params}
                style={{ width: "100%" }}
                variant="outlined"
                label="Selecione o produto"
                placeholder="Produto"
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid
        container
        justify="center"
        css={css`
          && {
            margin-top: 24px;
          }
        `}
      >
        <Grid item xs={8} sm={6} lg={6}>
          <NumberFormat
            customInput={TextField}
            fullWidth
            required
            format={decimalFormatter3}
            decimalScale={3}
            fixedDecimalScale={true}
            thousandSeparator="."
            decimalSeparator=","
            type="tel"
            allowNegative={false}
            isNumericString={true}
            disabled={loading || transactionPending}
            label={"QTD"}
            variant="outlined"
            value={transactionItemQuantity}
            onValueChange={(values: any) => {
              setTransactionItemQuantity(Number(values.value));
            }}
            onBlur={() => {
              setFetchDiscount(true);
            }}
            onKeyPress={evt => {
              if (evt.key === "Enter") {
                handleAddNewTransaction();
              }
            }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        justify="center"
        css={css`
          && {
            margin-top: 24px;
          }
        `}
      >
        <Grid item xs={8} sm={6} lg={6}>
          <NumberFormat
            customInput={TextField}
            fullWidth
            required
            format={decimalFormatter4}
            decimalScale={4}
            fixedDecimalScale={true}
            thousandSeparator="."
            type="tel"
            decimalSeparator=","
            allowNegative={false}
            isNumericString={true}
            disabled={loading || transactionPending}
            label={"Valor unitário"}
            variant="outlined"
            value={unitPriceBase}
            onValueChange={(values: any) => {
              if (values.value && Number(values.value) !== unitPriceBase) {
                setUnitPriceBase(Number(values.value));
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    variant="contained"
                    title="Atualiza o valor do produto na UsePay (2 vezes por: dia/produto)"
                    disabled={
                      loading ||
                      transactionPending ||
                      unitPriceBase <= 0 ||
                      !transactionItemValue?.code ||
                      desconto?.data?.precoBase * 10000 === unitPriceBase ||
                      priceUpdated
                    }
                    color={enable ? "primary" : "default"}
                    onClick={handleUpdatePrice}
                  >
                    Atualizar
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      {preco_update && priceUpdated && (
        <Grid container justify="center">
          <Grid item xs={8} sm={6} lg={6}>
            {preco_update.error && (
              <div>
                {desconto?.data?.precoBase * 10000 !== unitPriceBase &&
                  unitPriceBase > 0 && (
                    <Typography variant="caption" display="block" color="error">
                      {preco_update.message}
                    </Typography>
                  )}
              </div>
            )}
            {!preco_update.error && preco_update.isValid && (
              <Typography variant="caption" display="block">
                Preço Atualizado !
              </Typography>
            )}
          </Grid>
        </Grid>
      )}
      <Grid
        container
        justify="center"
        css={css`
          && {
            margin-top: 24px;
          }
        `}
      >
        <Grid item xs={8} sm={6} lg={6}>
          <NumberFormat
            customInput={TextField}
            fullWidth
            required={false}
            format={
              desconto?.data?.desconto?.toString()?.length === 6
                ? decimalFormatter4
                : decimalFormatter3
            }
            decimalScale={
              desconto?.data?.desconto?.toString()?.length === 6 ? 4 : 3
            }
            type="tel"
            fixedDecimalScale={true}
            thousandSeparator="."
            decimalSeparator=","
            allowNegative={false}
            isNumericString={true}
            disabled={true}
            label={"Negociado"}
            variant="outlined"
            value={discountAmount}
          />
        </Grid>
      </Grid>
      {children}
      <UsePayExtraDriverInfo
        enable={requestDriverInfoEnabled}
        setEnable={(val: any) => {
          setRequestDriverInfoEnabled(val);
          setTransactionWithoutQrCode(val);
        }}
        loading={loading || transactionPending}
        transactionDetailsItems={transactionDetailsItems}
        setTransactionDetailsItems={setTransactionDetailsItems}
        requestPlate={true}
        initialDriverInfo={initialDriverInfo}
        driverInfo={driverInfo}
        setDriverInfo={setDriverInfo}
        merchant={merchant}
        app={app}
        itemsFleet={itemsFleet}
      />
    </div>
  );
};

export default UsePayFields;
