import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import {
  getRegisterStatus,
  getReportRegisters,
} from "app/ducks/registers.duck";

import React from "react";
import { Redirect } from "react-router-dom";
import Screen from "./ReportReceivable.screen";
import { stateReduxType as authStateType } from "shared/types/authentication.type";
import { getReportReceivables } from "app/ducks/receivables.duck";
import { stateReduxType } from "./ReportReceivable.type";

const useTypedSelector: TypedUseSelectorHook<authStateType> = useSelector;
const useTypedSelectorReports: TypedUseSelectorHook<stateReduxType> = useSelector;
const useTypedSelectorTransactions: TypedUseSelectorHook<any> = useSelector;

const ApplicationsContainer: React.FC<any> = ({ history }) => {
  const dispatch = useDispatch();

  const { reports } = useTypedSelectorReports(state => state.app) || {};

  const { authentication } = useTypedSelector(state => state.shared) || {};
  const { signedIn, data: auth } = authentication;
  const { user } = auth;
  const merchant = user?.merchants[0];
  const { receivable } = useTypedSelectorTransactions(state => state.app) || {};
  const { register } = useTypedSelectorTransactions(state => state.app) || {};

  if (!signedIn) {
    return <Redirect to="/login" />;
  }
  const handleDispatchGetTransactions = (params: any) =>
    dispatch(
      getReportReceivables({
        ...params,
        merchant_id: merchant.id,
      }),
    );

  const handleDispatchGetRegisters = (params: any) => {
    dispatch(
      getReportRegisters({
        ...params,
        merchant_id: merchant.id,
      }),
    );
  };

  const handleDispatchGetRegisterStatus = (params: any) =>
    dispatch(
      getRegisterStatus({
        ...params,
        merchant_id: merchant.id,
      }),
    );

  return (
    <Screen
      history={history}
      getTransactions={handleDispatchGetTransactions}
      getRegisterStatus={handleDispatchGetRegisterStatus}
      getRegisters={handleDispatchGetRegisters}
      registerState={register}
      transactionsExtState={receivable}
      reportsState={reports}
    />
  );
};

export default ApplicationsContainer;
