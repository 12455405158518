/** @jsx jsx */
import React, { useCallback, useEffect, useState } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { css, jsx } from "@emotion/core";
import OnboardingOriginalFields from "./OnboardingOriginalFields";
import OnboardingOriginalConfirmation from "./OnboardingOriginalConfirmation";
import OnboardingOriginalTerms from "./OnboardingOriginalTerms";
import { Button, Grid } from "@material-ui/core";
import { useSnackbar } from "notistack";
import {
  getMerchantById,
  handleMerchantActionTermFile,
  handleMerchantIssuerAction,
  handleMerchantRegistration,
  handleMerchantUpdateComplement,
} from "app/ducks/merchant.duck";

var parseHtml = require("html-react-parser");
const useGenericSelector: TypedUseSelectorHook<any> = useSelector;

const OnboardingOriginal: React.FC<any> = ({ app, handleFinalization }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [formDataValues, setFormDataValues] = useState(null);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { authentication } = useGenericSelector(state => state.shared) || {};
  const { data } = authentication;
  const { user } = data;

  const { merchant: merchantState } =
    useGenericSelector(state => state.app) || {};

  const { merchant, loading, fileTermb64 } = merchantState || {};

  const getMerchant = useCallback(async () => {
    dispatch(getMerchantById(user.merchants[0].id));
  }, [dispatch, user.merchants]);

  useEffect(() => {
    getMerchant();
  }, [getMerchant]);

  // handleMerchantRegistration
  const wraphandleMerchantRegister = useCallback(
    async (app: any) => {
      const response: any = await dispatch(
        handleMerchantRegistration({
          id: user.activeMerchant.id,
          appid: app.id,
        }),
      );

      if (response.error) {
        return enqueueSnackbar(
          response.message || "Falha ao solicitar registro do estabelecimento",
          {
            autoHideDuration: 7000,
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
          },
        );
      }

      setCurrentStep(3);
      return response;
    },
    [dispatch, user.activeMerchant.id, enqueueSnackbar],
  );

  const handleMerchantRegister = async () => {
    // send merchant to registration process
    await wraphandleMerchantRegister(app);
  };

  // handleMerchantUpdateComplement
  const wraphandleMerchantUpdateComplement = useCallback(
    async (app, merchant, payload: any, nextStep?: number) => {
      const response: any = await dispatch(
        handleMerchantUpdateComplement({
          ...(payload || {}),
          id: user.activeMerchant.id,
          appid: app.id,
          merchant,
          organization_id: user.activeMerchant.organization_id,
        }),
      );
      if (response.error) {
        return enqueueSnackbar(
          "Falha ao atualizar os dados de complemento do estabelecimento",
          {
            autoHideDuration: 3000,
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
          },
        );
      }
      const responseTerm: any = await dispatch(
        handleMerchantActionTermFile({
          id: user.activeMerchant.id,
          appid: app.id,
        }),
      );

      if (nextStep) setCurrentStep(nextStep);

      return response;
    },
    [
      dispatch,
      user.activeMerchant.id,
      user.activeMerchant.organization_id,
      enqueueSnackbar,
    ],
  );

  const handleUpdateOnboarding = async (values: any) => {
    setFormDataValues(values);
    // update merchant complement data
    return wraphandleMerchantUpdateComplement(app, merchant, values, 2);
  };

  const handleResendConfirmation = async () => {
    await wraphandleMerchantUpdateComplement(app, merchant, formDataValues, 3);
    return enqueueSnackbar(
      "Um novo token foi enviado para seu E-mail / Celular.",
      {
        autoHideDuration: 3000,
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      },
    );
  };

  // handleMerchantIssuerAction
  const wraphandleMerchantIssuerAction = useCallback(
    async (app, payload: any) => {
      const { tokenCode } = payload;
      const response: any = await dispatch(
        handleMerchantIssuerAction({
          id: user.activeMerchant.id,
          appid: app.id,
          tokenCode,
        }),
      );
      return response;
    },
    [dispatch, user.activeMerchant.id],
  );

  const handleVerifyOnboarding = async (data: any) => {
    return wraphandleMerchantIssuerAction(app, data);
  };

  return (
    <div>
      {currentStep === 0 && (
        <Grid>
          {app?.enable_process_html && parseHtml(app?.enable_process_html)}
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={4} sm={4} lg={4}>
              <Button
                onClick={handleFinalization}
                variant="outlined"
                css={css`
                  && {
                    margin-top: 15px;
                    border-radius: 15px;
                  }
                `}
              >
                <div>Voltar</div>
              </Button>
            </Grid>
            <Grid item xs={4} sm={4} lg={4}>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                css={css`
                  && {
                    border-radius: 15px;
                    width: 100%;
                  }
                `}
                onClick={() => {
                  setCurrentStep(1);
                }}
              >
                <div>Continuar</div>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}

      {currentStep === 1 && (
        <OnboardingOriginalFields
          loading={loading}
          merchant={merchant}
          handleFinalization={handleUpdateOnboarding}
        />
      )}
      {currentStep === 2 && (
        <OnboardingOriginalTerms
          loading={loading}
          fileBase64={fileTermb64}
          handleGoBack={() => {
            setCurrentStep(1);
          }}
          handleFinalization={handleMerchantRegister}
        />
      )}
      {currentStep === 3 && (
        <OnboardingOriginalConfirmation
          handleFinalization={handleFinalization}
          handleConfirmation={handleVerifyOnboarding}
          handleResendConfirmation={handleResendConfirmation}
          formDataValues={formDataValues}
        />
      )}
    </div>
  );
};

export default OnboardingOriginal;
