import { API_URL_WITH_PORT, PORTS } from "shared/configs/api.config";

import { Dispatch } from "redux";
import fetch from "shared/utils/fetch.util";

type Actions = {
  type: string;
  payload?: object;
  error?: object;
};

export const Types = {
  INIT_NOTIFICATIONS_LOAD: "app/notifications/INIT",
  SUCCESS_NOTIFICATIONS_LOAD: "app/notifications/SUCCESS",
  ERROR_NOTIFICATIONS_LOAD: "app/notifications/ERROR",
  ADD_NOTIFICATION: "app/notifications/ADD",
  READ_ALL_NOTIFICATIONS: "app/notifications/READALL",
};

const initialState: any = {
  messages: [],
  loading: false,
  error: null,
};

export default (state = initialState, action: Actions) => {
  const { type, payload, error } = action;

  const reducers = {
    [Types.INIT_NOTIFICATIONS_LOAD]: {
      ...state,
      messages: [],
      error: null,
      loading: true,
    },
    [Types.ERROR_NOTIFICATIONS_LOAD]: {
      loading: false,
      messages: [],
      error,
    },
    [Types.SUCCESS_NOTIFICATIONS_LOAD]: {
      ...state,
      loading: false,
      messages: payload,
      error: false,
    },
    [Types.ADD_NOTIFICATION]: {
      ...state,
      error: null,
      loading: true,
      messages: [payload, ...state.messages],
    },
    [Types.READ_ALL_NOTIFICATIONS]: {
      ...state,
      error: null,
      loading: true,
      messages: state.messages.map((msg: any) => {
        return {
          ...msg,
          read: true,
        };
      }),
    },
  };

  return reducers[type] || state;
};

export const loadNotifications = () => async (dispatch: Dispatch<any>) => {
  try {
    dispatch({
      type: Types.INIT_NOTIFICATIONS_LOAD,
    });
    const response = await fetch(
      `${API_URL_WITH_PORT(PORTS.MESSAGES)}/messages`,
      {
        customUrl: true,
        method: "GET",
        auth: true,
      },
    );
    const result = await response.json();
    if (!response.ok) {
      throw result;
    }

    const { results } = result;
    dispatch({
      type: Types.SUCCESS_NOTIFICATIONS_LOAD,
      payload: results,
    });

    return result;
  } catch (e) {
    dispatch({
      type: Types.ERROR_NOTIFICATIONS_LOAD,
      error: {
        visible: true,
        ...e,
      },
    });
    return false;
  }
};

export const setReadAllMessages = () => async (dispatch: Dispatch<any>) => {
  try {
    dispatch({
      type: Types.READ_ALL_NOTIFICATIONS,
    });
    const response = await fetch(
      `${API_URL_WITH_PORT(PORTS.MESSAGES)}/messages/readall`,
      {
        customUrl: true,
        method: "POST",
        auth: true,
      },
    );
    return true;
  } catch (e) {
    console.log("READ_ALL_NOTIFICATIONS action failed:", e);
    return false;
  }
};

class SingleMessage {}

export const addNewNotification = (singleMessage: SingleMessage) => async (
  dispatch: Dispatch<any>,
) => {
  try {
    dispatch({
      type: Types.ADD_NOTIFICATION,
      payload: singleMessage,
    });
    return true;
  } catch (e) {
    console.log("ADD_NOTIFICATION action failed:", e);
    return false;
  }
};
