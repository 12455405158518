import { API_URL_WITH_PORT, PORTS } from "shared/configs/api.config";

import { Dispatch } from "redux";
import fetch from "shared/utils/fetch.util";
import { stateReduxUsers } from "../screens/Attendants/Attendants.type";

type Actions = {
  type: string;
  payload?: object;
  error?: object;
};

export const Types = {
  INIT: "app/attendants/INIT",
  SUCCESS: "app/attendants/SUCCESS",
  ERROR: "app/attendants/ERROR",
  SUCCESS_UPDATE_ATTENDANT: "app/attendants/SUCCESS_UPDATE_ATTENDANT",
};

const initialState: stateReduxUsers = {
  attendants: [],
  attendant: null,
  loading: false,
  error: null,
};

export default (state = initialState, action: Actions) => {
  const { type, payload, error } = action;

  const reducers = {
    [Types.INIT]: {
      ...state,
      error: null,
      loading: true,
    },
    [Types.ERROR]: {
      ...state,
      loading: false,
      error,
    },
    [Types.SUCCESS]: {
      loading: false,
      attendants: payload,
      error: false,
    },
    [Types.SUCCESS_UPDATE_ATTENDANT]: {
      loading: false,
      attendants: getUpdatedState(state.attendants, payload),
      error: false,
    },
  };

  return reducers[type] || state;
};

const getUpdatedState = (users: any, payload: any) => {
  if (!payload?.id) return users;
  return users.map((user: any) =>
    user.id === payload.id ? { ...user, ...payload } : user,
  );
};

export const handleGetAttendants = (data: any) => async (
  dispatch: Dispatch<any>,
) => {
  try {
    dispatch({
      type: Types.INIT,
    });

    const response = await fetch(
      `${API_URL_WITH_PORT(PORTS.ATTENDANTS)}/attendants?merchant_id=${
        data.merchant_id
      }`,
      {
        customUrl: true,
        method: "GET",
        auth: true,
      },
    );

    const result = await response.json();
    if (!response.ok) {
      throw result;
    }

    const { results } = result;
    dispatch({
      type: Types.SUCCESS,
      payload: results,
    });

    return results;
  } catch (e) {
    const error = {
      visible: true,
      ...e,
    };
    dispatch({
      type: Types.ERROR,
      error,
    });
    return error;
  }
};

export const handleUpdateAttendant = (data: any) => async (
  dispatch: Dispatch<any>,
) => {
  try {
    dispatch({
      type: Types.INIT,
    });

    const { id, created_at, updated_at, merchant_id, ...dataPayload } = data;
    const response = await fetch(
      `${API_URL_WITH_PORT(PORTS.ATTENDANTS)}/attendants/${data.id}`,
      {
        customUrl: true,
        method: "PATCH",
        auth: true,
        body: JSON.stringify({
          ...dataPayload,
        }),
      },
    );

    const result = await response.json();
    if (!response.ok) {
      throw result;
    }

    dispatch({
      type: Types.SUCCESS_UPDATE_ATTENDANT,
      payload: data,
    });

    return result;
  } catch (e) {
    const error = {
      visible: true,
      ...e,
    };
    dispatch({
      type: Types.ERROR,
      error,
    });
    return error;
  }
};

export const handleAddAttendant = (data: any) => async (
  dispatch: Dispatch<any>,
) => {
  try {
    dispatch({
      type: Types.INIT,
    });

    const response = await fetch(
      `${API_URL_WITH_PORT(PORTS.ATTENDANTS)}/attendants`,
      {
        customUrl: true,
        method: "POST",
        auth: true,
        body: JSON.stringify({
          ...data,
        }),
      },
    );

    const result = await response.json();
    if (!response.ok) {
      throw result;
    }

    dispatch(
      handleGetAttendants({
        merchant_id: data.merchant_id,
      }),
    );
    return result;
  } catch (e) {
    const error = {
      visible: true,
      ...e,
    };
    dispatch({
      type: Types.ERROR,
      error,
    });
    return error;
  }
};
