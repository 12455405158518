import { Button, Grid, IconButton, TextField } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { css, jsx } from "@emotion/core";

import { Autocomplete } from "@material-ui/lab";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import NumberFormat from "react-number-format";

/** @jsx jsx */

function decimalFormatter(value: any) {
  if (!Number(value)) return "";
  const amount = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value / 100);
  return `${amount}`.replace("R$", "");
}

const TicketFields: React.FC<any> = ({
  enable,
  transactionItems,
  transactionPending,
  loading,
  transactionDetailsItems,
  setTransactionDetailsItems,
  currencyFormatter,
  setTotalAmount,
  refresh,
  setInvalidExtraFields,
  setDirectValueNotAllowed,
  setDisableNextStep,
}) => {
  const initFuel = {
    description: "",
    code: "",
    quantity: 0,
    value: 0,
    unit_price: 0,
    type: "FUEL",
  };
  const [fuelItem, setFuelItem] = useState<any>(initFuel);
  const [fuelItemSelected, setFuelItemSelected] = useState<any>(null);

  const initOil = {
    description: "Óleo",
    quantity: 0,
    value: 0,
    type: "OIL",
  };
  const [oilItem, setOilItem] = useState<any>(initOil);
  const [servicesItems, setServicesitems] = useState<any>([]);
  const maxServices = 6;

  useEffect(() => {
    setFuelItem(initFuel);
    setOilItem(initOil);
    setServicesitems([]);
  }, [refresh]);
  useEffect(() => {
    if (!transactionDetailsItems || transactionDetailsItems.length === 0)
      return;
    if (fuelItem && fuelItem.code.length > 0) return;
    if (servicesItems && servicesItems.length > 0) return;

    const fuel = transactionDetailsItems.find(
      (f: any) => f.type === "FUEL" && f.quantity > 0,
    );
    if (fuel) {
      const fuelOr = transactionItems?.find(
        (f: any) =>
          f.type === "fuelTypes" && f.code.toString() === fuel.code.toString(),
      );
      const fuelQ = fuel?.quantity * 100;
      if (fuelItem.quantity !== fuelQ || fuelItem.value !== fuel?.value) {
        setFuelItem({
          description: fuel?.description,
          code: fuel.code.toString(),
          quantity: fuelQ,
          value: fuel.value,
          unit_price: fuelOr?.value || 0,
          type: "FUEL",
        });
      }
      setFuelItemSelected(fuelOr);
    }

    const oil = transactionDetailsItems.find(
      (f: any) => f.type === "OIL" && f.quantity > 0,
    );
    if (oil) {
      const oilQ = oil?.quantity * 100;
      if (oilItem.quantity !== oilQ || oilItem.value !== oil?.value) {
        setOilItem({
          ...oilItem,
          description: oil?.description,
          quantity: oilQ,
          value: oil?.value,
          type: "OIL",
        });
      }
    }

    const services = transactionDetailsItems.filter(
      (f: any) => f.type === "SERVICE",
    );
    if (services && services.length > 0) {
      const servicesMap = services.map((m: any) => {
        return {
          id: Math.random(),
          description: m?.description,
          code: m?.code.toString() || "",
          quantity: m?.quantity || 1,
          value: m?.value || 0,
          type: "SERVICE",
        };
      });
      setServicesitems(servicesMap);
    }
  }, [transactionDetailsItems]);

  useEffect(() => {
    const servAmount =
      servicesItems && servicesItems.length > 0
        ? servicesItems
            .map((m: any) => m.value)
            .reduce((a: any, b: any) => a + b)
        : 0;
    const total = fuelItem.value + oilItem.value + servAmount;

    setTotalAmount(total.toString());
    const services = servicesItems.map((m: any) => {
      return {
        description: m.description,
        code: m.code.toString(),
        quantity: 1,
        value: m.value,
        type: m.type,
      };
    });
    const items = [
      fuelItem.code && fuelItem.quantity > 0 && fuelItem.value > 0
        ? {
            description: fuelItem?.description,
            code: fuelItem.code.toString(),
            quantity: fuelItem.quantity / 100,
            value: fuelItem.value,
            type: fuelItem.type,
          }
        : null,
      oilItem.quantity > 0 && oilItem.value > 0
        ? {
            description: oilItem?.description,
            quantity: oilItem.quantity / 100,
            value: oilItem.value,
            type: oilItem.type,
          }
        : null,
      ...services,
    ];
    setTransactionDetailsItems(items.filter(f => f != null));

    const fuelValid = fuelItem.quantity > 0 && fuelItem.value > 0;
    const oilValid = oilItem.quantity > 0 && oilItem.value > 0;
    const serviceValid =
      servicesItems.length > 0 && servicesItems.some((s: any) => s.value > 0);

    const invalidFields = !(fuelValid || oilValid || serviceValid);

    setInvalidExtraFields(invalidFields);
    setDisableNextStep(invalidFields);
  }, [fuelItem, oilItem, servicesItems]);

  if (!enable) return <div></div>;

  setDirectValueNotAllowed(true);

  return (
    <div style={{ marginBottom: "50px" }}>
      <Grid
        container
        justify="center"
        css={css`
          && {
            margin-top: 24px;
          }
        `}
      >
        <Grid item xs={8} sm={6} lg={6}>
          <Autocomplete
            disableClearable={false}
            autoComplete
            autoSelect
            style={{ width: "100%" }}
            size="small"
            loading={loading || transactionPending}
            disabled={loading || transactionPending}
            value={fuelItemSelected}
            options={transactionItems?.filter(
              (f: any) => f.type === "fuelTypes",
            )}
            getOptionLabel={(item: any) => {
              if (!item?.code) return "";
              return `${item?.code} - ${item?.description}`;
            }}
            onChange={(event: any, value: any) => {
              if (!value) {
                setFuelItem(initFuel);
                setFuelItemSelected(value);
                return;
              }
              setFuelItem({
                ...fuelItem,
                code: value.code,
                description: value.description,
                value: fuelItem.quantity * Number(value.value),
                unit_price: Number(value.value),
              });
              setFuelItemSelected(value);
            }}
            renderInput={params => (
              <TextField
                {...params}
                style={{ width: "100%" }}
                variant="outlined"
                label="Tipo combustível"
                placeholder="Tipo combustível"
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid
        container
        justify="center"
        css={css`
          && {
            margin-top: 24px;
          }
        `}
      >
        <Grid item xs={8} sm={6} lg={6}>
          <NumberFormat
            customInput={TextField}
            fullWidth
            required={false}
            format={decimalFormatter}
            isNumericString={true}
            decimalScale={2}
            type="tel"
            fixedDecimalScale={true}
            thousandSeparator="."
            decimalSeparator=","
            allowNegative={false}
            disabled={loading || transactionPending}
            label={"Litros abastecimento"}
            variant="outlined"
            value={fuelItem?.quantity}
            InputProps={{ inputProps: { min: 0.1, max: 9999999 } }}
            onValueChange={(values: any) => {
              setFuelItem({
                ...fuelItem,
                quantity: Number(values.value),
                value: fuelItem.unit_price
                  ? fuelItem.unit_price * Number(values.value)
                  : 0,
              });
            }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        justify="center"
        css={css`
          && {
            margin-top: 24px;
          }
        `}
      >
        <Grid item xs={8} sm={6} lg={6}>
          <NumberFormat
            customInput={TextField}
            fullWidth
            required={false}
            format={currencyFormatter}
            fixedDecimalScale={true}
            type="tel"
            thousandSeparator="."
            decimalSeparator=","
            allowNegative={false}
            disabled={loading || transactionPending || fuelItem?.unit_price > 0}
            label={"Valor abastecimento"}
            variant="outlined"
            value={fuelItem?.value}
            InputProps={{ inputProps: { min: 1, max: 9999999 } }}
            onValueChange={(values: any) => {
              setFuelItem({
                ...fuelItem,
                value: Number(values.value),
              });
            }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        justify="center"
        css={css`
          && {
            margin-top: 24px;
          }
        `}
      >
        <Grid item xs={8} sm={6} lg={6}>
          <NumberFormat
            customInput={TextField}
            fullWidth
            required={false}
            format={decimalFormatter}
            isNumericString={true}
            decimalScale={2}
            type="tel"
            fixedDecimalScale={true}
            thousandSeparator="."
            decimalSeparator=","
            allowNegative={false}
            disabled={loading || transactionPending}
            label={"Litros óleo"}
            variant="outlined"
            value={oilItem?.quantity}
            InputProps={{
              inputProps: {
                min: 1,
                max: 9999999,
              },
            }}
            onValueChange={(values: any) => {
              setOilItem({
                ...oilItem,
                quantity: Number(values.value),
              });
            }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        justify="center"
        css={css`
          && {
            margin-top: 24px;
          }
        `}
      >
        <Grid item xs={8} sm={6} lg={6}>
          <NumberFormat
            customInput={TextField}
            fullWidth
            required={false}
            format={currencyFormatter}
            fixedDecimalScale={true}
            thousandSeparator="."
            decimalSeparator=","
            type="tel"
            allowNegative={false}
            disabled={loading || transactionPending}
            label={"Valor óleo"}
            variant="outlined"
            value={oilItem?.value}
            InputProps={{ inputProps: { min: 1, max: 9999999 } }}
            onValueChange={(values: any) => {
              setOilItem({
                ...oilItem,
                value: Number(values.value),
              });
            }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        justify="center"
        css={css`
          && {
            margin-top: 24px;
          }
        `}
      >
        <Grid item xs={8} sm={6} lg={6}>
          <div title={"Limite de 6 serviços adicionais"}>
            Manutenção / Serviços -{" "}
            <small>
              {servicesItems.length}/{maxServices}
            </small>
          </div>
        </Grid>
        <Grid item xs={8} sm={6} lg={6}>
          <Button
            onClick={() => {
              if (servicesItems.length === maxServices) return;
              setServicesitems([
                ...servicesItems,
                {
                  id: Math.random(),
                  code: "",
                  value: 0,
                  type: "SERVICE",
                },
              ]);
            }}
            disabled={loading || transactionPending}
            color="primary"
            variant="outlined"
            css={css`
              && {
                border-radius: 15px;
              }
            `}
          >
            Adicionar serviço
          </Button>
        </Grid>
      </Grid>

      {servicesItems.map((item: any) => (
        <Grid
          key={item.id}
          container
          justify="space-evenly"
          css={css`
            && {
              margin-top: 24px;
            }
          `}
        >
          <Grid item xs={5} sm={5} lg={5}>
            <Autocomplete
              disableClearable
              autoComplete
              autoSelect
              style={{ width: "100%" }}
              loading={loading || transactionPending}
              disabled={loading || transactionPending}
              options={transactionItems?.filter(
                (f: any) => f.type === "services",
              )}
              value={
                transactionItems?.find(
                  (f: any) =>
                    f.type === "services" &&
                    f.code.toString() === item?.code?.toString(),
                ) || null
              }
              getOptionLabel={(item: any) => {
                if (!item?.code) return "";
                return `${item?.code} - ${item?.description}`;
              }}
              onChange={(event: any, value: any) => {
                var index = servicesItems.findIndex(
                  (f: any) => f.id === item.id,
                );
                servicesItems[index] = {
                  ...servicesItems[index],
                  code: Number(value.code),
                  description: value.description,
                };
                setServicesitems([...servicesItems]);
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  style={{ width: "100%" }}
                  variant="outlined"
                  label="Manutenção ou Serviço"
                  placeholder="Selecione o serviço"
                />
              )}
            />
          </Grid>
          <Grid item xs={4} sm={4} lg={4}>
            <NumberFormat
              customInput={TextField}
              fullWidth
              required
              type="tel"
              format={currencyFormatter}
              fixedDecimalScale={true}
              thousandSeparator="."
              decimalSeparator=","
              allowNegative={false}
              disabled={loading || transactionPending}
              label={"R$"}
              variant="outlined"
              value={item.value}
              InputProps={{ inputProps: { min: 1, max: 9999999 } }}
              onValueChange={(values: any) => {
                var index = servicesItems.findIndex(
                  (f: any) => f.id === item.id,
                );
                servicesItems[index] = {
                  ...servicesItems[index],
                  value: Number(values.value),
                };
                setServicesitems([...servicesItems]);
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1} lg={1}>
            <IconButton
              disabled={loading || transactionPending}
              onClick={() => {
                const items = servicesItems.filter(
                  (f: any) => f.id !== item.id,
                );
                setServicesitems(items);
              }}
              color="inherit"
            >
              <HighlightOffIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}
    </div>
  );
};

export default TicketFields;
