import {
  CustomMuiTheme,
  CustomTablePaggination,
  MuiDefaultOptions,
  useTableCustomStyles,
} from "app/components/Grid/MuiTable";
import {
  Grid,
  TableCell,
  TableFooter,
  TableRow,
  ThemeProvider,
} from "@material-ui/core";
import MUIDataTable, { MUIDataTableColumnDef } from "mui-datatables";
import React, { Fragment, useEffect, useState } from "react";
import { css, jsx } from "@emotion/core";

import { Skeleton } from "@material-ui/lab";
import { formatterToBrlCurrency } from "shared/utils/formatter.util";
import { getIntegrationTypeFlags } from "../../../components/getAppTypeFlags";
import moment from "moment";

/** @jsx jsx */

const Screen: React.FC<any> = ({ transactions, loading, filters }) => {
  const classes = useTableCustomStyles();

  const [transactionsFiltered, setTransactionsFiltered] = useState([]);
  const [filterList, setFilterList] = useState<any>([]);

  useEffect(() => {
    setTransactionsFiltered(transactions);
  }, [transactions]);

  const { isPinbankApp, isAmeApp, isPayInsightsApp } = getIntegrationTypeFlags(
    filters?.selectedIssuer?.identifier_code,
  );

  const getColumns = (): MUIDataTableColumnDef[] => {
    if (isPinbankApp)
      return [
        {
          name: "DataCaptura",
          label: "Dt. Transação",
          options: {
            filter: true,
            sort: true,
            sortDirection: "desc",
            filterList: filterList[0] ? filterList[0] : undefined,
            customBodyRender: (
              value: any,
              tableMeta: any,
              updateValue: any,
            ) => {
              return moment(value).format("DD/MM/YYYY");
            },
          },
        },
        {
          name: "merchant",
          label: "Estabelecimento",
          options: {
            filter: true,
            sort: true,
            filterList: filterList[1] ? filterList[1] : undefined,
            customBodyRender: (value, tableMeta, updateValue) => {
              const document_number = (value?.document_number || "").replace(
                /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                "$1.$2.$3/$4-$5",
              );
              if (value && value.trading_name)
                return `${document_number} - ${value?.trading_name}`;
              return "";
            },
          },
        },
        {
          name: "NsuToken",
          label: "NSU",
          options: {
            filter: true,
            sort: true,
            filterList: filterList[2] ? filterList[2] : undefined,
          },
        },
        {
          name: "IdPinsafeTransacao",
          label: "Cód. Autorização",
          options: {
            filter: true,
            sort: true,
            filterList: filterList[3] ? filterList[3] : undefined,
          },
        },
        {
          name: "ValorTransacao",
          label: "Valor",
          options: {
            filter: true,
            sort: true,
            filterList: filterList[4] ? filterList[4] : undefined,
            customBodyRender: (
              value: any,
              tableMeta: any,
              updateValue: any,
            ) => {
              return formatterToBrlCurrency(value);
            },
          },
        },
        {
          name: "NomePagador",
          label: "Descrição",
          options: {
            filter: true,
            sort: true,
            filterList: filterList[5] ? filterList[5] : undefined,
          },
        },
      ];

    if (isAmeApp)
      return [
        {
          name: "date",
          label: "Dt. Transação",
          options: {
            filter: true,
            sort: true,
            sortDirection: "desc",
            filterList: filterList[0] ? filterList[0] : undefined,
            customBodyRender: (
              value: any,
              tableMeta: any,
              updateValue: any,
            ) => {
              return moment(value).format("DD/MM/YYYY");
            },
          },
        },
        {
          name: "merchant",
          label: "Estabelecimento",
          options: {
            filter: true,
            sort: true,
            filterList: filterList[1] ? filterList[1] : undefined,
            customBodyRender: (value, tableMeta, updateValue) => {
              const document_number = (value?.document_number || "").replace(
                /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                "$1.$2.$3/$4-$5",
              );
              if (value && value.trading_name)
                return `${document_number} - ${value?.trading_name}`;
              return "";
            },
          },
        },
        {
          name: "nsu",
          label: "NSU",
          options: {
            filter: true,
            sort: true,
            filterList: filterList[2] ? filterList[2] : undefined,
          },
        },
        {
          name: "id",
          label: "Cód. Autorização",
          options: {
            filterList: filterList[3] ? filterList[3] : undefined,
          },
        },
        {
          name: "amount",
          label: "Valor",
          options: {
            filter: true,
            sort: true,
            filterList: filterList[4] ? filterList[4] : undefined,
            customBodyRender: (
              value: any,
              tableMeta: any,
              updateValue: any,
            ) => {
              return formatterToBrlCurrency(value / 100);
            },
          },
        },
        {
          name: "description",
          label: "Descrição",
          options: {
            filter: true,
            sort: true,
            filterList: filterList[5] ? filterList[5] : undefined,
          },
        },
      ];
    if (isPayInsightsApp) {
      // pay insights
      return [
        {
          name: "Dt_movimentacao",
          label: "Dt. Transação",
          options: {
            filter: true,
            sort: true,
            sortDirection: "desc",
            filterList: filterList[0] ? filterList[0] : undefined,
            customBodyRender: (
              value: any,
              tableMeta: any,
              updateValue: any,
            ) => {
              return moment(value).format("DD/MM/YYYY");
            },
          },
        },
        {
          name: "merchant",
          label: "Estabelecimento",
          options: {
            filter: true,
            sort: true,
            filterList: filterList[1] ? filterList[1] : undefined,
            customBodyRender: (value, tableMeta, updateValue) => {
              const document_number = (value?.document_number || "").replace(
                /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                "$1.$2.$3/$4-$5",
              );
              if (value && value.trading_name)
                return `${document_number} - ${value?.trading_name}`;
              return "";
            },
          },
        },
        {
          name: "NSU",
          label: "NSU",
          options: {
            filter: true,
            sort: true,
            filterList: filterList[2] ? filterList[2] : undefined,
          },
        },
        {
          name: "AuthorizationCode",
          label: "Cód. Autorização",
          options: {
            filter: true,
            sort: true,
            filterList: filterList[3] ? filterList[3] : undefined,
          },
        },
        {
          name: "VLR_movimentado",
          label: "Valor",
          options: {
            filter: true,
            sort: true,
            filterList: filterList[4] ? filterList[4] : undefined,
            customBodyRender: (
              value: any,
              tableMeta: any,
              updateValue: any,
            ) => {
              return formatterToBrlCurrency(value);
            },
          },
        },
        {
          name: "payee_name",
          label: "Descrição",
          options: {
            filter: true,
            sort: true,
            filterList: filterList[5] ? filterList[5] : undefined,
          },
        },
      ];
    }

    // default
    return [
      {
        name: "date",
        label: "Dt. Transação",
        options: {
          filter: true,
          sort: true,
          sortDirection: "desc",
          filterList: filterList[0] ? filterList[0] : undefined,
          customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
            return moment(value).format("DD/MM/YYYY HH:mm:ss");
          },
        },
      },
      {
        name: "merchant",
        label: "Estabelecimento",
        options: {
          setCellProps: () => ({
            style: {
              "@media (max-width:959.95px)": {
                maxWidth: "250px",
              },
            },
          }),
          filter: true,
          sort: true,
          filterList: filterList[1] ? filterList[1] : undefined,
          customBodyRender: (value, tableMeta, updateValue) => {
            const document_number = (value?.document_number || "").replace(
              /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
              "$1.$2.$3/$4-$5",
            );
            if (value && value.trading_name)
              return `${document_number} - ${value?.trading_name}`;
            return "";
          },
        },
      },
      {
        name: "nsu",
        label: "NSU",
        options: {
          filter: true,
          sort: true,
          filterList: filterList[2] ? filterList[2] : undefined,
        },
      },
      {
        name: "authorization",
        label: "Cód. Autorização",
        options: {
          filter: true,
          sort: true,
          filterList: filterList[3] ? filterList[3] : undefined,
        },
      },
      {
        name: "amount",
        label: "Valor",
        options: {
          filter: true,
          sort: true,
          filterList: filterList[4] ? filterList[4] : undefined,
          customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
            return formatterToBrlCurrency(value);
          },
        },
      },
      {
        name: "name",
        label: "Descrição",
        options: {
          setCellProps: () => ({
            style: {
              "@media (max-width:959.95px)": {
                maxWidth: "250px",
              },
            },
          }),
          filter: true,
          sort: true,
          filterList: filterList[5] ? filterList[5] : undefined,
        },
      },
    ];
  };

  return (
    <Grid item xs={12} sm={12} lg={12}>
      {loading ? (
        <Grid item>
          <Skeleton variant="rect" height={30} />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
        </Grid>
      ) : (
        <Grid item xs={12} sm={12} lg={12}>
          <ThemeProvider theme={CustomMuiTheme}>
            <MUIDataTable
              title={
                <Fragment>
                  <Grid className={classes.tableTitle}>
                    Transações {filters.selectedIssuer?.name} - Detalhado
                  </Grid>
                  <Grid>
                    <small>
                      Período:{" "}
                      {moment(filters.selectedDateBegin).isValid()
                        ? moment(filters.selectedDateBegin).format("DD/MM/YYYY")
                        : "-"}{" "}
                      até{" "}
                      {moment(filters.selectedDateEnd).isValid()
                        ? moment(filters?.selectedDateEnd).format("DD/MM/YYYY")
                        : "-"}{" "}
                    </small>
                  </Grid>
                </Fragment>
              }
              data={transactions}
              css={css`
                && {
                  margin-top: 15px;
                }
              `}
              columns={getColumns()}
              options={{
                ...MuiDefaultOptions,
                onFilterChange: (
                  changedColumn: any,
                  filterList: any,
                  type: any,
                ) => {
                  setFilterList(filterList);

                  const hasField = (index: any, elementValue: any) => {
                    if (filterList[index].length === 0) return true;
                    return filterList[index].includes(elementValue);
                  };

                  const filtered: any = [];
                  transactions.forEach((element: any) => {
                    let elementValid = false;

                    if (isPinbankApp) {
                      elementValid =
                        hasField(
                          0,
                          moment(element["DataCaptura"]).format("DD/MM/YYYY"),
                        ) &&
                        hasField(2, element["NsuToken"]) &&
                        hasField(3, element["IdPinsafeTransacao"]) &&
                        hasField(
                          4,
                          formatterToBrlCurrency(element["ValorTransacao"]),
                        ) &&
                        hasField(5, element["NomePagador"]);

                      if (elementValid) filtered.push(element);
                    } else if (isPayInsightsApp) {
                      elementValid =
                        hasField(
                          0,
                          moment(element["Dt_movimentacao"]).format(
                            "DD/MM/YYYY",
                          ),
                        ) &&
                        hasField(2, element["ID"]) &&
                        hasField(3, element["TransactionID"]) &&
                        hasField(
                          4,
                          formatterToBrlCurrency(element["VLR_movimentado"]),
                        ) &&
                        hasField(5, element["payee_name"]);

                      if (elementValid) filtered.push(element);
                    } else if (isAmeApp) {
                      element["value"] = element["value"] / 100;

                      elementValid =
                        hasField(
                          0,
                          moment(element["date"]).format("DD/MM/YYYY"),
                        ) &&
                        hasField(2, element["nsu"]) &&
                        hasField(3, element["id"]) &&
                        hasField(4, formatterToBrlCurrency(element["value"])) &&
                        hasField(5, element["description"]);

                      if (elementValid) filtered.push(element);
                    } else {
                      elementValid =
                        hasField(
                          0,
                          moment(element["date"]).format("DD/MM/YYYY"),
                        ) &&
                        hasField(2, element["nsu"]) &&
                        hasField(3, element["authorization"]) &&
                        hasField(
                          4,
                          formatterToBrlCurrency(element["amount"]),
                        ) &&
                        hasField(5, element["name"]);

                      if (elementValid) filtered.push(element);
                    }
                  });

                  setTransactionsFiltered(filtered);
                },
                downloadOptions: {
                  filename: "transacoes-emissor-detalhado.csv",
                  separator: ";",
                  filterOptions: {
                    useDisplayedColumnsOnly: true,
                    useDisplayedRowsOnly: true,
                  },
                },
                customFooter: (
                  rowCount: number,
                  page: number,
                  rowsPerPage: number,
                  changeRowsPerPage: (page: string | number) => void,
                  changePage: (newPage: number) => void,
                ) => {
                  if (
                    !transactionsFiltered ||
                    transactionsFiltered.length === 0
                  )
                    return <Fragment />;

                  const getTotalValue = () => {
                    let valueKey = "amount";

                    if (isPinbankApp) valueKey = "ValorTransacao";
                    if (isPayInsightsApp) valueKey = "VLR_movimentado";
                    if (isAmeApp) valueKey = "amount";

                    let totalValue = transactionsFiltered
                      .map((m: any) => Number(m[valueKey]))
                      .reduce((a: any, b: any) => a + b);

                    if (isAmeApp) totalValue = totalValue / 100;

                    return formatterToBrlCurrency(totalValue);
                  };

                  return (
                    <TableFooter>
                      <TableRow>
                        <TableCell
                          colSpan={2}
                          align="center"
                          className={classes.tableTotalTitle}
                        >
                          Totais
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          align="center"
                          className={classes.tableHeader}
                        >
                          Quantidade
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tableHeader}
                        >
                          Valor
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell
                          align="center"
                          className={classes.tableRowTotal}
                        >
                          <b> {transactionsFiltered.length}</b>
                        </TableCell>

                        <TableCell
                          align="center"
                          className={classes.tableRowTotal}
                        >
                          <b>{getTotalValue()}</b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <CustomTablePaggination
                          rowCount={rowCount}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          changePage={changePage}
                          changeRowsPerPage={changeRowsPerPage}
                        />
                      </TableRow>
                    </TableFooter>
                  );
                },
              }}
            />
          </ThemeProvider>
        </Grid>
      )}
    </Grid>
  );
};

export default Screen;
