import { API_URL_WITH_PORT, PORTS } from "shared/configs/api.config";

import { Dispatch } from "redux";
import fetch from "shared/utils/fetch.util";

type Actions = {
  type: string;
  payload?: object;
  error?: object;
};

export const Types = {
  INIT: "app/receivables/INIT",
  SUCCESS: "app/receivables/SUCCESS",
  ERROR: "app/receivables/ERROR",
  INIT_GET_RECEIVABLES: "app/receivables/INIT_GET_RECEIVABLES",
  SUCCESS_GET_RECEIVABLES: "app/receivables/SUCCESS_GET_RECEIVABLES",
};

type stateReceivables = {
  receivables: [];
  loading: boolean;
  error: any;
};

const initialState: stateReceivables = {
  receivables: [],
  loading: false,
  error: null,
};

export default (state = initialState, action: Actions) => {
  const { type, payload, error } = action;

  const reducers = {
    [Types.INIT]: {
      ...state,
      ...initialState,
      error: null,
      loading: true,
    },
    [Types.ERROR]: {
      ...initialState,
      error,
    },
    [Types.INIT_GET_RECEIVABLES]: {
      ...state,
      ...initialState,
      error: null,
      loading: true,
    },
    [Types.SUCCESS_GET_RECEIVABLES]: {
      ...state,
      loading: false,
      receivables: payload,
      error: false,
    },
  };

  return reducers[type] || state;
};

export const getReportReceivables = ({
  merchant_id,
  dateBegin,
  dateEnd,
}: any) => async (dispatch: Dispatch<any>) => {
  try {
    dispatch({
      type: Types.INIT_GET_RECEIVABLES,
    });

    let begin = "";
    let end = "";
    if (dateBegin && dateEnd) {
      begin = new Date(dateBegin).toISOString();
      end = new Date(dateEnd).toISOString();
    } else {
      begin = new Date().toISOString();
      end = begin;
    }

    const response = await fetch(
      `${API_URL_WITH_PORT(
        PORTS.TRANSACTION,
      )}/receivables?dateBegin=${begin}&dateEnd=${end}&merchant_id=${merchant_id}`,
      {
        customUrl: true,
        method: "GET",
        auth: true,
      },
    );
    const result = await response.json();
    if (!response.ok) {
      throw result;
    }
    const { results } = result;

    dispatch({
      type: Types.SUCCESS_GET_RECEIVABLES,
      payload: results,
    });

    return results;
  } catch (e) {
    dispatch({
      type: Types.ERROR,
      error: null,
    });
    return false;
  }
};
