import React, { useCallback, useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { getMerchantById, handleUpdateUser } from "app/ducks/merchant.duck";

import Screen from "./Merchant.screen";
import { stateReduxType as authStateReduxType } from "shared/types/authentication.type";
import { stateReduxType } from "./Merchant.type";
import { useSnackbar } from "notistack";

const useTypedSelector: TypedUseSelectorHook<authStateReduxType> = useSelector;
const useTypedSelectorApps: TypedUseSelectorHook<stateReduxType> = useSelector;

const Container: React.FC<any> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { merchant: merchantState } =
    useTypedSelectorApps(state => state.app) || {};
  const { merchant } = merchantState;
  const { authentication } = useTypedSelector(state => state.shared) || {};
  const { data } = authentication;
  const { user } = data;

  const handleUpdate = async (data: any) => {
    const response: any = await dispatch(handleUpdateUser(data));
    if (response.error) {
      return enqueueSnackbar(response.message, {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      });
    }

    history.push("/app");
    return enqueueSnackbar("Usuário atualizado com sucesso", {
      autoHideDuration: 3000,
      variant: "success",
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
    });
  };

  const initialSchema = useSelector(
    (state: any) => state.public.createAccount.form,
  );
  const initialData = !merchant ? initialSchema : merchant;

  const getMerchant = useCallback(async () => {
    if (user.profile.key === "AdminEC")
      dispatch(getMerchantById(user.merchants[0].id));
    else dispatch(getMerchantById(user.id));
  }, [dispatch, getMerchantById]);

  useEffect(() => {
    getMerchant();
  }, [getMerchant]);

  return (
    <Screen
      merchantState={merchantState}
      initialSchema={initialData}
      handleUpdate={handleUpdate}
    />
  );
};

export default Container;
