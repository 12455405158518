export function getIntegrationTypeFlags(identifier_code: string) {
  // pinbank
  const pinbankIdentifier = "81";
  const isPinbankApp = identifier_code === pinbankIdentifier;

  // payinsights
  const payinsightsIdentifier = "99";
  const isPayInsightsApp = identifier_code === payinsightsIdentifier;

  // ame
  const ameIdentifier = "100";
  const isAmeApp = identifier_code === ameIdentifier;

  // ticket
  const ticketIdentifier = "103";
  const isTicketApp = identifier_code === ticketIdentifier;

  // payinsights
  const usepayIdentifier = "105";
  const isUsePayApp = identifier_code === usepayIdentifier;

  // original
  const originalIdentifier = "106";
  const isBancoOriginal = identifier_code === originalIdentifier;

  // pagamento por link
  const pagamentolinkIdentifier = "107";
  const isPagamentoLink = identifier_code === pagamentolinkIdentifier;

  return {
    isPinbankApp,
    isAmeApp,
    isPayInsightsApp,
    isUsePayApp,
    isTicketApp,
    isBancoOriginal,
    isPagamentoLink,
  };
}
