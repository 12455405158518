import { Close, Print } from "@material-ui/icons";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  createMuiTheme,
  makeStyles,
  Grid,
} from "@material-ui/core";
import React, { Fragment, useRef } from "react";
import { css, jsx } from "@emotion/core";

import ReactToPrint from "react-to-print";
import { colors } from "shared/configs/styles.config";
import { formatterToCurrency } from "shared/utils/formatter.util";
import moment from "moment";
import { ptBR } from "@material-ui/core/locale";
import Logo from "shared/components/Logo";

/** @jsx jsx */

const theme = createMuiTheme(
  {
    palette: {
      primary: {
        main: colors.primary,
      },
      secondary: {
        main: colors.secondary,
      },
    },
    overrides: {
      MuiTableCell: {
        paddingNone: {
          padding: "5px",
        },
      },
    },
  },
  ptBR,
);

const useStyles = makeStyles({
  container: {
    fontSize: "12px",
    fontFamily: "Times New Roman",
    width: "210px",
    maxWidth: "210px",
  },
  centered: {
    textAlign: "center",
    alignContent: "center",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
});

const TransactionCreated: React.FC<any> = ({
  enable,
  transactionInfo,
  application,
  handleClose,
}) => {
  const classes = useStyles();
  const componentRef = useRef<any>();
  const linkToPrint = () => {
    return (
      <IconButton
        className="hidden-print"
        aria-label="Recibo"
        color="primary"
        title="Imprimir Recibo"
      >
        <Print />
      </IconButton>
    );
  };

  if (!enable) return <div></div>;
  const items = [
    transactionInfo?.details_metadata?.singleItem,
    ...(transactionInfo?.details_metadata?.items || []),
  ].filter(f => f !== undefined);

  return (
    <div className="root" ref={componentRef}>
      <div>
        <div className={classes.container}>
          <style>{`.root span {
    margin-left: 10px;
    font-size: 12px;
    font-family: 'Times New Roman';
    border-bottom-style: inset;
}

.MuiTableCell-sizeSmall {
  padding: 0px 15px 0px 15px;
}

.root >
td,
th,
tr,
table {    
    border-collapse: collapse;
}

.centered {
    text-align: center;
    align-content: center;
}

.img {
    text-align: center;
    align-content: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 30%;
}

@media print {
    .root span {color:black;},
    .hidden-print,
    .hidden-print * {
        display: none !important;
    }
}`}</style>
          <Grid container justify="center" style={{ cursor: "pointer" }}>
            <Grid item xs="auto">
              <Logo
                css={css`
                  && {
                    max-width: 80px;
                    cursor: pointer;
                  }
                `}
              />
            </Grid>
          </Grid>
          <div className={classes.centered}>
            <div className={classes.heading}>Transação registrada</div>
            <br />
            <div className={classes.secondaryHeading}>
              Transação com{" "}
              {transactionInfo?.application_root?.name
                ? `${transactionInfo?.application_root?.name} / ${transactionInfo?.application?.name}`
                : transactionInfo?.application?.name}
            </div>
            <br />
          </div>
          <Typography variant="caption" display="block" color="textPrimary">
            Data:{" "}
            {moment(transactionInfo?.transaction_date).format(
              "DD/MM/YYYY HH:mm:ss",
            )}
          </Typography>
          <Typography variant="caption" display="block" color="textPrimary">
            Validade:{" "}
            {moment(transactionInfo?.transaction_date)
              .add(2, "hours")
              .format("DD/MM/YYYY HH:mm:ss")}{" "}
            (2h)
          </Typography>
          {transactionInfo.status_description &&
            transactionInfo.status_description.length > 1 && (
              <Typography variant="caption" display="block" color="textPrimary">
                Desc. Pagamento:{" "}
                {transactionInfo.status_description.substring(0, 25)}
              </Typography>
            )}
          {transactionInfo?.transaction_amount > 0 && (
            <Typography variant="caption" display="block" color="textPrimary">
              Valor: {formatterToCurrency(transactionInfo.transaction_amount)}
            </Typography>
          )}
          {transactionInfo?.transaction_amount > 0 &&
            transactionInfo?.transaction_amount !==
              transactionInfo?.original_amount && (
              <Fragment>
                <Typography
                  variant="caption"
                  display="block"
                  color="textPrimary"
                >
                  <Fragment>
                    Valor Original:{" "}
                    {formatterToCurrency(transactionInfo.original_amount)}
                  </Fragment>
                </Typography>
                <Typography
                  variant="caption"
                  display="block"
                  color="textPrimary"
                >
                  <Fragment>
                    Desconto:{" "}
                    {formatterToCurrency(
                      transactionInfo.original_amount -
                        transactionInfo.transaction_amount,
                    )}
                  </Fragment>
                </Typography>
              </Fragment>
            )}

          {transactionInfo?.details_metadata?.reference?.placa && (
            <Typography variant="caption" display="block" color="textPrimary">
              Placa: {transactionInfo?.details_metadata?.reference?.placa}
            </Typography>
          )}
          {transactionInfo?.details_metadata?.reference?.contato && (
            <Typography variant="caption" display="block" color="textPrimary">
              Contato:
              {`${transactionInfo?.details_metadata?.reference.contato ||
                ""}`.replace("55", "")}
            </Typography>
          )}
          <Typography variant="caption" color="textPrimary" display="block">
            Referência: {transactionInfo.reference_id}
          </Typography>
          <Typography variant="caption" color="textPrimary" display="block">
            NSU: {transactionInfo.nsu}
          </Typography>
          {transactionInfo.attendant_description &&
            transactionInfo.attendant_description.length > 1 && (
              <Typography
                variant="caption"
                display="block"
                color="textPrimary"
                css={css`
                  && {
                    margin-top: 15px;
                  }
                `}
              >
                Atendente: {transactionInfo.attendant_description}
              </Typography>
            )}
          {(transactionInfo?.details_metadata?.items ||
            transactionInfo?.details_metadata?.singleItem) && (
            <div>
              <br />
              <Typography
                className={classes.secondaryHeading}
                variant="caption"
                color="textPrimary"
                display="block"
              >
                Lista de produtos ou serviços
              </Typography>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell component={"span"}>Descrição</TableCell>
                    <TableCell component={"span"} align="right">
                      Qtd
                    </TableCell>
                    <TableCell component={"span"} align="right">
                      Valor
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items
                    .filter(f => f !== null && f?.quantity > 0)
                    .map((item: any) => (
                      <TableRow key={Math.random()}>
                        <TableCell component={"span"} scope="row">
                          <small>{item.description}</small>
                        </TableCell>
                        <TableCell component={"span"} align="right">
                          {item.quantity}
                        </TableCell>
                        <TableCell component={"span"} align="right">
                          {formatterToCurrency(
                            (item.value | transactionInfo.transaction_amount) /
                              100,
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </div>
          )}
          <br />
          <div className={classes.centered}>
            <div className={classes.secondaryHeading}>
              Obrigado pela Confiança!
            </div>
          </div>
        </div>
      </div>
      <div className={classes.centered}>
        <ReactToPrint
          trigger={linkToPrint}
          content={() => componentRef.current}
        />
        <IconButton
          className="hidden-print"
          aria-label="Fechar"
          color="primary"
          title="Fechar"
          onClick={handleClose}
        >
          <Close />
        </IconButton>
      </div>
    </div>
  );
};

export default TransactionCreated;
