import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  GridList,
  GridListTile,
  Paper,
  Typography,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { css, jsx } from "@emotion/core";
import {
  currencyFormatterComp,
  formatDocumentNumber,
  formatterToCurrency,
} from "shared/utils/formatter.util";

import { Alert } from "@material-ui/lab";
import ArrowForwardOutlinedIcon from "@material-ui/icons/ArrowForwardOutlined";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import Logo from "shared/components/Logo";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import SnackNotification from "app/components/SnackNotification";
import { grey } from "@material-ui/core/colors";
import moment from "moment";
import { subscribeToChannel } from "shared/utils/websocketClient";
import { useHistory } from "react-router-dom";

/** @jsx jsx */

const QRCode = require("qrcode.react");

const FileInstruction = (
  <div>
    <h3
      style={{
        textAlign: "center",
      }}
    >
      <span
        style={{
          color: "#000000",
        }}
      >
        <strong>Formas de pagamento - Passo a passo</strong>
      </span>
    </h3>
    <p>&nbsp;</p>
    <p>
      <strong>Pagamento com o QR Code (C&oacute;digo QR)</strong>
    </p>
    <ul>
      <li>
        Abra o aplicativo de pagamento do seu celular e escaneie o QR Code.
        Ap&oacute;s, no aplicativo de pagamento &eacute; s&oacute; verificar os
        dados e confirmar.
      </li>
      <li>
        Assim que o pagamento for conclu&iacute;do volte para a tela dos dados
        de pagamento e verifique a mensagem de confirma&ccedil;&atilde;o do
        pagamento.
      </li>
    </ul>
    <p>
      <strong>Pagamento com "Pix Copia e Cola"</strong>
    </p>
    <ul>
      <li>Clique no bot&atilde;o "Copiar c&oacute;digo do QR Code".</li>
      <li>
        Abra o aplicativo ou entre no site da institui&ccedil;&atilde;o de
        pagamento de sua prefer&ecirc;ncia.
      </li>
      <li>
        Localize a op&ccedil;&atilde;o "Pix - Copia e Cola" (O nome pode variar
        de acordo com a institui&ccedil;&atilde;o).&nbsp;
      </li>
      <li>
        Cole o c&oacute;digo copiado anteriormente, revise os dados e confirme o
        pagamento.
        <ul>
          <li>
            <span
              style={{
                color: "#ff0000",
              }}
            >
              <strong>Importante</strong>
            </span>
            : Sempre verifique o nome do estabelecimento que vai receber o
            pagamento antes de concluir a transa&ccedil;&atilde;o.
          </li>
        </ul>
      </li>
    </ul>
    <p>
      <strong>
        Pagamento com clique no bot&atilde;o "Abrir app ..."&nbsp;
      </strong>
    </p>
    <ul>
      <li>
        Se voc&ecirc; optou por pagar atrav&eacute;s de aplicativos como
        <strong>
          <em>Ame</em>
        </strong>
        <em>,</em>
        <strong>
          <em> PicPay ou Mercado Pago</em>
        </strong>{" "}
        basta clicar no bot&atilde;o "<em>Abrir app ...</em>" que aparece na
        tela de resumo do pagamento e ser&aacute; direcionado automaticamente
        para o aplicativo.
      </li>
      <li>Revise os dados e confirme o pagamento.</li>
    </ul>
  </div>
);
const Screen: React.FC<any> = ({ reference_id, transactionData, loading }) => {
  let history = useHistory();

  const [displayCopySuccessSnack, setDisplayCopySuccessSnack] = useState(false);
  const [displayInformationSnack, setDisplayInformationSnack] = useState(false);
  const [displayConfirmedSnack, setDisplayConfirmedSnack] = useState(false);
  const [transactionInfo, setTransactionInfo] = useState(transactionData);

  useEffect(() => {
    if (!transactionData) return;
    setTransactionInfo(transactionData);
    const { status } = transactionData;
    if (status === "Confirmado") {
      setDisplayConfirmedSnack(true);
    }

    const handler = (update: any, flags: any) => {
      const { status } = update;
      if (status === "Confirmado") {
        setDisplayConfirmedSnack(true);
        setTransactionInfo(update);
      }
    };
    subscribeToChannel({
      channel: `/transactions/${reference_id}`,
      handler,
    });
  }, [transactionData]);

  if (!transactionData || !transactionInfo) {
    return <div></div>;
  }

  const { status } = transactionData;

  if (status != "Aguardando pagamento" && status != "Confirmado") {
    return (
      <div
        style={{
          display: "flex",
          height: "100vh",
        }}
      >
        <Grid container alignItems="center" direction="row">
          <Grid item xs={12}>
            <Alert
              severity="info"
              css={css`
                && {
                  font-size: 20px;
                  font-weight: 800;
                }
              `}
            >
              Ops, este link de pagamento não esta mais disponível.
              <Grid item>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  display="block"
                >
                  <strong>Identificador:</strong> {reference_id}
                </Typography>
              </Grid>
            </Alert>
          </Grid>
        </Grid>
      </div>
    );
  }

  const defaultExpiration = 30;
  const dateTran = moment(
    moment(transactionInfo.transaction_date).add(defaultExpiration, "minutes"),
  );
  const expired = dateTran.isBefore(moment());

  if (expired && status != "Confirmado") {
    return (
      <div
        style={{
          display: "flex",
          height: "100vh",
        }}
      >
        <Grid container alignItems="center" direction="row">
          <Grid item xs={12}>
            <Alert
              severity="info"
              css={css`
                && {
                  font-size: 20px;
                  font-weight: 800;
                }
              `}
            >
              Ops, este link de pagamento expirou.
              <Grid item>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  display="block"
                >
                  <strong>Identificador:</strong> {reference_id}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" color="textSecondary" component="p">
                  <Fragment>
                    Data:{" "}
                    {moment(transactionInfo.transaction_date).format(
                      "DD/MM/YYYY HH:mm:ss",
                    )}{" "}
                    {" - "}
                    <small>
                      {moment(transactionInfo.transaction_date).fromNow()}
                    </small>
                  </Fragment>
                </Typography>
              </Grid>
            </Alert>
          </Grid>
        </Grid>
      </div>
    );
  }

  if (displayConfirmedSnack) {
    return (
      <div
        style={{
          display: "flex",
          height: "100vh",
          // width: "100%",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: grey[300],
        }}
      >
        <Grid
          container
          alignItems="center"
          alignContent="center"
          direction="column"
        >
          <Grid item>
            <Card>
              <Grid
                container
                justify="center"
                style={{ cursor: "pointer", marginTop: "20px" }}
                onClick={() => {
                  window.open("https://qrway.com.br/", "_blank");
                }}
              >
                <Grid item xs="auto">
                  <Logo
                    css={css`
                      && {
                        max-width: 60px;
                      }
                    `}
                  />
                </Grid>
              </Grid>
              <CardHeader
                titleTypographyProps={{
                  variant: "h6",
                  color: "secondary",
                }}
                avatar={
                  <CheckCircleOutlineIcon
                    color="secondary"
                    style={{ fontSize: 50 }}
                  />
                }
                title="Pagamento Confirmado!"
                subheader={`${moment(transactionInfo.authorization_date).format(
                  "LLLL",
                )}`}
                subheaderTypographyProps={{
                  variant: "subtitle2",
                }}
              ></CardHeader>

              <CardContent>
                <Paper variant="outlined" square>
                  <Grid style={{ padding: "10px" }}>
                    {transactionInfo.merchant?.trading_name && (
                      <Typography
                        variant="caption"
                        display="block"
                        color="textSecondary"
                      >
                        Estabelecimento:{" "}
                        {transactionInfo.merchant?.trading_name}
                      </Typography>
                    )}
                    {transactionInfo.merchant?.trading_name && (
                      <Typography
                        variant="caption"
                        display="block"
                        color="textSecondary"
                      >
                        CNPJ: {transactionInfo.merchant?.document_number}
                      </Typography>
                    )}
                    {transactionInfo?.transaction_amount > 0 && (
                      <Typography
                        variant="caption"
                        display="block"
                        color="textSecondary"
                      >
                        Valor:{" "}
                        {currencyFormatterComp(
                          transactionInfo.transaction_amount * 100,
                        )}
                      </Typography>
                    )}
                    {transactionInfo.status_description &&
                      transactionInfo.status_description.length > 1 && (
                        <Typography
                          variant="caption"
                          display="block"
                          color="textSecondary"
                        >
                          Desc. Pagamento: {transactionInfo.status_description}
                        </Typography>
                      )}
                    {transactionInfo?.authorization_number && (
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        display="block"
                      >
                        Nº Autorização: {transactionInfo.authorization_number}
                      </Typography>
                    )}
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      display="block"
                    >
                      Referência: {transactionInfo.reference_id}
                    </Typography>

                    {transactionInfo?.details?.reference?.numero_pedido && (
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        display="block"
                      >
                        Núm. Pedido:{" "}
                        <strong>
                          {transactionInfo.details.reference.numero_pedido}
                        </strong>
                      </Typography>
                    )}

                    <Typography
                      variant="caption"
                      color="textSecondary"
                      display="block"
                    >
                      NSU: {transactionInfo.nsu}
                    </Typography>

                    {transactionInfo.authorization_date && (
                      <Typography
                        variant="caption"
                        display="block"
                        color="textSecondary"
                      >
                        <small>
                          Confirmado:{" "}
                          {moment(transactionInfo.authorization_date).fromNow()}
                        </small>
                      </Typography>
                    )}
                  </Grid>
                </Paper>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
      }}
    >
      <Grid container alignItems="center" direction="row">
        <SnackNotification
          open={displayCopySuccessSnack}
          onClose={() => setDisplayCopySuccessSnack(false)}
          snackProps={{ autoHideDuration: 3000 }}
        >
          <Alert
            onClose={() => setDisplayCopySuccessSnack(false)}
            severity="info"
          >
            Código copiado para a àrea de transferência.
          </Alert>
        </SnackNotification>

        <SnackNotification
          open={displayInformationSnack}
          onClose={() => setDisplayInformationSnack(false)}
        >
          <Paper style={{ maxHeight: 600, overflow: "auto" }}>
            <Alert
              onClose={() => setDisplayInformationSnack(false)}
              severity="info"
            >
              {FileInstruction}
            </Alert>
          </Paper>
        </SnackNotification>

        {loading && (
          <Grid container justify="center" alignItems="center" direction="row">
            <Grid item xs="auto" style={{ padding: "30px" }}>
              <CircularProgress size={50} color="secondary" />
            </Grid>
          </Grid>
        )}

        {displayConfirmedSnack && (
          <div
            style={{
              display: "flex",
              height: "100vh",
            }}
          >
            <Grid item xs={12} sm={12} lg={12}>
              <Card>
                <CardHeader />
                <CardHeader
                  titleTypographyProps={{
                    variant: "h6",
                    color: "secondary",
                  }}
                  avatar={
                    <CheckCircleOutlineIcon
                      color="secondary"
                      style={{ fontSize: 50 }}
                    />
                  }
                  title="Pagamento Confirmado!"
                  subheader={moment(transactionInfo.authorization_date).format(
                    "LLLL",
                  )}
                  subheaderTypographyProps={{
                    variant: "subtitle2",
                  }}
                />

                <CardContent>
                  <Paper variant="outlined" square>
                    <Grid style={{ padding: "10px" }}>
                      {transactionInfo?.transaction_amount > 0 && (
                        <Typography
                          variant="caption"
                          display="block"
                          color="textSecondary"
                        >
                          Valor:{" "}
                          {currencyFormatterComp(
                            transactionInfo.transaction_amount * 100,
                          )}
                        </Typography>
                      )}

                      <Typography
                        variant="caption"
                        color="textSecondary"
                        display="block"
                      >
                        Referência: {transactionInfo.reference_id}
                      </Typography>
                    </Grid>
                  </Paper>
                </CardContent>
              </Card>
            </Grid>
          </div>
        )}

        {!displayConfirmedSnack && (
          <Grid item xs={12} sm={6}>
            <GridList style={{ padding: "10px" }}>
              <GridListTile key="Subheader" cols={2} style={{ height: "auto" }}>
                <Grid item>
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    direction="row"
                  >
                    <img
                      src={transactionInfo.application.logo_external}
                      alt={transactionInfo.application.name_external}
                      style={{ width: "120px" }}
                    />
                  </Grid>
                </Grid>

                <Grid item style={{ padding: "10px" }}>
                  <Typography
                    variant="h6"
                    align={"center"}
                    gutterBottom
                    css={css`
                      && {
                        font-size: 20px;
                        font-weight: 800;
                      }
                    `}
                  >
                    Pague{" "}
                    {formatterToCurrency(
                      transactionInfo.transaction_amount || 0,
                    )}{" "}
                    para {transactionInfo?.merchant?.name}.
                  </Typography>
                </Grid>

                <Grid item>
                  <Alert severity="info">
                    <Grid
                      container
                      justify="center"
                      alignItems="center"
                      direction="row"
                    >
                      <Button
                        fullWidth
                        variant="text"
                        color="default"
                        css={css`
                          && {
                            border-radius: 10px;
                          }
                        `}
                        onClick={() => {
                          setDisplayInformationSnack(true);
                        }}
                      >
                        Como funciona o pagamento
                        <ArrowForwardOutlinedIcon />
                      </Button>
                    </Grid>
                  </Alert>
                </Grid>
              </GridListTile>
            </GridList>
          </Grid>
        )}

        {!displayConfirmedSnack && (
          <Grid item xs={12} sm={6}>
            <Paper variant="outlined">
              <GridList
                css={css`
                  && {
                    background-color: #716e6e;
                  }
                `}
                style={{ padding: "40px" }}
              >
                <GridListTile
                  key="Subheader"
                  cols={2}
                  style={{ height: "auto" }}
                >
                  <Grid item>
                    <Typography
                      variant="h6"
                      align={"center"}
                      gutterBottom
                      css={css`
                        && {
                          font-size: 20px;
                          font-weight: 800;
                          color: #f7f7f7;
                        }
                      `}
                    >
                      Use o QR Code para pagar
                    </Typography>
                  </Grid>

                  <Grid item style={{ marginTop: "10px" }}>
                    <Typography
                      css={css`
                        && {
                          font-weight: 500;
                          color: #f7f7f7;
                        }
                      `}
                      variant="body1"
                      align={"center"}
                      gutterBottom
                    >
                      Abra o app em que vai fazer o pagamento, escaneie a imagem
                      ou cole o código do QR Code
                    </Typography>
                  </Grid>

                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    direction="row"
                    style={{ marginTop: "10px" }}
                  >
                    <Paper style={{ padding: "10px" }}>
                      <QRCode
                        value={transactionInfo?.qrcode.value}
                        size={145}
                        renderAs={"canvas"}
                        level={"H"}
                      />
                    </Paper>
                  </Grid>

                  <Grid item style={{ marginTop: "10px" }}>
                    <Typography
                      variant="h6"
                      align={"center"}
                      gutterBottom
                      css={css`
                        && {
                          font-weight: 500;
                          color: #f7f7f7;
                        }
                      `}
                    >
                      {formatterToCurrency(
                        transactionInfo.transaction_amount || 0,
                      )}
                    </Typography>
                  </Grid>

                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    direction="row"
                    style={{ padding: "20px" }}
                  >
                    <Grid item>
                      {transactionInfo.qrcode?.deep_link && (
                        <Button
                          fullWidth
                          variant="contained"
                          color="default"
                          css={css`
                            && {
                              border-radius: 10px;
                            }
                          `}
                          onClick={() => {
                            window.open(
                              transactionInfo.qrcode?.deep_link,
                              "_blank",
                            );
                          }}
                        >
                          Abrir app {transactionInfo.application.name}
                          <OpenInNewIcon style={{ marginLeft: "10px" }} />
                        </Button>
                      )}
                      {!transactionInfo.qrcode?.deep_link && (
                        <Button
                          fullWidth
                          variant="contained"
                          color="default"
                          css={css`
                            && {
                              border-radius: 10px;
                              font-weight: 500;
                            }
                          `}
                          onClick={() => {
                            navigator.clipboard.writeText(
                              transactionInfo?.qrcode.value,
                            );
                            setDisplayCopySuccessSnack(true);
                          }}
                        >
                          Copiar código do QR Code
                          <FileCopyOutlinedIcon
                            style={{ marginLeft: "10px" }}
                          />
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </GridListTile>
              </GridList>
              <Paper
                variant="outlined"
                square
                style={{ padding: "5px", marginTop: "10px" }}
              >
                <GridList style={{ padding: "20px" }}>
                  <GridListTile
                    key="Subheader"
                    cols={2}
                    style={{ height: "auto" }}
                  >
                    <Grid item>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        display="block"
                      >
                        <strong>Nome:</strong> {transactionInfo.merchant?.name}
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        display="block"
                      >
                        <strong>CNPJ:</strong>{" "}
                        {formatDocumentNumber(
                          transactionInfo.merchant?.document_number,
                        )}
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        display="block"
                      >
                        <strong>Pagar com:</strong>{" "}
                        {transactionInfo.application?.name_external}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        display="block"
                      >
                        <strong>Identificador:</strong> {reference_id}
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        display="block"
                      >
                        <strong>Pedido:</strong>{" "}
                        {transactionInfo.details?.reference?.numero_pedido}
                      </Typography>
                    </Grid>

                    <Grid item style={{ marginTop: "10px" }}>
                      <Typography variant="body2" color="error">
                        <strong>ATENÇÂO:</strong>{" "}
                        <Typography variant="body2" color="textSecondary">
                          Este link de pagamento tem validade de 30 minutos.
                        </Typography>
                      </Typography>
                    </Grid>

                    <Grid
                      container
                      justify="center"
                      style={{ cursor: "pointer", marginTop: "20px" }}
                      onClick={() => {
                        window.open("https://qrway.com.br/", "_blank");
                      }}
                    >
                      <Grid item xs="auto">
                        <Logo
                          css={css`
                            && {
                              max-width: 60px;
                            }
                          `}
                        />
                      </Grid>
                    </Grid>
                  </GridListTile>
                </GridList>
              </Paper>
            </Paper>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default Screen;
