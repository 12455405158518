import {
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  ThemeProvider,
} from "@material-ui/core";
import {
  CustomMuiTheme,
  MuiDefaultOptions,
  useTableCustomStyles,
} from "app/components/Grid/MuiTable";
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React, { Fragment, useEffect, useState } from "react";
import { css, jsx } from "@emotion/core";

import MUIDataTable from "mui-datatables";
import MomentUtils from "@date-io/moment";
import SearchIcon from "@material-ui/icons/Search";
import { Skeleton } from "@material-ui/lab";
import { formatterToBrlCurrency } from "shared/utils/formatter.util";
import moment from "moment";

/** @jsx jsx */

const TransactionsPaymentLink: React.FC<any> = ({
  getTransactions,
  lastTransactions,
  loading,
  expand,
  merchantParams,
  availableMerchants,
  activeMerchant,
}) => {
  const classes = useTableCustomStyles();

  const [selectedDateBegin, setSelectedDateBegin] = useState(
    new Date(new Date().setHours(0, 0, 0)),
  );
  const [selectedDateEnd, setSelectedDateEnd] = useState(
    new Date(new Date().setHours(23, 59, 59)),
  );
  const [filterList, setFilterList] = useState<any>([]);
  const [selectedMerchants, setSelectedMerchants] = useState<any>([]);

  const handleDateBeginChange = (date: any) => {
    if (!date) setSelectedDateBegin(new Date(new Date().setHours(0, 0, 0)));
    else setSelectedDateBegin(date);
  };

  const handleDateEndChange = (date: any) => {
    if (!date) setSelectedDateEnd(new Date(new Date().setHours(23, 59, 59)));
    else setSelectedDateEnd(date);
  };

  const handleMerchantChange = (event: React.ChangeEvent<{ value: any }>) => {
    const value = event.target.value;
    if (value) setSelectedMerchants(value);
  };

  const isValid = () => {
    return (
      moment(selectedDateBegin).isValid() && moment(selectedDateEnd).isValid()
    );
  };

  const handleGetTransactions = () => {
    getTransactions({
      dateBegin: selectedDateBegin,
      dateEnd: selectedDateEnd,
      grouped: false,
      offset: 0,
      limit: 2000,
      onlyConfirmed: false,
      merchant_ids: selectedMerchants.map((it: any) => it.id),
    });
  };

  useEffect(() => {
    handleGetTransactions();
  }, []);

  useEffect(() => {
    if (activeMerchant)
      setSelectedMerchants(
        availableMerchants.filter((m: any) => m.id === activeMerchant.id),
      );
  }, [activeMerchant]);

  return (
    <Grid item xs={12} sm={12} lg={12}>
      {loading ? (
        <Grid item>
          <Skeleton variant="rect" height={30} />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
        </Grid>
      ) : (
        <Grid item xs={12} sm={12} lg={12}>
          {!expand ? (
            <ThemeProvider theme={CustomMuiTheme}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <Grid container justify="space-evenly">
                  <Grid item>
                    <KeyboardDatePicker
                      size={"small"}
                      label="Data início"
                      cancelLabel="Cancelar"
                      value={selectedDateBegin}
                      format="DD/MM/YYYY"
                      onChange={handleDateBeginChange}
                      maxDate={selectedDateEnd}
                      maxDateMessage="Data deve ser menor ou igual a data final"
                      invalidDateMessage="Data inválida"
                      css={{
                        maxWidth: 150,
                      }}
                      disableFuture
                    />
                    <KeyboardTimePicker
                      size={"small"}
                      label="Hora"
                      ampm={false}
                      cancelLabel="Cancelar"
                      value={selectedDateBegin}
                      format="HH:mm:ss"
                      onChange={handleDateBeginChange}
                      views={["hours", "minutes", "seconds"]}
                      invalidDateMessage="Horário inválido"
                      invalidLabel="Horário inválido"
                      css={{
                        maxWidth: 150,
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <KeyboardDatePicker
                      size={"small"}
                      label="Data final"
                      cancelLabel="Cancelar"
                      value={selectedDateEnd}
                      format="DD/MM/YYYY"
                      onChange={handleDateEndChange}
                      minDate={selectedDateBegin}
                      minDateMessage="Data deve ser maior ou igual a data inicial"
                      invalidDateMessage="Data inválida"
                      disableFuture
                      css={{
                        maxWidth: 150,
                      }}
                    />
                    <KeyboardTimePicker
                      size={"small"}
                      label="Hora"
                      ampm={false}
                      cancelLabel="Cancelar"
                      value={selectedDateEnd}
                      format="HH:mm:ss"
                      onChange={handleDateEndChange}
                      views={["hours", "minutes", "seconds"]}
                      maxDateMessage="Horário deve ser menor ou igual a data final"
                      invalidDateMessage="Horário inválido"
                      css={{
                        maxWidth: 150,
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <FormControl>
                      <InputLabel>Estabelecimento</InputLabel>
                      <Select
                        onChange={handleMerchantChange}
                        value={selectedMerchants}
                        renderValue={(selected: any) =>
                          selected.map((it: any) => it.name).join(", ")
                        }
                        autoWidth
                        multiple
                        css={{
                          minWidth: 150,
                          maxWidth: 350,
                        }}
                      >
                        {availableMerchants.map((row: any, index: any) => (
                          <MenuItem key={row.id} value={row}>
                            <Checkbox
                              checked={
                                selectedMerchants.filter(
                                  (it: any) => it.name === row.name,
                                )?.length > 0
                              }
                            />
                            {row.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={() => {
                        handleGetTransactions();
                      }}
                      fullWidth
                      color="secondary"
                      startIcon={<SearchIcon />}
                      disabled={!isValid()}
                      css={css`
                        && {
                          margin-top: 10px;
                          font-weight: bold;
                        }
                      `}
                    >
                      PESQUISAR
                    </Button>
                  </Grid>
                </Grid>
              </MuiPickersUtilsProvider>

              <MUIDataTable
                title={<Grid className={classes.tableTitle}>Transações</Grid>}
                data={lastTransactions}
                css={css`
                  && {
                    margin-top: 15px;
                  }
                `}
                columns={[
                  {
                    name: "merchant",
                    label: "Estabelecimento",
                    options: {
                      filter: true,
                      sort: true,
                      filterList: filterList[0] ? filterList[0] : undefined,
                      customBodyRender: (value, tableMeta, updateValue) => {
                        const document_number = (
                          value?.document_number || ""
                        ).replace(
                          /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                          "$1.$2.$3/$4-$5",
                        );
                        if (value && value.trading_name)
                          return `${document_number} - ${value?.trading_name}`;
                        return "";
                      },
                    },
                  },
                  {
                    name: "transaction_date",
                    label: "Data",
                    options: {
                      filter: true,
                      sort: true,
                      filterList: filterList[1] ? filterList[1] : undefined,
                      customBodyRender: (value, tableMeta, updateValue) => {
                        return moment(value).format("DD/MM/YYYY");
                      },
                    },
                  },
                  {
                    name: "transaction_date",
                    label: "Hora",
                    options: {
                      filter: true,
                      sort: true,
                      filterList: filterList[2] ? filterList[2] : undefined,
                      customBodyRender: (value, tableMeta, updateValue) => {
                        return moment(value).format("HH:mm:ss");
                      },
                    },
                  },
                  {
                    name: "application.name",
                    label: "Aplicativo",
                    options: {
                      filter: true,
                      sort: true,
                      filterList: filterList[3] ? filterList[3] : undefined,
                      customBodyRender: (value, tableMeta, updateValue) => {
                        return value;
                      },
                    },
                  },
                  {
                    name: "details_metadata.reference.numero_pedido",
                    label: "Num. Pedido",
                    options: {
                      filter: true,
                      sort: true,
                      filterList: filterList[4] ? filterList[4] : undefined,
                    },
                  },
                  {
                    name: "details_metadata.reference.contato",
                    label: "Contato",
                    options: {
                      filter: true,
                      sort: true,
                      filterList: filterList[5] ? filterList[5] : undefined,
                      customBodyRender: (value, tableMeta, updateValue) => {
                        const valueForm = `${value || ""}`.replace("55", "");
                        return valueForm;
                      },
                    },
                  },
                  {
                    name: "details_metadata.attendant",
                    label: "Atendente",
                    options: {
                      filter: true,
                      sort: true,
                      filterList: filterList[6] ? filterList[6] : undefined,
                      customBodyRender: (value, tableMeta, updateValue) => {
                        if (value && value.code)
                          return `${value?.code} ${value?.name}`;
                        return "";
                      },
                    },
                  },
                  {
                    name: "transaction_amount",
                    label: "Valor",
                    options: {
                      filter: true,
                      sort: true,
                      filterList: filterList[7] ? filterList[7] : undefined,
                      customBodyRender: (value, tableMeta, updateValue) => {
                        return formatterToBrlCurrency(value);
                      },
                    },
                  },
                  {
                    name: "status",
                    label: "Status",
                    options: {
                      filter: true,
                      sort: true,
                      filterList: filterList[8] ? filterList[8] : undefined,
                      customBodyRender: (value, tableMeta, updateValue) => {
                        switch (value) {
                          case "PENDING":
                            const row = lastTransactions[tableMeta.rowIndex];
                            const dateTran = moment(
                              moment(row.transaction_date).add(30, "minutes"),
                            );
                            const expired = dateTran.isBefore(moment());
                            if (expired) return "Expirado";
                            return "Não Confirmado";
                          case "PAID":
                            return "Confirmado";
                          case "REVERSAL":
                            return "Estornado";
                          default:
                            return "Não Confirmado";
                        }
                      },
                    },
                  },
                ]}
                options={{
                  ...MuiDefaultOptions,
                  onFilterChange: (
                    changedColumn: any,
                    filterList: any,
                    type: any,
                  ) => {
                    setFilterList(filterList);

                    const hasField = (index: any, elementValue: any) => {
                      if (filterList[index].length === 0) return true;
                      return filterList[index].includes(elementValue);
                    };

                    const filtered: any = [];
                    lastTransactions.forEach((element: any) => {
                      let elementValid = false;
                      const { application, details_metadata } = element;
                      const attendantTag = details_metadata?.attendant?.code
                        ? `${details_metadata?.attendant?.code} ${details_metadata?.attendant?.name}`
                        : "";
                      elementValid =
                        hasField(
                          1,
                          moment(element["transaction_date"]).format(
                            "DD/MM/YYYY",
                          ),
                        ) &&
                        hasField(
                          2,
                          moment(element["transaction_date"]).format(
                            "HH:mm:ss",
                          ),
                        ) &&
                        hasField(3, application.name) &&
                        hasField(
                          4,
                          details_metadata?.reference?.numero_pedido,
                        ) &&
                        hasField(5, details_metadata?.reference?.contato) &&
                        hasField(6, attendantTag) &&
                        hasField(
                          7,
                          formatterToBrlCurrency(element["transaction_amount"]),
                        ) &&
                        hasField(8, element["status"]);

                      if (elementValid) filtered.push(element);
                    });
                  },
                  downloadOptions: {
                    filename: "transacoes_pagamento_link.csv",
                    separator: ";",
                    filterOptions: {
                      useDisplayedColumnsOnly: true,
                      useDisplayedRowsOnly: true,
                    },
                  },
                }}
              />
            </ThemeProvider>
          ) : (
            <Grid />
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default TransactionsPaymentLink;
