import * as Yup from "yup";

import validateCNPJ from "shared/utils/validateCNPJ.util";
import validateCPF from "shared/utils/validateCPF.util";

const user = Yup.object().shape({
  email: Yup.string()
    .required("Campo obrigatório")
    .email("O campo necessita de um email válido"),
  document_number: Yup.string()
    .when("email", (email: string, schema: any) => {
      return schema.test(
        "document_number",
        "Você deve digitar um CPF válido",
        (value: any) => {
          const documentLength = !!value && String(value).length;
          if (!documentLength) {
            return false;
          }
          if (documentLength < 11 || !validateCPF(value)) {
            return false;
          }
          return true;
        },
      );
    })
    .required("Campo obrigatório"),
  phone_prefix: Yup.number()
    .nullable()
    .required("Campo obrigatório"),
  phone_number: Yup.string().required("Campo obrigatório"),
  password: Yup.string().required("Campo obrigatório"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), null], "As senhas não conferem")
    .required("Campo obrigatório"),
});

const address = Yup.object().shape({
  zip_code: Yup.string().required("Campo obrigatório"),
  street: Yup.string().required("Campo obrigatório"),
  number: Yup.number()
    .nullable()
    .typeError("Campo deve ser númerico")
    .required("Campo obrigatório"),
  complement: Yup.string().notRequired(),
  neighborhood: Yup.string().required("Campo obrigatório"),
  city: Yup.string().required("Campo obrigatório"),
  state: Yup.string().required("Campo obrigatório"),
  receipt_path: Yup.string().notRequired(),
});

const bank_account = Yup.object().shape({
  bank_number: Yup.string().required("Campo obrigatório"),
  branch_number: Yup.string().required("Campo obrigatório"),
  account_number: Yup.string().required("Campo obrigatório"),
  account_digit: Yup.string().required("Campo obrigatório"),
});

const document_number = Yup.string().when(
  "type",
  (type: string, schema: any) => {
    const document = type === "PJ" ? "CNPJ" : "CPF";

    return schema.test(
      "document_number",
      `Você deve digitar um ${document} válido`,
      (value: string) => {
        const documentLength = !!value && String(value).length;

        if (!documentLength) {
          return false;
        }

        if (document === "CNPJ") {
          if (documentLength < 14 || !validateCNPJ(value)) {
            return false;
          }
        }

        if (document === "CPF") {
          if (documentLength < 11 || !validateCPF(value)) {
            return false;
          }
        }

        return true;
      },
    );
  },
);

const merchant = Yup.object().shape({
  id: Yup.string().notRequired(),
  contact_name: Yup.string().required("Campo obrigatório"),
  document_number,
  phone_prefix: Yup.number()
    .nullable()
    .required("Campo obrigatório"),
  phone_number: Yup.string().required("Campo obrigatório"),
  cnae: Yup.string().when("type", (type: string, schema: any) =>
    type === "PJ" ? schema.required("Campo obrigatório") : schema.notRequired(),
  ),
  mcc: Yup.string().notRequired(),
  constitution_date: Yup.string().notRequired(),
  type: Yup.string().required("Campo obrigatório"),
  accept_terms: Yup.bool().oneOf([true], "Você precisa aceitar os termos"),
  address,
  bank_account,
  trading_name: Yup.string().when("type", (type: string, schema: any) =>
    type === "PJ" ? schema.required("Campo obrigatório") : schema.notRequired(),
  ),
  company_name: Yup.string().when("type", (type: string, schema: any) =>
    type === "PJ" ? schema.required("Campo obrigatório") : schema.notRequired(),
  ),
});

export default Yup.object().shape({
  user,
  merchant,
});
