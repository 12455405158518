import { applyMiddleware, combineReducers, compose, createStore } from "redux";

import { Types as AuthTypes } from "shared/ducks/authentication.duck";
import appDucks from "app/ducks";
import errorMiddleware from "shared/middlewares/error.middleware";
import publicDucks from "public/ducks";
import shared from "shared/ducks";
import thunkMiddleware from "redux-thunk";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const appReducer = combineReducers({
  app: appDucks,
  public: publicDucks,
  shared
});

const rootReducer = (state: any, action: any) => {
  if (action.type === AuthTypes.LOGOFF) {
    state = undefined;
  }

  return appReducer(state, action);
};

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunkMiddleware, errorMiddleware))
);

export default store;
