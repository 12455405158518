/** @jsx jsx */

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Snackbar,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import MainSchema from "./LoginTerminal.schema";
import { css, jsx } from "@emotion/core";

import Alert from "@material-ui/lab/Alert";
import Logo from "shared/components/Logo";
import Slide from "@material-ui/core/Slide";
import { TextField } from "formik-material-ui";
import { TransitionProps } from "@material-ui/core/transitions";
import useQuery from "shared/hooks/useQuery.hook";

function TransitionDown(props: TransitionProps) {
  return <Slide {...props} direction="down" />;
}

const LoginTerminal: React.FC<any> = ({ handleLogin, authentication }) => {
  const [hasError, setHasError] = useState(false);
  const [loadingAuth, setLoadingAuth] = useState(true);
  const query = useQuery();
  const fabricante = query.get("fabricante");
  const modelo = query.get("modelo");
  const numero = query.get("numero");

  const { loading, error } = authentication || {};
  const { message } = error || {};

  useEffect(() => {
    if (message) {
      setHasError(true);
      setLoadingAuth(false);
    }
  }, [message]);

  useEffect(() => {
    handleLogin({ fabricante, modelo, numero });
  }, []);

  return (
    <Fragment>
      <Formik
        initialValues={{
          fabricante,
          modelo,
          numero,
        }}
        onSubmit={handleLogin}
        validationSchema={MainSchema}
      >
        {({ submitForm, isSubmitting, errors, dirty }) => (
          <Form>
            <Grid
              container
              direction="column"
              alignItems="center"
              css={css`
                && {
                  min-height: 100vh;
                }
              `}
            >
              <Grid item md sm={12}>
                <Grid container justify="center" style={{ cursor: "pointer" }}>
                  <Grid item xs="auto">
                    <Logo
                      css={css`
                        && {
                          max-width: 200px;
                        }
                      `}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  css={css`
                    && {
                      margin-top: 30px;
                    }
                  `}
                >
                  <Grid item xs>
                    <TextField
                      disabled={true}
                      name="fabricante"
                      label="Fabricante"
                      variant="outlined"
                      fullWidth
                      autoComplete="nope"
                    />
                    <TextField
                      disabled={true}
                      name="modelo"
                      label="Modelo"
                      variant="outlined"
                      fullWidth
                      autoComplete="nope"
                      css={css`
                        && {
                          margin-top: 10px;
                        }
                      `}
                    />
                    <TextField
                      disabled={true}
                      name="numero"
                      label="Número Terminal"
                      variant="outlined"
                      fullWidth
                      autoComplete="nope"
                      css={css`
                        && {
                          margin-top: 10px;
                        }
                      `}
                    />
                    <div
                      css={css`
                        && {
                          margin: 4px;
                          position: relative;
                        }
                      `}
                    >
                      {hasError && (
                        <Grid item xs={12} sm={12}>
                          <Snackbar
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "center",
                            }}
                            TransitionComponent={TransitionDown}
                            open={true}
                            autoHideDuration={3000}
                            onClose={() => setHasError(false)}
                          >
                            <Alert
                              onClose={() => setHasError(false)}
                              severity="error"
                            >
                              {message}
                            </Alert>
                          </Snackbar>
                        </Grid>
                      )}
                      {loadingAuth && (
                        <Box
                          textAlign="center"
                          fontSize="13px"
                          fontWeight="bold"
                          padding="5px"
                        >
                          {"Processando autorização"}
                          <CircularProgress size={30} color="secondary" />
                        </Box>
                      )}
                      <Button
                        onClick={submitForm}
                        disabled={
                          isSubmitting ||
                          Object.keys(errors).length > 0 ||
                          loadingAuth
                        }
                        fullWidth
                        variant="contained"
                        color="secondary"
                        css={css`
                          && {
                            margin-top: 20px;
                            border-radius: 15px;
                          }
                        `}
                      >
                        Habilitar
                        {(loading || loadingAuth) && (
                          <CircularProgress
                            size={24}
                            color="secondary"
                            css={css`
                              && {
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                margin-top: -12px;
                                margin-left: -12px;
                              }
                            `}
                          />
                        )}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
};

export default LoginTerminal;
