import { API_URL_WITH_PORT, PORTS } from "shared/configs/api.config";

const Client = require("@hapi/nes/lib/client").Client;

const url = `${API_URL_WITH_PORT(PORTS.WEBSOCKET)}`;
const client: any = new Client(url);
let connectAttempts = 0;
export const start = async () => {
  try {
    await client.connect();
    console.log("Websocket conected:", client.id);
  } catch (error) {
    console.log("Websocket connection failed:", error);
  }
};
export const subscribeToChannel = async ({
  channel,
  handler,
}: {
  channel: string;
  handler?: Function;
}) => {
  console.log("subscribeToChannel", channel, client?.id);
  const defHandler = (update: any, flags: any) => {
    console.log("Empty handler", update, flags);
  };
  if (!client?.id) {
    if (connectAttempts < 5) {
      connectAttempts++;
      setTimeout(async () => {
        await start().then(() => subscribeToChannel({ channel, handler }));
      }, 1000);
    }
  } else {
    client.subscribe(channel, handler || defHandler);
  }
};
