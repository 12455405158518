import { API_URL_WITH_PORT, PORTS } from "shared/configs/api.config";

import { Dispatch } from "redux";
import fetch from "shared/utils/fetch.util";
import { formType } from "public/ducks/createAccount/createAccount.type";
import { stateReduxUsers } from "../screens/Merchant/Merchant.type";

type Actions = {
  type: string;
  payload?: object;
  error?: object;
};

export const Types = {
  INIT: "app/merchant/INIT",
  SUCCESS: "app/merchant/SUCCESS",
  ERROR: "app/merchant/ERROR",

  INIT_ONBOARDING: "app/merchant/INIT_ONBOARDING",
  SUCCESS_ONBOARDING: "app/merchant/SUCCESS_ONBOARDING",
  ERROR_ONBOARDING: "app/merchant/ERROR_ONBOARDING",

  INIT_ONBOARDING_REGISTRATION: "app/merchant/INIT_ONBOARDING_REGISTRATION",
  SUCCESS_ONBOARDING_REGISTRATION:
    "app/merchant/SUCCESS_ONBOARDING_REGISTRATION",
  ERROR_ONBOARDING_REGISTRATION: "app/merchant/ERROR_ONBOARDING_REGISTRATION",

  INIT_ONBOARDING_ISSUER_ACTION: "app/merchant/INIT_ONBOARDING_ISSUER_ACTION",
  SUCCESS_ONBOARDING_ISSUER_ACTION:
    "app/merchant/SUCCESS_ONBOARDING_ISSUER_ACTION",
  ERROR_ONBOARDING_ISSUER_ACTION: "app/merchant/ERROR_ONBOARDING_ISSUER_ACTION",

  INIT_TERM_FILE: "app/merchant/INIT_TERM_FILE",
  SUCCESS_TERM_FILE: "app/merchant/SUCCESS_TERM_FILE",
  ERROR_TERM_FILE: "app/merchant/ERROR_TERM_FILE",
};

const initialState: stateReduxUsers = {
  merchant: null,
  fileTermb64: null,
  loading: false,
  error: null,
};

export default (state = initialState, action: Actions) => {
  const { type, payload, error } = action;

  const reducers = {
    [Types.INIT]: {
      ...state,
      merchant: null,
      error: null,
      loading: true,
    },
    [Types.ERROR]: {
      ...state,
      loading: false,
      error,
    },
    [Types.SUCCESS]: {
      ...state,
      loading: false,
      merchant: payload,
      error: false,
    },

    [Types.INIT_ONBOARDING]: {
      ...state,
      error: null,
      loading: true,
    },
    [Types.ERROR_ONBOARDING]: {
      ...state,
      loading: false,
      error,
    },
    [Types.SUCCESS_ONBOARDING]: {
      ...state,
      loading: false,
      error: false,
    },

    [Types.INIT_ONBOARDING_REGISTRATION]: {
      ...state,
      error: null,
      loading: true,
    },
    [Types.ERROR_ONBOARDING_REGISTRATION]: {
      ...state,
      loading: false,
      error,
    },
    [Types.SUCCESS_ONBOARDING_REGISTRATION]: {
      ...state,
      loading: false,
      error: false,
    },

    [Types.INIT_ONBOARDING_ISSUER_ACTION]: {
      ...state,
      error: null,
      loading: true,
    },
    [Types.ERROR_ONBOARDING_ISSUER_ACTION]: {
      ...state,
      loading: false,
      error,
    },
    [Types.SUCCESS_ONBOARDING_ISSUER_ACTION]: {
      ...state,
      loading: false,
      error: false,
    },

    [Types.INIT_TERM_FILE]: {
      ...state,
      error: null,
      loading: true,
    },
    [Types.ERROR_TERM_FILE]: {
      ...state,
      loading: false,
      error,
    },
    [Types.SUCCESS_TERM_FILE]: {
      ...state,
      fileTermb64: payload,
      loading: false,
      error: false,
    },
  };

  return reducers[type] || state;
};

export const getMerchantById = (id: string) => async (
  dispatch: Dispatch<any>,
) => {
  try {
    dispatch({
      type: Types.INIT,
    });

    const response = await fetch(
      `${API_URL_WITH_PORT(PORTS.LOGIN)}/accounts/${id}`,
      {
        customUrl: true,
        method: "GET",
        auth: true,
      },
    );

    const result = await response.json();
    if (!response.ok) {
      throw result;
    }

    dispatch({
      type: Types.SUCCESS,
      payload: parserMerchantToState(result),
    });

    return result;
  } catch (e) {
    const error = {
      visible: true,
      ...e,
    };
    dispatch({
      type: Types.ERROR,
      error,
    });
    return error;
  }
};

const parser = (data: formType): any => {
  delete data.merchant.accept_terms;
  delete data.merchant.document_number;
  delete data.merchant.cnae;
  delete data.merchant.mcc;
  delete data.merchant.constitution_date;
  delete data.merchant.type;

  return {
    merchant: {
      ...data.merchant,
      phone_prefix: Number(data.merchant.phone_prefix),
      address: {
        ...data.merchant.address,
        number: Number(data.merchant.address.number),
      },
    },
  };
};

export const handleUpdateUser = (data: any) => async (
  dispatch: Dispatch<any>,
) => {
  try {
    dispatch({
      type: Types.INIT,
    });

    const response = await fetch(
      `${API_URL_WITH_PORT(PORTS.LOGIN)}/accounts/${data.id}`,
      {
        customUrl: true,
        method: "PATCH",
        auth: true,
        body: JSON.stringify({
          ...parser(data),
        }),
      },
    );

    const result = await response.json();
    if (!response.ok) {
      throw result;
    }

    dispatch({
      type: Types.SUCCESS,
      payload: data,
    });

    return result;
  } catch (e) {
    const error = {
      visible: true,
      ...e,
    };
    dispatch({
      type: Types.ERROR,
      error,
    });
    return error;
  }
};

const parserMerchantToState = (merchant: any) => {
  const bank_account = merchant.merchant_bank_accounts.pop();
  const merchant_user = merchant.merchant_user.pop();
  const merchant_partner = merchant.merchant_partner.pop();
  const { user } = merchant_user;

  return {
    id: merchant.id,
    user: {
      name: user?.name,
      email: user.email,
      document_number: user.document_number,
      phone_prefix: user.phone_prefix,
      phone_number: user.phone_number,
    },
    partner: merchant_partner,
    merchant: {
      contact_name: user.name,
      document_number: merchant.document_number,
      phone_prefix: merchant.phone_prefix,
      phone_number: merchant.phone_number,
      cnae: merchant.cnae,
      mcc: merchant.document_number,
      constitution_date: merchant.constitution_date || new Date(),
      constitution_type: merchant.constitution_type,
      month_revenue: merchant.month_revenue,
      patrimony: merchant.patrimony,
      number_of_employees: merchant.number_of_employees,
      type: merchant.type,
      accept_terms: merchant.document_number,
      address: {
        zip_code: merchant.address_zip_code,
        street: merchant.address_street,
        number: merchant.address_number,
        complement: merchant.address_complement,
        neighborhood: merchant.address_neighborhood,
        city: merchant.address_city,
        state: merchant.address_state,
        receipt_path: merchant.address_receipt_path || "",
      },
      bank_account: {
        bank_number: bank_account.bank_number,
        branch_number: bank_account.branch_number,
        account_number: bank_account.account_number,
        account_digit: bank_account.account_digit,
      },
      trading_name: merchant.trading_name,
      company_name: merchant.company_name,
    },
  };
};

export const handleMerchantUpdateComplement = (data: {
  id: string;
  appid: string;
  [key: string]: any;
}) => async (dispatch: Dispatch<any>) => {
  try {
    dispatch({
      type: Types.INIT_ONBOARDING,
    });

    const { merchant } = data.merchant;
    const {
      address,
      trading_name,
      company_name,
      document_number,
      type,
      phone_prefix,
      phone_number,
      mcc,
      cnae,
    } = merchant;

    const response = await fetch(
      `${API_URL_WITH_PORT(PORTS.MERCHANT)}/merchants/${data.id}`,
      {
        customUrl: true,
        method: "PATCH",
        auth: true,
        body: JSON.stringify({
          merchant: {
            address,
            trading_name,
            company_name,
            document_number,
            type,
            phone_prefix,
            phone_number,
            mcc,
            cnae,
            organization_id: data.organization_id,
            origin: "autoonboarding",
            month_revenue: Number(data.month_revenue) / 100,
            patrimony: Number(data.patrimony) / 100,
            number_of_employees: data.number_of_employees,
            constitution_type: data.constitution_type,
            partners: [
              {
                name: data.fullname,
                document_number: data.document_legal,
                document_type: "PF",
                birth_date: data.birth_date,
                mother_name: data.mother_name,
                phone_prefix: data.phone_prefix,
                phone_number: data.phone_number,
                email: data.email,
                capital_total: data.capital_total,
                attribute: data.attribute,
              },
            ],
            bank_account: {
              bank_number: data.bank_number,
              branch_number: data.bank_agency,
              account_number: data.bank_account_number,
              account_digit: data.bank_account_number_digit,
            },
          },
        }),
      },
    );

    const result = await response.json();
    if (!response.ok) {
      return { error: result };
    }

    dispatch({
      type: Types.SUCCESS_ONBOARDING,
      payload: result,
    });

    return result;
  } catch (e) {
    const error = {
      visible: true,
      error: true,
      ...e,
    };
    dispatch({
      type: Types.ERROR_ONBOARDING,
      error,
    });
    return error;
  }
};

export const handleMerchantActionTermFile = (data: {
  id: string;
  appid: string;
  [key: string]: any;
}) => async (dispatch: Dispatch<any>) => {
  try {
    dispatch({
      type: Types.INIT_TERM_FILE,
    });

    const response = await fetch(
      `${API_URL_WITH_PORT(PORTS.MERCHANT)}/merchants/${
        data.id
      }/issuer-action/${data.appid}`,
      {
        customUrl: true,
        method: "POST",
        auth: true,
        body: JSON.stringify({
          type: "generatepreterm",
          data: "nope",
        }),
      },
    );

    const result = await response.json();
    if (!response.ok) {
      return { error: result };
    }

    dispatch({
      type: Types.SUCCESS_TERM_FILE,
      payload: result.finalPdf,
    });

    return result;
  } catch (e) {
    const error = {
      visible: true,
      error: true,
      ...e,
    };
    dispatch({
      type: Types.ERROR_TERM_FILE,
      error,
    });
    return error;
  }
};

export const handleMerchantRegistration = (data: {
  id: string;
  appid: string;
}) => async (dispatch: Dispatch<any>) => {
  try {
    dispatch({
      type: Types.INIT_ONBOARDING_REGISTRATION,
    });

    const response = await fetch(
      `${API_URL_WITH_PORT(PORTS.MERCHANT)}/merchants/${
        data.id
      }/registrations/${data.appid}`,
      {
        customUrl: true,
        method: "POST",
        auth: true,
        body: JSON.stringify({}),
      },
    );

    const result = await response.json();
    if (!response.ok) {
      throw result;
    }

    dispatch({
      type: Types.SUCCESS_ONBOARDING_REGISTRATION,
      payload: result,
    });

    return result;
  } catch (e) {
    const error = {
      error: true,
      visible: true,
      ...e,
    };
    dispatch({
      type: Types.ERROR_ONBOARDING_REGISTRATION,
      error,
    });
    return error;
  }
};

export const handleMerchantIssuerAction = (data: {
  id: string;
  appid: string;
  type?: string;
  tokenCode: string;
}) => async (dispatch: Dispatch<any>) => {
  try {
    dispatch({
      type: Types.INIT_ONBOARDING_ISSUER_ACTION,
    });

    const response = await fetch(
      `${API_URL_WITH_PORT(PORTS.MERCHANT)}/merchants/${
        data.id
      }/issuer-action/${data.appid}`,
      {
        customUrl: true,
        method: "POST",
        auth: true,
        body: JSON.stringify({
          type: data?.type || "verify", //only exists verify for now
          data: { tokenCode: data.tokenCode },
        }),
      },
    );

    const result = await response.json();
    if (!response.ok) {
      return { error: result };
    }

    dispatch({
      type: Types.SUCCESS_ONBOARDING_ISSUER_ACTION,
      payload: result,
    });

    return result;
  } catch (e) {
    const error = {
      visible: true,
      error: true,
      ...e,
    };
    dispatch({
      type: Types.ERROR_ONBOARDING_ISSUER_ACTION,
      error,
    });
    return error;
  }
};
