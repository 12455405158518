import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import React from "react";
import { Redirect } from "react-router-dom";
import Screen from "./ReportRefund.screen";
import { stateReduxType as appsStateReduxType } from "app/screens/Apps/Apps.type";
import { stateReduxType as authStateType } from "shared/types/authentication.type";
import { getApplications } from "app/ducks/applications.duck";
import { runConciliationActionReport } from "app/ducks/conciliations.duck";

const useTypedSelectorGeneric: TypedUseSelectorHook<any> = useSelector;
const useTypedSelector: TypedUseSelectorHook<authStateType> = useSelector;
const useTypedSelectorApps: TypedUseSelectorHook<appsStateReduxType> = useSelector;

const ApplicationsContainer: React.FC<any> = ({ history }) => {
  const dispatch = useDispatch();

  const { authentication } = useTypedSelector(state => state.shared) || {};
  const { signedIn, data: auth } = authentication;
  const { user } = auth;
  const { activeMerchant, merchants: availableMerchants } = user;

  const { conciliations: conciliationsState } =
    useTypedSelectorGeneric(state => state.app) || {};

  const { applications } = useTypedSelectorApps(state => state.app) || {};
  const { apps } = applications;

  if (!signedIn) {
    return <Redirect to="/login" />;
  }

  const handleDispatchGetConciliations = (params: any) =>
    dispatch(
      runConciliationActionReport({
        ...params,
      }),
    );

  const handleDispatchGetApplications = (params: any) =>
    dispatch(
      getApplications({
        ...params,
      }),
    );

  return (
    <Screen
      history={history}
      getConciliations={handleDispatchGetConciliations}
      conciliationsState={conciliationsState}
      getApps={handleDispatchGetApplications}
      applications={apps}
      activeMerchant={activeMerchant}
      availableMerchants={availableMerchants}
    />
  );
};

export default ApplicationsContainer;
