import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import {
  handleAddUser,
  handleGetUsers,
  handleUpdateUser,
} from "app/ducks/usersEc.duck";

import React from "react";
import Screen from "./Users.screen";
import { stateReduxType } from "./Users.type";
import { useSnackbar } from "notistack";

const useTypedSelectorApps: TypedUseSelectorHook<stateReduxType> = useSelector;

const Container: React.FC<any> = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { usersEc } = useTypedSelectorApps(state => state.app) || {};

  const handleUpdate = async (app: any) => {
    const response: any = await dispatch(handleUpdateUser(app));
    if (response.error) {
      return enqueueSnackbar(response.message, {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      });
    }

    return enqueueSnackbar("Usuário atualizado com sucesso", {
      autoHideDuration: 3000,
      variant: "success",
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
    });
  };
  const handleCreate = async (app: any) => {
    const response: any = await dispatch(handleAddUser(app));
    if (response.error) {
      return enqueueSnackbar(response.message, {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      });
    }

    return enqueueSnackbar("Usuário adicionado com sucesso", {
      autoHideDuration: 3000,
      variant: "success",
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
    });
  };
  return (
    <Screen
      handleGetUsers={handleGetUsers}
      handleUpdateUser={handleUpdate}
      handleAddUser={handleCreate}
      usersEc={usersEc}
    />
  );
};

export default Container;
