import { Button, Grid, InputAdornment, TextField } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { ToggleOff, ToggleOn } from "@material-ui/icons";
import { css, jsx } from "@emotion/core";

import NumberFormat from "react-number-format";
import { useDispatch } from "react-redux";
import validateCPF from "shared/utils/validateCPF.util";

/** @jsx jsx */

const UsePayExtraDriverInfo: React.FC<any> = ({
  enable,
  setEnable,
  loading,
  transactionDetailsItems,
  setTransactionDetailsItems,
  driverInfo,
  setDriverInfo,
}) => {
  const [errorFields, setErrorFields] = useState<any>();

  useEffect(() => {
    if (!driverInfo?.cpf || !validateCPF(driverInfo.cpf))
      setTransactionDetailsItems({
        ...transactionDetailsItems,
        driverInfo: {
          ...driverInfo,
          cpf: null,
        },
      });
    else setTransactionDetailsItems({ ...transactionDetailsItems, driverInfo });
    if (!driverInfo.cpf || !driverInfo.senha || !driverInfo.controle) return;
  }, [driverInfo]);

  const isPixTransaction = (driverInfo: any) => {
    const isPix =
      (driverInfo?.metodo_pagamento || "").toLowerCase().indexOf("pix") > 0;
    return isPix;
  };

  return (
    <Fragment>
      <Grid
        container
        justify="center"
        css={css`
          && {
            margin-top: 15px;
            margin-bottom: 10px;
          }
        `}
      >
        {!isPixTransaction(driverInfo) && (
          <Grid
            item
            xs={8}
            sm={7}
            lg={7}
            css={css`
              && {
                text-align: -webkit-center;
              }
            `}
          >
            <Button
              variant="contained"
              disabled={loading}
              color={enable ? "primary" : "default"}
              onClick={() => {
                setEnable(!enable);
                setTransactionDetailsItems({
                  ...transactionDetailsItems,
                  driverInfo: {
                    ...driverInfo,
                    cpf: null,
                    controle: null,
                    senha: null,
                  },
                });
                setDriverInfo({
                  ...driverInfo,
                  cpf: null,
                  controle: null,
                  senha: null,
                });
              }}
            >
              {enable ? <ToggleOn /> : <ToggleOff />}
              Motorista sem Celular
            </Button>
          </Grid>
        )}
      </Grid>

      {/* fields */}
      {enable && (
        <Fragment>
          <Grid
            container
            justify="center"
            css={css`
              && {
                margin-top: 24px;
              }
            `}
          >
            <Grid item xs={8} sm={6} lg={6}>
              <NumberFormat
                customInput={TextField}
                fullWidth
                required={true}
                type="tel"
                // format={decimalFormatter}
                isNumericString={true}
                fixedDecimalScale={true}
                thousandSeparator="."
                decimalSeparator=","
                allowNegative={false}
                disabled={loading}
                label={"Hodômetro"}
                variant="outlined"
                value={driverInfo?.controle}
                InputProps={{
                  inputProps: {
                    min: 1,
                    max: 999999999,
                  },
                }}
                onValueChange={(values: any) => {
                  const { value } = values;

                  setDriverInfo({
                    ...driverInfo,
                    controle: value,
                  });
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            justify="center"
            css={css`
              && {
                margin-top: 24px;
              }
            `}
          >
            <Grid item xs={8} sm={6} lg={6}>
              <NumberFormat
                customInput={TextField}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {driverInfo?.cpf && validateCPF(driverInfo?.cpf)
                        ? "ok"
                        : "x"}
                    </InputAdornment>
                  ),
                }}
                format={"###.###.###-##"}
                fullWidth
                required
                type="tel"
                autoComplete="nope"
                disabled={loading}
                label={"CPF"}
                variant="outlined"
                error={!!errorFields?.["CPF"]}
                helperText={errorFields?.["CPF"]}
                value={driverInfo?.cpf}
                onBlur={async ({
                  target: { value },
                }: React.ChangeEvent<HTMLInputElement>) => {}}
                onValueChange={(values: any) => {
                  const { value } = values;
                  const documentLength = !!value && String(value).length;

                  if (documentLength < 11 || !validateCPF(value)) {
                    setErrorFields({
                      ...errorFields,
                      CPF: "CPF inválido ou não informado",
                    });
                  } else {
                    const { CPF, ...restFields } = errorFields || {};
                    setErrorFields(restFields);
                  }
                  setDriverInfo({ ...driverInfo, cpf: value });
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            justify="center"
            css={css`
              && {
                margin-top: 24px;
              }
            `}
          >
            <Grid item xs={8} sm={6} lg={6}>
              <TextField
                fullWidth
                required
                label={"Senha"}
                variant="outlined"
                InputProps={{
                  inputProps: {
                    minLength: 6,
                    maxLength: 20,
                  },
                }}
                type="password"
                value={driverInfo?.senha}
                autoComplete="new-password"
                disabled={loading}
                onChange={({ target: { value } }: any) => {
                  setDriverInfo({
                    ...driverInfo,
                    senha: value,
                  });
                }}
              />
            </Grid>
          </Grid>
        </Fragment>
      )}
    </Fragment>
  );
};

export default UsePayExtraDriverInfo;
