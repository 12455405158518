/** @jsx jsx */

import {
  Button,
  CircularProgress,
  Grid,
  Link,
  Snackbar,
  Typography,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import Schema, { recoverPassword } from "./Login.schema";
import { css, jsx } from "@emotion/core";

import Alert from "@material-ui/lab/Alert";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Logo from "shared/components/Logo";
import Slide from "@material-ui/core/Slide";
import { TextField } from "formik-material-ui";
import { TransitionProps } from "@material-ui/core/transitions";
import { colors } from "shared/configs/styles.config";
import packageJson from "../../../../package.json";
import { screenPropsType } from "./Login.type";

const qs = require("query-string");

function TransitionDown(props: TransitionProps) {
  return <Slide {...props} direction="down" />;
}

const LoginScreen: React.FC<screenPropsType> = ({
  handleLogin,
  authentication,
  history,
  handleRecoverPassword,
}) => {
  const [modalRecoverPassword, setModalRecoverPassword] = useState(false);
  const [recoverPasswordSuccess, setRecoverPasswordSuccess] = useState(false);
  const [hasError, setHasError] = useState(false);

  const { loading, error } = authentication || {};
  const { message } = error || {};

  useEffect(() => {
    if (message) setHasError(true);
  }, [message]);

  let selectRef: any = React.createRef();
  const handleFocus = () => {
    selectRef.current.focus();
  };

  return (
    <Fragment>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        onSubmit={handleLogin}
        validationSchema={Schema}
      >
        {({ submitForm, isSubmitting, errors, dirty }) => (
          <Form>
            <Grid
              container
              direction="column"
              alignItems="center"
              css={css`
                && {
                  min-height: 100vh;
                }
              `}
            >
              <Grid item md sm={12}>
                <Grid container justify="center" style={{ cursor: "pointer" }}>
                  <Grid item xs="auto">
                    <Logo
                      css={css`
                        && {
                          max-width: 200px;
                        }
                      `}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  css={css`
                    && {
                      margin-top: 30px;
                    }
                  `}
                >
                  <Grid item xs>
                    <TextField
                      onKeyPress={ev => {
                        if (ev.key === "Enter") {
                          handleFocus();
                          ev.preventDefault();
                        }
                      }}
                      autoFocus
                      name="email"
                      required
                      label="E-mail"
                      variant="outlined"
                      fullWidth
                      autoComplete="username"
                    />
                    <TextField
                      inputProps={{
                        id: "user_password",
                        ref: selectRef,
                      }}
                      onKeyPress={ev => {
                        if (ev.key === "Enter") {
                          submitForm();
                          ev.preventDefault();
                        }
                      }}
                      name="password"
                      required
                      label="Senha"
                      variant="outlined"
                      fullWidth
                      autoComplete="current-password"
                      type="password"
                      css={css`
                        && {
                          margin-top: 24px !important;
                        }
                      `}
                    />
                    <Typography>
                      <Link
                        href="#"
                        onClick={() => setModalRecoverPassword(true)}
                        css={css`
                          && {
                            padding: 0;
                            text-decoration: underline;
                          }
                        `}
                      >
                        Esqueceu sua senha?
                      </Link>
                    </Typography>
                    <div
                      css={css`
                        && {
                          margin: 4px;
                          position: relative;
                        }
                      `}
                    >
                      {hasError && (
                        <Grid item xs={12} sm={12}>
                          <Snackbar
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "center",
                            }}
                            TransitionComponent={TransitionDown}
                            open={true}
                            autoHideDuration={3000}
                            onClose={() => setHasError(false)}
                          >
                            <Alert
                              onClose={() => setHasError(false)}
                              severity="error"
                            >
                              {message}
                            </Alert>
                          </Snackbar>
                        </Grid>
                      )}
                      <Button
                        onClick={submitForm}
                        disabled={
                          isSubmitting ||
                          Object.keys(errors).length > 0 ||
                          !dirty
                        }
                        fullWidth
                        variant="contained"
                        color="secondary"
                        css={css`
                          && {
                            margin-top: 20px;
                            border-radius: 15px;
                          }
                        `}
                      >
                        Login
                        {loading && (
                          <CircularProgress
                            size={24}
                            color="secondary"
                            css={css`
                              && {
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                margin-top: -12px;
                                margin-left: -12px;
                              }
                            `}
                          />
                        )}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justify="center"
                  css={css`
                    && {
                      margin-top: 32px;
                    }
                  `}
                >
                  <Grid item xs="auto">
                    <Typography
                      css={css`
                        && {
                          color: ${colors.gray};
                        }
                      `}
                    >
                      Não possui cadastro?{" "}
                      <Link
                        href="#"
                        onClick={(e: any) => {
                          e.preventDefault();
                          const apps = process.env.REACT_APP_APPLICATION_CREATE?.split(
                            ",",
                          );
                          const query: any = { channel: "portal" };
                          if (apps?.length) query.apps = apps;
                          if (process.env.REACT_APP_ORIGIN)
                            query.origin = process.env.REACT_APP_ORIGIN;
                          const search = qs.stringify(query);

                          history.push({
                            pathname: "/create-account",
                            search,
                          });
                        }}
                        css={css`
                          && {
                            padding: 0;
                            text-decoration: underline;
                          }
                        `}
                      >
                        Cadastre-se!
                      </Link>
                    </Typography>
                  </Grid>
                  <Grid item xs="auto">
                    <div
                      css={css`
                        && {
                          margin-left: 10px;
                          margin-top: 10px;
                          font-size: xx-small;
                        }
                      `}
                    >
                      <small> {`v.${packageJson.version}`}</small>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <Dialog
        open={modalRecoverPassword}
        onClose={() => setModalRecoverPassword(false)}
        aria-labelledby="form-dialog-title"
      >
        <Formik
          initialValues={{
            email: "",
          }}
          onSubmit={data => handleRecoverPassword(data)}
          validationSchema={recoverPassword}
        >
          {({ submitForm, isSubmitting, errors, dirty }) => (
            <Form>
              <DialogTitle id="form-dialog-title">
                Recuperação de senha
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Informe seu <b>e-mail</b> e clique no botão <b>Enviar</b> para
                  iniciar a recuperação da sua senha. Você receberá um link para
                  troca de senha na sua caixa de e-mail.
                </DialogContentText>
                <Grid
                  container
                  justify="flex-end"
                  css={css`
                    && {
                      margin-top: 24px;
                    }
                  `}
                ></Grid>
                <TextField
                  autoFocus
                  type="email"
                  name="email"
                  required
                  label="E-mail"
                  variant="outlined"
                  fullWidth
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    submitForm();
                    setModalRecoverPassword(false);
                    setRecoverPasswordSuccess(true);
                  }}
                  disabled={
                    isSubmitting || Object.keys(errors).length > 0 || !dirty
                  }
                  color="secondary"
                  variant="contained"
                  css={css`
                    && {
                      margin-top: 15px;
                      border-radius: 15px;
                      width: 50%;
                    }
                  `}
                >
                  Enviar
                </Button>
              </DialogActions>
              <Grid
                container
                justify="flex-end"
                css={css`
                  && {
                    margin-top: 24px;
                  }
                `}
              ></Grid>
            </Form>
          )}
        </Formik>
      </Dialog>
      <Snackbar
        open={recoverPasswordSuccess}
        autoHideDuration={4000}
        onClose={() => setRecoverPasswordSuccess(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success">
          Um e-mail para troca de senha foi enviado para sua caixa de entrada.
        </Alert>
      </Snackbar>
    </Fragment>
  );
};

export default LoginScreen;
