import {
  CustomMuiTheme,
  CustomTablePaggination,
  MuiDefaultOptions,
  useTableCustomStyles,
} from "app/components/Grid/MuiTable";
import {
  Grid,
  TableCell,
  TableFooter,
  TableRow,
  ThemeProvider,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { css, jsx } from "@emotion/core";

import MUIDataTable from "mui-datatables";
import { Skeleton } from "@material-ui/lab";
import { formatterToBrlCurrency } from "shared/utils/formatter.util";
import moment from "moment";

/** @jsx jsx */

const Screen: React.FC<any> = ({ transactions, loading, filters }) => {
  const classes = useTableCustomStyles();

  const [transactionsFiltered, setTransactionsFiltered] = useState([]);
  const [filterList, setFilterList] = useState<any>([]);

  useEffect(() => {
    setTransactionsFiltered(transactions);
  }, [transactions]);

  return (
    <Grid item xs={12} sm={12} lg={12}>
      {loading ? (
        <Grid item>
          <Skeleton variant="rect" height={30} />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
        </Grid>
      ) : (
        <Grid item xs={12} sm={12} lg={12}>
          <ThemeProvider theme={CustomMuiTheme}>
            <MUIDataTable
              title={
                <Fragment>
                  <Grid className={classes.tableTitle}>
                    Transações {filters.selectedIssuer?.name} - Resumido
                  </Grid>
                  <Grid>
                    <small>
                      Período:{" "}
                      {moment(filters.selectedDateBegin).isValid()
                        ? moment(filters.selectedDateBegin).format("DD/MM/YYYY")
                        : "-"}{" "}
                      até{" "}
                      {moment(filters.selectedDateEnd).isValid()
                        ? moment(filters?.selectedDateEnd).format("DD/MM/YYYY")
                        : "-"}{" "}
                    </small>
                  </Grid>
                </Fragment>
              }
              data={transactions}
              css={css`
                && {
                  margin-top: 15px;
                }
              `}
              columns={[
                {
                  name: "date",
                  label: "Data",
                  options: {
                    filter: true,
                    sort: true,
                    sortDirection: "desc",
                    filterList: filterList[0] ? filterList[0] : undefined,
                    customBodyRender: (value, tableMeta, updateValue) => {
                      return moment(value).format("DD/MM/YYYY");
                    },
                  },
                },
                {
                  name: "merchant",
                  label: "Estabelecimento",
                  options: {
                    filter: true,
                    setCellProps: () => ({
                      style: {
                        "@media (max-width:959.95px)": {
                          maxWidth: "250px",
                        },
                      },
                    }),
                    sort: true,
                    filterList: filterList[1] ? filterList[1] : undefined,
                    customBodyRender: (value, tableMeta, updateValue) => {
                      const document_number = (
                        value?.document_number || ""
                      ).replace(
                        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                        "$1.$2.$3/$4-$5",
                      );
                      if (value && value.trading_name)
                        return `${document_number} - ${value?.trading_name}`;
                      return "";
                    },
                  },
                },
                {
                  name: "total",
                  label: "Quantidade",
                  options: {
                    filter: true,
                    sort: true,
                    filterList: filterList[2] ? filterList[2] : undefined,
                  },
                },
                {
                  name: "value",
                  label: "Valor",
                  options: {
                    filter: true,
                    sort: true,
                    filterList: filterList[3] ? filterList[3] : undefined,
                    customBodyRender: (value, tableMeta, updateValue) => {
                      return formatterToBrlCurrency(value);
                    },
                  },
                },
              ]}
              options={{
                ...MuiDefaultOptions,
                onFilterChange: (
                  changedColumn: any,
                  filterList: any,
                  type: any,
                ) => {
                  setFilterList(filterList);

                  const hasField = (index: any, elementValue: any) => {
                    if (filterList[index].length === 0) return true;
                    return filterList[index].includes(elementValue);
                  };

                  const filtered: any = [];
                  transactions.forEach((element: any) => {
                    let elementValid = false;
                    elementValid =
                      hasField(
                        0,
                        moment(element["date"]).format("DD/MM/YYYY"),
                      ) &&
                      hasField(2, element["total"]) &&
                      hasField(3, formatterToBrlCurrency(element["value"]));

                    if (elementValid) filtered.push(element);
                  });

                  setTransactionsFiltered(filtered);
                },
                downloadOptions: {
                  filename: "transacoes-emissor-resumo.csv",
                  separator: ";",
                  filterOptions: {
                    useDisplayedColumnsOnly: true,
                    useDisplayedRowsOnly: true,
                  },
                },
                customFooter: (
                  rowCount: number,
                  page: number,
                  rowsPerPage: number,
                  changeRowsPerPage: (page: string | number) => void,
                  changePage: (newPage: number) => void,
                ) => {
                  if (
                    !transactionsFiltered ||
                    transactionsFiltered.length === 0
                  )
                    return <Fragment />;

                  return (
                    <TableFooter>
                      <TableRow>
                        <TableCell
                          colSpan={2}
                          align="center"
                          className={classes.tableTotalTitle}
                        >
                          Totais
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          align="center"
                          className={classes.tableHeader}
                        >
                          Quantidade
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tableHeader}
                        >
                          Valor
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell
                          align="center"
                          className={classes.tableRowTotal}
                        >
                          <b>
                            {" "}
                            {transactionsFiltered
                              .map((m: any) => m.total)
                              .reduce((a: any, b: any) => a + b)}
                          </b>
                        </TableCell>

                        <TableCell
                          align="center"
                          className={classes.tableRowTotal}
                        >
                          <b>
                            {formatterToBrlCurrency(
                              transactionsFiltered
                                .map((m: any) => {
                                  return Number(m.value);
                                })
                                .reduce((a: any, b: any) => a + b),
                            )}
                          </b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <CustomTablePaggination
                          rowCount={rowCount}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          changePage={changePage}
                          changeRowsPerPage={changeRowsPerPage}
                        />
                      </TableRow>
                    </TableFooter>
                  );
                },
              }}
            />
          </ThemeProvider>
        </Grid>
      )}
    </Grid>
  );
};

export default Screen;
