import React, { useEffect } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import {
  handleListTransactions,
  initState,
  newTransaction,
} from "app/ducks/transactions.duck";

import Screen from "./UsePay.screen";
import { stateReduxType as appsStateReduxType } from "app/screens/Apps/Apps.type";
import { stateReduxType as authStateReduxType } from "shared/types/authentication.type";
import { stateReduxType as reportStateReduxType } from "../ReportClosing/ReportClosing.type";
import { stateReduxType as transactionStateReduxType } from "./UsePay.type";

const useTypedSelectorAuth: TypedUseSelectorHook<authStateReduxType> = useSelector;
const useTypedSelector: TypedUseSelectorHook<transactionStateReduxType> = useSelector;
const useTypedSelectorApps: TypedUseSelectorHook<appsStateReduxType> = useSelector;
const useTypedSelectorReports: TypedUseSelectorHook<reportStateReduxType> = useSelector;

const TransactionsContainer: React.FC<any> = ({ history }) => {
  const { applications } = useTypedSelectorApps(state => state.app) || {};
  const { app } = applications;

  const { transactions } = useTypedSelector(state => state.app) || {};
  const { reports } = useTypedSelectorReports(state => state.app) || {};

  const { authentication } = useTypedSelectorAuth(state => state.shared) || {};
  const { data: auth, params: merchantParams } = authentication;
  const { user } = auth;
  const merchant = user?.merchants[0];

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initState());
  }, []);

  return (
    <Screen
      handleNewTransaction={(data: any) =>
        newTransaction({
          data,
          auth,
        })
      }
      handleListTransactions={(meta: any = null) => {
        var start = new Date();
        start.setHours(0, 0, 0, 0);
        var end = new Date();
        end.setHours(23, 59, 59, 999);
        dispatch(
          handleListTransactions({
            dateBegin: start.toISOString(),
            dateEnd: end.toISOString(),
            application_id: app.id,
            merchant_id: merchant.id,
            issuer_id: app && app.issuer_entity_id,
            offset: 0,
            limit: 1000,
            onlyConfirmed: false,
            sort: "DESC",
            meta
          }),
        );
      }}
      history={history}
      application={app}
      transactions={transactions}
      reports={reports}
      merchantParams={merchantParams}
    />
  );
};

export default TransactionsContainer;
