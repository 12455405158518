import { Grid, TextField } from "@material-ui/core";
/** @jsx jsx */
import React, { Fragment, useEffect, useState } from "react";
import { css, jsx } from "@emotion/core";

const ExternalReferenceField: React.FC<any> = ({
  enable,
  setExternalReference,
  externalReference,
  transactionPending,
  loading,
}) => {
  if (!enable) return <div></div>;

  return (
    <Grid
      container
      justify="center"
      css={css`
        && {
          margin-top: 24px;
        }
      `}
    >
      <Grid item xs={8} sm={6} lg={6}>
        <TextField
          fullWidth
          required
          label={"Digite o código de referência"}
          variant="outlined"
          disabled={loading || transactionPending}
          value={externalReference}
          onChange={({ target: { value } }: any) => {
            setExternalReference(value.trim().toUpperCase());
          }}
        />
      </Grid>
    </Grid>
  );
};

export default ExternalReferenceField;
