import { Grid, TextField, Typography } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { css, jsx } from "@emotion/core";
import {
  currencyFormatterComp,
  formatterToCurrency,
} from "shared/utils/formatter.util";

import NumberFormat from "react-number-format";

/** @jsx jsx */

function currencyFormatter3(value: any) {
  if (!Number(value)) return "";
  const amount = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    maximumFractionDigits: 3,
    minimumFractionDigits: 3,
  }).format(value / 1000);
  return `${amount}`;
}

export const getTransactionValueMin = (application: any) => {
  return application?.allowed_min_value || 0;
};
export const getTransactionValueMax = (application: any) => {
  return application?.allowed_max_value || 99999;
};

export const transactionValueOnBlur = ({
  application,
  transactionValue,
  decimalScale,
  setTransactionValueError,
}: any) => ({
  target: { value, ...rest },
}: React.ChangeEvent<HTMLInputElement>) => {
  const val = Number(transactionValue) / (decimalScale === 3 ? 1000 : 100);
  const min = getTransactionValueMin(application);
  const max = getTransactionValueMax(application);
  setTransactionValueError(val < min || val > max);
};

export const transactionValueIsAllowed = ({ getTransactionValueMax }: any) => (
  values: import("react-number-format").NumberFormatValues,
): boolean => {
  const { formattedValue, floatValue } = values;
  const max = getTransactionValueMax();
  return formattedValue === "" || floatValue / 100 <= max;
};

const TransactionTotalValue: React.FC<any> = ({
  loading,
  isStaticQrCode,
  isDynamicWithoutValue,
  decimalScale,
  transactionValueError,
  setTransactionValueError,
  application,
  transactionValue,
  setTransactionValue,
  directValueNotAllowed,
  handleAddNewTransaction,
}) => {
  return (
    <Grid
      container
      justify="center"
      css={css`
        && {
          margin-top: 24px;
        }
      `}
    >
      {!isStaticQrCode && !isDynamicWithoutValue && (
        <Grid item xs={8} sm={6} lg={6}>
          <NumberFormat
            customInput={TextField}
            fullWidth
            required
            format={
              decimalScale === 2 ? currencyFormatterComp : currencyFormatter3
            }
            type="tel"
            onBlur={transactionValueOnBlur({
              application,
              transactionValue,
              decimalScale,
              setTransactionValueError,
            })}
            isAllowed={transactionValueIsAllowed({
              getTransactionValueMax,
            })}
            error={transactionValueError}
            decimalScale={decimalScale}
            fixedDecimalScale={true}
            thousandSeparator="."
            decimalSeparator=","
            allowNegative={false}
            isNumericString={true}
            prefix={"R$ "}
            disabled={loading || directValueNotAllowed}
            label={
              directValueNotAllowed
                ? "Valor calculado da transação"
                : "Digite o valor da transação"
            }
            variant="outlined"
            value={transactionValue}
            onValueChange={(values: any) => {
              setTransactionValue(values.value);
            }}
            onKeyPress={evt => {
              if (evt.key === "Enter") {
                handleAddNewTransaction();
              }
            }}
          />
          {transactionValueError && (
            <small>
              <Typography variant="caption" color={"error"} align={"center"}>
                Valor inválido. Minímo:{" "}
                {formatterToCurrency(getTransactionValueMin(application))} e
                Máximo:{" "}
                {formatterToCurrency(getTransactionValueMax(application))}
              </Typography>
            </small>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default TransactionTotalValue;
