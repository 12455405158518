import { Grid } from "@material-ui/core";
/** @jsx jsx */
import React, { Fragment, useState } from "react";
import { css, jsx } from "@emotion/core";

import Alert from "@material-ui/lab/Alert";
import FormCreateAccountComponent from "public/components/FormCreateAccount/FormCreateAccount.component";
import Header from "app/components/Header/Header.container";
import Schema from "./Merchant.schema";
import Skeleton from "@material-ui/lab/Skeleton";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import { TransitionProps } from "@material-ui/core/transitions";
import { screenPropsType } from "./Merchant.type";

function TransitionDown(props: TransitionProps) {
  return <Slide {...props} direction="down" />;
}

const Screen: React.FC<screenPropsType> = ({
  merchantState,
  initialSchema,
  handleUpdate,
}) => {
  const [hasError, setHasError] = useState(false);
  const { loading, error } = merchantState || {};
  const { message } = error || {};

  return (
    <Fragment>
      <Grid
        container
        justify="center"
        alignItems="center"
        direction="row"
        spacing={3}
      >
        <Grid item xs={12} lg={12}>
          <Header authentication={null} loading={loading} />
        </Grid>
        {hasError && (
          <Grid item xs={12} sm={12}>
            <Grid
              container
              justify="flex-end"
              css={css`
                && {
                  margin-top: 24px;
                }
              `}
            ></Grid>
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              TransitionComponent={TransitionDown}
              open={true}
              autoHideDuration={6000}
              onClose={() => setHasError(false)}
            >
              <Alert onClose={() => setHasError(false)} severity="error">
                {message}
              </Alert>
            </Snackbar>
          </Grid>
        )}
        <Grid
          container
          justify="flex-end"
          css={css`
            && {
              margin-top: 24px;
            }
          `}
        ></Grid>
        <Grid
          item
          xs={12}
          sm={12}
          container
          justify="space-between"
          alignItems="center"
          direction="row"
        >
          <Grid
            container
            justify="flex-end"
            css={css`
              && {
                margin-top: 24px;
              }
            `}
          ></Grid>
        </Grid>
        {/* Form Screen */}
        {!loading ? (
          <FormCreateAccountComponent
            initialSchema={initialSchema}
            formSchema={Schema}
            handleCreateAccount={handleUpdate}
            userIsTaken={false}
          />
        ) : (
          <Grid item sm={10} xs={10}>
            <Skeleton variant="rect" height={50} />
            <Skeleton variant="text" />
            <Skeleton variant="rect" height={200} />
            <Skeleton variant="text" />
            <Skeleton variant="rect" height={200} />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="rect" height={100} />
          </Grid>
        )}
      </Grid>
    </Fragment>
  );
};

export default Screen;
