import {
  Card,
  CardContent,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  ThemeProvider,
  Typography,
  createMuiTheme,
  makeStyles,
} from "@material-ui/core";
import {
  CustomMuiTheme,
  useTableCustomStyles,
} from "app/components/Grid/MuiTable";
import MUIDataTable, { MUIDataTableColumnDef } from "mui-datatables";
import React, { Fragment } from "react";
import { css, jsx } from "@emotion/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import RegistersDetails from "./RegistersDetails";
import { Skeleton } from "@material-ui/lab";
import { colors } from "shared/configs/styles.config";
import { formatterToBrlCurrency } from "shared/utils/formatter.util";
import moment from "moment";
import { ptBR } from "@material-ui/core/locale";

/** @jsx jsx */

const getColumns = (): MUIDataTableColumnDef[] => {
  return [
    {
      name: "Dt_movimentacao",
      label: "Dt. Transação",
      options: {
        print: true,
        sortDirection: "asc",
        display: "false",
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return moment(value).format("DD/MM/YYYY HH:mm");
        },
      },
    },
    {
      name: "Descricao_Movimento",
      label: "Descrição",
      options: {
        print: true,
        display: "false",
      },
    },
    {
      name: "Tipo_Movimento",
      label: "Tipo Movimento",
      options: {
        print: true,
        display: "false",
      },
    },
    {
      name: "VLR_movimentado",
      label: "Valor",
      options: {
        print: true,
        display: "false",
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return formatterToBrlCurrency(value);
        },
      },
    },
  ];
};

const Screen: React.FC<any> = ({ transactions, loading, filters, title }) => {
  const classes = useTableCustomStyles();
  return (
    <Grid
      item
      xs={12}
      sm={12}
      lg={12}
      css={css`
        background-color: #f5f5f5;
      `}
    >
      {loading ? (
        <Grid container spacing={6}>
          <Grid item xs={12} sm={12}>
            <Skeleton variant="rect" />
          </Grid>
        </Grid>
      ) : (
        <Fragment>
          <Grid className={classes.tableTitle}>{title}</Grid>
        </Fragment>
      )}
      {loading ? (
        <Grid container spacing={6}>
          <Grid item xs={12} sm={12}>
            <Skeleton variant="rect" />
          </Grid>
        </Grid>
      ) : (
        <MUIDataTable
          title={""}
          data={
            transactions.length > 0
              ? transactions
                  .map((m: any) => m.items)
                  .reduce((a: [], b: []) => a.concat(b), [])
              : []
          }
          css={css`
            background-color: #f5f5f5;
            height: 45px;
          `}
          columns={getColumns()}
          options={{
            elevation: 0,
            filter: false,
            print: false,
            viewColumns: false,
            responsive: "simple",
            selectableRows: "none",
            search: false,
            pagination: false,
            textLabels: {
              body: {
                noMatch: "Nenhuma transação encontrada",
              },
            },
          }}
        />
      )}

      {loading ? (
        <Grid container spacing={6}>
          <Grid item xs={2} sm={1}>
            <Skeleton variant="rect" height={150} />
          </Grid>
          <Grid item xs={10} sm={11}>
            <Skeleton variant="rect" height={150} />
          </Grid>
          <Grid item xs={2} sm={1}>
            <Skeleton variant="rect" height={150} />
          </Grid>
          <Grid item xs={10} sm={11}>
            <Skeleton variant="rect" height={150} />
          </Grid>
          <Grid item xs={2} sm={1}>
            <Skeleton variant="rect" height={150} />
          </Grid>
          <Grid item xs={10} sm={11}>
            <Skeleton variant="rect" height={150} />
          </Grid>
        </Grid>
      ) : (
        <Grid
          item
          xs={12}
          sm={12}
          lg={12}
          css={css`
            background-color: #f5f5f5;
          `}
        >
          <ThemeProvider theme={CustomMuiTheme}>
            {renderItems(transactions, loading, classes, filters)}
          </ThemeProvider>
        </Grid>
      )}
    </Grid>
  );
};

export default Screen;

function renderItems(
  transactions: any,
  loading: any,
  classes: any,
  filters: any,
): React.ReactNode {
  const cards: any = [];
  transactions.forEach((trans: any, index: any) => {
    cards.push(
      <Grid
        key={index}
        container
        css={css`
          align-items: center;
        `}
      >
        <Grid item xs={12} sm={2} lg={2}>
          <Typography variant="subtitle2" align={"center"}>
            {moment(trans.DataCaptura).format("DD/MMM")}
          </Typography>
          <Typography
            variant="subtitle1"
            align={"center"}
            css={css`
              margin-bottom: 35px;
            `}
          >
            <small>{moment(trans.DataCaptura).format("dddd")}</small>
          </Typography>
        </Grid>
        <Grid
          item
          sm={10}
          xs={12}
          css={css`
            padding: 10px;
          `}
        >
          <Card>
            <CardContent>
              <ExpansionPanel elevation={0}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Grid container>
                    <Grid item sm={12} xs={12} lg={12}>
                      <Grid container>
                        <Grid item xs={12} sm={4}>
                          <Typography variant="caption">Operações</Typography>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <Typography variant="caption">
                            Valor Crédito
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Typography variant="caption">
                            Valor Débito
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item sm={12} xs={12} lg={12}>
                      <Grid container>
                        <Grid item xs={12} sm={4}>
                          <small>
                            <Typography variant="subtitle2" align={"left"}>
                              {trans.total > 0
                                ? `${
                                    trans.items?.filter(
                                      (f: any) =>
                                        f.Tipo_Movimento === "Credito",
                                    ).length
                                  } créditos \n`
                                : `0 créditos`}{" "}
                            </Typography>
                            <Typography variant="subtitle2" align={"left"}>
                              {trans.total > 0
                                ? `${
                                    trans.items?.filter(
                                      (f: any) =>
                                        f.Tipo_Movimento !== "Credito",
                                    ).length
                                  } débitos`
                                : `0 débitos`}{" "}
                            </Typography>
                          </small>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <Typography variant="subtitle2" align={"left"}>
                            {formatterToBrlCurrency(
                              trans.items
                                ?.filter(
                                  (f: any) => f.Tipo_Movimento === "Credito",
                                )
                                .map((m: any) => Number(m.VLR_movimentado))
                                .reduce((a: any, b: any) => a + b, 0),
                            )}{" "}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <Typography variant="subtitle2" align={"left"}>
                            {formatterToBrlCurrency(
                              trans.items
                                ?.filter(
                                  (f: any) => f.Tipo_Movimento !== "Credito",
                                )
                                .map((m: any) => Number(m.VLR_movimentado))
                                .reduce((a: any, b: any) => a + b, 0),
                            )}{" "}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <RegistersDetails
                    transactions={trans.items}
                    loading={loading}
                    filters={filters}
                  />
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </CardContent>
          </Card>
        </Grid>
      </Grid>,
    );
  });

  return <Grid>{cards}</Grid>;
}
