import * as Yup from "yup";

export const attendantCreate = Yup.object().shape({
  name: Yup.string().required("Campo obrigatório"),
  document_number: Yup.string().required("Campo obrigatório"),
  phone_prefix: Yup.number()
    .nullable()
    .required("Campo obrigatório"),
  phone_number: Yup.string().required("Campo obrigatório"),
  code: Yup.string().required("Campo obrigatório"),
});

export const attendantEdit = Yup.object().shape({
  name: Yup.string().required("Campo obrigatório"),
  document_number: Yup.string().required("Campo obrigatório"),
  phone_prefix: Yup.number()
    .nullable()
    .required("Campo obrigatório"),
  phone_number: Yup.string().required("Campo obrigatório"),
  active: Yup.boolean()
    .default(true)
    .required("Campo obrigatório"),
  code: Yup.string().required("Campo obrigatório"),
});
