import { API_URL_WITH_PORT, PORTS } from "shared/configs/api.config";

import { Dispatch } from "redux";
import fetch from "shared/utils/fetch.util";

type Actions = {
  type: string;
  payload?: object;
  error?: object;
};

export const Types = {
  INIT: "app/reports/INIT",
  SUCCESS_GROUP: "app/reports/SUCCESS_GROUP",
  SUCCESS_DETAILS: "app/reports/SUCCESS_DETAILS",
  ERROR: "app/reports/ERROR",
};

const initialState: any = {
  transactions: [],
  loading: false,
  error: null,
};

export default (state = initialState, action: Actions) => {
  const { type, payload, error } = action;

  const reducers = {
    [Types.INIT]: {
      ...state,
      error: null,
      loading: true,
    },
    [Types.ERROR]: {
      paymentConfirmed: null,
      loading: false,
      transactions: [],
      error,
    },
    [Types.SUCCESS_GROUP]: {
      ...state,
      transactions: payload,
      loading: false,
      error: false,
    },
    [Types.SUCCESS_DETAILS]: {
      ...state,
      transactionsDetails: payload,
      loading: false,
      error: false,
    },
  };

  return reducers[type] || state;
};

export const getTransactions = ({
  application_id,
  user_id,
  issuer_id,
  dateBegin,
  dateEnd,
  grouped,
  offset,
  limit,
  onlyConfirmed,
  sort,
  merchant_ids,
}: any) => async (dispatch: Dispatch<any>) => {
  try {
    let queryParams = "";
    if (application_id) {
      queryParams = `&application_id=${application_id}`;
    }
    if (user_id) {
      queryParams += `&user_id=${user_id}`;
    }
    if (onlyConfirmed) {
      queryParams += `&onlyConfirmed=${onlyConfirmed}`;
    }
    if (issuer_id) {
      queryParams += `&issuer_id=${issuer_id}`;
    }
    if (sort) {
      queryParams += `&sort=${sort}`;
    }
    if (merchant_ids?.length > 0) {
      queryParams += merchant_ids.map((it: any) => `&merchant_ids=${it}`).join("");
    }

    dispatch({
      type: Types.INIT,
    });
    const response = await fetch(
      `${API_URL_WITH_PORT(
        PORTS.TRANSACTION,
      )}/transactions?offset=${offset}&limit=${limit}&grouped=${grouped}&dateBegin=${dateBegin}&dateEnd=${dateEnd}${queryParams}`,
      {
        customUrl: true,
        method: "GET",
        auth: true,
      },
    );

    const result = await response.json();
    if (!response.ok) {
      throw result;
    }

    const { results } = result;
    if (grouped) {
      dispatch({
        type: Types.SUCCESS_GROUP,
        payload: results,
      });
    } else {
      dispatch({
        type: Types.SUCCESS_DETAILS,
        payload: results,
      });
    }

    return results;
  } catch (e) {
    dispatch({
      type: Types.ERROR,
      error: {
        visible: true,
        ...e,
      },
    });
    return false;
  }
};
