import React, { useCallback } from 'react';
import { confirmUserPdv, setAuth, checkUserCode } from 'shared/ducks/authentication.duck';

import ConfirmEmail from './ConfirmEmailPassword.screen';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useQuery from 'shared/hooks/useQuery.hook';

const ConfirmEmailContainer: React.FC = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const history = useHistory();
  const code = query.get('code');
  const showTermsOfService = query.get('requestConfirm') === 'true'

  const handleConfirmUser = useCallback(
    async data => {
      const response = await dispatch(
        confirmUserPdv({
          code: code,
          data,
        }),
      );

      if (!response) {
        return null;
      }
      await dispatch(setAuth(response));
      return history.push('/app');
    },
    [dispatch, history, code],
  );
  const handleValidateCode = async () => await checkUserCode({ code })();

  return <ConfirmEmail handleConfirmUser={handleConfirmUser} handleValidateCode={handleValidateCode} showTermsOfService={showTermsOfService} />;
};

export default ConfirmEmailContainer;
