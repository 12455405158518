import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import {
  handleCheckPayment,
  handleResendNotification,
  requestReversalTransaction,
} from "app/ducks/transactions.duck";

import React from "react";
import { Redirect } from "react-router-dom";
import Screen from "./ReportClosing.screen";
import { stateReduxType as authStateType } from "shared/types/authentication.type";
import { getTransactions } from "app/ducks/reports.duck";
import { stateReduxType } from "./ReportClosing.type";
import { stateReduxType as transactionsStateType } from "app/screens/Transactions/Transactions.type";

const useTypedSelector: TypedUseSelectorHook<authStateType> = useSelector;
const useTypedSelectorReports: TypedUseSelectorHook<stateReduxType> = useSelector;
const useTypedSelectorTransactions: TypedUseSelectorHook<transactionsStateType> = useSelector;

const ApplicationsContainer: React.FC<any> = ({ history }) => {
  const dispatch = useDispatch();

  const { reports } = useTypedSelectorReports(state => state.app) || {};
  const { authentication } = useTypedSelector(state => state.shared) || {};
  const { transactions } =
    useTypedSelectorTransactions(state => state.app) || {};
  const { loading } = transactions;
  const { signedIn, params: merchantParams, data } = authentication;
  const availableMerchants: any[] = data?.user?.merchants || [];
  const activeMerchant: any = data?.user?.activeMerchant || {};

  if (!signedIn) {
    return <Redirect to="/login" />;
  }

  const handleGetTransactions = (params: any) =>
    dispatch(
      getTransactions({
        ...params,
      }),
    );

  const handleDispatchCheckPayment = (params: any) =>
    dispatch(
      handleCheckPayment({
        ...params,
      }),
    );

  const handleDispatchResendNotification = (params: any) =>
    dispatch(
      handleResendNotification({
        ...params,
      }),
    );

  const handleRequestReversalTransaction = async (data: any) => {
    const { reversal, request } = data;
    await Promise.all([dispatch(requestReversalTransaction(reversal))]);
    handleGetTransactions(request);
  };

  return (
    <Screen
      getTransactions={handleGetTransactions}
      history={history}
      checkPayment={handleDispatchCheckPayment}
      reports={reports}
      transactionLoading={loading}
      handleRequestReversal={handleRequestReversalTransaction}
      merchantParams={merchantParams}
      resendNotification={handleDispatchResendNotification}
      availableMerchants={availableMerchants}
      activeMerchant={activeMerchant}
    />
  );
};

export default ApplicationsContainer;
