import {
  Avatar,
  Badge,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Menu,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { css, jsx } from "@emotion/core";
import { green, grey } from "@material-ui/core/colors";

import AddBoxIcon from "@material-ui/icons/AddBox";
import AlarmIcon from "@material-ui/icons/Alarm";
import CheckIcon from "@material-ui/icons/Check";
import DraftsIcon from "@material-ui/icons/Drafts";
import MailIcon from "@material-ui/icons/Mail";
import TodayIcon from "@material-ui/icons/Today";
import { formatterToCurrency } from "shared/utils/formatter.util";
import moment from "moment";

/** @jsx jsx */

const useStyles = makeStyles({
  root: {
    // minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
});

const Messages: React.FC<any> = ({
  messages,
  messagesToRead,
  setReadAllMessages,
}) => {
  const classes = useStyles();

  const [openMessages, setMenuMessagesOpen] = useState(false);

  const [anchorMsgEl, setAnchorMsgEl] = React.useState<null | HTMLElement>(
    null,
  );
  const handleMsgMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorMsgEl(event.currentTarget);
    setMenuMessagesOpen(true);
  };
  const bull = <span className={classes.bullet}>•</span>;

  return (
    <div>
      <IconButton aria-controls="menu-messages" onClick={handleMsgMenu}>
        <Badge
          color={messagesToRead > 0 ? "primary" : "secondary"}
          variant="dot"
          badgeContent={messagesToRead}
        >
          <MailIcon />
        </Badge>
      </IconButton>
      <Menu
        id="menu-messages"
        anchorEl={anchorMsgEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={openMessages}
        onClose={() => {
          setMenuMessagesOpen(false);
          if (messagesToRead > 0) setReadAllMessages();
        }}
      >
        <List
          css={css`
            && {
              padding: 5px;
            }
          `}
        >
          {messages?.map((msg: any, index: any) => {
            const { type, read, metadata: metaStr, created_at } = msg;
            const colorRead = read ? grey[500] : green[500];
            const metadata = JSON.parse(metaStr);
            return (
              <Card
                key={index}
                className={classes.root}
                variant="outlined"
                css={css`
                  && {
                    margin-top: 5px;
                    padding: 5px;
                  }
                `}
              >
                <CardHeader
                  action={
                    <IconButton aria-label="settings">
                      {type === "TransactionPaid" ? (
                        <CheckIcon />
                      ) : (
                        <AlarmIcon />
                      )}
                    </IconButton>
                  }
                  title={`App ${metadata?.application?.name}`}
                  subheader={moment(created_at).fromNow()}
                  className={classes.title}
                  titleTypographyProps={{
                    variant: "body2",
                  }}
                  subheaderTypographyProps={{
                    variant: "caption",
                  }}
                  css={css`
                    && {
                      padding: 3px;
                    }
                  `}
                />
                <CardContent
                  css={css`
                    && {
                      padding: 3px;
                    }
                  `}
                >
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    {msg.title}
                  </Typography>

                  <Typography variant="body2" component="p">
                    <small>Ref: {metadata.reference_id}</small>
                    <br />
                    <small>
                      {msg.description} - Valor:{" "}
                      {formatterToCurrency(metadata.transaction_amount)}
                    </small>
                  </Typography>
                </CardContent>
                {/* <CardActions>
                  <Button size="small">Ver Detalhes</Button>
                </CardActions> */}
              </Card>
            );
          })}

          {messages && messages.length === 0 && (
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <DraftsIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={"Nenhuma nova mensagem"} secondary={""} />
            </ListItem>
          )}
        </List>
      </Menu>
    </div>
  );
};

export default Messages;
