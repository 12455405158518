import { API_URL_WITH_PORT, PORTS } from "shared/configs/api.config";

import { Dispatch } from "redux";
import decode from "jwt-decode";
import fetch from "shared/utils/fetch.util";
import { isUserAllowedToAccessOrigin } from "shared/utils/getToken.util";
import { responseType } from "public/screens/Login/Login.type";
import { stateReduxAuthentication } from "../types/authentication.type";

const userStorage = localStorage.getItem("user") || null;
const user = userStorage && decode<any>(JSON.parse(userStorage).token);

type Actions = {
  type: string;
  payload?: object;
  error?: object;
};

export const Types = {
  RESET_ERROR: "shared/authentication/RESET_ERROR",
  INIT: "shared/authentication/INIT",
  SUCCESS: "shared/authentication/SUCCESS",
  ERROR: "shared/authentication/ERROR",
  LOGOFF: "shared/authentication/LOGOFF",
  SET_MERCHANT_PARAMS: "shared/authentication/SET_MERCHANT_PARAMS",
};

const initialState: stateReduxAuthentication = {
  data: user,
  signedIn: !!user,
  loading: false,
  error: null,
  params: null,
};

export default (state = initialState, action: Actions) => {
  const { type, payload, error } = action;

  const reducers = {
    [Types.RESET_ERROR]: {
      ...state,
      error: null,
    },
    [Types.INIT]: {
      ...state,
      error: null,
      loading: true,
    },
    [Types.ERROR]: {
      loading: false,
      data: null,
      signedIn: false,
      error,
    },
    [Types.SUCCESS]: {
      loading: false,
      data: payload,
      signedIn: true,
      error: false,
    },
    [Types.LOGOFF]: {
      loading: false,
      data: null,
      signedIn: false,
      error: null,
    },
    [Types.SET_MERCHANT_PARAMS]: {
      ...state,
      loading: false,
      params: payload,
    },
  };

  return reducers[type] || state;
};

const setUserObj = (user: object) =>
  localStorage.setItem("user", JSON.stringify(user));

const removeUserObj = () => localStorage.removeItem("user");

export const resetError = () => ({
  type: Types.RESET_ERROR,
});

export const setAuth = (result: any) => async (dispatch: Dispatch) => {
  setUserObj(result);

  dispatch({
    type: Types.SUCCESS,
    payload: decode(result.token),
  });
};

export const login = (data: responseType) => async (
  dispatch: Dispatch<any>,
) => {
  try {
    dispatch({
      type: Types.INIT,
    });
    const response = await fetch(
      `${API_URL_WITH_PORT(PORTS.LOGIN)}/accounts/auth`,
      {
        customUrl: true,
        method: "post",
        body: JSON.stringify({
          ...data,
          origin: 1,
        }),
      },
    );

    const result = await response.json();
    if (!response.ok) {
      throw result;
    }

    const decodedToken = decode(result.token);
    const userOriginValid = isUserAllowedToAccessOrigin(decodedToken);
    if (!userOriginValid) {
      throw {
        message: "Usuário sem permissão para aplicação",
      };
    }
    dispatch(setAuth(result));

    loadMerchantParams(decodedToken);

    return result;
  } catch (e) {
    dispatch({
      type: Types.ERROR,
      error: {
        visible: true,
        ...e,
      },
    });

    return false;
  }
};

export const loadMerchantParams = (tokenDecoded: any) => async (
  dispatch: Dispatch<any>,
) => {
  try {
    const { user } = tokenDecoded;
    if (!user.merchants || user.merchants.length <= 0) {
      throw "Error to load merchant params";
    }
    const selectedMerchant = user.merchants[0];
    const response = await fetch(
      `${API_URL_WITH_PORT(PORTS.MERCHANT)}/merchants/${selectedMerchant.id
      }/params`,
      {
        customUrl: true,
        auth: true,
        method: "GET",
      },
    );
    const result = await response.json();
    if (!response.ok) {
      throw result;
    }
    dispatch({
      type: Types.SET_MERCHANT_PARAMS,
      payload: result,
    });
  } catch (error) {
    console.error(error.message, error);
  }
};

export const logoff = () => async (dispatch: Dispatch) => {
  removeUserObj();

  return dispatch({ type: Types.LOGOFF });
};

export const resendConfirmation = (type: number) => async () => {
  try {
    const response = await fetch(
      `${API_URL_WITH_PORT(PORTS.LOGIN)}/accounts/request-confirmation`,
      {
        customUrl: true,
        auth: true,
        method: "post",
        body: JSON.stringify({
          type,
        }),
      },
    );

    const result = await response.json();
    if (!response.ok) {
      throw result;
    }

    return result;
  } catch (e) {
    return false;
  }
};

export const confirmUser = ({ code, auth, merge }: any) => async () => {
  try {
    const response = await fetch(
      `${API_URL_WITH_PORT(PORTS.LOGIN)}/accounts/confirmation`,
      Object.assign(
        {},
        {
          customUrl: true,
          method: "post",
          body: JSON.stringify({
            code,
            ...merge,
          }),
        },
        auth && {
          headers: new Headers({
            Authorization: auth,
          }),
        },
        !auth && {
          auth: true,
        },
      ),
    );

    const result = await response.json();
    if (!response.ok) {
      throw result;
    }

    return result;
  } catch (e) {
    return false;
  }
};

export const changePassword = ({
  password,
  confirmPassword,
  token,
}: any) => async () => {
  try {
    const response = await fetch(
      `${API_URL_WITH_PORT(PORTS.LOGIN)}/accounts/change-password`,
      {
        customUrl: true,
        method: "post",
        body: JSON.stringify({
          password,
          confirmPassword,
          token,
        }),
      },
    );

    const result = await response.json();
    if (!response.ok) {
      throw result;
    }

    return result;
  } catch (e) {
    return false;
  }
};

export const recoverPassword = (data: any) => async () => {
  try {
    const response = await fetch(
      `${API_URL_WITH_PORT(PORTS.LOGIN)}/accounts/recover-password`,
      {
        customUrl: true,
        method: "post",
        body: JSON.stringify({
          ...data,
        }),
      },
    );

    const result = await response.json();
    if (!response.ok) {
      throw result;
    }

    return result;
  } catch (e) {
    return false;
  }
};

export const confirmUserPdv = ({ code, auth, data }: any) => async () => {
  try {
    const response = await fetch(
      `${API_URL_WITH_PORT(PORTS.LOGIN)}/accounts/users/confirmation`,
      Object.assign(
        {},
        {
          customUrl: true,
          method: "post",
          body: JSON.stringify({
            code,
            password: data.password,
            accept_terms: data.accept_terms,
          }),
        },
        auth && {
          headers: new Headers({
            Authorization: auth,
          }),
        },
        !auth && {
          auth: true,
        },
      ),
    );

    const result = await response.json();
    if (!response.ok) {
      throw result;
    }

    return result;
  } catch (e) {
    return false;
  }
};

export const checkUserCode = ({ code, auth }: any) => async () => {
  try {
    const response = await fetch(
      `${API_URL_WITH_PORT(PORTS.LOGIN)}/accounts/token-status?code=${code}`,
      Object.assign(
        {},
        {
          customUrl: true,
          method: "get",
        },
        auth && {
          headers: new Headers({
            Authorization: auth,
          }),
        },
        !auth && {
          auth: true,
        },
      ),
    );

    if (!response.ok) {
      return false;
    }

    return true;
  } catch (e) {
    return false;
  }
};



export const loginTerminal = (data: any) => async (
  dispatch: Dispatch<any>,
) => {
  try {
    dispatch({
      type: Types.INIT,
    });
    const response = await fetch(
      `${API_URL_WITH_PORT(PORTS.LOGIN)}/accounts/auth/terminal`,
      {
        customUrl: true,
        method: "post",
        body: JSON.stringify({
          manufacturer: data.fabricante,
          model: data.modelo,
          number: data.numero,
        }),
      },
    );

    const result = await response.json();
    if (!response.ok) {
      throw result;
    }

    const decodedToken = decode(result.token);
    const userOriginValid = isUserAllowedToAccessOrigin(decodedToken);
    if (!userOriginValid) {
      throw {
        message: "Terminal sem permissão para aplicação",
      };
    }
    dispatch(setAuth(result));
    loadMerchantParams(decodedToken);
    return result;
  } catch (e) {
    dispatch({
      type: Types.ERROR,
      error: {
        visible: true,
        ...e,
      },
    });

    return false;
  }
};
