import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import "shared/styles/reset.style.css";
import "app/styles/app.style.css";

import * as Sentry from "@sentry/react";
import * as serviceWorker from "./serviceWorker";

import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import { Integrations } from "@sentry/tracing";
import { Provider } from "react-redux";
import React from "react";
import ReactDOM from "react-dom";
import Routes from "./routes";
import { SnackbarProvider } from "notistack";
import { colors } from "shared/configs/styles.config";
import { ptBR } from "@material-ui/core/locale";
import store from "store";

const pkgJson = require("../package.json");

Sentry.init({
  dsn:
    "https://b8ef5ed8c9a3424fa85c70c9dddcd021@o658423.ingest.sentry.io/5961701",
  // integrations: [new Integrations.BrowserTracing()],
  // tracesSampleRate: 0,
  // debug: true,
  environment: process.env.REACT_APP_ENV || "homolog",
  release: pkgJson.version,
});

const App = () => {
  const theme = createMuiTheme(
    {
      palette: {
        primary: {
          main: colors.primary,
        },
        secondary: {
          main: colors.secondary,
        },
      },
      shape: {
        borderRadius: 8,
      },
      typography: {
        button: {
          textTransform: "none",
          fontSize: 14,
          fontWeight: "normal",
        },
      },
      spacing: 4,
    },
    ptBR,
  );

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider>
          <BrowserRouter>
            <Switch>
              <Route path="/" component={Routes} />
            </Switch>
          </BrowserRouter>
        </SnackbarProvider>
      </ThemeProvider>
    </Provider>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
