import { API_URL_WITH_PORT, PORTS } from "shared/configs/api.config";
import {
  enumPersonType,
  formType,
  responseToApi,
  stateTypes,
} from "./createAccount.type";

import { Dispatch } from "redux";
import fetch from "shared/utils/fetch.util";

export const Types = {
  INIT: "site/createAccount/INIT",
  SUCCESS: "site/createAccount/SUCCESS",
  ERROR: "site/createAccount/ERROR",
  SET_FORM: "site/createAccount/SET_FORM",
  SET_USER_DATA_TAKEN: "site/createAccount/SET_USER_DATA_TAKEN",
};

const initialState: stateTypes = {
  loading: false,
  error: null,
  data: null,
  form: {
    user: {
      email: "",
      password: "",
      document_number: "",
      phone_prefix: null,
      phone_number: "",
      confirm_password: "",
    },
    merchant: {
      contact_name: "",
      document_number: "",
      phone_prefix: null,
      phone_number: "",
      cnae: "",
      mcc: "",
      constitution_date: new Date().toString(),
      type: enumPersonType.CNPJ,
      accept_terms: false,
      address: {
        zip_code: "",
        street: "",
        number: "",
        complement: "",
        neighborhood: "",
        city: "",
        state: "",
        receipt_path: "",
      },
      bank_account: {
        bank_number: "",
        branch_number: "",
        account_number: "",
        account_digit: "",
      },
      trading_name: "",
      company_name: "",
    },
    apps: [],
  },
};

export default (state = initialState, actions: any) => {
  const { type, payload }: any = actions;

  const reducers = {
    [Types.INIT]: {
      ...state,
      loading: true,
    },
    [Types.SUCCESS]: {
      ...state,
      loading: false,
    },
    [Types.ERROR]: {
      ...state,
      loading: false,
    },
    [Types.SET_FORM]: {
      ...state,
      form: payload,
    },
    [Types.SET_USER_DATA_TAKEN]: {
      ...state,
      userIsTaken: payload,
      loading: false,
    },
  };

  return reducers[type] || state;
};

const parser = (data: formType): responseToApi => {
  let payload: any = {
    user: {
      email: data.user.email,
      document_number: data.user.document_number,
      phone_prefix: data.user.phone_prefix,
      phone_number: data.user.phone_number,
      password: data.user.password,
    },
    merchant: {
      ...data.merchant,
      phone_prefix: Number(data.merchant.phone_prefix),
      address: {
        ...data.merchant.address,
        number: data.merchant.address.number,
      },
    },
  };

  if (data.apps && data.apps.length > 0) {
    payload = {
      ...payload,
      apps: data.apps || [],
    };
  }
  return payload;
};

export const createAccount = (data: formType) => async (dispatch: Dispatch) => {
  dispatch({
    type: Types.SET_FORM,
    payload: data,
  });

  try {
    dispatch({
      type: Types.INIT,
    });

    const response = await fetch(`${API_URL_WITH_PORT(PORTS.LOGIN)}/accounts`, {
      customUrl: true,
      method: "post",
      body: JSON.stringify({
        ...parser(data),
      }),
    });

    const result = await response.json();

    if (!response.ok) {
      return result;
    }

    dispatch({
      type: Types.SUCCESS,
    });

    return result;
  } catch (e) {
    dispatch({
      type: Types.ERROR,
      error: {
        visible: true,
        ...e,
      },
    });

    return false;
  }
};

export const checkUserAvailability = (type: number, data: string) => async (
  dispatch: Dispatch,
) => {
  try {
    dispatch({
      type: Types.INIT,
    });

    let payload =
      type === 1
        ? {
            type,
            email: data,
          }
        : {
            type,
            document: data
              .replace(/\./g, "")
              .replace(/\//g, "")
              .replace(/\-/g, ""),
          };

    const response = await fetch(
      `${API_URL_WITH_PORT(PORTS.LOGIN)}/accounts/check-availability`,
      {
        customUrl: true,
        method: "post",
        body: JSON.stringify(payload),
      },
    );
    const userDataIsTaken = !response.ok;
    dispatch({
      type: Types.SET_USER_DATA_TAKEN,
      payload: userDataIsTaken,
    });
    return userDataIsTaken;
  } catch (e) {
    dispatch({
      type: Types.ERROR,
      error: {
        visible: false,
      },
    });
    return false;
  }
};
