import {
  Checkbox,
  Button,
  Grid,
  Link,
  CircularProgress,
} from "@material-ui/core";
/** @jsx jsx */
import React, { Fragment, useState } from "react";
import { css, jsx } from "@emotion/core";

const OnboardingOriginalTerms: React.FC<any> = ({
  loading,
  handleFinalization,
  handleGoBack,
  fileBase64,
}) => {
  const [currentTermIndex, setCurrentTerm] = useState(0);
  const [termConfirmed, setTermConfirmed] = useState(false);

  const config: any = [
    {
      name: "Termo de Consentimento",
      url: "/terms/Termo_Consentimento_Chave_Pix_Aleatoria.v1.pdf",
    },
    {
      name: "Condições Gerais dos Serviços de Cobrança PIX",
      url: "/terms/Termo_Gerais_Pix_Cobranca_Original.v1.pdf",
    },
    {
      name: "Condições Gerais e Proposta de Abertura de Conta de Pagamento",
      url: "",
      data: fileBase64,
    },
  ];

  const getCurrentPdf = () => {
    const { url, data } = config[currentTermIndex];

    if (!url) return `data:application/pdf;base64,${data}`;
    return url;
  };

  const getCurrentName = () => {
    return config[currentTermIndex].name;
  };

  const isLastTerm = () => {
    return currentTermIndex == config.length - 1;
  };

  return (
    <Fragment>
      <Grid>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          css={css`
            && {
              height: 400px;
            }
          `}
        >
          <object
            data={getCurrentPdf()}
            type="application/pdf"
            width="100%"
            height="100%"
          >
            <p>
              Exibir termos de uso <a href={getCurrentPdf()}> abrir link.</a>
            </p>
          </object>
        </Grid>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          css={css`
            && {
              margin-top: 24px;
            }
          `}
        >
          <Grid item xs={9}>
            <Checkbox
              checked={termConfirmed}
              disabled={loading}
              onClick={() => {
                setTermConfirmed(!termConfirmed);
              }}
            ></Checkbox>
            <label
              htmlFor="acceptsTerms"
              onClick={() => {
                setTermConfirmed(!termConfirmed);
              }}
            >
              Declaro que li e aceito{" "}
              <Link
                href={getCurrentPdf()}
                target="_blank"
                css={css`
                  && {
                    text-decoration: underline;
                  }
                `}
              >
                {getCurrentName()}
              </Link>
            </label>
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            css={css`
              && {
                margin-top: 24px;
              }
            `}
          >
            {isLastTerm() && (
              <Grid item xs={4} sm={4} lg={4}>
                <Button
                  onClick={handleGoBack}
                  variant="outlined"
                  css={css`
                    && {
                      margin-top: 15px;
                      border-radius: 15px;
                    }
                  `}
                >
                  <div>Voltar</div>
                </Button>
              </Grid>
            )}

            <Grid item xs={9} sm={5}>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                css={css`
                  && {
                    margin-top: 20px;
                    border-radius: 15px;
                    width: 100%;
                  }
                `}
                disabled={loading || !termConfirmed}
                onClick={() => {
                  const next = currentTermIndex + 1;
                  if (next > 2) {
                    handleFinalization();
                    return;
                  }
                  setTermConfirmed(false);
                  setCurrentTerm(next);
                }}
              >
                <div>Continuar</div>
                {loading && (
                  <CircularProgress
                    size={24}
                    color="secondary"
                    css={css`
                      && {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        margin-top: -12px;
                        margin-left: -12px;
                      }
                    `}
                  />
                )}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default OnboardingOriginalTerms;
