import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  Grid,
  Paper,
  Slide,
  Snackbar,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { css, jsx } from "@emotion/core";

import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { Print } from "@material-ui/icons";
import TransactionCreated from "app/components/Transaction/TransactionCreated";
import { TransitionProps } from "@material-ui/core/transitions";
import { colors } from "shared/configs/styles.config";
import { formatterToCurrency } from "shared/utils/formatter.util";
import { loadNotifications } from "public/ducks/notification.duck";
import moment from "moment";
import { subscribeToChannel } from "shared/utils/websocketClient";
import { useDispatch } from "react-redux";

/** @jsx jsx */

function TransitionDown(props: TransitionProps) {
  return <Slide {...props} direction="down" />;
}
const useStyles = makeStyles({
  paymentInfoContainer: {
    minWidth: 275,
  },
  containerCardPayment: {
    backgroundColor: colors.secondary,
  },
});

export const subscribeTransactionInfo = (
  transactionInfo: any,
  dispatch: any,
) => {
  const { reference_id } = transactionInfo;
  const handler = (update: any, flags: any) => {
    dispatch(loadNotifications());
  };
  subscribeToChannel({
    channel: `/transactions/${reference_id}`,
    handler,
  });
};

const TransactionInfo: React.FC<any> = ({
  enable,
  setEnabled,
  transactionInfo,
  application,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [enablePrint, setEnablePrint] = useState(false);

  useEffect(() => {
    if (transactionInfo) subscribeTransactionInfo(transactionInfo, dispatch);
  }, [transactionInfo]);

  if (!enable || !transactionInfo || !transactionInfo.transaction_date)
    return <div></div>;
  let authorization_number: string = transactionInfo?.authorization_number?.toString();
  if (!authorization_number) authorization_number = "";

  return (
    <Grid item xs={12} sm={12}>
      <Grid
        container
        justify="flex-end"
        css={css`
          && {
            margin-top: 24px;
          }
        `}
      ></Grid>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        TransitionComponent={TransitionDown}
        open={true && !enablePrint}
        onClose={() => setEnabled(false)}
      >
        <Card>
          <CardHeader className={classes.containerCardPayment} />
          <CardHeader
            titleTypographyProps={{
              variant: "h6",
              color: "secondary",
            }}
            avatar={
              <CheckCircleOutlineIcon
                color="secondary"
                style={{ fontSize: 50 }}
              />
            }
            title="Transação registrada!"
            subheader={moment(transactionInfo.transaction_date).format("LLLL")}
            subheaderTypographyProps={{
              variant: "subtitle2",
            }}
          />

          <CardContent>
            <Paper variant="outlined" square>
              <Grid style={{ padding: "10px" }}>
                <Typography
                  variant="caption"
                  display="block"
                  color="textSecondary"
                >
                  Aplicativo:
                  {transactionInfo?.application_root?.name
                    ? `${transactionInfo?.application_root?.name} / ${transactionInfo?.application?.name}`
                    : transactionInfo?.application?.name}
                </Typography>
                {transactionInfo.payment_description &&
                  transactionInfo.payment_description.length > 1 && (
                    <Typography
                      variant="caption"
                      display="block"
                      color="textSecondary"
                    >
                      Desc. Pagamento: {transactionInfo.payment_description}
                    </Typography>
                  )}
                {transactionInfo?.transaction_amount > 0 && (
                  <Typography
                    variant="caption"
                    display="block"
                    color="textSecondary"
                  >
                    Valor:{" "}
                    {formatterToCurrency(transactionInfo.transaction_amount)}
                  </Typography>
                )}

                {authorization_number && (
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    display="block"
                  >
                    Nº Autorização:{" "}
                    {authorization_number?.length <= 10
                      ? authorization_number
                      : `${authorization_number.substring(
                          0,
                          5,
                        )}...${authorization_number.slice(-5)}`}
                  </Typography>
                )}

                <Typography
                  variant="caption"
                  color="textSecondary"
                  display="block"
                >
                  Referência: {transactionInfo.reference_id}
                </Typography>

                {transactionInfo.nsu && (
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    display="block"
                  >
                    NSU: {transactionInfo.nsu}
                  </Typography>
                )}

                {transactionInfo?.details_metadata?.reference && (
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    display="block"
                  >
                    Notificação enviada para:{" "}
                    <strong>
                      {`${transactionInfo?.details_metadata?.reference
                        .contato || ""}`.replace("55", "")}
                    </strong>
                  </Typography>
                )}

                {transactionInfo?.details_metadata?.reference
                  ?.numero_pedido && (
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    display="block"
                  >
                    Número do Pedido:{" "}
                    <strong>
                      {transactionInfo.details_metadata.reference.numero_pedido}
                    </strong>
                  </Typography>
                )}

                {transactionInfo.attendant_description &&
                  transactionInfo.attendant_description.length > 1 && (
                    <Typography
                      variant="caption"
                      display="block"
                      color="textSecondary"
                      css={css`
                        && {
                          margin-top: 15px;
                        }
                      `}
                    >
                      Atendente: {transactionInfo.attendant_description}
                    </Typography>
                  )}
              </Grid>
            </Paper>
          </CardContent>
          <CardActions>
            <Grid
              container
              justify="center"
              alignItems="center"
              direction="row"
            >
              <Grid container justify="center" alignItems="center">
                <Grid item xs={12} sm={12} lg={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    css={css`
                      && {
                        border-radius: 15px;
                      }
                    `}
                    onClick={() => {
                      setEnablePrint(true);
                    }}
                  >
                    Imprimir comprovante
                    <Print
                      fontSize={"small"}
                      css={css`
                        && {
                          margin-left: 5px;
                        }
                      `}
                    />
                  </Button>
                </Grid>
              </Grid>
              <Grid
                container
                justify="center"
                css={css`
                  && {
                    margin-top: 10px;
                    margin-bottom: 2px;
                  }
                `}
              >
                <Grid item xs={12} sm={12} lg={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    css={css`
                      && {
                        border-radius: 15px;
                      }
                    `}
                    onClick={() => {
                      setEnabled(false);
                    }}
                  >
                    OK
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </Snackbar>
      <Dialog open={enablePrint} onClose={() => setEnablePrint(false)}>
        <DialogContent>
          <TransactionCreated
            enable={enablePrint}
            transactionInfo={transactionInfo}
            application={application}
            handleClose={() => setEnablePrint(false)}
          />
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default TransactionInfo;
