let prefix = null;
if (process.env.REACT_APP_ENV)
  prefix = process.env.REACT_APP_ENV === "production" ? "" : "-hom";

let ENV = prefix ?? "-hom"; //"": Produção | "-hom": Homologação
const PROTOCOL = "https";
const DOMAIN = `api-login${ENV}.qrway.com.br`;
const PATH = "api";

export const PORTS = {
  LOGIN: 2000,
  ATTENDANTS: 2001,
  APPLICATION: 2010,
  TRANSACTION: 2020,
  MERCHANT: 2030,
  EXTERNAL: 2060,
  DOMAIN: 4600,
  WEBSOCKET: 4700,
  MESSAGES: 4800,
};

export const API_URL = `${PROTOCOL}://${DOMAIN}/${PATH}`;

export const API_URL_WITH_PORT = (port: number) => {
  let serviceDomain = DOMAIN;
  switch (port) {
    case 2001:
      serviceDomain = `api-attendants${ENV}.qrway.com.br`;
      break;
    case 2010:
      serviceDomain = `api-application${ENV}.qrway.com.br`;
      break;
    case 2020:
      serviceDomain = `api-transaction${ENV}.qrway.com.br`;
      break;
    case 2030:
      serviceDomain = `api-merchant${ENV}.qrway.com.br`;
      break;
    case 2060:
      serviceDomain = `api-external${ENV}.qrway.com.br`;
      break;
    case 4600:
      serviceDomain = `api-domain${ENV}.qrway.com.br`;
      break;
    case 4700:
      return `wss://api-websocket${ENV}.qrway.com.br`;
    case 4800:
      serviceDomain = `api-websocket${ENV}.qrway.com.br`;
      break;
    default:
      break;
  }
  return `${PROTOCOL}://${serviceDomain}/${PATH}`;
};
