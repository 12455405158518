import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Dialog,
  DialogActions,
  Grid,
  Typography,
} from "@material-ui/core";
import React, { Fragment } from "react";
import { css, jsx } from "@emotion/core";
import { EMerchantApplicationStatus } from "app/ducks/applications.duck";

/** @jsx jsx */
var parseHtml = require("html-react-parser");

const AboutInfo: React.FC<any> = ({
  app,
  enable,
  appIsDisabled,
  handleClose,
}) => {
  if (!enable) {
    return <div></div>;
  }

  const parseStatus = (status: EMerchantApplicationStatus) => {
    switch(status) {
      case EMerchantApplicationStatus.Disponivel:
        return "Disponível";
      case EMerchantApplicationStatus.AtivacaoEmProcesso:
        return "Ativação Em Processo";
      case EMerchantApplicationStatus.AtivacaoSolicitada:
        return "Ativação Solicitada";
      case EMerchantApplicationStatus.Ativo:
        return "Ativo";
      default:
        return null;
    }
  }

  return (
    <Grid item xs={12} sm={12}>
      <Grid
        container
        justify="flex-end"
        css={css`
          && {
            margin-top: 24px;
          }
        `}
      ></Grid>
      <Dialog
        onClose={() => handleClose(false)}
        aria-labelledby="simple-dialog-title"
        open={true}
        fullWidth={true}
      >
        {!app?.about_html && (
          <Card elevation={0}>
            <CardHeader
              avatar={
                <CardMedia
                  css={css`
                    && {
                      height: 0;
                      width: 150px;
                      padding-top: 80%;
                    }
                  `}
                  image={app.logo_path || app.logo_path}
                  title={app.name}
                />
              }
              title={`Aplicativo ${app.name}`}
            />

            <CardContent>
              <Typography variant="body2" color="textSecondary" component="p">
                <Fragment>
                  Status: {parseStatus(app?.merchant_application[0]?.status) || (appIsDisabled(app) ? "Inativo" : "Ativo")}
                </Fragment>
              </Typography>
            </CardContent>
          </Card>
        )}

        {app?.about_html && parseHtml(app?.about_html)}

      </Dialog>
    </Grid>
  );
};

export default AboutInfo;
