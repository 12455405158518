import { Types as TypesAuth, logoff } from "shared/ducks/authentication.duck";
import getToken, {
  isUserAllowedToAccessOrigin,
} from "shared/utils/getToken.util";

import decode from "jwt-decode";

export default ({ dispatch }: any) => (next: any) => (action: any) => {
  const { error, type } = action;
  const { statusCode, forceLogout } = error || {};
  const UNAUTHORIZED = 401;

  let originInvalid = false;
  if (type && type.includes(TypesAuth.SET_MERCHANT_PARAMS)) {
    // before set params check if user is allowed to access this origin
    const token = getToken();
    const decodedToken = decode(token);
    originInvalid = !isUserAllowedToAccessOrigin(decodedToken);
  }

  var isResetLogin = type === "shared/authentication/RESET_ERROR";
  if (
    isResetLogin ||
    (TypesAuth.ERROR !== type && +statusCode === UNAUTHORIZED) ||
    originInvalid
  ) {
    return dispatch(logoff());
  }

  return next(action);
};
