import {
  CustomMuiTheme,
  MuiDefaultOptions,
  useTableCustomStyles,
} from "app/components/Grid/MuiTable";
import { Grid, IconButton, ThemeProvider } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { css, jsx } from "@emotion/core";

import MUIDataTable from "mui-datatables";
import { Skeleton } from "@material-ui/lab";
import { formatterToBrlCurrency } from "shared/utils/formatter.util";
import moment from "moment";
import ListAltIcon from "@material-ui/icons/ListAlt";
import TableDefault from "app/components/TableDefault";
import ConciliationInformation from "./ConciliationInformation";

/** @jsx jsx */

const Screen: React.FC<any> = ({
  stateConciliations,
  loading,
  filters,
  selectedType,
}) => {
  const [info, setInfo] = useState<any>();
  const [modalInfo, setModalInfo] = useState(false);

  const { Analytical, Charges, Consolidated, Devolutions, Transfers } =
    stateConciliations || {};

  console.log("stateConciliations", selectedType, stateConciliations);

  const defaultSubtitle = (
    <small>
      Período:{" "}
      {moment(filters.selectedDateBegin).isValid()
        ? moment(filters.selectedDateBegin).format("DD/MM/YYYY")
        : "-"}{" "}
      até{" "}
      {moment(filters.selectedDateEnd).isValid()
        ? moment(filters?.selectedDateEnd).format("DD/MM/YYYY")
        : "-"}{" "}
    </small>
  );

  if (selectedType === "Analytical") {
    const { data } = Analytical || {};
    const { transfers } = data || {};
    return (
      <Fragment>
        <TableDefault
          loading={loading}
          title={"Consulta - Analítico"}
          subtitle={defaultSubtitle}
          data={transfers}
          columns={[
            {
              name: "e2eid",
              label: "ID",
              options: {
                filter: true,
                sort: true,
              },
            },
            {
              name: "documentNumber",
              label: "Estabelecimento",
              options: {
                filter: true,
                sort: true,
                customBodyRender: (
                  value: any,
                  tableMeta: any,
                  updateValue: any,
                ) => {
                  const document_number = (value || "").replace(
                    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                    "$1.$2.$3/$4-$5",
                  );
                  return document_number;
                },
              },
            },
            {
              name: "transactionDate",
              label: "Data",
              options: {
                filter: true,
                sort: true,
                sortDirection: "desc",
                customBodyRender: (
                  value: any,
                  tableMeta: any,
                  updateValue: any,
                ) => {
                  return moment(value).format("DD/MM/YYYY HH:mm");
                },
              },
            },
            {
              name: "amountValue",
              label: "Valor",
              options: {
                filter: true,
                sort: true,
                customBodyRender: (
                  value: any,
                  tableMeta: any,
                  updateValue: any,
                ) => {
                  return formatterToBrlCurrency(value);
                },
              },
            },
            {
              name: "amoutBalance",
              label: "Valor Bruto",
              options: {
                filter: true,
                sort: true,
                customBodyRender: (
                  value: any,
                  tableMeta: any,
                  updateValue: any,
                ) => {
                  return formatterToBrlCurrency(value);
                },
              },
            },
            {
              name: "fee",
              label: "Taxa",
              options: {
                filter: true,
                sort: true,
                customBodyRender: (
                  value: any,
                  tableMeta: any,
                  updateValue: any,
                ) => {
                  return formatterToBrlCurrency(value);
                },
              },
            },
            {
              name: "totalFee",
              label: "Valor Taxa",
              options: {
                filter: true,
                sort: true,
                customBodyRender: (
                  value: any,
                  tableMeta: any,
                  updateValue: any,
                ) => {
                  return formatterToBrlCurrency(value);
                },
              },
            },
            {
              name: "amountDevolution",
              label: "Valor Devolução",
              options: {
                filter: true,
                sort: true,
                customBodyRender: (
                  value: any,
                  tableMeta: any,
                  updateValue: any,
                ) => {
                  return formatterToBrlCurrency(value);
                },
              },
            },
            {
              name: "status",
              label: "Status",
              options: {
                filter: true,
                sort: true,
              },
            },
            {
              name: "",
              options: {
                filter: true,
                sort: true,
                customBodyRender: (
                  value: any,
                  tableMeta: any,
                  updateValue: any,
                ) => {
                  const { rowData } = tableMeta;
                  const row: any = transfers.find(
                    (f: any) => f.e2eid === rowData[0],
                  );
                  console.log("Row", row, rowData);
                  const { chargesList } = row;

                  return (
                    <IconButton
                      aria-label="informacoes"
                      color="primary"
                      title="Exibir informações extras"
                      onClick={() => {
                        setInfo(chargesList);
                        setModalInfo(true);
                      }}
                    >
                      <ListAltIcon />
                    </IconButton>
                  );
                },
              },
            },
          ]}
        />
        <ConciliationInformation
          loading={loading}
          enable={modalInfo}
          onClose={setModalInfo}
          info={info}
        />
      </Fragment>
    );
  }

  if (selectedType === "Charges") {
    const { data: dataCharges } = Charges || {};
    const { charges } = dataCharges || {};
    return (
      <TableDefault
        loading={loading}
        title={"Consulta - Cobranças"}
        subtitle={defaultSubtitle}
        data={charges}
        columns={[
          {
            name: "documentNumber",
            label: "Estabelecimento",
            options: {
              filter: true,
              sort: true,
              customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
              ) => {
                const document_number = (value || "").replace(
                  /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                  "$1.$2.$3/$4-$5",
                );
                return document_number;
              },
            },
          },
          {
            name: "transferDate",
            label: "Data",
            options: {
              filter: true,
              sort: true,
              sortDirection: "desc",
              customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
              ) => {
                return moment(value).format("DD/MM/YYYY HH:mm");
              },
            },
          },
          {
            name: "transactionAmount",
            label: "Valor",
            options: {
              filter: true,
              sort: true,
              customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
              ) => {
                return formatterToBrlCurrency(value);
              },
            },
          },
          {
            name: "transferAmount",
            label: "Valor Transferência",
            options: {
              filter: true,
              sort: true,
              customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
              ) => {
                return formatterToBrlCurrency(value);
              },
            },
          },
          {
            name: "fee",
            label: "Taxa",
            options: {
              filter: true,
              sort: true,
              customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
              ) => {
                return formatterToBrlCurrency(value);
              },
            },
          },
          {
            name: "statusCharge",
            label: "Status Cobrança",
            options: {
              filter: true,
              sort: true,
            },
          },
          {
            name: "statusTransfer",
            label: "Status Transferência",
            options: {
              filter: true,
              sort: true,
            },
          },
        ]}
      />
    );
  }

  if (selectedType === "Consolidated") {
    const { data } = Consolidated || {};
    return (
      <TableDefault
        loading={loading}
        title={"Consulta - Consolidado"}
        subtitle={defaultSubtitle}
        data={[{ ...data }]}
        columns={[
          {
            name: "amountBalance",
            label: "Valor Bruto",
            options: {
              filter: true,
              sort: true,
              customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
              ) => {
                return formatterToBrlCurrency(value);
              },
            },
          },
          {
            name: "amountValue",
            label: "Valor Líquido",
            options: {
              filter: true,
              sort: true,
              customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
              ) => {
                return formatterToBrlCurrency(value);
              },
            },
          },
          {
            name: "transactionsFee",
            label: "Valor Taxas",
            options: {
              filter: true,
              sort: true,
              customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
              ) => {
                return formatterToBrlCurrency(value);
              },
            },
          },
        ]}
      />
    );
  }

  if (selectedType === "Devolutions") {
    const { data: dataDevolutions } = Devolutions || {};
    const { devolutions } = dataDevolutions || {};
    return (
      <TableDefault
        loading={loading}
        title={"Consulta - Devoluções"}
        subtitle={defaultSubtitle}
        data={devolutions}
        columns={[
          {
            name: "txid",
            label: "ID",
            options: {
              filter: true,
              sort: true,
            },
          },
          {
            name: "transferDate",
            label: "Data",
            options: {
              filter: true,
              sort: true,
              sortDirection: "desc",
              customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
              ) => {
                return moment(value).format("DD/MM/YYYY HH:mm");
              },
            },
          },
          {
            name: "devolutionDate",
            label: "Data Devolução",
            options: {
              filter: true,
              sort: true,
              customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
              ) => {
                return moment(value).format("DD/MM/YYYY HH:mm");
              },
            },
          },
          {
            name: "devolutionAmount",
            label: "Valor Devolução",
            options: {
              filter: true,
              sort: true,
              customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
              ) => {
                return formatterToBrlCurrency(value);
              },
            },
          },
          {
            name: "status",
            label: "Status",
            options: {
              filter: true,
              sort: true,
            },
          },
        ]}
      />
    );
  }

  if (selectedType === "Transfers") {
    const { data } = Transfers || {};
    const { transfers } = data || {};
    return (
      <TableDefault
        loading={loading}
        title={"Consulta - Transferências"}
        subtitle={defaultSubtitle}
        data={transfers}
        columns={[
          {
            name: "documentNumber",
            label: "Estabelecimento",
            options: {
              filter: true,
              sort: true,
              customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
              ) => {
                const document_number = (value || "").replace(
                  /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                  "$1.$2.$3/$4-$5",
                );
                return document_number;
              },
            },
          },
          {
            name: "transactionDate",
            label: "Data",
            options: {
              filter: true,
              sort: true,
              sortDirection: "desc",
              customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
              ) => {
                return moment(value).format("DD/MM/YYYY HH:mm");
              },
            },
          },
          {
            name: "amountValue",
            label: "Valor",
            options: {
              filter: true,
              sort: true,
              customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
              ) => {
                return formatterToBrlCurrency(value);
              },
            },
          },
          {
            name: "amoutBalance",
            label: "Valor Bruto",
            options: {
              filter: true,
              sort: true,
              customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
              ) => {
                return formatterToBrlCurrency(value);
              },
            },
          },
          {
            name: "fee",
            label: "Taxa",
            options: {
              filter: true,
              sort: true,
              customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
              ) => {
                return formatterToBrlCurrency(value);
              },
            },
          },
          {
            name: "totalFee",
            label: "Valor Taxa",
            options: {
              filter: true,
              sort: true,
              customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
              ) => {
                return formatterToBrlCurrency(value);
              },
            },
          },
          {
            name: "amountDevolutions",
            label: "Valor Devolução",
            options: {
              filter: true,
              sort: true,
              customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
              ) => {
                return formatterToBrlCurrency(value);
              },
            },
          },
          {
            name: "status",
            label: "Status",
            options: {
              filter: true,
              sort: true,
            },
          },
        ]}
      />
    );
  }

  return <Grid></Grid>;
};

export default Screen;
