import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import {
  handleAddAttendant,
  handleGetAttendants,
  handleUpdateAttendant,
} from "app/ducks/attendants.duck";

import React from "react";
import Screen from "./Attendants.screen";
import { stateReduxType } from "./Attendants.type";
import { useSnackbar } from "notistack";
import { stateReduxType as stateReduxTypeAuth } from "shared/types/authentication.type";

const useTypedSelectorApps: TypedUseSelectorHook<stateReduxType> = useSelector;

const useTypedSelectorAuth: TypedUseSelectorHook<stateReduxTypeAuth> = useSelector;

const Container: React.FC<any> = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { attendants } = useTypedSelectorApps(state => state.app) || {};
  const { authentication } = useTypedSelectorAuth(state => state.shared) || {};
  const { data: userData } = authentication;
  const { user } = userData;
  const merchant = user?.merchants[0];

  const handleUpdate = async (attendant: any) => {
    const response: any = await dispatch(handleUpdateAttendant(attendant));
    if (response.error) {
      return enqueueSnackbar(response.message, {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      });
    }

    return enqueueSnackbar("Usuário atualizado com sucesso", {
      autoHideDuration: 3000,
      variant: "success",
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
    });
  };
  const handleCreate = async (app: any) => {
    const { user } = userData;
    const response: any = await dispatch(
      handleAddAttendant({
        ...app,
        merchant_id: user.merchants[0].id,
      }),
    );
    if (response.error) {
      return enqueueSnackbar(response.message, {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      });
    }

    return enqueueSnackbar("Usuário adicionado com sucesso", {
      autoHideDuration: 3000,
      variant: "success",
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
    });
  };
  return (
    <Screen
      handleGet={() => {
        dispatch(
          handleGetAttendants({
            merchant_id: merchant && merchant.id,
          }),
        );
      }}
      handleUpdate={handleUpdate}
      handleAdd={handleCreate}
      attendantsList={attendants}
    />
  );
};

export default Container;
