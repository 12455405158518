import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from "@material-ui/core";
import { css, jsx } from "@emotion/core";

import { EMerchantApplicationStatus } from "app/ducks/applications.duck";
import React from "react";
import { getIntegrationTypeFlags } from "app/components/getAppTypeFlags";

/** @jsx jsx */

const handleImageError = (e: any) => {
  e.target.onerror = null;
  e.target.src = "https://via.placeholder.com/200x60?text=App+Sem+Logo+:(";
};

const AppCard: React.FC<any> = ({
  app,
  setSelectedApp,
  appIsDisabled,
  handleSelectApplication,
  setStartOnboarding,
  openAppMenu,
  history,
  loading,
  appOnboardingIsAvailable,
}) => {
  const parseStatus = (status: EMerchantApplicationStatus) => {
    switch (status) {
      case EMerchantApplicationStatus.Disponivel:
        return "ATIVAÇÃO DISPONÍVEL";
      case EMerchantApplicationStatus.AtivacaoEmProcesso:
        return "ATIVAÇÃO EM PROCESSO";
      case EMerchantApplicationStatus.AtivacaoSolicitada:
        return "ATIVAÇÃO SOLICITADA";
      case EMerchantApplicationStatus.Ativo:
        return "ATIVO";
      default:
        return "INATIVO";
    }
  };
  const appHasOnboarding = (app: any) => {
    return app.enable_onboarding;
  };

  const appHasCustomFlow = (app: any) => {
    return !!app.enable_custom_flow;
  };

  const handleAppClick = () => {
    setSelectedApp(app);

    if (
      appIsDisabled(app) &&
      appHasOnboarding(app) &&
      appOnboardingIsAvailable(app)
    ) {
      setSelectedApp(app);
      setStartOnboarding(true);
      return;
    }

    handleSelectApplication(app);

    if (appHasCustomFlow(app)) {
      const {
        isPinbankApp,
        isAmeApp,
        isPayInsightsApp,
        isUsePayApp,
        isTicketApp,
        isPagamentoLink,
      } = getIntegrationTypeFlags(app?.issuer_entity?.identifier_code);

      if (isUsePayApp) history.push(app?.custom_flow_path || "/app/usepay");
      if (isPagamentoLink) history.push(app?.custom_flow_path || "/app/link");
      return;
    }

    history.push("/app/new-transaction");
  };

  return (
    <Grid
      item
      xs={"auto"}
      component={Card}
      key={app.id}
      title={app.name}
      css={css`
        && {
          @media (max-width: 960px) {
            width: 100px !important;
            height: 100px !important;
            margin-bottom: 8px;
            margin-left: 8px;
            position: relative;
          }
          @media (min-width: 600px) {
            width: 200px !important;
            height: 180px !important;
            margin-bottom: 15px;
            margin-left: 15px;
            position: relative;
          }
        }
      `}
    >
      <CardActionArea onClick={handleAppClick} disabled={appIsDisabled(app)}>
        <CardContent>
          <img
            onError={handleImageError}
            width="150px"
            src={app.logo_path}
            alt={app.name}
          />
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          css={css`
            z-index: 100;
          `}
        >
          <Grid
            item
            css={css`
              position: absolute;
              bottom: 0;
              left: 5;
            `}
          >
            {appIsDisabled(app) &&
              appHasOnboarding(app) &&
              appOnboardingIsAvailable(app) && (
                <Button
                  css={css`
                    min-width: unset !important;
                  `}
                  onClick={() => {
                    setSelectedApp(app);
                    setStartOnboarding(true);
                  }}
                  disabled={loading}
                >
                  <Typography
                    variant={"button"}
                    css={css`
                      color: #f48120;
                      font-weight: bold;
                      @media (max-width: 960px) {
                        font-size: 9px !important;
                      }
                      @media (min-width: 600px) {
                        font-size: 12px !important;
                      }
                    `}
                  >
                    ATIVAR
                  </Typography>
                </Button>
              )}
            {appIsDisabled(app) &&
              !(appHasOnboarding(app) && appOnboardingIsAvailable(app)) && (
                <Typography
                  css={css`
                    color: grey;
                    font-weight: bold !important;
                    @media (max-width: 960px) {
                      font-size: 9px !important;
                    }
                    @media (min-width: 600px) {
                      font-size: 12px !important;
                    }
                  `}
                >
                  {parseStatus(app?.merchant_application[0]?.status)}
                </Typography>
              )}
          </Grid>
          <Grid
            item
            css={css`
              position: absolute;
              bottom: 0;
              right: 0;
              @media (max-width: 960px) {
                width: 33%;
              }
              @media (min-width: 600px) {
                width: unset;
              }
            `}
          >
            <Button
              css={css`
                min-width: unset !important;
              `}
              onClick={e => {
                openAppMenu(e, app);
              }}
            >
              <img
                css={css`
                  z-index: 100;
                  cursor: pointer;
                `}
                src={"/assets/ico-menu-dots.svg"}
                alt={"..."}
              />
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Grid>
  );
};

export default AppCard;
