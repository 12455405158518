import { API_URL_WITH_PORT, PORTS } from "shared/configs/api.config";

import { Dispatch } from "redux";
import fetch from "shared/utils/fetch.util";
import { stateReduxApplications } from "../screens/Apps/Apps.type";

export enum EMerchantApplicationStatus {
  Ativo = "ATIVO",
  Disponivel = "DISPONIVEL",
  AtivacaoSolicitada = "ATIVACAO_SOLICITADA",
  AtivacaoEmProcesso = "ATIVACAO_EM_PROCESSO",
}

type Actions = {
  type: string;
  payload?: object;
  error?: object;
};

export const Types = {
  INIT: "app/applications/INIT",
  SUCCESS: "app/applications/SUCCESS",
  ERROR: "app/applications/ERROR",
  SET_APPLICATION: "app/applications/SET_APPLICATION",
  SET_LOADING: "app/applications/SET_LOADING",
};

const initialState: stateReduxApplications = {
  apps: [],
  app: null,
  loading: false,
  error: null,
};

export default (state = initialState, action: Actions) => {
  const { type, payload, error } = action;

  const reducers = {
    [Types.INIT]: {
      ...state,
      apps: [],
      error: null,
      loading: true,
    },
    [Types.ERROR]: {
      loading: false,
      apps: [],
      error,
    },
    [Types.SUCCESS]: {
      loading: false,
      apps: payload,
      error: false,
    },
    [Types.SET_APPLICATION]: {
      ...state,
      // apps: [],
      app: payload,
    },
    [Types.SET_LOADING]: {
      ...state,
      loading: payload,
    },
  };

  return reducers[type] || state;
};

export const getApplications = ({ issuer_id }: any) => async (
  dispatch: Dispatch<any>,
) => {
  try {
    dispatch({
      type: Types.INIT,
    });

    let queryParams = "";
    if (issuer_id) {
      queryParams = `?issuer_id=${issuer_id}`;
    }

    const response = await fetch(
      `${API_URL_WITH_PORT(PORTS.APPLICATION)}/applications${queryParams}`,
      {
        customUrl: true,
        method: "GET",
        auth: true,
      },
    );

    const result = await response.json();
    if (!response.ok) {
      throw result;
    }

    const { results } = result;
    dispatch({
      type: Types.SUCCESS,
      payload: results,
    });

    return results;
  } catch (e) {
    dispatch({
      type: Types.ERROR,
      error: {
        visible: true,
        ...e,
      },
    });
    return false;
  }
};

export const handleSelectApplication = (app: any) => async (
  dispatch: Dispatch<any>,
) => {
  dispatch({
    type: Types.SET_APPLICATION,
    payload: app,
  });
};

export const handleUpdateMerchantApplication = ({
  merchant_id,
  application_id,
  payload,
}: any) => async (dispatch: Dispatch<any>) => {
  try {
    dispatch({
      type: Types.SET_LOADING,
      payload: true,
    });
    const response = await fetch(
      `${API_URL_WITH_PORT(
        PORTS.APPLICATION,
      )}/applications/${application_id}/merchant/${merchant_id}`,
      {
        customUrl: true,
        method: "PATCH",
        auth: true,
        body: JSON.stringify({
          ...payload,
        }),
      },
    );

    const result = await response.json();
    if (!response.ok) {
      throw result;
    }
    dispatch(getApplications({}));

    return result;
  } catch (e) {
    const error = {
      visible: true,
      ...e,
    };
    return error;
  }
};
