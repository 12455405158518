import React, { useEffect } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import {
  loadNotifications,
  setReadAllMessages,
} from "public/ducks/notification.duck";

import HeaderScreen from "./Header.screen";
import { stateReduxType as appsStateReduxType } from "../../screens/Apps/Apps.type";
import { loadMerchantParams } from "shared/ducks/authentication.duck";
import { logoff } from "shared/ducks/authentication.duck";
import { stateReduxType } from "shared/types/authentication.type";
import { useHistory } from "react-router-dom";

const useTypedSelector: TypedUseSelectorHook<stateReduxType> = useSelector;
const useTypedSelectorApps: TypedUseSelectorHook<appsStateReduxType> = useSelector;

const HeaderContainer: React.FC<any> = ({ loading }) => {
  const dispatch = useDispatch();

  const { authentication } = useTypedSelector(state => state.shared) || {};
  const { applications } = useTypedSelectorApps(state => state.app) || {};
  const { notification } = useSelector((state: any) => state.public) || {};
  const { messages } = notification;
  const { apps } = applications;
  const { data: auth, params: merchantParams } = authentication;

  let history = useHistory();
  useEffect(() => {
    if (!merchantParams) dispatch(loadMerchantParams(auth));
  }, []);

  return (
    <HeaderScreen
      handleLogout={logoff}
      authentication={authentication}
      history={history}
      loading={loading}
      applications={apps}
      merchantParams={merchantParams}
      // messages={messages}
      messages={[]}
      setReadAllMessages={() => {
        // dispatch(setReadAllMessages());
      }}
      loadNotifications={() => {
        //dispatch(loadNotifications());
      }}
    />
  );
};

export default HeaderContainer;
