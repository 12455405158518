import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import {
  handleCheckPayment,
  handleListTransactions,
} from "app/ducks/transactions.duck";

import React from "react";
import { Redirect } from "react-router-dom";
import Screen from "./ReportMatching.screen";
import { stateReduxType as appsStateReduxType } from "app/screens/Apps/Apps.type";
import { stateReduxType as authStateType } from "shared/types/authentication.type";
import { getApplications } from "app/ducks/applications.duck";
import { getIssuers } from "app/ducks/issuer.duck";
import { getTransactions } from "app/ducks/reports.duck";
import { stateReduxType } from "./ReportMatching.type";
import { stateReduxType as transactionsStateType } from "app/screens/Transactions/Transactions.type";

const useTypedSelectorGeneric: TypedUseSelectorHook<any> = useSelector;
const useTypedSelector: TypedUseSelectorHook<authStateType> = useSelector;
const useTypedSelectorReports: TypedUseSelectorHook<stateReduxType> = useSelector;
const useTypedSelectorTransactions: TypedUseSelectorHook<transactionsStateType> = useSelector;
const useTypedSelectorApps: TypedUseSelectorHook<appsStateReduxType> = useSelector;

const ApplicationsContainer: React.FC<any> = ({ history }) => {
  const dispatch = useDispatch();

  const { reports } = useTypedSelectorReports(state => state.app) || {};

  const { authentication } = useTypedSelector(state => state.shared) || {};
  const { signedIn, data: auth } = authentication;
  const { user } = auth;
  const { activeMerchant, merchants: availableMerchants } = user;
  const { transactions } =
    useTypedSelectorTransactions(state => state.app) || {};

  const { issuer } = useTypedSelectorGeneric(state => state.app) || {};

  const { applications } = useTypedSelectorApps(state => state.app) || {};
  const { apps } = applications;

  if (!signedIn) {
    return <Redirect to="/login" />;
  }

  const handleGetTransactions = (params: any) =>
    dispatch(
      getTransactions({
        ...params,
      }),
    );

  const handleDispatchCheckPayment = (params: any) =>
    dispatch(
      handleCheckPayment({
        ...params,
      }),
    );

  const handleDispatchListTransactions = (params: any) =>
    dispatch(
      handleListTransactions({
        ...params,
      }),
    );

  const handleDispatchGetIssuers = (params: any) =>
    dispatch(
      getIssuers({
        ...params,
      }),
    );

  const handleDispatchGetApplications = (params: any) =>
    dispatch(
      getApplications({
        ...params,
      }),
    );

  return (
    <Screen
      history={history}
      getTransactions={handleGetTransactions}
      getListTransactions={handleDispatchListTransactions}
      getIssuers={handleDispatchGetIssuers}
      checkPayment={handleDispatchCheckPayment}
      transactionsExtState={transactions}
      reportsState={reports}
      issuerState={issuer}
      getApps={handleDispatchGetApplications}
      applications={apps}
      activeMerchant={activeMerchant}
      availableMerchants={availableMerchants}
    />
  );
};

export default ApplicationsContainer;
