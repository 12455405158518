import { combineReducers } from "redux";

type listOfReducersType = {
  name: string;
  path: string;
};

const reducers = {};

const listOfReducers: Array<listOfReducersType> = [
  {
    name: "createAccount",
    path: "createAccount",
  },
  {
    name: "domainData",
    path: "domainData.duck",
  },
  {
    name: "transaction",
    path: "transaction.duck",
  },
  {
    name: "notification",
    path: "notification.duck",
  },
];

listOfReducers.map(elm =>
  Object.assign(reducers, {
    ...reducers,
    [elm.name]: require(`./${elm.path}`).default,
  }),
);

export default combineReducers({
  ...reducers,
});
