import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import FormCreateAccountComponent from "./FormCreateAccount.component";
import React from "react";
import Schema from "./FormCreateAccount.schema";
import { createAccount } from "../../ducks/createAccount/createAccount.duck";
import { useSnackbar } from "notistack";

const useTypedSelector: TypedUseSelectorHook<any> = useSelector;

const FormCreateAccountContainer: React.FC<any> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { createAccount: createAccountState } =
    useTypedSelector(state => state.public) || {};
  const { userIsTaken } = createAccountState;

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const apps = params.getAll("apps");
  const channel = params.get("channel") || "QRWAY";
  const origin = params.get("origin") || "Portal";

  const handleCreateAccount = async (data: any) => {
    const response: any = await dispatch(
      createAccount({
        ...data,
        apps,
        merchant: {
          ...data.merchant,
          channel,
          origin,
        },
      }),
    );

    if (response.error) {
      return enqueueSnackbar(response.message, {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      });
    }
    history.push("/");
    return enqueueSnackbar(
      "Cadastro realizado com sucesso, confirme o e-email para efetuar o login",
      {
        autoHideDuration: 3000,
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      },
    );
  };

  const initialSchema = useSelector(
    (state: any) => state.public.createAccount.form,
  );

  return (
    <FormCreateAccountComponent
      initialSchema={initialSchema}
      formSchema={Schema}
      handleCreateAccount={handleCreateAccount}
      userIsTaken={userIsTaken}
    />
  );
};

export default FormCreateAccountContainer;
