export enum enumPersonType {
  CPF = "PF",
  CNPJ = "PJ",
}

export type responseToApi = {
  user: {
    email: string;
    document_number: string;
    phone_prefix: null | number; //
    phone_number: string; //
    password: string;
  };
  merchant: {
    contact_name: string; //
    document_number: string; //
    phone_prefix: null | number; //
    phone_number: string; //
    cnae: string; //
    mcc: string;
    constitution_date: string;
    type: enumPersonType; //
    accept_terms: boolean; //
    address: {
      zip_code: string; //
      street: string; //
      number: string; //
      complement: string; //
      neighborhood: string; //
      city: string; //
      state: string; //
      receipt_path: string;
    };
    bank_account: {
      bank_number: string;
      branch_number: string;
      account_number: string;
      account_digit: string;
    };
    trading_name: string; //
    company_name: string; //
  };
  apps: [];
};

export type formType = responseToApi & {
  user: {
    confirm_password: string;
  };
};

export type stateTypes = {
  loading: boolean;
  error: any;
  data: any;
  form: formType;
};
