import "moment/locale/pt-br";

import {
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React, { Fragment, useEffect, useState } from "react";
import { css, jsx } from "@emotion/core";

import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import Header from "app/components/Header/Header.container";
import MomentUtils from "@date-io/moment";
import SearchIcon from "@material-ui/icons/Search";
import TransactionsRefunds from "./components/TransactionsRefunds";
import moment from "moment";
import { screenPropsType } from "./ReportRefund.type";
import AppSelector from "../Apps/components/AppSelector";
import MerchantSelector from "app/components/Fields/MerchantSelector";
import { Autocomplete } from "@material-ui/lab";

/** @jsx jsx */

enum ConciliationTypeDisplay {
  Analytical = "Analítico",
  Charges = "Cobranças",
  Consolidated = "Consolidado",
  Devolutions = "Devoluções",
  Transfers = "Transferências",
}

const parseConsolidationTypes = (typeDisplay: any) => {
  return Object.entries(ConciliationTypeDisplay).find(
    ([key, val]) => val === typeDisplay,
  )?.[0];
};

const Screen: React.FC<screenPropsType> = ({
  history,
  getConciliations,
  conciliationsState,
  getApps,
  applications,
  activeMerchant,
  availableMerchants,
}) => {
  const { loading, stateConciliations } = conciliationsState;

  // only for app original atm
  const [availableApps, setAvailableApps] = useState<any>(applications);

  // merchant
  const [selectedMerchants, setSelectedMerchants] = useState<any>([]);
  const handleMerchantChange = (event: any, value: any) => {
    if (value) setSelectedMerchants([value]);
  };
  useEffect(() => {
    if (activeMerchant)
      setSelectedMerchants(
        availableMerchants.filter((m: any) => m.id === activeMerchant.id),
      );
  }, [activeMerchant]);

  // dates
  const [selectedDateBegin, setSelectedDateBegin] = useState(
    new Date(new Date()).setDate(new Date().getDate() - 1),
  );
  const [selectedDateEnd, setSelectedDateEnd] = useState(
    new Date().setDate(new Date().getDate() - 1),
  );

  const handleDateBeginChange = (date: any) => {
    if (!date)
      setSelectedDateBegin(
        new Date(new Date()).setDate(new Date().getDate() - 1),
      );
    else setSelectedDateBegin(date);
  };

  const handleDateEndChange = (date: any) => {
    if (!date) setSelectedDateEnd(new Date().setDate(new Date().getDate() - 1));
    else setSelectedDateEnd(date);
  };

  // application
  const [selectedApp, setSelectedApp] = useState<any>({
    name: "",
    id: "",
  });

  useEffect(() => {
    if (applications && applications.length === 0) {
      getApps({});
    }
  }, []);

  useEffect(() => {
    if (applications && applications.length > 0) {
      const apps = applications.filter(
        (f: any) => f.name.indexOf("Banco Original") > 0,
      );
      setAvailableApps(apps);
      setSelectedApp(apps[0]);
    }
  }, [applications]);

  const handleChangeApp = (event: any, value: any) => {
    if (value) setSelectedApp(value);
  };

  const [selectedType, setSelectedType] = useState<any>(
    ConciliationTypeDisplay.Analytical,
  );

  // Run Action
  const handleRunGetConciliation = () => {
    getConciliations({
      application_id: selectedApp?.id,
      dateBegin: selectedDateBegin,
      dateEnd: selectedDateEnd,
      // merchant_id: selectedMerchantsFirst,
      merchant_ids: selectedMerchants.map((it: any) => it.id),
      details: {
        type: parseConsolidationTypes(selectedType),
      },
    });
  };

  const isValid = () => {
    return (
      moment(selectedDateBegin).isValid() &&
      moment(selectedDateEnd).isValid() &&
      !!selectedApp?.id
    );
  };

  return (
    <Fragment>
      <Header authentication={null} loading={loading} />
      <Grid
        container
        spacing={2}
        justify="center"
        alignItems="center"
        direction="row"
      >
        <Grid item xs={8} lg={12}>
          <GridList>
            <GridListTile key="Subheader" cols={2} style={{ height: "auto" }}>
              <Grid
                container
                justify="flex-end"
                css={css`
                  && {
                    margin-top: 24px;
                  }
                `}
              ></Grid>
              <Typography
                variant="h6"
                align={"center"}
                css={css`
                  margin-bottom: 35px;
                `}
              >
                Consulta de Reembolso
              </Typography>
            </GridListTile>
          </GridList>

          {/* Filters */}
          <Grid container justify="space-evenly">
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Grid item>
                <KeyboardDatePicker
                  size={"small"}
                  label="Data início"
                  cancelLabel="Cancelar"
                  value={selectedDateBegin}
                  format="DD/MM/YYYY"
                  onChange={handleDateBeginChange}
                  maxDate={selectedDateEnd}
                  maxDateMessage="Data deve ser menor ou igual a data final"
                  invalidDateMessage="Data inválida"
                  disableFuture
                  css={{
                    maxWidth: 150,
                  }}
                />
              </Grid>
              <Grid item>
                <KeyboardDatePicker
                  size={"small"}
                  label="Data final"
                  cancelLabel="Cancelar"
                  value={selectedDateEnd}
                  format="DD/MM/YYYY"
                  onChange={handleDateEndChange}
                  minDate={selectedDateBegin}
                  minDateMessage="Data deve ser maior ou igual a data inicial"
                  invalidDateMessage="Data inválida"
                  disableFuture
                  css={{
                    maxWidth: 150,
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
            <Grid item>
              <FormControl
                css={css`
                  && {
                    min-width: 200px;
                  }
                `}
              >
                <Autocomplete
                  disableClearable={false}
                  autoComplete
                  autoSelect
                  style={{ width: "100%" }}
                  multiple={false}
                  loading={loading}
                  disabled={loading}
                  value={selectedType}
                  options={Object.values(ConciliationTypeDisplay)}
                  getOptionLabel={(option: any) => option}
                  renderOption={(option: any) => {
                    return <React.Fragment>{option}</React.Fragment>;
                  }}
                  onChange={(event: any, value: any) => {
                    setSelectedType(value);
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      style={{ width: "100%" }}
                      variant="outlined"
                      label="Tipo"
                      placeholder="Tipo"
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl
                css={css`
                  && {
                    min-width: 200px;
                  }
                `}
              >
                <AppSelector
                  loading={loading}
                  appsList={availableApps}
                  appId={selectedApp?.id}
                  onChange={handleChangeApp}
                  inputRef={() => {}}
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl
                css={css`
                  && {
                    min-width: 200px;
                  }
                `}
              >
                <MerchantSelector
                  loading={loading}
                  merchantList={availableMerchants}
                  merchantIds={selectedMerchants}
                  multiple={false}
                  value={selectedMerchants[0]}
                  onChange={handleMerchantChange}
                />
              </FormControl>
            </Grid>
            <Grid item>
              <Button
                onClick={() => {
                  handleRunGetConciliation();
                }}
                fullWidth
                color="secondary"
                startIcon={<SearchIcon />}
                disabled={!isValid() || loading}
                css={css`
                  && {
                    margin-top: 10px;
                    font-weight: bold;
                  }
                `}
              >
                PESQUISAR
              </Button>
            </Grid>
          </Grid>

          {/* Data Table Transações */}
          <Grid container spacing={2} direction="row">
            <TransactionsRefunds
              selectedType={parseConsolidationTypes(selectedType)}
              stateConciliations={stateConciliations}
              loading={loading}
              filters={{
                selectedDateBegin,
                selectedDateEnd,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default Screen;
