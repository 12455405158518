export default () => {
  const { token } = JSON.parse(localStorage.getItem("user") || "{}");

  return token;
};

export const isUserAllowedToAccessOrigin = (decodedToken: any) => {
  if (document.location.origin.includes("http://localhost:")) return true;
  const { user } = decodedToken || {};
  const { activeMerchant } = user || {};
  const origin = activeMerchant.origin.trim();
  const appHostOrigin = (document.location.origin || "").trim();
  const allowed = appHostOrigin.includes(origin);
  return allowed;
};
