import * as Yup from "yup";

export default Yup.object().shape({
  email: Yup.string()
    .email("E-mail deve ser válido")
    .required("Campo obrigatório"),
  password: Yup.string().required("Campo obrigatório")
});

export const recoverPassword = Yup.object().shape({
  email: Yup.string()
    .email("E-mail deve ser válido")
    .required("Campo obrigatório")
});
