import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { css, jsx } from "@emotion/core";

import { colors } from "shared/configs/styles.config";

/** @jsx jsx */




const useStyles = makeStyles({
  paymentTypeSelected: {
    border: "1px solid",
    padding: "3px",
    borderLeftWidth: "thick",
    borderColor: colors.secondary,
  },
  paymentTypeOff: {
    border: "1px solid",
    padding: "3px",
    borderLeftWidth: "thick",
    borderColor: "rgba(0, 0, 0, 0.23)",
  },
  paymentTypeLabelSelected: {
    color: colors.secondary,
  },
  paymentTypeLabelOff: {
    color: "rgba(0, 0, 0, 0.54)",
  },
});

const ForwardPaymentField: React.FC<any> = ({
  enable,
  loading,
  isForwardPayment,
  setPaymentType,
  transactionPending,
  transactionInstallments,
  setTransactionInstallments,
  transactionValue,
  currencyFormatter,
}) => {
  const classes = useStyles();

  if (!enable) return <div></div>;

  return (
    <div>
      <Grid
        container
        justify="center"
        css={css`
          && {
            margin-top: 24px;
          }
        `}
      >
        <Grid
          item
          xs={12}
          sm={6}
          lg={6}
          css={css`
            && {
              text-align: center;
            }
          `}
        >
          <FormControl>
            <FormLabel>Forma de Pagamento</FormLabel>
            <RadioGroup value={isForwardPayment ? "2" : "1"}>
              <Grid
                container
                css={css`
                  && {
                    margin-top: 10px;
                  }
                `}
              >
                <Grid
                  item
                  className={
                    isForwardPayment
                      ? classes.paymentTypeOff
                      : classes.paymentTypeSelected
                  }
                >
                  <FormControlLabel
                    disabled={loading || transactionPending}
                    value="1"
                    // defaultValue={isForwardPayment ? }
                    control={<Radio />}
                    className={
                      isForwardPayment
                        ? classes.paymentTypeLabelOff
                        : classes.paymentTypeLabelSelected
                    }
                    label="À vista"
                    onChange={event => {
                      setPaymentType(1);
                    }}
                  />
                </Grid>
                <Grid
                  item
                  className={
                    isForwardPayment
                      ? classes.paymentTypeSelected
                      : classes.paymentTypeOff
                  }
                  css={css`
                    && {
                      margin-left: 10px;
                    }
                  `}
                >
                  <FormControlLabel
                    value="2"
                    disabled={loading || transactionPending}
                    control={<Radio />}
                    label="Parcelado"
                    className={
                      isForwardPayment
                        ? classes.paymentTypeLabelSelected
                        : classes.paymentTypeLabelOff
                    }
                    onChange={event => {
                      setPaymentType(2);
                    }}
                  />
                </Grid>
              </Grid>
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      {isForwardPayment && (
        <Grid
          container
          justify="center"
          css={css`
            && {
              margin-top: 24px;
            }
          `}
        >
          <Grid item xs={8} sm={6} lg={6}>
            <TextField
              fullWidth
              required
              label={"Número de parcelas"}
              variant="outlined"
              type="number"
              InputProps={{ inputProps: { min: 2, max: 12 } }}
              disabled={loading || transactionPending}
              value={transactionInstallments}
              onChange={({ target: { value } }: any) => {
                let valueInstallments = Number(value);
                if (valueInstallments > 12) valueInstallments = 12;
                setTransactionInstallments(valueInstallments);
              }}
            />
            <Typography variant="caption" align={"center"}>
              Valores: {transactionInstallments}X{" "}
              {transactionValue &&
                currencyFormatter(
                  Number(transactionValue) / transactionInstallments,
                )}
            </Typography>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default ForwardPaymentField;
